import { Alert, Button, Collapse, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid, IconButton, Paper, Snackbar, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, TextField, Tooltip } from '@mui/material'
import React from 'react'
import SearchIcon from '@mui/icons-material/Search';
import AddBoxOutlinedIcon from '@mui/icons-material/AddBoxOutlined';
import CustomPaginationActionsTable from '../../table/PaginationTable';
import Constantes from '../../Constantes';
import CloseIcon from '@mui/icons-material/Close';
import QuestionMarkOutlinedIcon from '@mui/icons-material/QuestionMarkOutlined';
import CheckCircleOutlinedIcon from '@mui/icons-material/CheckCircleOutlined';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import DoneOutlineOutlinedIcon from '@mui/icons-material/DoneOutlineOutlined';
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';
import EditarEquipos from './editarEquipos';
import { Close, Delete, EditOutlined, Output, RemoveShoppingCart } from '@mui/icons-material';
import EgresoEquipo from './egresoEquipo';
import DlgEgresoEquipo from './egresos/dlgEgresoEquipo';

function Equipos() {
    const [buscar, setBuscar] = React.useState("");
    const [rows, setRows] = React.useState([]);
    const [showAlert, setShowAlert] = React.useState(false);
    const [alertMessage, setAlertMessage] = React.useState("");
    const [dialogNewEgreso, setDialogNewEgreso] = React.useState(false);
    const [listarDatosEgreso, setListarDatosEgreso] = React.useState([]);
    const [dialogEquipo, setDialogEquipo] = React.useState({
        show: false,
        titulo: "",
        fk_cmp_equipo: ""
    });
    const [dialogEliminado, setDialogEliminado] = React.useState({
        show: false,
        titulo: "",
        fk_cmp_equipo: "",
        message: "",
        eliminado: false,
    });
    const [alertGeneral, setAlertGeneral] = React.useState({
        show: false,
        message: '',
        severity: 'warning'
    });
    const [equipoStock, setEquipoStock] = React.useState({
        fk_cmp_equipo: "",
        stock: "",
        nombre: "",
    });
    const [dialogEgreso, setDialogEgreso] = React.useState(false);
    const columns = ['CODIGO', 'NOMBRE', 'STOCK', 'UND.', 'VIDA UTIL', 'ESTADO STOCK'];
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };
    const handleChange = (event) => {
        setBuscar(event.target.value);
    }
    const [showConfirmarEliminar, setShowConfirmarEliminar] = React.useState(false);
    const search = async () => {
        const rptadetalles = await fetch(`${Constantes.RUTA_API}listar/listarEquiposBusqueda.php?search=${buscar}`)
        const detalles = await rptadetalles.json();
        if (detalles.length > 0) {
            setRows(detalles);
        } else {
            setRows(detalles);
            setShowAlert(true);
            setAlertMessage("No se encontraron resultados.");
            setTimeout(() => {
                setShowAlert(false);
                setAlertMessage("");
            }, 3000);
        }
    }
    const ejecutarAccion = (accion, codigo) => {
        if (accion === "EDIT") {
            setDialogEquipo({
                show: true,
                titulo: "EDITAR",
                fk_cmp_equipo: codigo,
            })
        }
        if (accion === "DELETE") {
            setDialogEquipo({
                show: false,
                titulo: "DELETE",
                fk_cmp_equipo: codigo,
            })
            setShowConfirmarEliminar(true);
        }
        if (accion === "NEW") {
            setDialogEquipo({
                show: true,
                titulo: "NUEVO",
                fk_cmp_equipo: codigo,
            })
        }
    }
    const eliminarEquipo = async () => {

        const eliminar = await fetch(`${Constantes.RUTA_API}mantenimiento/eliminarEquipo.php?codigo=${dialogEquipo.fk_cmp_equipo}`);
        const rptaEliminar = await eliminar.json();
        if (rptaEliminar.executed) {
            setDialogEliminado({
                show: true,
                titulo: "ELIMINADO",
                fk_cmp_equipo: dialogEquipo.fk_cmp_equipo,
                message: "Fue eliminado con exito.",
                eliminado: true,
            })
        } else {
            setDialogEliminado({
                show: true,
                titulo: "ERROR",
                fk_cmp_equipo: dialogEquipo.fk_cmp_equipo,
                message: "No se pudo eliminar, tiene compras relacionadas.",
                eliminado: false,
            })
        }
        setTimeout(() => {
            cerrarEliminado();
        }, 3000);
    }
    const cerrarEliminado = () => {
        setDialogEliminado({
            show: false,
            titulo: "",
            fk_cmp_equipo: "",
            message: "",
            eliminado: false,
        })
        setShowConfirmarEliminar(false);
        search();
    }
    const cerrarEquipo = () => {
        setDialogEquipo({
            show: false,
            titulo: "",
            fk_cmp_equipo: "",
        })
    }
    const handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            search();
        }
    }
    const enviarEquipoStock = (codigo, stock, nombre) => {
        setDialogNewEgreso(true);
        setEquipoStock({
            fk_cmp_equipo: codigo,
            stock: stock,
            nombre: nombre,
        })
    }
    const cerrarDialogEgreso = () => {
        setDialogEgreso(false);
    }
    const closeNuevoEgreso = () => {
        setDialogNewEgreso(false);
    }
    const confirNuevoStock = () => {
        setAlertGeneral({ message: 'AGREGADO CORRECTAMENTE', severity: 'success', show: true });
        setTimeout(() => {
            setAlertGeneral({ message: '', severity: 'warning', show: false });
        }, 3000);
    }
    const ErrConfirNuevoStock = () => {
        setAlertGeneral({ message: 'NO HAY STOCK SUFICIENTE PARA PROCEDER', severity: 'warning', show: true });
        setTimeout(() => {
            setAlertGeneral({ message: '', severity: 'warning', show: false });
        }, 3000);
    }
    return (
        <Grid container spacing={1}>
            <Snackbar open={alertGeneral.show} onClose={() => setAlertGeneral({ show: false, message: '', severity: 'warning' })} anchorOrigin={{ vertical: 'top', horizontal: 'right' }}>
                <Alert onClose={() => setAlertGeneral({ show: false, message: '', severity: 'warning' })} severity={alertGeneral.severity} sx={{ width: '100%' }}>
                    {alertGeneral.message}
                </Alert>
            </Snackbar>
            <Dialog open={dialogNewEgreso} onClose={() => setDialogNewEgreso(false)} maxWidth="xs">
                <DialogTitle sx={{ textAlign: "center" }}>
                    INGRESE LA CANTIDAD A EGRESAR
                    <IconButton
                        aria-label="close"
                        onClick={() => setDialogNewEgreso(false)}
                        sx={{
                            position: 'absolute',
                            right: 8,
                            top: 8,
                            color: (theme) => theme.palette.grey[500],
                        }}
                    >
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <DialogContent>
                    <DlgEgresoEquipo closeNuevoEgreso={closeNuevoEgreso} cerrarDialogEgreso={cerrarDialogEgreso} cmp_equipo={equipoStock} search={search} confirNuevoStock={confirNuevoStock} ErrConfirNuevoStock={ErrConfirNuevoStock} />
                </DialogContent>
            </Dialog>
            <Dialog open={dialogEquipo.show} maxWidth='md'>
                <DialogTitle>
                    {dialogEquipo.titulo} EQUIPO
                    <IconButton
                        aria-label="close"
                        onClick={cerrarEquipo}
                        sx={{
                            position: 'absolute',
                            right: 8,
                            top: 8,
                            color: (theme) => theme.palette.grey[500],
                        }}
                    >
                        <Close />
                    </IconButton>
                </DialogTitle>
                <DialogContent>
                    <EditarEquipos fk_cmp_equipo={dialogEquipo.fk_cmp_equipo} parentCloseChild={cerrarEquipo} search={search} />
                </DialogContent>
            </Dialog>
            <Dialog open={showConfirmarEliminar}>
                <DialogTitle>
                    ELIMINAR <QuestionMarkOutlinedIcon />
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>{`Está a punto de eliminar un equipo (${dialogEquipo.fk_cmp_equipo}), esta accion no se puede deshacer. ¿Desea continuar?`}</DialogContentText>
                </DialogContent>
                <DialogActions sx={{ alignContent: 'center' }}>
                    <Button variant='outlined' color='success' startIcon={<CheckCircleOutlinedIcon />} onClick={eliminarEquipo}>SI</Button>
                    <Button variant='outlined' color='error' startIcon={<CancelOutlinedIcon />} onClick={() => setShowConfirmarEliminar(false)}>NO</Button>
                </DialogActions>
            </Dialog>
            <Dialog open={dialogEliminado.show}>
                <DialogTitle>
                    {dialogEliminado.eliminado ? <DoneOutlineOutlinedIcon /> : <ErrorOutlineOutlinedIcon />}
                    {dialogEliminado.titulo}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>{`El equipo ${dialogEliminado.fk_cmp_equipo}, ${dialogEliminado.message}`}</DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button variant='outlined' color='primary' startIcon={<CheckCircleOutlinedIcon />} onClick={cerrarEliminado}>ACEPTAR</Button>
                </DialogActions>
            </Dialog>
            <Grid item xs={12} sm={12} md={8}>
                <TextField
                    id="busqueda"
                    label="Nombre:"
                    type="text"
                    placeholder='Buscar el equipo'
                    inputProps={{ style: { textTransform: "uppercase" } }}
                    value={buscar}
                    fullWidth
                    size='small'
                    onChange={handleChange}
                    onKeyDown={handleKeyDown}
                />
            </Grid>
            <Grid item xs={5} sm={4} md={2} textAlign='center'>
                <Button fullWidth onClick={search} variant='contained' startIcon={<SearchIcon fontSize='large' />}> BUSCAR </Button>
            </Grid>
            <Grid item xs={5} sm={4} md={2} textAlign='center'>
                <Button fullWidth onClick={() => ejecutarAccion("NEW", "STK2-00000000")} color='success' variant='contained' startIcon={<AddBoxOutlinedIcon fontSize='large' />}> NUEVO </Button>
            </Grid>
            <Grid item xs={12}>
                <Collapse in={showAlert}>
                    <Alert
                        action={
                            <IconButton
                                aria-label="close"
                                color="inherit"
                                size="small"
                                onClick={() => {
                                    setShowAlert(false);
                                }}
                            >
                                <CloseIcon fontSize="inherit" />
                            </IconButton>
                        }
                        sx={{ mb: 2 }}
                        severity="warning"
                    >
                        {alertMessage}
                    </Alert>
                </Collapse>
            </Grid>
            <Grid item xs={12}>
                <Paper sx={{ width: '100%', mb: 2, mt: 2 }}>
                    <TableContainer>
                        <Table stickyHeader>
                            <TableHead>
                                <TableRow>
                                    {columns.map((item) => (
                                        <TableCell key={item} style={{ fontWeight: 'bold' }}>
                                            {item}
                                        </TableCell>
                                    ))}
                                    <TableCell></TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {
                                    (rowsPerPage > 0
                                        ? rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                        : rows
                                    ).map((row, index) => (
                                        <TableRow key={index}>
                                            <TableCell>{row.codigo}</TableCell>
                                            <TableCell>{row.nombre}</TableCell>
                                            <TableCell align='right'>{row.stock_actual}</TableCell>
                                            <TableCell >{row.unidad}</TableCell>
                                            <TableCell align='center'>{row.vida_util}</TableCell>
                                            <TableCell>{row.estado_stock}</TableCell>
                                            <TableCell align="right">
                                                <Tooltip title="EGRESOS" placement='top'>
                                                    <IconButton color="info" onClick={() => enviarEquipoStock(row.codigo, row.stock_actual, row.nombre)} ><Output /></IconButton>
                                                </Tooltip>
                                                <Tooltip title="EDITAR MATERIAL" placement='top'>
                                                    <IconButton color="info" onClick={() => ejecutarAccion("EDIT", row.codigo)}><EditOutlined /></IconButton>
                                                </Tooltip>
                                                <Tooltip title="ELIMINAR MATERIAL" placement='top'>
                                                    <IconButton color="error" onClick={() => ejecutarAccion("DELETE", row.codigo)}><Delete /></IconButton>
                                                </Tooltip>
                                            </TableCell>
                                        </TableRow>
                                    ))
                                }
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <TablePagination
                        rowsPerPageOptions={[10, 25, 50]}
                        component="div"
                        labelRowsPerPage="Filas por columnas"
                        count={rows.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                </Paper>
            </Grid>
        </Grid>
    )
}

export default Equipos