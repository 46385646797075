import { Button, Grid, IconButton, Table, TableBody, TableCell, TableContainer, TableFooter, TableHead, TablePagination, TableRow, TextField, Tooltip } from '@mui/material'
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import React from 'react'
import Constantes from '../../Constantes';

function BuscarRegistroGastos({ childSeleccion }) {
  const [parametrosBusqueda, setParametrosBusqueda] = React.useState({ codigo: "", fecha: "", referencia: "" })
  const [resultados, setResultados] = React.useState([]);
  const buscarRegistros = async () => {
    const fetchResultado = await fetch(`${Constantes.RUTA_API}listar/listarRegistroGasto.php`, {
      method: "POST",
      body: JSON.stringify(parametrosBusqueda),
    });
    const resultadoDB = await fetchResultado.json();
    setResultados(resultadoDB);
  };
  const handleChangeParametro = (e) => {
    setParametrosBusqueda((paramAnt) => ({
      ...paramAnt,
      [e.target.id]: e.target.value.toUpperCase(),
    }))
  }
  return (
    <Grid container spacing={2}>
      <Grid item xs={6} sm={3}>
        <TextField
          label="Codigo: "
          fullWidth
          variant='filled'
          type="text"
          id='codigo'
          value={parametrosBusqueda.codigo}
          onChange={handleChangeParametro}
          placeholder='OTRO-00000000'
        />
      </Grid>
      <Grid item xs={6} sm={3}>
        <TextField
          label="Fecha: "
          fullWidth
          variant='filled'
          type="date"
          id='fecha'
          InputLabelProps={{
            shrink: true,
          }}
          value={parametrosBusqueda.fecha}
          onChange={handleChangeParametro}
        />
      </Grid>
      <Grid item xs={9} sm={4}>
        <TextField
          label="Referencia: "
          fullWidth
          variant='filled'
          type="text"
          id='referencia'
          value={parametrosBusqueda.referencia}
          onChange={handleChangeParametro}
          placeholder='OTRO-00000000'
        />
      </Grid>
      <Grid item xs={3} sm={2} sx={{ mt: 1 }}>
        <Tooltip title='Buscar'>
          <Button variant='contained' startIcon={<SearchOutlinedIcon />} onClick={buscarRegistros}>BUSCAR</Button>
        </Tooltip>
      </Grid>
      <Grid item xs={12}>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell style={{ fontWeight: 'bold' }}>CODIGO</TableCell>
                <TableCell style={{ fontWeight: 'bold' }}>FECHA</TableCell>
                <TableCell style={{ fontWeight: 'bold' }}>REFERENCIA</TableCell>
                <TableCell style={{ fontWeight: 'bold' }}>GASTO</TableCell>
                <TableCell style={{ fontWeight: 'bold' }}>CANT.</TableCell>
                <TableCell style={{ fontWeight: 'bold' }}>P.U.</TableCell>
                <TableCell style={{ fontWeight: 'bold' }}>TOTAL</TableCell>
                <TableCell style={{ fontWeight: 'bold' }}></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {resultados.map((res) => {
                return (
                  <TableRow key={res.codigo}>
                    <TableCell>{res.codigo}</TableCell>
                    <TableCell>{res.fecha}</TableCell>
                    <TableCell>{res.referencia}</TableCell>
                    <TableCell>{res.gasto}</TableCell>
                    <TableCell>{res.cantidad}</TableCell>
                    <TableCell>{res.precio_unitario}</TableCell>
                    <TableCell>{res.total}</TableCell>
                    <TableCell>
                      <Tooltip title='Editar Registro'>
                        <IconButton color='info' onClick={() => (childSeleccion(res.codigo))}><EditOutlinedIcon /></IconButton>
                      </Tooltip>
                    </TableCell>
                  </TableRow>
                )
              })}
            </TableBody>
            {/* <TableFooter>
              <TablePagination>

              </TablePagination>
            </TableFooter> */}
          </Table>
        </TableContainer>
      </Grid>
    </Grid>
  )
}

export default BuscarRegistroGastos