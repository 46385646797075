import React, { useEffect, useState } from 'react';
import { Line } from "react-chartjs-2";
import {
    Chart as Chartjs,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Filler,
    BarElement,
    Title,
    Tooltip,
    Legend,
} from "chart.js"
import html2canvas from 'html2canvas';
import { Button, Grid, Stack } from '@mui/material';

Chartjs.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Filler,
    BarElement,
    Title,
    Tooltip,
    Legend,
);
export default function GraficoLineal(props) {
    const imgGrafico = sessionStorage.getItem("imgGrafico");
    const valuesGrafLine = props.valuesGrafLine;
    const [chartData, setChartData] = useState({
        labels: [],
        datasets: [],
    });
    const [chartOptions, setChartOptions] = useState({});
    const exportImg = () => {
        window.scrollTo(0, 0);
        const input = document.getElementById("export_img");
        html2canvas(input).then(function (canvas) {
            sessionStorage.setItem("imgGrafico", canvas.toDataURL("image/jpeg", 0.9));
        });
        props.alertChartBarrAgreg();
    }
    const quitarImg = () => {
        sessionStorage.removeItem("imgGrafico");
        props.alertChartBarrRemov();
    }
    useEffect((e) => {
        setChartData({
            labels: valuesGrafLine.tags,
            // labels: labelsRet(),
            datasets: [
                {
                    label: 'VENTAS',
                    data: valuesGrafLine.ventas,
                    borderColor: "rgb(75, 192, 192)",
                    backgroundColor: "rgb(75, 192, 192,0.5)",
                    fill: false,
                    tension: 0.3,
                    // backgroundColor: transparentize(CHART_COLORS.red, 0.5),
                },
                {
                    label: 'GASTOS',
                    data: valuesGrafLine.gastos,
                    borderColor: "rgb(255, 99, 132)",
                    backgroundColor: "rgb(255, 99, 132,0.5)",
                    fill: false,
                    tension: 0.4,
                },
                {
                    label: 'RES',
                    data: valuesGrafLine.res,
                    borderColor: "rgb(54, 162, 235)",
                    backgroundColor: "rgb(54, 162, 235,0.5)",
                    fill: false,
                    tension: 0.5,
                }
            ]
        });
        setChartOptions({
            responsive: true,
            plugins: {
                legend: {
                    position: "top"
                },
                title: {
                    display: true,
                    text: valuesGrafLine.message,
                },
            },
        });
    }, [valuesGrafLine]);
    return (
        <>
            {/* height: { xs: "1000px", md: "100vh" } */}
            <Grid container sx={{ justifyContent: "center" }} >
                <Grid item xs={12}>
                    <div className='export_img' id='export_img' >
                        <Line data={chartData} options={chartOptions} />
                    </div>
                </Grid>
                <Grid item xs={12} sx={{ padding: "25px 0" }}>
                    <Stack spacing={0.3} direction="row" sx={{ justifyContent: "center" }}>
                        {imgGrafico === null &&
                            <Button variant="contained" size="small" color='success' onClick={() => exportImg()}>AGREGAR GRAFICO A PDF</Button>
                        }
                        {imgGrafico !== null &&
                            <Button variant="contained" size="small" color='warning' onClick={() => quitarImg()}>QUITAR GRAFICO DE PDF</Button>
                        }
                    </Stack>
                </Grid>
            </Grid>
        </>

    )
}
