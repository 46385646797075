import { Autocomplete, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControl, Grid, IconButton, InputLabel, MenuItem, Select, TextField, ToggleButton, ToggleButtonGroup } from '@mui/material'
import CancelIcon from '@mui/icons-material/Cancel';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import DeleteIcon from '@mui/icons-material/Delete';
import Constantes from '../Constantes';
import React, { useEffect } from 'react'
import { CheckCircleOutline, Close } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';

function EditarTratamiento(props) {
    useEffect((e) => {
        const fillFamGen = async () => {
            const famGenTratamiento = await fetch(`${Constantes.RUTA_API}listar/listarFamiliaGeneralTratamiento.php`);
            const FGTratDB = await famGenTratamiento.json();
            setTratamiento({
                codigo: "NEW",
                nombre: "",
                descripcion: "",
                fk_ven_familia_especifica: "",
            });
            setListFamGen(FGTratDB);
        }
        const showDialog = () => {
            setShow(props.mostrar);
            fillFamGen();
        }
        showDialog();
    }, [props.mostrar])
    const [accionTratamiento, setAccionTratamiento] = React.useState("NUEVO");
    const [tratamiento, setTratamiento] = React.useState({
        codigo: "NEW",
        nombre: "",
        descripcion: "",
        fk_ven_familia_especifica: "",
    })
    const [listFamGen, setListFamGen] = React.useState([]);
    const [listFamEsp, setListFamEsp] = React.useState([]);
    const [fk_ven_familia_general, setFamGen] = React.useState('');
    const [fk_ven_familia_especifica, setFamEsp] = React.useState('');
    const [nombreTratamiento, setNombreTramiento] = React.useState({
        error: false,
        helpertext: ''
    });
    const [tratamientos, setTratamientos] = React.useState([]);
    const handleChangeAccionTratamiento = (event) => {
        setAccionTratamiento(event.target.value);
        setFamGen('');
        setFamEsp('');
        setTratamiento({
            codigo: "NEW",
            nombre: "",
            descripcion: "",
            fk_ven_familia_especifica: "",
        });
    }
    const [showAddTratamiento, setShow] = React.useState(false);
    const [showConfirmacion, setConfirmacion] = React.useState(false);
    const [loadingNuevo, setLoadingNuevo] = React.useState(false);
    const [loadingEliminar, setLoadingEliminar] = React.useState(false);
    const [messageConfirmacion, setMessageConfirm] = React.useState("");
    const handleChangeFamGenTrat = async (event) => {
        if (event) {
            let famGen = event.target.value
            setFamGen(famGen);
            const famEspTratamiento = await fetch(`${Constantes.RUTA_API}listar/listarFamiliaEspecificaTratamientoXFG.php?codigo=${famGen}`);
            const FETratDB = await famEspTratamiento.json();
            setListFamEsp(FETratDB);
        }
    }
    const handleChangeFamEspTrat = async (event) => {
        let valor = event.target.value;
        setFamEsp(valor);
        const tratamientoActualizado = tratamiento;
        tratamientoActualizado["fk_ven_familia_especifica"] = valor;
        console.log(tratamientoActualizado);
        setTratamiento(tratamientoActualizado);
        if (accionTratamiento === "EDITAR") {
            const tratamientos = await fetch(`${Constantes.RUTA_API}listar/listarTratamientosXFE.php?codigo=${valor}`);
            const tratDB = await tratamientos.json();
            setTratamientos(tratDB);
        } else {
            setTratamientos([]);
        }
    }
    const handleChangeTratamiento = (event) => {
        let id = event.target.id;
        let valor = event.target.value;
        if (id === "nombre") {
            if (valor !== "") {
                setNombreTramiento({ error: false, helpertext: '' });
            } else {
                setNombreTramiento({ error: true, helpertext: 'Nombre tratamiento obligatorio' });
            }
        }
        setTratamiento((tratamientoAnt) => ({
            ...tratamientoAnt,
            [id]: valor,
        }));
    }
    const handleChangeTratamientoSeleccionado = (event, values) => {
        setTratamiento(values);
    }
    const saveTratamiento = async () => {
        if (tratamiento.nombre !== "") {
            setLoadingNuevo(true);
            const guardarTratamiento = await fetch(`${Constantes.RUTA_API}/mantenimiento/guardarTratamiento.php`, {
                method: "POST",
                body: JSON.stringify({
                    codigo: tratamiento.codigo,
                    nombre: tratamiento.nombre.toUpperCase(),
                    descripcion: tratamiento.descripcion !== null ? tratamiento.descripcion.toUpperCase() : '',
                    fk_ven_familia_especifica: tratamiento.fk_ven_familia_especifica,
                }),
            });
            const guardarJS = await guardarTratamiento.json();
            if (guardarJS.guardado) {
                setLoadingNuevo(false);
                setAccionTratamiento('NUEVO');
                setMessageConfirm("Tratamiento guardado con exito");
                setConfirmacion(true);
                setTimeout(() => {
                    setConfirmacion(false);
                    parentHideDialog();
                }, 2000);
            }
        } else {
            setNombreTramiento({ error: true, helpertext: 'Nombre tratamiento obligatorio' });
        }
    }
    const deleteTratamiento = async () => {
        setLoadingEliminar(true);
        const eliminarTratamiento = await fetch(`${Constantes.RUTA_API}mantenimiento/eliminarTratamiento.php?codigo=${tratamiento.codigo}`);
        const guardarJS = await eliminarTratamiento.json();
        if (guardarJS.executed) {
            setLoadingEliminar(false);
            setMessageConfirm("Tratamiento eliminado con exito");
            setConfirmacion(true);
            setTimeout(() => {
                setConfirmacion(false);
                parentHideDialog();
            }, 2000);
        }
        else {
            setMessageConfirm("Tratamiento eliminado con exito");
            setConfirmacion(true);
            setTimeout(() => {
                setConfirmacion(false);
                parentHideDialog();
            }, 2000);
        }
    }
    const parentHideDialog = () => {
        setShow(false);
        setConfirmacion(false);
        props.parentHideDialog(false);
        setFamEsp('');
        setFamGen('');
    }
    return (
        <>
            <Dialog open={showConfirmacion}>
                <DialogTitle style={{ textAlign: 'center' }}>
                    <CheckCircleOutline color="success" />
                </DialogTitle>
                <DialogContent style={{ textAlign: 'center' }}>
                    <DialogContentText>{messageConfirmacion}</DialogContentText>
                </DialogContent>
                <DialogActions style={{ justifyContent: 'center' }}>
                    <Button onClick={parentHideDialog} startIcon={<CheckCircleIcon />} variant='contained' color='success'>Aceptar</Button>
                </DialogActions>
            </Dialog>
            <Dialog open={showAddTratamiento}>
                <DialogTitle>
                    Mantenimiento de Tratamientos
                    <IconButton
                        aria-label="close"
                        onClick={parentHideDialog}
                        sx={{
                            position: 'absolute',
                            right: 8,
                            top: 8,
                            color: (theme) => theme.palette.grey[500],
                        }}
                    >
                        <Close />
                    </IconButton>
                </DialogTitle>
                <DialogContent>
                    <Grid container spacing={1}>
                        <Grid item md={12} xs={12}>
                            <ToggleButtonGroup color='primary' exclusive onChange={handleChangeAccionTratamiento} value={accionTratamiento}>
                                <ToggleButton value={"NUEVO"} key={"NUEVO"}> NUEVO</ToggleButton>
                                <ToggleButton value={"EDITAR"} key={"EDITAR"}> EDITAR</ToggleButton>
                            </ToggleButtonGroup>
                        </Grid>
                    </Grid>
                    <Grid container spacing={1}>
                        <Grid item md={6} xs={12}>
                            <FormControl fullWidth margin="normal">
                                <InputLabel id="select-fam-general-label">Familia General:</InputLabel>
                                <Select
                                    labelId="select-fam-general-lb"
                                    id="select-fam-general"
                                    value={fk_ven_familia_general}
                                    onChange={handleChangeFamGenTrat}
                                    label="Familia General:"
                                    defaultValue=""
                                >
                                    {listFamGen.map((fe) => (
                                        <MenuItem value={fe.codigo} key={fe.codigo}>{fe.nombre}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item md={6} xs={12}>
                            <FormControl fullWidth margin="normal">
                                <InputLabel id="select-fam-especifica-label">Familia Especifica:</InputLabel>
                                <Select
                                    labelId="select-fam-especifica-lb"
                                    id="select-fam-especifica"
                                    value={fk_ven_familia_especifica}
                                    onChange={handleChangeFamEspTrat}
                                    fullWidth
                                    label="Familia especifica:"
                                    defaultValue=""
                                >
                                    {listFamEsp.map((fe) => (
                                        <MenuItem value={fe.codigo} key={fe.codigo}>{fe.nombre}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                        {accionTratamiento === "EDITAR" &&
                            <Grid item xs={12}>
                                <Autocomplete
                                    onChange={handleChangeTratamientoSeleccionado}
                                    options={tratamientos}
                                    getOptionLabel={(option) => option.nombre || ''}
                                    id='fk_ven_tratamiento'
                                    clearOnEscape
                                    value={tratamiento}
                                    isOptionEqualToValue={(option, value) => option.codigo === value.codigo}
                                    renderInput={(params) => (
                                        <TextField {...params} label='Tratamiento' variant='standard' />
                                    )}
                                    renderOption={(props, option) => (
                                        <li {...props} key={option.codigo} >{option.codigo}&nbsp;&nbsp;&nbsp;{option.nombre}</li>
                                    )}
                                />
                            </Grid>
                        }
                    </Grid>
                    <Grid container spacing={1}>
                        <Grid item md={6} xs={12}>
                            <TextField
                                label='Codigo:'
                                placeholder='#'
                                variant='filled'
                                id='codigo'
                                value={tratamiento.codigo}
                                disabled
                                fullWidth
                                margin="normal"
                            />
                        </Grid>
                        <Grid item md={6} xs={12}>
                            <TextField
                                label='Nombre:'
                                placeholder='Nombre del tratamiento.'
                                variant='filled'
                                id='nombre'
                                fullWidth
                                margin="normal"
                                value={tratamiento.nombre}
                                onChange={handleChangeTratamiento}
                                inputProps={{ style: { textTransform: 'uppercase' } }}
                                error={nombreTratamiento.error}
                                helperText={nombreTratamiento.helpertext}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                label='Descripcion:'
                                placeholder='Pequeña descripcion del tratamiento.'
                                variant='filled'
                                id='descripcion'
                                value={tratamiento.descripcion}
                                onChange={handleChangeTratamiento}
                                fullWidth
                                inputProps={{ style: { textTransform: 'uppercase' } }}
                            />
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions style={{ justifyContent: 'center' }}>
                    <LoadingButton loading={loadingNuevo} onClick={saveTratamiento} startIcon={<CheckCircleIcon />} variant='outlined' color='success'>Aceptar</LoadingButton>
                    <Button onClick={parentHideDialog} startIcon={<CancelIcon />} variant='outlined' color='error'>Cancelar</Button>
                    {accionTratamiento === "EDITAR" &&
                        <LoadingButton loading={loadingEliminar} onClick={deleteTratamiento} startIcon={<DeleteIcon />} variant='outlined' color='secondary'>Eliminar</LoadingButton>
                    }
                </DialogActions>
            </Dialog>
        </>
    )
}
export default EditarTratamiento