import { Autocomplete, Button, Divider, Grid, Paper, Table, TableBody, TableCell, TableContainer, TableFooter, TableHead, TablePagination, TableRow, TextField, Tooltip, Typography } from '@mui/material'
import React, { useEffect } from 'react'
import SearchIcon from '@mui/icons-material/Search';
import Constantes from '../../Constantes';
import SyncAltIcon from '@mui/icons-material/SyncAlt';
import { PictureAsPdf, Save, SaveAlt } from '@mui/icons-material';
import ExportPDF from '../export/ExportPDF';
import { PDFDownloadLink, PDFViewer } from '@react-pdf/renderer';
import excel from '../../../images/exportarPdfExcel/excel.png';
import pdf from '../../../images/exportarPdfExcel/pdf.png';
import ExportExcel from '../export/ExportExcel';
import { LoadingButton } from '@mui/lab';

function ReporteRegistrosPagos(props) {
    const fecha = new Date();
    let fechaStr = `${fecha.getFullYear()}-${(fecha.getMonth() + 1).toString().padStart(2, '0')}-${fecha.getDate().toString().padStart(2, '0')}`;
    const [busqueda, setBusqueda] = React.useState("");
    const [fechaDesde, setFechaDesde] = React.useState(fechaStr);
    const [fechaHasta, setFechaHasta] = React.useState(fechaStr);
    const [resultados, setResultados] = React.useState([]);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [page, setPage] = React.useState(0);
    const [pacientes, setPacientes] = React.useState([]);
    const [pacienteSeleccionado, setPacienteSeleccionado] = React.useState([]);
    const [generarPdf, setGenerarPdf] = React.useState(false);
    const [datosEmpresa, setDatosEmpresa] = React.useState({
        ruc: '',
        razon_social: '',
        direccion: ''
    });

    useEffect(() => {
        const loadPacientes = async () => {
            const fetchPacientes = await fetch(`${Constantes.RUTA_API}listar/listarCliente.php`)
            const pacientesDB = await fetchPacientes.json();
            setPacientes(pacientesDB);
        }
        const getDatosEmpresa = async () => {
            const getRuc = await fetch(`${Constantes.RUTA_API}/recuperar/recuperarParametro.php?codigo=RUC`, {
                method: 'GET'
            });
            const response_getRuc = await getRuc.json();
            const getRazon = await fetch(`${Constantes.RUTA_API}/recuperar/recuperarParametro.php?codigo=RAZON_SOCIAL`, {
                method: 'GET'
            });
            const response_getRazon = await getRazon.json();
            // console.log(response_getRazon);
            const getDireccion = await fetch(`${Constantes.RUTA_API}/recuperar/recuperarParametro.php?codigo=DIRECCION`, {
                method: 'GET'
            });
            const response_getDireccion = await getDireccion.json();
            setDatosEmpresa({ ruc: response_getRuc.valor_texto, razon_social: response_getRazon.valor_texto, direccion: response_getDireccion.valor_texto });
        }
        getDatosEmpresa();
        loadPacientes();
    }, [])

    // console.log("busqueda");
    // console.log(datosEmpresa);

    const handleChange = (event) => {
        let valor = event.target.value;
        if (event) {
            if (event.target.id === "fecha_desde") {
                setFechaDesde(valor);
                setGenerarPdf(false);
            }
            if (event.target.id === "fecha_hasta") {
                setFechaHasta(valor);
                setGenerarPdf(false);
            }
        }
    }
    const copyDate = () => {
        setFechaHasta(fechaDesde);
    }
    const buscarPorCliente = async () => {
        const resultado = await fetch(`${Constantes.RUTA_API}reportes/reporteRegistroPorCliente.php?codigo=${pacienteSeleccionado.codigo}`);
        const resultadoDB = await resultado.json();
        setResultados(resultadoDB)
        setGenerarPdf(false);
    }
    const buscarPorFecha = async () => {
        if (fechaDesde !== "" && fechaHasta !== "") {
            const resultado = await fetch(`${Constantes.RUTA_API}reportes/reporteRegistroPorRangoFecha.php?fecha_desde=${fechaDesde}&fecha_hasta=${fechaHasta}`);
            const resultadoDB = await resultado.json();
            setResultados(resultadoDB)
            setPacienteSeleccionado([]);
        } else {
            setResultados([]);
        }
        setGenerarPdf(false);
    }
    const alertPdf = () => {
        props.alertPdf();
    }
    const alertExcel = () => {
        props.alertExcel();
    }
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };
    const handleChangePaciente = (e, values) => {
        setPacienteSeleccionado(values);
        setPage(0);
        buscarPorCliente();
    }
    const colorTable = {
        header: "#1976D2",
        footer: "",
        subtotal: "#6FAEEE",
        total: "#5B9AC8",
        color: "#fff"
    }
    return (
        <Grid>
            <Paper elevation={5} sx={{ marginTop: "10px", p: 1.5 }}>
                <Grid container spacing={1}>
                    <Grid item xs={12}>
                        <Grid container>
                            <Grid item md={12} xs={12}>
                                {/* , borderBottom: "1px solid #949494" */}
                                <Grid container sx={{ justifyContent: "space-between", padding: "5px 0" }}>
                                    <Grid item xs={7} md={4}>
                                        <Typography className="typoColor" gutterBottom align="left" color="text.secondary" sx={{ fontSize: "18px" }}  >
                                            RESUMEN REGISTROS
                                        </Typography>
                                    </Grid>
                                    <Grid item md={4} xs={5}>
                                        <Grid container spacing={0.2} sx={{ justifyContent: "end" }}>
                                            <Grid item xs={6} md={4}>
                                                {resultados.length !== 0 ? generarPdf ?
                                                    <PDFDownloadLink document={<ExportPDF tipo="resReg" paciente={busqueda} datosGeneral={resultados} datosEmpresa={datosEmpresa} dataFechas={{ "fechaDesde": fechaDesde, "fechaHasta": fechaHasta }} />} fileName="REP-RESUMEN-REGISTROS">
                                                        {({ loading }) => (loading ?
                                                            <Grid sx={{ textDecoration: "none" }}>
                                                                <LoadingButton loading loadingPosition="start" startIcon={<Save />} variant="contained" fullWidth sx={{ display: { xs: "none", md: "flex" } }} >
                                                                    GENERANDO
                                                                </LoadingButton>
                                                                <Button variant="contained" fullWidth color="error" sx={{ display: { xs: "flex", md: "none" } }}><PictureAsPdf sx={{ padding: "0 2px" }} /></Button>
                                                            </Grid> :
                                                            <Grid >
                                                                <Button variant="contained" fullWidth color="error" sx={{ display: { xs: "none", md: "flex" } }}><SaveAlt sx={{ padding: "0 2px" }} />DESCARGAR</Button>
                                                                <Tooltip title="DESCARGAR PDF" placement="top">
                                                                    <Button variant="contained" fullWidth color="error" sx={{ display: { xs: "flex", md: "none" } }}><SaveAlt sx={{ padding: "0 2px" }} /></Button>
                                                                </Tooltip>
                                                            </Grid>
                                                        )}
                                                    </PDFDownloadLink> :
                                                    <Grid>
                                                        <Button variant="contained" fullWidth color="error" onClick={() => { setGenerarPdf(true); }} sx={{ display: { xs: "none", md: "flex" } }}><PictureAsPdf sx={{ padding: "0 2px" }} />GENERAR</Button>
                                                        <Tooltip title="GENERAR PDF" placement="top">
                                                            <Button variant="contained" fullWidth color="error" onClick={() => { setGenerarPdf(true); }} sx={{ display: { xs: "flex", md: "none" } }}><PictureAsPdf sx={{ padding: "0 2px" }} /></Button>
                                                        </Tooltip>
                                                    </Grid>
                                                    :
                                                    <Button variant="contained" fullWidth color="error" onClick={() => { alertPdf(); }}><PictureAsPdf sx={{ padding: "0 2px" }} />PDF</Button>
                                                }
                                            </Grid>
                                            <Grid item xs={6} md={4}>
                                                {resultados.length !== 0 ?
                                                    <ExportExcel data={resultados} nameReport="RESUMEN-REGISTROS" tipo="resumenRegistros" />
                                                    :
                                                    <>
                                                        <Tooltip title="EXPORTAR EXCEL" placement='top'>
                                                            <Button variant='contained' fullWidth color="success" startIcon={<img style={{ width: '20px', height: '20px' }} src={excel} />} sx={{ display: { xs: "none", md: "flex" } }} onClick={alertExcel} >EXCEL</Button>
                                                        </Tooltip>
                                                        <Button variant='contained' fullWidth color="success" startIcon={<img style={{ width: '20px', height: '24px' }} src={excel} />} sx={{ display: { xs: "flex", md: "none" } }} onClick={alertExcel} ></Button>
                                                    </>
                                                }
                                                {/* <Button fullWidth variant="contained" color="success" onClick={exportarExcel}><PictureAsPdf sx={{ padding: "0 2px" }} />EXCEL</Button> */}
                                            </Grid>
                                            {/* <Grid item xs={6}><Button variant='contained' color='success' size='large' onClick={exportarPDF} startIcon={<PictureAsPdfOutlinedIcon />}>PDF</Button></Grid> */}
                                        </Grid>
                                    </Grid>

                                </Grid>
                                <Divider sx={{ paddingBottom: "10px" }} />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid container sx={{ padding: "10px 0" }}>
                    </Grid>
                    <Grid container item xs={12} md={5} sx={{ justifyContent: { xs: "center", md: "start" } }}>
                        <Grid item xs={8} md={8}>
                            <Autocomplete
                                onChange={handleChangePaciente}
                                options={pacientes}
                                value={pacienteSeleccionado}
                                getOptionLabel={(option) => option.apellidos_nombres || ""}
                                renderOption={(props, option) => {
                                    return (
                                        <li {...props} key={option.codigo}>
                                            {option.apellidos_nombres}
                                        </li>
                                    );
                                }}
                                inputValue={busqueda}
                                onInputChange={(event, newInputValue) => {
                                    setBusqueda(newInputValue.toUpperCase());
                                }}
                                id="apellidos_nombres"
                                clearOnEscape
                                size='small'
                                renderInput={(params) => (
                                    <TextField {...params} label="Paciente" variant="standard" />
                                )}
                            />
                        </Grid>
                        <Grid item xs={2} md={2} sx={{ pt: { xs: 1, md: 0 } }}>
                            <Tooltip title="Buscar por Paciente">
                                <Button variant='contained' color='primary' size='large' onClick={buscarPorCliente} ><SearchIcon /></Button>
                            </Tooltip>
                        </Grid>
                    </Grid>
                    <Grid container item xs={12} md={7} sx={{ justifyContent: { xs: "center", md: "end" }, pt: 2, pb: 2 }}>
                        <Grid container item xs={12} md={6} spacing={0.3} sx={{ justifyContent: { xs: "center", md: "end" }, pb: { xs: 2, md: 0 } }}>
                            <Grid item xs={8} md={6}>
                                <TextField
                                    id="fecha_desde"
                                    label="Desde:"
                                    type="date"
                                    value={fechaDesde}
                                    fullWidth
                                    size='small'
                                    onChange={handleChange}
                                />
                            </Grid>
                            <Grid item xs={2} md={2} >
                                <Tooltip title="Sincronizar fechas">
                                    <Button variant='contained' color='primary' size='large' onClick={copyDate}><SyncAltIcon /></Button>
                                </Tooltip>
                            </Grid>
                        </Grid>
                        <Grid container item xs={12} md={6} spacing={0.3} sx={{ justifyContent: "center", }}>
                            <Grid item xs={8} md={6}>
                                <TextField
                                    id="fecha_hasta"
                                    label="Hasta:"
                                    type="date"
                                    value={fechaHasta}
                                    fullWidth
                                    size='small'
                                    onChange={handleChange}
                                />
                            </Grid>
                            <Grid item xs={2} md={2}>
                                <Tooltip title="Buscar por Fecha">
                                    <Button variant='contained' color='primary' size='large' onClick={buscarPorFecha}><SearchIcon /></Button>
                                </Tooltip>
                            </Grid>
                        </Grid>


                    </Grid>
                    <Grid item xs={12}>
                        <TableContainer>
                            <Table>
                                <TableHead sx={{ backgroundColor: colorTable.header }}>
                                    <TableRow >
                                        <TableCell sx={{ color: colorTable.color }}>PACIENTE</TableCell>
                                        <TableCell sx={{ color: colorTable.color }}>TRATAMIENTO</TableCell>
                                        <TableCell sx={{ color: colorTable.color }} align="center">CONTROL</TableCell>
                                        <TableCell sx={{ color: colorTable.color }} align="right">CONTADO</TableCell>
                                        <TableCell sx={{ color: colorTable.color }} align="right">TARJETA</TableCell>
                                        <TableCell sx={{ color: colorTable.color }} align="right">DEPOSITO</TableCell>
                                        <TableCell sx={{ color: colorTable.color }} align="center">FECHA</TableCell>
                                        <TableCell sx={{ color: colorTable.color }}>FAMILIA</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {
                                        resultados.length > 0 &&
                                        resultados.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map(detalle => {
                                            return <TableRow key={detalle.id}>
                                                <TableCell >{detalle.paciente}</TableCell>
                                                <TableCell>{detalle.tratamiento}</TableCell>
                                                <TableCell align="center">{detalle.controles}</TableCell>
                                                <TableCell align="right">S/ {detalle.efectivo}</TableCell>
                                                <TableCell align="right">S/ {detalle.tarjeta}</TableCell>
                                                <TableCell align="right">S/ {detalle.deposito}</TableCell>
                                                <TableCell align="center">{detalle.fecha}</TableCell>
                                                <TableCell >{detalle.familia}</TableCell>
                                            </TableRow>
                                        })
                                    }
                                    {
                                        resultados.length === 0 &&
                                        <TableRow key={0}>
                                            <TableCell colSpan={8} align='center'>No se encontraron resultados</TableCell>
                                        </TableRow>
                                    }

                                </TableBody>
                            </Table>
                        </TableContainer>
                        <TablePagination
                            rowsPerPageOptions={[10, 25, 50]}
                            component="div"
                            count={resultados.length}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                    </Grid>

                </Grid>
            </Paper >
            {/* {
                <PDFViewer width="100%" height={600}>
                    <ExportPDF tipo="resReg" paciente={busqueda} datosGeneral={resultados} datosEmpresa={datosEmpresa} dataFechas={{ "fechaDesde": fechaDesde, "fechaHasta": fechaHasta }} />
                </PDFViewer>
            } */}
        </Grid >
    )
}

export default ReporteRegistrosPagos
