import { IconButton, TableCell, TableRow, Link, Tooltip } from '@mui/material';
import React, { Component } from 'react'
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';

class FilaListarCliente extends Component {
  constructor(props) {
    super(props);
    this.state = {
      eliminado: false,
    };
    this.eliminar = this.eliminar.bind(this);
  }

  eliminar = async () => {
    this.props.parentDelete(this.props.cliente.codigo);
  }
  // dialogClienteEdit
  render() {
    return (
      <TableRow key={this.props.cliente.codigo}>
        <TableCell>{this.props.cliente.codigo}</TableCell>
        <TableCell>{this.props.cliente.apellidos_nombres}</TableCell>
        <TableCell>{this.props.cliente.numero_documento}</TableCell>
        <TableCell>{this.props.cliente.telefono_1}</TableCell>
        <TableCell>{this.props.cliente.estado}</TableCell>
        <TableCell>{this.props.cliente.fk_com_referencia}</TableCell>
        <TableCell align="right">
          <Tooltip title="EDITAR CLIENTE" placement='top'>
            <IconButton color="info" onClick={() => this.props.dialogClienteEdit(this.props.cliente.codigo)}>
              <EditIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title="ELIMINAR CLIENTE" placement='top'>
            <IconButton color="error" onClick={this.eliminar} >
              <DeleteIcon />
            </IconButton>
          </Tooltip>
        </TableCell>
      </TableRow>
    )
  }
}

export default FilaListarCliente;