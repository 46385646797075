import { CalendarMonth, Check, Close, Help, Lock, LockOpen, PictureAsPdf, Save, SaveAlt, Undo } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { Alert, Button, Chip, Dialog, DialogActions, DialogContent, DialogTitle, Grid, IconButton, InputAdornment, Paper, Snackbar, TextField, Typography } from '@mui/material';
import { PDFDownloadLink } from '@react-pdf/renderer';
// import { Container } from '@mui/system';
import React, { useEffect, useState } from 'react';
import Constantes from '../Constantes';
import ReporteCajaExcel from './reporte/reporteCajaExcel';
import ReporteCajaPdf from './reporte/reporteCajaPdf';

export default function Caja(props) {
  let userData = JSON.parse(sessionStorage.getItem('logedAppUser'));
  const fecha = new Date();
  const mes = fecha.getMonth() + 1;
  const mesActual = mes <= 9 ? '0' + mes : mes;
  const dia = fecha.getDate() <= 9 ? '0' + fecha.getDate() : fecha.getDate();
  const fechaHoy = `${fecha.getFullYear()}-${mesActual}-${dia}`;
  const [fechaInput, setFechaInput] = useState('');
  const [dialogCajAbierta, setDialogCajAbierta] = useState(false);
  const [datosEmpresa, setDatosEmpresa] = React.useState({});
  const [pagosCajaCobranza, setPagosCajaCobranza] = React.useState([]);
  const [tiposPago, setTiposPago] = React.useState([]);
  const [alertGeneral, setAlertGeneral] = React.useState({
    show: false,
    message: '',
    severity: 'warning'
  });
  const [cajaSubmit, setCajaSubmit] = useState({
    id: 0,
    fk_log_usuario_creado_por: '',
    fecha_apertura: '',
    hora_apertura: '',
    fecha_cierre: '',
    hora_cierre: ''
  });
  const [cajAbierta, setCajAbierta] = useState({
    id: 0,
    fk_log_usuario_creado_por: '',
    fecha_apertura: '',
    hora_apertura: '',
    fecha_cierre: '',
    hora_cierre: ''
  });
  // handles 
  const handleFechaCaja = (e) => {
    const { value } = e.target;
    setFechaInput(value);
    recuperarCajaFecha(value);
  }
  const handleClose = () => {
    props.closeCaja();
  }
  // functions
  const recuperarCajaFecha = async (fecha) => {
    const search_caja = await fetch(`${Constantes.RUTA_API}recuperar/recuperarCajaCobranzaFecha.php?fecha=${fecha}`);
    const response = await search_caja.json();
    if (response !== false) {
      setCajaSubmit(response);
      listartPagosCaja(response.id);
    } else {
      setCajaSubmit({
        id: 0,
        fk_log_usuario_creado_por: '',
        fecha_apertura: '',
        hora_apertura: '',
        fecha_cierre: '',
        hora_cierre: ''
      });
    }
  }
  const irCajaAbierta = async () => {
    const caja_abierta = await fetch(`${Constantes.RUTA_API}listar/listarCajasAbiertas.php`);
    const response_caja_abierta = await caja_abierta.json();
    if (response_caja_abierta !== false) {
      setFechaInput(response_caja_abierta.fecha_apertura);
      setCajaSubmit(response_caja_abierta);
      listartPagosCaja();
    } else {
      setAlertGeneral({ message: 'No existe un caja abierta', severity: 'warning', show: true });
      setTimeout(() => { setAlertGeneral({ show: false, message: '', severity: 'warning' }) }, 3000);
    }
  }
  const abrirCaja = async () => {
    const search_caja = await fetch(`${Constantes.RUTA_API}recuperar/recuperarCajaCobranzaFecha.php?fecha=${fechaInput}`);
    const response = await search_caja.json();
    if (response !== false) {
      const caja_abierta = await fetch(`${Constantes.RUTA_API}listar/listarCajasAbiertas.php`);
      const response_cajabierta = await caja_abierta.json();
      if (response_cajabierta !== false) {
        setCajAbierta(response_cajabierta);
        setDialogCajAbierta(true);
      } else {
        const abrirCaja = await fetch(`${Constantes.RUTA_API}mantenimiento/guardarCajaCobranza.php`, {
          method: 'POST',
          body: JSON.stringify({
            id: response.id,
            fk_log_usuario_creado_por: response.fk_log_usuario_creado_por,
            fecha_apertura: response.fecha_apertura,
            hora_apertura: response.hora_apertura,
            fecha_cierre: '',
            hora_cierre: ''
          })
        });
        const response_abierta = await abrirCaja.json();
        setCajaSubmit({
          id: cajaSubmit.id,
          fk_log_usuario_creado_por: cajaSubmit.fk_log_usuario_creado_por,
          fecha_apertura: cajaSubmit.fecha_apertura,
          hora_apertura: cajaSubmit.hora_apertura,
          fecha_cierre: null,
          hora_cierre: null
        });
        setCajAbierta({
          id: 0,
          fk_log_usuario_creado_por: '',
          fecha_apertura: '',
          hora_apertura: '',
          fecha_cierre: '',
          hora_cierre: ''
        });
      }
    } else {
      const caja_abierta = await fetch(`${Constantes.RUTA_API}listar/listarCajasAbiertas.php`);
      const response_cajabierta = await caja_abierta.json();
      if (response_cajabierta !== false) {
        setCajAbierta(response_cajabierta);
        setDialogCajAbierta(true);
      } else {
        const abrirCaja = await fetch(`${Constantes.RUTA_API}mantenimiento/guardarCajaCobranza.php`, {
          method: 'POST',
          body: JSON.stringify({
            id: cajaSubmit.id,
            fk_log_usuario_creado_por: userData.usuario,
            fecha_apertura: fechaInput,
            hora_apertura: new Date().toLocaleTimeString(),
            fecha_cierre: '',
            hora_cierre: ''
          })
        });
        const response_abierta = await abrirCaja.json();
        setCajaSubmit({
          id: response_abierta.id,
          fk_log_usuario_creado_por: userData.usuario,
          fecha_apertura: fechaInput,
          hora_apertura: new Date().toLocaleDateString(),
          fecha_cierre: null,
          hora_cierre: null
        });
        setCajAbierta({
          id: 0,
          fk_log_usuario_creado_por: '',
          fecha_apertura: '',
          hora_apertura: '',
          fecha_cierre: '',
          hora_cierre: ''
        });
      }
    }
    setAlertGeneral({ message: 'Caja correctamente abierta', severity: 'success', show: true });
    setTimeout(() => { setAlertGeneral({ show: false, message: '', severity: 'warning' }) }, 3000);
  }
  const cerrarCaja = async () => {
    const cerrarCaja = await fetch(`${Constantes.RUTA_API}mantenimiento/guardarCajaCobranza.php`, {
      method: 'POST',
      body: JSON.stringify({
        id: cajaSubmit.id,
        fk_log_usuario_creado_por: cajaSubmit.fk_log_usuario_creado_por,
        fecha_apertura: cajaSubmit.fecha_apertura,
        hora_apertura: cajaSubmit.hora_apertura,
        fecha_cierre: fechaHoy,
        hora_cierre: new Date().toLocaleTimeString()
      })
    });
    const response = await cerrarCaja.json();
    if (response.guardado) {
      setCajaSubmit({
        id: cajaSubmit.id,
        fk_log_usuario_creado_por: cajaSubmit.fk_log_usuario_creado_por,
        fecha_apertura: cajaSubmit.fecha_apertura,
        hora_apertura: cajaSubmit.hora_apertura,
        fecha_cierre: fechaHoy,
        hora_cierre: new Date().toLocaleTimeString()
      });
      setCajAbierta({
        id: 0,
        fk_log_usuario_creado_por: '',
        fecha_apertura: '',
        hora_apertura: '',
        fecha_cierre: '',
        hora_cierre: ''
      });
    }
    setAlertGeneral({ message: 'Caja correctamente cerrada', severity: 'success', show: true });
    setTimeout(() => { setAlertGeneral({ show: false, message: '', severity: 'warning' }) }, 3000);
  }
  const cerrarCajaAbierta = async () => {
    const cerrarCaja = await fetch(`${Constantes.RUTA_API}mantenimiento/guardarCajaCobranza.php`, {
      method: 'POST',
      body: JSON.stringify({
        id: cajAbierta.id,
        fk_log_usuario_creado_por: cajAbierta.fk_log_usuario_creado_por,
        fecha_apertura: cajAbierta.fecha_apertura,
        hora_apertura: cajAbierta.hora_apertura,
        fecha_cierre: fechaHoy,
        hora_cierre: new Date().toLocaleTimeString()
      })
    });
    const response_cerrada = await cerrarCaja.json();
    setCajAbierta({
      id: 0,
      fk_log_usuario_creado_por: '',
      fecha_apertura: '',
      hora_apertura: '',
      fecha_cierre: '',
      hora_cierre: ''
    });
    if (response_cerrada.guardado) {
      let element = {};
      const search_caja = await fetch(`${Constantes.RUTA_API}recuperar/recuperarCajaCobranzaFecha.php?fecha=${fechaInput}`);
      const response = await search_caja.json();
      if (response !== false) {
        element = {
          id: response.id,
          fk_log_usuario_creado_por: response.fk_log_usuario_creado_por,
          fecha_apertura: response.fecha_apertura,
          hora_apertura: response.hora_apertura,
          fecha_cierre: '',
          hora_cierre: ''
        };
      } else {
        element = {
          id: 0,
          fk_log_usuario_creado_por: userData.usuario,
          fecha_apertura: fechaInput,
          hora_apertura: new Date().toLocaleTimeString(),
          fecha_cierre: '',
          hora_cierre: ''
        };
      }
      const abrirCaja = await fetch(`${Constantes.RUTA_API}mantenimiento/guardarCajaCobranza.php`, {
        method: 'POST',
        body: JSON.stringify(element)
      });
      const response_abierta = await abrirCaja.json();
      setCajaSubmit({
        id: element.id,
        fk_log_usuario_creado_por: element.usuario,
        fecha_apertura: element.fecha_apertura,
        hora_apertura: element.hora_apertura,
        fecha_cierre: null,
        hora_cierre: null
      });
    }
    setAlertGeneral({ message: 'Caja correctamente abierta', severity: 'success', show: true });
    setTimeout(() => { setAlertGeneral({ show: false, message: '', severity: 'warning' }) }, 3000);
    setDialogCajAbierta(false);
  }
  const validatePagosPdf = () => {
    if (pagosCajaCobranza.length === 0) {
      setAlertGeneral({ message: 'No se encontraron pagos registrados para la caja selecionada', severity: 'warning', show: true });
      setTimeout(() => { setAlertGeneral({ show: false, message: '', severity: 'warning' }) }, 3000);
    }
  }
  const listartPagosCaja = async (id) => {
    const pagosCajaCobranza = await fetch(`${Constantes.RUTA_API}listar/listarPagosCajaCobranza.php?id=${id}`);
    const response_pagos = await pagosCajaCobranza.json();
    setPagosCajaCobranza(response_pagos);
  }
  useEffect(() => {
    const cajaAbierta = async () => {
      const caja_abierta = await fetch(`${Constantes.RUTA_API}listar/listarCajasAbiertas.php`);
      const response = await caja_abierta.json();
      if (response !== false) {
        setFechaInput(response.fecha_apertura);
        setCajaSubmit(response);
        listartPagosCaja(response.id);
      } else {
        recuperarCajaFecha(fechaHoy);
        setFechaInput(fechaHoy);
      }
    }
    const datosEmprafnt = async () => {
      const getRuc = await fetch(`${Constantes.RUTA_API}/recuperar/recuperarParametro.php?codigo=RUC`, {
        method: 'GET'
      });
      const response_getRuc = await getRuc.json();
      const getRazon = await fetch(`${Constantes.RUTA_API}/recuperar/recuperarParametro.php?codigo=RAZON_SOCIAL`, {
        method: 'GET'
      });
      const response_getRazon = await getRazon.json();
      const getDireccion = await fetch(`${Constantes.RUTA_API}/recuperar/recuperarParametro.php?codigo=DIRECCION`, {
        method: 'GET'
      });
      const response_getDireccion = await getDireccion.json();
      setDatosEmpresa({ ruc: response_getRuc.valor_texto, razon_social: response_getRazon.valor_texto, direccion: response_getDireccion.valor_texto });
    }
    datosEmprafnt();
    cajaAbierta();
  }, []);
  return (
    <>
      <Dialog open={dialogCajAbierta} maxWidth="sm">
        <DialogTitle style={{ textAlign: 'center' }}>
          <Help color="warning" style={{ fontSize: '60px' }} />
        </DialogTitle>
        <DialogContent style={{ textAlign: 'center' }}>
          <Typography variant='h5'>Caja Abierta {cajAbierta.fecha_apertura}</Typography>
          <Typography variant='body1'>¿Estas seguro de cerrar caja?</Typography>
        </DialogContent>
        <DialogActions>
          <Button startIcon={<Lock />} variant="contained" color="success" onClick={cerrarCajaAbierta}>CERRAR CAJA</Button>
          <Button startIcon={<Close />} onClick={() => setDialogCajAbierta(false)} variant="contained" color="error">CANCELAR</Button>
        </DialogActions>
      </Dialog>
      <Snackbar open={alertGeneral.show} autoHideDuration={6000} onClose={() => setAlertGeneral({ show: false, message: '', severity: 'warning' })} anchorOrigin={{ vertical: 'top', horizontal: 'right' }}>
        <Alert onClose={() => setAlertGeneral({ show: false, message: '', severity: 'warning' })} severity={alertGeneral.severity} sx={{ width: '100%' }}>
          {alertGeneral.message}
        </Alert>
      </Snackbar>
      <Grid container spacing={2} sx={{ p: 2.5 }}>
        <Grid item md={7} sm={6} xs={12}>
          <Grid item xs={12}>
            <Typography style={{ fontSize: '18px' }}>ACCIONES DE CAJA</Typography>
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="FECHA DE CAJA"
              fullWidth
              margin='normal'
              type="date"
              value={fechaInput}
              InputProps={{
                startAdornment: (
                  <InputAdornment position='start'>
                    <CalendarMonth />
                  </InputAdornment>
                )
              }}
              onChange={handleFechaCaja}
            />
          </Grid>
          <Grid container spacing={2}>
            {
              cajaSubmit.fecha_cierre !== null || cajaSubmit.fecha_cierre === "" ?
                < Grid item md={6} sm={6} xs={12} style={{ marginTop: '8px' }}>
                  <Button fullWidth startIcon={<LockOpen />} variant='contained' color='success' onClick={abrirCaja}>ABRIR CAJA</Button>
                </Grid> : ''
            }
            {
              cajaSubmit.fecha_cierre === null &&
              <Grid item md={6} sm={6} xs={12} style={{ marginTop: '8px' }}>
                <Button fullWidth startIcon={<Lock />} variant='contained' color='warning' onClick={cerrarCaja}>CERRAR CAJA</Button>
              </Grid>
            }
            {
              cajaSubmit.fecha_cierre !== null ?
                < Grid item md={6} sm={6} xs={12} sx={{ marginTop: '8px' }}>
                  <Button fullWidth startIcon={<Undo />} variant='contained' color='primary' onClick={irCajaAbierta}>IR CAJA ABIERTA</Button>
                </Grid> : ''
            }
            {
              cajaSubmit.fecha_apertura !== "" &&
              <Grid item md={3} sm={6} xs={6} style={{ marginTop: '8px' }}>
                {
                  pagosCajaCobranza.length > 0 ?
                    <PDFDownloadLink style={{ textDecoration: 'none' }} document={<ReporteCajaPdf datosEmpresa={datosEmpresa} datosCaja={cajaSubmit} pagosCajaCobranza={pagosCajaCobranza} tiposPago={tiposPago} setAlertGeneral={setAlertGeneral} />} fileName='RptCajaCobranza'>
                      {({ loading }) => (loading ?
                        <LoadingButton loading loadingPosition="start" startIcon={<PictureAsPdf />} variant="contained" fullWidth>
                          GENERANDO
                        </LoadingButton>
                        :
                        <Button variant="contained" fullWidth color="error" startIcon={<PictureAsPdf />}>pdf</Button>
                      )}
                    </PDFDownloadLink> :
                    <Button fullWidth startIcon={<PictureAsPdf />} variant='contained' color='error' onClick={validatePagosPdf} >PDF</Button>
                }
              </Grid>
            }
            {
              cajaSubmit.fecha_apertura !== "" &&
              <Grid item md={3} sm={6} xs={6} style={{ marginTop: '8px' }}>
                <ReporteCajaExcel pagosCajaCobranza={pagosCajaCobranza} setAlertGeneral={setAlertGeneral} datosCaja={cajaSubmit} />
              </Grid>
            }
          </Grid>
        </Grid>
        <Grid item md={0.5} sm={0.5} sx={{ display: { xs: 'none', sm: 'block', md: 'block' } }}><div style={{ height: '100%', background: 'rgba(0,0,0,0.85)', width: '1px' }}></div></Grid>
        <Grid item md={4} sm={5} xs={12} >
          <Typography style={{ fontSize: '18px' }}>DATOS DE CAJA</Typography>
          <div style={{ display: 'flex', marginTop: '6px' }}>
            <Typography style={{ fontSize: '14px', fontWeight: 'bold' }}>FECHA DE APERTURA:</Typography>
            <Typography style={{ fontsize: '14px', marginLeft: '6px' }}>{cajaSubmit.fecha_apertura !== "" ? cajaSubmit.fecha_apertura : 'NO EXISTE'}</Typography>
          </div>
          <div style={{ display: 'flex', marginTop: '6px' }}>
            <Typography style={{ fontSize: '14px', fontWeight: 'bold' }}>FECHA DE CIERRE:</Typography>
            {
              cajaSubmit.fecha_cierre === null ?
                <Typography style={{ fontsize: '14px', marginLeft: '6px' }}>ABIERTA</Typography> : cajaSubmit.fecha_cierre !== "" ? <Typography style={{ fontsize: '14px', marginLeft: '6px' }}>{cajaSubmit.fecha_cierre}</Typography> : <Typography style={{ fontsize: '14px', marginLeft: '6px' }}>NO EXISTE</Typography>
            }
          </div>
          <div style={{ display: 'flex', marginTop: '6px' }}>
            <Typography style={{ fontSize: '14px', fontWeight: 'bold' }}>USUARIO:  </Typography>
            <Typography style={{ fontsize: '14px', marginLeft: '6px' }}>{cajaSubmit.fk_log_usuario_creado_por !== "" ? cajaSubmit.fk_log_usuario_creado_por : 'NO EXISTE'}</Typography>
          </div>
          <div style={{ display: 'flex', marginTop: '6px' }}>
            <Typography style={{ fontSize: '14px', fontWeight: 'bold', marginRight: '6px', marginTop: '6px' }}>ESTADO: </Typography>
            {
              cajaSubmit.fecha_apertura === '' && cajaSubmit.fecha_cierre === '' ?
                <Chip color="warning" label="NO EXISTE"></Chip> : ''
            }
            {
              cajaSubmit.fecha_apertura !== '' && cajaSubmit.fecha_cierre === null ?
                <Chip color="success" label="ABIERTA"></Chip> : ''
            }
            {
              cajaSubmit.fecha_apertura !== '' && cajaSubmit.fecha_cierre !== null ?
                <Chip color="error" label="CERRADA"></Chip> : ''
            }
          </div>
        </Grid>
      </Grid>
    </>
  )
}
