import { Alert, Box, Button, Grid, IconButton, Paper, Snackbar, Stack, Tab, Table, TableBody, TableCell, TableContainer, TableFooter, TableHead, TableRow, Tooltip, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import Constantes from '../Constantes';
import PreviewIcon from '@mui/icons-material/Preview';
import { LoadingButton, TabContext, TabList, TabPanel } from '@mui/lab'
import { PictureAsPdf, Save, SaveAlt } from '@mui/icons-material';
import excel from '../../images/exportarPdfExcel/excel.png';
import { PDFViewer, PDFDownloadLink } from '@react-pdf/renderer';
import ExportPDF from './export/ExportPDF';
import ExportExcel from './export/ExportExcel';

function RepBrackets() {
    const [resultado, setResultado] = React.useState([]);
    const [famGeneral, setFamGeneral] = React.useState([]);
    const [value, setValue] = React.useState("1");
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    // exportar pdf
    const [datosEmpresa, setDatosEmpresa] = React.useState({
        ruc: '',
        razon_social: '',
        direccion: ''
    });
    const [alertGeneralPdf, setAlertGeneralPdf] = React.useState({
        open: false,
        type: 'warning',
        text: ''
    });

    useEffect((e) => {
        const llenarDatos = async () => {
            const listarDatos = await fetch(`${Constantes.RUTA_API}reportes/reporteVentasTratamientoGeneral.php`);
            const resultadoDatos = await listarDatos.json();
            setResultado(resultadoDatos);
            const listarFG = await fetch(`${Constantes.RUTA_API}listar/listarFamiliaGeneralTratamiento.php`);
            const listaFG = await listarFG.json();
            setFamGeneral(listaFG);

            //datos de empresa
            const getRuc = await fetch(`${Constantes.RUTA_API}/recuperar/recuperarParametro.php?codigo=RUC`, {
                method: 'GET'
            });
            const response_getRuc = await getRuc.json();
            const getRazon = await fetch(`${Constantes.RUTA_API}/recuperar/recuperarParametro.php?codigo=RAZON_SOCIAL`, {
                method: 'GET'
            });
            const response_getRazon = await getRazon.json();
            const getDireccion = await fetch(`${Constantes.RUTA_API}/recuperar/recuperarParametro.php?codigo=DIRECCION`, {
                method: 'GET'
            });
            const response_getDireccion = await getDireccion.json();
            setDatosEmpresa({ ruc: response_getRuc.valor_texto, razon_social: response_getRazon.valor_texto, direccion: response_getDireccion.valor_texto });

        }
        llenarDatos();
    }, []);

    const alertPdf = (dat) => {
        console.log("alert");
        console.log(dat);
    }
    const [generarPdf, setGenerarPdf] = useState(false);

    const clickTab = () => {
        setGenerarPdf(false);
    }
    return (
        <Grid >
            <Paper elevation={5} sx={{ marginTop: "10px" }}>
                <Snackbar open={alertGeneralPdf.open} autoHideDuration={5000} onClose={() => setAlertGeneralPdf({ ...alertGeneralPdf, open: false })} anchorOrigin={{ vertical: 'top', horizontal: 'right' }}>
                    <Alert onClose={() => setAlertGeneralPdf({ ...alertGeneralPdf, open: false })} severity={alertGeneralPdf.type} sx={{ width: '100%' }}>
                        {alertGeneralPdf.text}
                    </Alert>
                </Snackbar>
                <TabContext value={value}>
                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                        <TabList onChange={handleChange} aria-label="API tabs" variant="scrollable"  >
                            <Tab label="PRE-BRACKEO" value="1" onClick={clickTab} />
                            <Tab label="BRACKEO" value="2" onClick={clickTab} />
                            <Tab label="OTROS TRATAMIENTOS" value="3" onClick={clickTab} />
                            <Tab label="RESEMENTADO" value="4" onClick={clickTab} />
                        </TabList>
                    </Box>
                    {
                        famGeneral.map((fam, index) => (
                            <Grid item xs={12} key={fam.codigo}>
                                <TabPanel value={"" + (index + 1) + ""}>
                                    <Grid container sx={{ justifyContent: "space-between", p: 1 }}>
                                        <Grid item md={6}>
                                        </Grid>
                                        <Grid item md={3}>
                                            <Stack spacing={0.3} direction="row">
                                                {generarPdf ?
                                                    <PDFDownloadLink document={<ExportPDF tipo={fam.codigo} datosGeneral={resultado} datosGeneralFam={famGeneral} datosEmpresa={datosEmpresa} />} fileName={"REPORTE-" + fam.nombre}>
                                                        {({ loading }) => (loading ? <LoadingButton
                                                            loading
                                                            loadingPosition="start"
                                                            startIcon={<Save />}
                                                            variant="contained"
                                                            fullWidth
                                                        >
                                                            GENERANDO
                                                        </LoadingButton> : <Tooltip title="DESCARGAR PDF" placement="top"><Button variant="contained" fullWidth color="error"><SaveAlt sx={{ padding: "0 2px" }} />DESCARGAR</Button></Tooltip>)}
                                                    </PDFDownloadLink> :
                                                    <Button variant="contained" fullWidth color="error" onClick={() => { setGenerarPdf(true); }}><PictureAsPdf sx={{ padding: "0 2px" }} />GENERAR</Button>
                                                }
                                                <ExportExcel data={resultado} nameReport={"REPORTE-" + fam.nombre} tipo={fam.codigo} />
                                            </Stack>
                                        </Grid>
                                    </Grid>
                                    <TableContainer sx={{ maxHeight: 480 }}>
                                        <Table key={fam.codigo} size='small' stickyHeader>
                                            <TableHead key={fam.codigo} >
                                                <TableRow key={fam.codigo} >
                                                    <TableCell sx={{ backgroundColor: "#1976D2", color: "#fff" }}>TRATAMIENTO</TableCell>
                                                    <TableCell sx={{ backgroundColor: "#1976D2", color: "#fff" }} align='center'>PRIMERA</TableCell>
                                                    <TableCell sx={{ backgroundColor: "#1976D2", color: "#fff" }} align='center'>ULTIMA</TableCell>
                                                    <TableCell sx={{ backgroundColor: "#1976D2", color: "#fff" }} align='right'>VENTAS</TableCell>
                                                    <TableCell sx={{ backgroundColor: "#1976D2", color: "#fff" }} align='right'>REP %</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {resultado.filter(res => res.fk_ven_familia_general === fam.codigo).map(trat => {
                                                    return <TableRow key={trat.codigo}>
                                                        <TableCell>{trat.nombre}</TableCell>
                                                        <TableCell align='center'>{trat.primera}</TableCell>
                                                        <TableCell align='center'>{trat.ultima}</TableCell>
                                                        <TableCell align='right'>S/ {trat.total}</TableCell>
                                                        <TableCell align='right'>{(trat.total * 100 / resultado.filter(res => res.fk_ven_familia_general === fam.codigo).reduce((total, item) => parseFloat(total) + parseFloat(item.total), 0)).toFixed(2)} %</TableCell>
                                                    </TableRow>
                                                })}
                                                {/* <TableRow sx={{ backgroundColor: "#5B9AC8" }}>
                                                    <TableCell sx={{ color: "#fff" }} colSpan={3}>TOTAL</TableCell>
                                                    <TableCell sx={{ color: "#fff" }} colSpan={2} align='right'> S/ {resultado.filter(res => res.fk_ven_familia_general === fam.codigo).reduce((total, item) => parseFloat(total) + parseFloat(item.total), 0).toFixed(2)}</TableCell>
                                                </TableRow> */}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                    <TableContainer sx={{ maxHeight: 80 }}>
                                        <Table size='small' stickyHeader>
                                            <TableBody >
                                                <TableRow sx={{ backgroundColor: "#5B9AC8" }}>
                                                    <TableCell sx={{ color: "#fff" }} colSpan={3}>TOTAL</TableCell>
                                                    <TableCell sx={{ color: "#fff" }} colSpan={2} align='right'> S/ {resultado.filter(res => res.fk_ven_familia_general === fam.codigo).reduce((total, item) => parseFloat(total) + parseFloat(item.total), 0).toFixed(2)}</TableCell>
                                                </TableRow>
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </TabPanel>
                            </Grid>
                        ))
                    }
                    {/* <Grid>
                        <PDFViewer width="100%" height={600}>
                            <ExportPDF tipo="RESE" datosGeneral={resultado} datosGeneralFam={famGeneral} datosEmpresa={datosEmpresa} />
                        </PDFViewer>
                    </Grid> */}
                </TabContext>
            </Paper>
        </Grid>
    )
}

export default RepBrackets