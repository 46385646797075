import { IconButton, TableCell, TableRow, Tooltip } from '@mui/material';
import React, { Component } from 'react';
import PaidIcon from '@mui/icons-material/Paid';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';

class filaListarDetalleRegistro extends Component {
    constructor (props){
        super(props);
        this.state={
        }
    }
    payRegistry= async (accion) => {
        this.props.parentPayment(this.props.registrodetalle, accion);
    }
    
    render() {
        return (
            <TableRow key={this.props.registrodetalle.control}>
                <TableCell>{this.props.registrodetalle.control}</TableCell>
                <TableCell>{this.props.registrodetalle.fecha}</TableCell>
                <TableCell align='right'>{this.props.registrodetalle.cantidad}</TableCell>
                <TableCell align='right'>{this.props.registrodetalle.precio_unitario}</TableCell>
                <TableCell align='right'>{this.props.registrodetalle.total_detalle}</TableCell>
                <TableCell align='right'>{this.props.registrodetalle.total_pago_detalle}</TableCell>
                <TableCell align='right'>{this.props.registrodetalle.total_detalle - this.props.registrodetalle.total_pago_detalle}</TableCell>
                <TableCell align='center'>{this.props.registrodetalle.tipos_pago}</TableCell>
                <TableCell>
                    <Tooltip title='Pagos'>
                        <IconButton onClick={()=>{this.payRegistry("PAY")}} color="secondary"><PaidIcon/> </IconButton>
                    </Tooltip>
                    <Tooltip title='Eliminar pagos'>
                        <IconButton onClick={()=>{this.payRegistry("DELETE")}} color="error"><DeleteIcon/></IconButton>
                    </Tooltip>
                </TableCell>
            </TableRow>
        )
    }
}

export default filaListarDetalleRegistro