import { Alert, Button, Collapse, Grid, IconButton, InputAdornment, Paper, TextField } from '@mui/material';
import React from 'react'
import Constantes from '../components/Constantes';
import { AccountCircle, Key, Close } from '@mui/icons-material';
import { Box } from '@mui/system';
import logo from '../images/logo.png';
import { useNavigate } from 'react-router-dom';

function Login(props) {
    const navigate = useNavigate();
    const [username, setUserName] = React.useState("");
    const [password, setPassword] = React.useState("");
    const [showInfoAlert, setShowInfoAlert] = React.useState({ show: false, message: "", severity: "warning" });
    // const [loginStatus, setLoginstatus] = React.useState(false);
    const sendInfo = async () => {
        const payload = {
            username: username,
            password: password,
        };
        if (payload.username === "" || payload.password === "") {
            this.setState({ showInfoAlert: true });
        } else {
            const userLogFetch = await fetch(`${Constantes.RUTA_API}sistema/login.php`, {
                method: "POST",
                body: JSON.stringify(payload),
            });
            const userLogDB = await userLogFetch.json();
            if (userLogDB.autorized === true) {
                sessionStorage.setItem('logedAppUser', JSON.stringify(userLogDB));
                props.parentCallback(userLogDB);
                navigate('/');
            }
        }
    };
    const cambiarInput = (e) => {
        var input = document.getElementById('usuario').value;
        if (input !== "" && e.which === 13) {
            document.getElementById('password').focus();
        }
    }
    // cambiar de input a botton
    const cambiarBtn = (e) => {
        var input = document.getElementById('password').value;
        if (input !== "" && e.which === 13) {
            document.getElementById('btnAcceder').click();
        }
    }
    
    // console.log({ username });
    return (
        <Box sx={{ mt: '-60px', background: "linear-gradient(45deg, rgba(224,207,66,1) 0%, rgba(153,125,23,1) 45%, rgba(255,255,255,1) 100%)", height: "100vh", width: "100vw", marginRight: "auto", marginLeft: "auto" }}>
            <Paper elevation={8} sx={{ padding: "20px", position: 'absolute', left: '50%', top: '50%', maxWidth: "90vh", minWidth: "300px", width: "360px", minHeight: "440px", transform: 'translate(-50%, -50%)' }}>
                <Grid container spacing={3} alignItems="center" alignContent="center" textAlign="center">
                    <Collapse in={showInfoAlert.show}>
                        <Alert
                            action={
                                <IconButton
                                    aria-label="close"
                                    color="inherit"
                                    size="small"
                                    onClick={() => {
                                        setShowInfoAlert({ show: false, message: "", severity: "warning" })
                                    }}
                                >
                                    <Close fontSize="inherit" />
                                </IconButton>
                            }
                            sx={{ mb: 2 }}
                        >
                            {showInfoAlert.message}
                        </Alert>
                    </Collapse>
                    <Grid item xs={12} >
                        <img src={logo} alt="🦷" width={70} height={70} />
                        <h2>INICIAR SESIÓN</h2>
                    </Grid>
                    <Grid item xs={12} alignItems="center">
                        <TextField
                            id="usuario"
                            label="Usuario"
                            placeholder="Ingrese usuario"
                            fullWidth required
                            variant="filled"
                            style={{ textTransform: 'uppercase' }}
                            // onChange={event => setUserName(event.target.value)}
                            onChange={event => setUserName(event.target.value.toUpperCase())}
                            onKeyUp={cambiarInput}
                            value={username}
                            autoFocus
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <AccountCircle />
                                    </InputAdornment>
                                ),
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} alignItems="center">
                        <TextField
                            id='password'
                            label="CONTRASEÑA"
                            type="password"
                            placeholder="Ingrese contraseña"
                            fullWidth required
                            variant="filled"
                            onChange={event => setPassword(event.target.value)}
                            onKeyUp={cambiarBtn}
                            value={password}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <Key />
                                    </InputAdornment>
                                ),
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} alignItems="center">
                        <Button id="btnAcceder" onClick={sendInfo} color="primary" variant="contained" fullWidth className="logComponent">Acceder</Button>
                    </Grid>
                    <Grid item xs={12} alignItems="center">
                        <span>Si olvidó su contraseña contacte al administrador.</span>
                    </Grid>
                </Grid>
            </Paper>
        </Box>


    )
}

export default Login