import { Alert, Button, Grid, Paper, Snackbar, Table, TableBody, TableCell, TableContainer, TableFooter, TableHead, TableRow, TextField, Tooltip } from '@mui/material'
import React, { useEffect } from 'react'
import SearchIcon from '@mui/icons-material/Search';
import Constantes from '../../Constantes';
//import SyncAltIcon from '@mui/icons-material/SyncAlt';
import PictureAsPdfOutlinedIcon from '@mui/icons-material/PictureAsPdfOutlined';
import { Container } from '@mui/system';
import { PictureAsPdf } from '@mui/icons-material';
import { PDFDownloadLink, PDFViewer } from '@react-pdf/renderer';
import ExportPDF from '../export/ExportPDF';
import { blue } from '@mui/material/colors';
//import { renderToString } from "react-dom/server";
//import jsPDF from "jspdf";
function ReporteRegistrosPagosDia() {
    const fecha = new Date();
    let fechaStr = `${fecha.getFullYear()}-${(fecha.getMonth() + 1).toString().padStart(2, '0')}-${fecha.getDate().toString().padStart(2, '0')}`;
    const [fechaDesde, setFechaDesde] = React.useState(fechaStr);
    const [resultados, setResultados] = React.useState([]);
    const [datosEmpresa, setDatosEmpresa] = React.useState({
        ruc: '',
        razon_social: '',
        direccion: ''
    });
    const [alertGeneralPdf, setAlertGeneralPdf] = React.useState({
        open: false,
        type: 'warning',
        text: ''
    });
    useEffect(() => {
        const getDatosEmpresa = async () => {
            const getRuc = await fetch(`${Constantes.RUTA_API}/recuperar/recuperarParametro.php?codigo=RUC`, {
                method: 'GET'
            });
            const response_getRuc = await getRuc.json();
            const getRazon = await fetch(`${Constantes.RUTA_API}/recuperar/recuperarParametro.php?codigo=RAZON_SOCIAL`, {
                method: 'GET'
            });
            const response_getRazon = await getRazon.json();
            const getDireccion = await fetch(`${Constantes.RUTA_API}/recuperar/recuperarParametro.php?codigo=DIRECCION`, {
                method: 'GET'
            });
            const response_getDireccion = await getDireccion.json();
            setDatosEmpresa({ ruc: response_getRuc.valor_texto, razon_social: response_getRazon.valor_texto, direccion: response_getDireccion.valor_texto });
        }
        getDatosEmpresa();
    }, [])

    const handleChange = (event) => {
        let valor = event.target.value;
        if (event) {
            if (event.target.id === "fecha") {
                setFechaDesde(valor);
            }
        }
    }
    const buscarPorFecha = async () => {
        if (fechaDesde !== "") {
            const resultado = await fetch(`${Constantes.RUTA_API}reportes/reporteRegistroPorFecha.php?fecha=${fechaDesde}`);
            const resultadoDB = await resultado.json();
            setResultados(resultadoDB)
        } else {
            console.log("log");
            setResultados([]);
        }
    }
    const alertPdf = () => {
        setAlertGeneralPdf({
            open: true,
            type: 'error',
            text: 'No existen datos para exportar'
        })
        setTimeout(() => {
            setAlertGeneralPdf({
                open: false,
                type: 'warning',
                text: ''
            })
        }, 2700);
    }
    const colorTable = {
        header: blue[700],
        footer: "",
        fondo: "#EBF8FF",
        subtotal: blue[500],
        total: blue[300],
        color: "#fff"
    }
    return (
        <Container maxWidth='lg'>
            <Paper elevation={5} sx={{ marginTop: "45px", p: 2 }}>
                <Snackbar open={alertGeneralPdf.open} autoHideDuration={5000} onClose={() => setAlertGeneralPdf({ ...alertGeneralPdf, open: false })} anchorOrigin={{ vertical: 'top', horizontal: 'right' }}>
                    <Alert onClose={() => setAlertGeneralPdf({ ...alertGeneralPdf, open: false })} severity={alertGeneralPdf.type} sx={{ width: '100%' }}>
                        {alertGeneralPdf.text}
                    </Alert>
                </Snackbar>
                <Grid container spacing={1}>
                    <Grid item xs={12}><h3>REPORTE DE REGISTROS</h3></Grid>

                    <Grid container item xs={12} md={8} spacing={0.5} sx={{ justifyContent: { xs: 'start', md: 'start' } }} >
                        <Grid item xs={9} md={3}>
                            <TextField
                                id="fecha"
                                label="Fecha:"
                                type="date"
                                value={fechaDesde}
                                fullWidth
                                size='small'
                                onChange={handleChange}
                            />
                        </Grid>
                        <Grid item xs={3} md={1.5}>
                            <Tooltip title="Buscar por Fecha">
                                <Button variant='contained' fullWidth color='primary' onClick={buscarPorFecha}><SearchIcon /></Button>
                            </Tooltip>
                        </Grid>
                    </Grid>
                    <Grid container item xs={8} md={4} sx={{ justifyContent: { xs: 'end', md: 'end' } }}>
                        <Grid item xs={5} md={3}>
                            {resultados.length !== 0 ?
                                <PDFDownloadLink document={<ExportPDF tipo="registrosporfecha" datosGeneral={resultados} dataFechas={{ fecha: fechaDesde }} datosEmpresa={datosEmpresa} />} fileName="REP-REGISTROS-FECHA">
                                    {({ loading }) => (loading ? <Button variant="contained" fullWidth color="error"><PictureAsPdf sx={{ padding: "0 2px" }} />PDF</Button> : <Button variant="contained" fullWidth color="error"><PictureAsPdf sx={{ padding: "0 2px" }} />PDF</Button>)}
                                </PDFDownloadLink> :
                                <Button variant="contained" fullWidth color="error" onClick={alertPdf}><PictureAsPdf sx={{ padding: "0 2px" }} />PDF</Button>
                            }
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <TableContainer>
                            <Table size='small'>
                                <TableHead>
                                    <TableRow sx={{ backgroundColor: colorTable.header }}>
                                        <TableCell sx={{ fontWeight: "bold", color: colorTable.color }}>PACIENTE</TableCell>
                                        <TableCell sx={{ fontWeight: "bold", color: colorTable.color }}>FAMILIA</TableCell>
                                        <TableCell sx={{ fontWeight: "bold", color: colorTable.color }}>TRATAMIENTO</TableCell>
                                        <TableCell align='center' sx={{ fontWeight: "bold", color: colorTable.color }}>CONTROL</TableCell>
                                        <TableCell align='right' sx={{ fontWeight: "bold", color: colorTable.color }}>CONTADO</TableCell>
                                        <TableCell align='right' sx={{ fontWeight: "bold", color: colorTable.color }}>TARJETA</TableCell>
                                        <TableCell align='right' sx={{ fontWeight: "bold", color: colorTable.color }}>DEPOSITO</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {resultados.map(detalle => {
                                        return <TableRow key={detalle.id}>
                                            <TableCell>{detalle.paciente}</TableCell>
                                            <TableCell>{detalle.familia}</TableCell>
                                            <TableCell>{detalle.tratamiento}</TableCell>
                                            <TableCell align='center'>{detalle.controles}</TableCell>
                                            <TableCell align='right'>S/ {detalle.efectivo}</TableCell>
                                            <TableCell align='right'>S/ {detalle.tarjeta}</TableCell>
                                            <TableCell align='right'>S/ {detalle.deposito}</TableCell>
                                        </TableRow>
                                    })}
                                    <TableRow sx={{ backgroundColor: colorTable.subtotal }}>
                                        <TableCell colSpan={2}></TableCell>
                                        <TableCell colSpan={2} sx={{ fontWeight: "bold", color: "whitesmoke" }}>SUBTOTALES</TableCell>
                                        <TableCell align='right' sx={{ fontWeight: "bold", color: "whitesmoke" }}>S/ {resultados.reduce((total, item) => parseFloat(total) + parseFloat(item.efectivo), 0).toFixed(2)}</TableCell>
                                        <TableCell align='right' sx={{ fontWeight: "bold", color: "whitesmoke" }}>S/ {resultados.reduce((total, item) => parseFloat(total) + parseFloat(item.tarjeta), 0).toFixed(2)}</TableCell>
                                        <TableCell align='right' sx={{ fontWeight: "bold", color: "whitesmoke" }}>S/ {resultados.reduce((total, item) => parseFloat(total) + parseFloat(item.deposito), 0).toFixed(2)}</TableCell>
                                    </TableRow>
                                    <TableRow sx={{ backgroundColor: colorTable.total }}>
                                        <TableCell colSpan={2}></TableCell>
                                        <TableCell colSpan={2} sx={{ fontWeight: "bold", color: "whitesmoke" }}>TOTAL</TableCell>
                                        <TableCell colSpan={3} align='center' sx={{ fontWeight: "bold", color: "whitesmoke" }}>S/ {resultados.reduce((total, item) => parseFloat(total) + parseFloat(item.deposito) + parseFloat(item.efectivo) + parseFloat(item.tarjeta), 0).toFixed(2)}</TableCell>
                                    </TableRow>
                                </TableBody>
                                <TableFooter>

                                </TableFooter>
                            </Table>
                        </TableContainer>
                    </Grid>
                </Grid>
                {/* <Grid>
                    <PDFViewer width="100%" height={600}>
                        <ExportPDF tipo="registrosporfecha" datosGeneral={resultados} dataFechas={{ fecha: fechaDesde }} datosEmpresa={datosEmpresa} />
                    </PDFViewer>
                </Grid> */}
            </Paper>
        </Container>
    )
}
export default ReporteRegistrosPagosDia
