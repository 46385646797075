import { Alert, Box, Button, Dialog, DialogContent, DialogTitle, Divider, FormControl, Grid, IconButton, InputLabel, MenuItem, Paper, Select, Slide, Snackbar, Tab, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip, Typography } from '@mui/material'
import React, { useEffect } from 'react';
import Constantes from '../Constantes';
import PictureAsPdfOutlinedIcon from '@mui/icons-material/PictureAsPdfOutlined';
import RefreshIcon from '@mui/icons-material/Refresh';
import PreviewIcon from '@mui/icons-material/Preview';
import CloseIcon from '@mui/icons-material/Close';
import ViewDetallePagos from './ventas/viewDetallePagos';
import { LoadingButton, TabContext, TabList, TabPanel } from '@mui/lab';
import { PictureAsPdf, Save } from '@mui/icons-material';
import ExportPDF from './export/ExportPDF';
import { PDFDownloadLink, PDFViewer } from '@react-pdf/renderer';
import ExportExcel from './export/ExportExcel';
import excel from '../../images/exportarPdfExcel/excel.png';
import pdf from '../../images/exportarPdfExcel/pdf.png';
import GraficoBarra from '../../screens/charts/graficoBarra';
import BarChartIcon from '@mui/icons-material/BarChart';
import { id } from 'date-fns/locale';
import DemoCanvas from '../../screens/reportes/demoCanvas';
import { width } from '@mui/system';
import GraficoLineal from '../../screens/charts/graficoLineal';
import SaveAltIcon from '@mui/icons-material/SaveAlt';
import ExportPDFv2 from './export/ExportPDFv2';
const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

function VentasGastos() {
    const [ventasPorMes, setVentasPorMes] = React.useState([]);
    const [ventasPorMesAnho, setVentasPorMesAnho] = React.useState([]);
    const [resumenVentasGastos, setResumenVentasGastos] = React.useState([]);
    const [resumenTipoPago, setResumenTipoPago] = React.useState([]);
    const [anhos, setAnhos] = React.useState([]);
    const [anhoVentasgastos, setAnhoVentasGastos] = React.useState(2022);
    const [mesVentasgastos, setMesVentasGastos] = React.useState(1);
    const [anhoTipoPago, setAnhoTipoPago] = React.useState(2022);
    const [propsDetalles, setPropsDetalles] = React.useState({ anho: 2022, mes: 1, show: false, periodo: "" });
    const [generarPdf, setGenerarPdf] = React.useState(false);
    const [detallesGrafBarr, setDetallesGrafBarr] = React.useState({
        showTab1: false,
        showTab2: false,
        showTab3: false,
        showTab4: false,
    });
    // grafico de barra 
    const [valuesGrafBarr, setValuesGrafBarr] = React.useState({
        ventas: [],
        gastos: [],
        res: [],
        tags: [],
        anho: "",
        message: "",
    });
    const [valuesGrafBarr2, setValuesGrafBarr2] = React.useState({
        ventas: [],
        contado: [],
        pos: [],
        tranf: [],
        tags: [],
        anho: "",
        message: "",
    });
    // exportar pdf
    const [datosEmpresa, setDatosEmpresa] = React.useState({
        ruc: '',
        razon_social: '',
        direccion: ''
    });
    const [alertGeneralPdf, setAlertGeneralPdf] = React.useState({
        open: false,
        type: 'warning',
        text: ''
    });
    const meses = [
        { mes: 1, strMes: 'ENERO' },
        { mes: 2, strMes: 'FEBRERO' },
        { mes: 3, strMes: 'MARZO' },
        { mes: 4, strMes: 'ABRIL' },
        { mes: 5, strMes: 'MAYO' },
        { mes: 6, strMes: 'JUNIO' },
        { mes: 7, strMes: 'JULIO' },
        { mes: 8, strMes: 'AGOSTO' },
        { mes: 9, strMes: 'SEPTIEMBRE' },
        { mes: 10, strMes: 'OCTUBRE' },
        { mes: 11, strMes: 'NOVIEMBRE' },
        { mes: 12, strMes: 'DICIEMBRE' },
    ];
    const [value, setValue] = React.useState("1");
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    useEffect((e) => {
        const fillReportesin = async () => {
            const fecha = new Date();
            const anhosRes = await fetch(`${Constantes.RUTA_API}listar/listarAnhosPagos.php`);
            const anhosResDB = await anhosRes.json();
            setAnhos(anhosResDB);
            setAnhoVentasGastos(fecha.getFullYear());
            const porMes = await fetch(`${Constantes.RUTA_API}reportes/reporteVentasGastosAnho.php?anho=${fecha.getFullYear()}`);
            const porMesDB = await porMes.json();
            setVentasPorMes(porMesDB);
            // setVentasPorMes([]);
            setMesVentasGastos(fecha.getMonth() + 1);
            const porMesAnho = await fetch(`${Constantes.RUTA_API}reportes/reporteResumenMes.php?mes=${fecha.getMonth() + 1}`);
            const porMesAnhoDB = await porMesAnho.json();
            setVentasPorMesAnho(porMesAnhoDB);
            const tipoPago = await fetch(`${Constantes.RUTA_API}reportes/reporteVentasTipoPago.php?anho=${fecha.getFullYear()}`);
            const tipoPagoDB = await tipoPago.json();
            setAnhoTipoPago(fecha.getFullYear())
            setResumenTipoPago(tipoPagoDB);
            const resumen = await fetch(`${Constantes.RUTA_API}reportes/resumenVentasGastos.php`);
            const resumenDB = await resumen.json();
            setResumenVentasGastos(resumenDB);
            //datos de empresa
            const getRuc = await fetch(`${Constantes.RUTA_API}/recuperar/recuperarParametro.php?codigo=RUC`, {
                method: 'GET'
            });
            const response_getRuc = await getRuc.json();
            const getRazon = await fetch(`${Constantes.RUTA_API}/recuperar/recuperarParametro.php?codigo=RAZON_SOCIAL`, {
                method: 'GET'
            });
            const response_getRazon = await getRazon.json();
            const getDireccion = await fetch(`${Constantes.RUTA_API}/recuperar/recuperarParametro.php?codigo=DIRECCION`, {
                method: 'GET'
            });
            const response_getDireccion = await getDireccion.json();
            setDatosEmpresa({ ruc: response_getRuc.valor_texto, razon_social: response_getRazon.valor_texto, direccion: response_getDireccion.valor_texto });
        }
        fillReportesin();
    }, []);
    const handleChangeAnho = async (e) => {
        setAnhoVentasGastos(e.target.value);
        const porMes = await fetch(`${Constantes.RUTA_API}reportes/reporteVentasGastosAnho.php?anho=${e.target.value}`);
        const porMesDB = await porMes.json();
        setVentasPorMes(porMesDB);
        sessionStorage.removeItem("imgGrafico");
    }
    const handleChangeMes = async (e) => {
        setMesVentasGastos(e.target.value)
        const porMesAnho = await fetch(`${Constantes.RUTA_API}reportes/reporteResumenMes.php?mes=${e.target.value}`);
        const porMesAnhoDB = await porMesAnho.json();
        setVentasPorMesAnho(porMesAnhoDB);
    }
    const actualizarResumen = async (e) => {
        const resumen = await fetch(`${Constantes.RUTA_API}reportes/resumenVentasGastos.php`);
        const resumenDB = await resumen.json();
        setResumenVentasGastos(resumenDB);
    }
    const handleChangeAnhoTipo = async (e) => {
        setAnhoTipoPago(e.target.value)
        const tipoPago = await fetch(`${Constantes.RUTA_API}reportes/reporteVentasTipoPago.php?anho=${e.target.value}`);
        const tipoPagoDB = await tipoPago.json();
        setResumenTipoPago(tipoPagoDB);
    }
    const dataGraficoBar = () => {
        var valuesTmpVent = [];
        var valuesTmpGast = [];
        var valuesTmpRes = [];
        var valuesTmpMeses = [];

        ventasPorMes.forEach(el => {
            valuesTmpVent.push(Number(el.ventas));
            valuesTmpGast.push(Number(el.gastos));
            valuesTmpRes.push(Number(el.resumen));
            valuesTmpMeses.push((el.periodo).substr(0, 3));
        })
        setValuesGrafBarr({ ...valuesGrafBarr, ventas: valuesTmpVent, gastos: valuesTmpGast, res: valuesTmpRes, tags: valuesTmpMeses, message: `VENTAS Y GASTOS AÑO ${anhoVentasgastos}` });
    }

    const dataGraficoBarTab2 = () => {
        var valuesTmpVent = [];
        var valuesTmpGast = [];
        var valuesTmpRes = [];
        var valuesTmpAnhos = [];
        var valuesTmpMes = "";
        ventasPorMesAnho.forEach(el => {
            valuesTmpVent.push(Number(el.ventas));
            valuesTmpGast.push(Number(el.gastos));
            valuesTmpRes.push(Number(el.resumen));
            valuesTmpAnhos.push(el.anho);
        })
        meses.filter(m => m.mes === mesVentasgastos).forEach(mes => {
            valuesTmpMes = (mes.strMes);
        })

        setValuesGrafBarr({ ...valuesGrafBarr, ventas: valuesTmpVent, gastos: valuesTmpGast, tags: valuesTmpAnhos, res: valuesTmpRes, message: `VENTAS MES ${valuesTmpMes}` });
    }
    const dataGraficoBarTab3 = () => {
        var valuesTmpVent = [];
        var valuesTmpGast = [];
        var valuesTmpRes = [];
        var valuesTmpAnhos = [];
        resumenVentasGastos.forEach(el => {
            valuesTmpVent.push(Number(el.ventas));
            valuesTmpGast.push(Number(el.gastos));
            valuesTmpRes.push(Number(el.resumen));
            valuesTmpAnhos.push(el.anho);
        })

        setValuesGrafBarr({ ...valuesGrafBarr, ventas: valuesTmpVent, gastos: valuesTmpGast, tags: valuesTmpAnhos, res: valuesTmpRes, message: "RESUMEN POR AÑO" });
    }
    const dataGraficoBarTab4 = () => {

        var valuesTmpVent = [];
        var valuesTmpContado = [];
        var valuesTmpPos = [];
        var valuesTmpTranf = [];
        var valuesTmpMeses = [];
        resumenTipoPago.forEach(el => {
            valuesTmpVent.push(Number(el.total));
            valuesTmpContado.push(Number(el.efectivo));
            valuesTmpPos.push(Number(el.tarjeta));
            valuesTmpTranf.push(Number(el.deposito));
            valuesTmpMeses.push((el.periodo).substr(0, 3));
        });
        setValuesGrafBarr2({ ...valuesGrafBarr2, ventas: valuesTmpVent, contado: valuesTmpContado, pos: valuesTmpPos, tranf: valuesTmpTranf, tags: valuesTmpMeses, message: `RESUMEN TIPO DE PAGO AÑO ${anhoTipoPago}` });
    }
    // console.log("valuesGrafBarr2");
    // console.log(valuesGrafBarr2);

    const mostrarGrafico = (tabDial) => {

        if (tabDial === 1) { setDetallesGrafBarr({ ...detallesGrafBarr, showTab1: true }); dataGraficoBar(); }
        if (tabDial === 2) { setDetallesGrafBarr({ ...detallesGrafBarr, showTab2: true }); dataGraficoBarTab2(); }
        if (tabDial === 3) { setDetallesGrafBarr({ ...detallesGrafBarr, showTab3: true }); dataGraficoBarTab3(); }
        if (tabDial === 4) { setDetallesGrafBarr({ ...detallesGrafBarr, showTab4: true }); dataGraficoBarTab4(); }
    };
    const clickTab = () => {
        setGenerarPdf(false);
        setValuesGrafBarr({
            ventas: [],
            gastos: [],
            res: [],
            tags: [],
            anho: "",
            message: "",
        })
        sessionStorage.removeItem("imgGrafico");
    }
    // pdf alerta
    const alertPdf = () => {
        setAlertGeneralPdf({
            open: true,
            type: 'error',
            text: 'No existen datos para exportar'
        })
        setTimeout(() => {
            setAlertGeneralPdf({
                open: false,
                type: 'warning',
                text: ''
            })
        }, 2700);
    }
    const alertExcel = () => {
        setAlertGeneralPdf({
            open: true,
            type: 'error',
            text: 'No existen datos para exportar'
        })
        setTimeout(() => {
            setAlertGeneralPdf({
                open: false,
                type: 'warning',
                text: ''
            })
        }, 2700);
    }
    const alertChartBarrAgreg = () => {

        // var imgGrafico = sessionStorage.getItem("imgGrafico");
        setAlertGeneralPdf({
            open: true,
            type: 'success',
            text: 'Imagen Agregada a PDF Correctamente'
        })
        setTimeout(() => {
            setAlertGeneralPdf({
                open: false,
                type: 'warning',
                text: ''
            })
        }, 2700);
    }
    const alertChartBarrRemov = () => {

        var imgGrafico = sessionStorage.getItem("imgGrafico");
        if (imgGrafico === null) {
            setAlertGeneralPdf({
                open: true,
                type: 'success',
                text: 'Imagen Eliminada de PDF Correctamente'
            })
        } else {
            setAlertGeneralPdf({
                open: true,
                type: 'warning',
                text: 'Intente de Nuevo Porfavor'
            })
        }

        setTimeout(() => {
            setAlertGeneralPdf({
                open: false,
                type: 'warning',
                text: ''
            })
        }, 2700);
    }

    const showDetails = (anhoP, mesP, periodoP) => {
        setPropsDetalles({ anho: anhoP, mes: mesP, show: true, periodo: periodoP });
    }
    const colorTable = {
        header: "#1976D2",
        footer: "",
        subtotal: "#6FAEEE",
        total: "#5B9AC8",
        color: "#fff"
    }

    return (
        <Grid>
            <Paper elevation={5} sx={{ marginTop: "10px", p: 0 }}>
                <Snackbar open={alertGeneralPdf.open} autoHideDuration={5000} onClose={() => setAlertGeneralPdf({ ...alertGeneralPdf, open: false })} anchorOrigin={{ vertical: 'top', horizontal: 'right' }}>
                    <Alert onClose={() => setAlertGeneralPdf({ ...alertGeneralPdf, open: false })} severity={alertGeneralPdf.type} sx={{ width: '100%' }}>
                        {alertGeneralPdf.text}
                    </Alert>
                </Snackbar>

                <TabContext value={value}>
                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                        <TabList onChange={handleChange} aria-label="API tabs" variant="scrollable" scrollButtons allowScrollButtonsMobile>
                            <Tab label="VENTAS Y GASTOS POR AÑO" value="1" onClick={clickTab} />
                            <Tab label="VENTAS POR MES" value="2" onClick={clickTab} />
                            <Tab label="RESUMEN POR AÑO" value="3" onClick={clickTab} />
                            <Tab label="RESUMEN TIPO DE PAGO" value="4" onClick={clickTab} />
                        </TabList>
                    </Box>
                    <TabPanel value="1">
                        <Grid container>
                            <Dialog fullScreen open={propsDetalles.show} TransitionComponent={Transition}>
                                <DialogTitle>
                                    {`DETALLES DEL MES DE: ${propsDetalles.periodo} DEL AÑO: ${propsDetalles.anho}`}
                                    <IconButton
                                        aria-label="close"
                                        onClick={() => { setPropsDetalles({ anho: 2022, mes: 1, show: false, periodo: "" }) }}
                                        sx={{
                                            position: 'absolute',
                                            right: 8,
                                            top: 8,
                                            color: (theme) => theme.palette.grey[500],
                                        }}
                                    >
                                        <CloseIcon />
                                    </IconButton>
                                </DialogTitle>
                                <DialogContent>
                                    <ViewDetallePagos anho={propsDetalles.anho} mes={propsDetalles.mes} />
                                </DialogContent>
                            </Dialog>
                            <Dialog fullWidth maxWidth="md" open={detallesGrafBarr.showTab1} onClose={() => setDetallesGrafBarr({ ...detallesGrafBarr, showTab1: false })} TransitionComponent={Transition} >
                                <DialogTitle>
                                    <IconButton
                                        aria-label="close"
                                        onClick={() => setDetallesGrafBarr({ ...detallesGrafBarr, showTab1: false })}
                                        sx={{
                                            position: 'absolute',
                                            right: 8,
                                            top: 8,
                                            color: (theme) => theme.palette.grey[500],
                                        }}
                                    >
                                        <CloseIcon />
                                    </IconButton>
                                </DialogTitle>
                                <DialogContent>
                                    <GraficoBarra tipo="1" valuesGrafBarr={valuesGrafBarr} alertChartBarrAgreg={alertChartBarrAgreg} alertChartBarrRemov={alertChartBarrRemov} />
                                </DialogContent>
                            </Dialog>
                            <Grid container item xs={4} md={8} sx={{ justifyContent: { xs: 'start', md: 'start' } }}>
                                <FormControl fullWidth sx={{ minWidth: "100px", maxWidth: "200px" }}>
                                    <InputLabel id="demo-simple-select-label">AÑO A REPORTAR</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        value={anhoVentasgastos}
                                        label="AÑO A REPORTAR"
                                        onChange={handleChangeAnho}
                                        size='small'
                                    >
                                        {anhos.map(anho => {
                                            return <MenuItem key={anho.anho} value={anho.anho}>{anho.anho}</MenuItem>
                                        })}
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid container item xs={8} md={4} spacing={0.4} sx={{ justifyContent: { xs: 'end', md: 'end' } }}>
                                <Grid item md={4}>


                                    {ventasPorMes.length !== 0 ? generarPdf ?
                                        <PDFDownloadLink document={<ExportPDF tipo="ventgastxanho" datosGeneral={ventasPorMes} datosEmpresa={datosEmpresa} dataFechas={{ ventaAnho: anhoVentasgastos }} />} fileName="ventas-gastos-año">
                                            {({ loading }) => (loading ?
                                                <Grid sx={{ textDecoration: "none" }}>
                                                    <LoadingButton loading loadingPosition="start" startIcon={<Save />} variant="contained" fullWidth sx={{ display: { xs: "none", md: "flex" } }} >
                                                        GENERANDO
                                                    </LoadingButton>
                                                    <Button variant="contained" fullWidth color="error" sx={{ display: { xs: "flex", md: "none" } }}><PictureAsPdf sx={{ padding: "0 2px" }} /></Button>
                                                </Grid> :
                                                <Grid >
                                                    <Button variant="contained" fullWidth color="error" sx={{ display: { xs: "none", md: "flex" } }}><SaveAltIcon sx={{ padding: "0 2px" }} />DESCARGAR</Button>
                                                    <Tooltip title="DESCARGAR PDF" placement="top">
                                                        <Button variant="contained" fullWidth color="error" sx={{ display: { xs: "flex", md: "none" } }}><SaveAltIcon sx={{ padding: "0 2px" }} /></Button>
                                                    </Tooltip>
                                                </Grid>
                                            )}
                                        </PDFDownloadLink> :
                                        <Grid>
                                            <Button variant="contained" fullWidth color="error" onClick={() => { setGenerarPdf(true); }} sx={{ display: { xs: "none", md: "flex" } }}><PictureAsPdf sx={{ padding: "0 2px" }} />GENERAR</Button>
                                            <Tooltip title="GENERAR PDF" placement="top">
                                                <Button variant="contained" fullWidth color="error" onClick={() => { setGenerarPdf(true); }} sx={{ display: { xs: "flex", md: "none" } }}><PictureAsPdf sx={{ padding: "0 2px" }} /></Button>
                                            </Tooltip>
                                        </Grid>
                                        :
                                        <Button variant="contained" fullWidth color="error" onClick={() => { alertPdf(); }}><PictureAsPdf sx={{ padding: "0 2px" }} />PDF</Button>
                                    }
                                </Grid>
                                <Grid item md={4}>
                                    {ventasPorMes.length !== 0 ?
                                        <ExportExcel data={ventasPorMes} nameReport="VENTAS-GASTOS-AÑO" tipo="ventasgastosanho" />
                                        :
                                        <Tooltip title="EXPORTAR EXCEL" placement='top'>
                                            <Button variant='contained' fullWidth color="success" startIcon={<img style={{ width: '20px', height: '20px' }} src={excel} />} onClick={alertExcel}>EXCEL</Button>
                                        </Tooltip>
                                    }
                                </Grid>

                                <Grid item md={4}>
                                    <Button variant='contained' fullWidth color="primary" onClick={() => { mostrarGrafico(1); }} sx={{ display: { xs: "none", md: "flex" } }}><BarChartIcon sx={{ padding: "0 2px" }} />GRAFICO</Button>
                                    <Tooltip title="VER GRAFICO" placement='top'>
                                        <Button variant='contained' fullWidth color="primary" onClick={() => { mostrarGrafico(1); }} sx={{ display: { xs: "flex", md: "none" } }}><BarChartIcon sx={{ padding: "0 2px" }} /></Button>
                                    </Tooltip>
                                </Grid>
                            </Grid>

                            <Grid container sx={{ boxShadow: "0 4px 4px -4px rgba(0, 0, 0, 0.4)", paddingBottom: "20px" }}></Grid>
                            <Grid item xs={12} sx={{ padding: "20px 5px" }}>
                                <TableContainer>
                                    <Table size="small">
                                        <TableHead sx={{ backgroundColor: colorTable.header }}>
                                            <TableRow>
                                                <TableCell sx={{ color: colorTable.color }}>MES</TableCell>
                                                <TableCell sx={{ color: colorTable.color }} align="right">VENTAS</TableCell>
                                                <TableCell sx={{ color: colorTable.color }} align="right">CREC %</TableCell>
                                                <TableCell sx={{ color: colorTable.color }} align="right">GASTOS</TableCell>
                                                <TableCell sx={{ color: colorTable.color }} align="right">CREC %</TableCell>
                                                <TableCell sx={{ color: colorTable.color }} align="right">RES.</TableCell>
                                                <TableCell sx={{ color: colorTable.color }} align="right">CREC %</TableCell>
                                                <TableCell sx={{ color: colorTable.color, Width: "40px" }} />
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {ventasPorMes.map(detalle => {
                                                return <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }} key={detalle.mes}>
                                                    <TableCell component="th" scope="row">{detalle.periodo}</TableCell>
                                                    <TableCell component="th" scope="row" align="right">S/ {detalle.ventas}</TableCell>
                                                    <TableCell component="th" scope="row" align="right" sx={detalle.crec_ventas < 0 ? { color: "red" } : { color: "auto" }} >{detalle.crec_ventas}%</TableCell>
                                                    <TableCell component="th" scope="row" align="right">S/ {detalle.gastos}</TableCell>
                                                    <TableCell component="th" scope="row" align="right" sx={detalle.crec_gastos < 0 ? { color: "red" } : { color: "auto" }}>{detalle.crec_gastos}%</TableCell>
                                                    <TableCell component="th" scope="row" align="right">S/ {detalle.resumen}</TableCell>
                                                    <TableCell component="th" scope="row" align="right" sx={detalle.crec_resumen < 0 ? { color: "red" } : { color: "auto" }} >{detalle.crec_resumen}%</TableCell>
                                                    <TableCell component="th" scope="row" align="right"><Tooltip title='Ver detalles'><IconButton color='secondary' onClick={() => showDetails(anhoVentasgastos, detalle.mes, detalle.periodo)}><PreviewIcon /></IconButton></Tooltip></TableCell>
                                                </TableRow>
                                            })}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Grid>
                        </Grid>
                    </TabPanel>
                    <TabPanel value="2">
                        <Grid container>
                            <Dialog fullWidth maxWidth="md" open={detallesGrafBarr.showTab2} onClose={() => setDetallesGrafBarr({ ...detallesGrafBarr, showTab2: false })} TransitionComponent={Transition} >
                                <DialogTitle>
                                    <IconButton
                                        aria-label="close"
                                        onClick={() => setDetallesGrafBarr({ ...detallesGrafBarr, showTab2: false })}
                                        sx={{
                                            position: 'absolute',
                                            right: 8,
                                            top: 8,
                                            color: (theme) => theme.palette.grey[500],
                                        }}
                                    >
                                        <CloseIcon />
                                    </IconButton>
                                </DialogTitle>
                                <DialogContent>
                                    <GraficoBarra tipo="1" valuesGrafBarr={valuesGrafBarr} alertChartBarrAgreg={alertChartBarrAgreg} alertChartBarrRemov={alertChartBarrRemov} />
                                </DialogContent>
                            </Dialog>
                            <Grid container item xs={4} md={8} sx={{ justifyContent: { xs: 'start', md: 'start' } }}>
                                <FormControl sx={{ minWidth: "140px" }}>
                                    <InputLabel id="demo-simple-select-label-mes" >MES A REPORTAR</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-label-mes"
                                        id="demo-simple-select-mes"
                                        value={mesVentasgastos}
                                        label="MES A REPORTAR"
                                        onChange={handleChangeMes}
                                        size='small'
                                    >
                                        {meses.map(mes => {
                                            return <MenuItem key={mes.mes} value={mes.mes}>{mes.strMes}</MenuItem>
                                        })}
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid container item xs={8} md={4} spacing={0.4} sx={{ justifyContent: { xs: 'end', md: 'end' } }}>
                                <Grid item md={4}>
                                    {ventasPorMesAnho.length !== 0 ? generarPdf ?
                                        <PDFDownloadLink document={<ExportPDF tipo="ventaspormes" datosGeneral={ventasPorMesAnho} datosEmpresa={datosEmpresa} dataFechas={{ ventaspormes: mesVentasgastos }} />} fileName="ventas-mes">
                                            {({ loading }) => (loading ?
                                                <Grid sx={{ textDecoration: "none" }}>
                                                    <LoadingButton loading loadingPosition="start" startIcon={<Save />} variant="contained" fullWidth sx={{ display: { xs: "none", md: "flex" } }} >
                                                        GENERANDO
                                                    </LoadingButton>
                                                    <Button variant="contained" fullWidth color="error" sx={{ display: { xs: "flex", md: "none" } }}><PictureAsPdf sx={{ padding: "0 2px" }} /></Button>
                                                </Grid> :
                                                <Grid >
                                                    <Button variant="contained" fullWidth color="error" sx={{ display: { xs: "none", md: "flex" } }} ><SaveAltIcon sx={{ padding: "0 2px" }} />DESCARGAR</Button>
                                                    <Tooltip title="DESCARGAR PDF" placement="top">
                                                        <Button variant="contained" fullWidth color="error" sx={{ display: { xs: "flex", md: "none" } }}><SaveAltIcon sx={{ padding: "0 2px" }} /></Button>
                                                    </Tooltip>
                                                </Grid>
                                            )}
                                        </PDFDownloadLink> :
                                        <Grid>
                                            <Button variant="contained" fullWidth color="error" onClick={() => { setGenerarPdf(true); }} sx={{ display: { xs: "none", md: "flex" } }}><PictureAsPdf sx={{ padding: "0 2px" }} />GENERAR</Button>
                                            <Tooltip title="GENERAR PDF" placement="top">
                                                <Button variant="contained" fullWidth color="error" onClick={() => { setGenerarPdf(true); }} sx={{ display: { xs: "flex", md: "none" } }}><PictureAsPdf sx={{ padding: "0 2px" }} /></Button>
                                            </Tooltip>
                                        </Grid>
                                        :
                                        <Button variant="contained" fullWidth color="error" onClick={() => { alertPdf(); }}><PictureAsPdf sx={{ padding: "0 2px" }} />PDF</Button>
                                    }
                                </Grid>
                                <Grid item md={4}>
                                    {ventasPorMesAnho.length !== 0 ?
                                        <ExportExcel data={ventasPorMesAnho} nameReport="VENTAS-POR-MES" tipo="ventaspormes" />
                                        :
                                        <Tooltip title="EXPORTAR EXCEL" placement='top'>
                                            <Button variant='contained' fullWidth color="success" startIcon={<img style={{ width: '20px', height: '20px' }} src={excel} />} onClick={alertExcel}>EXCEL</Button>
                                        </Tooltip>
                                    }
                                </Grid>
                                <Grid item md={4}>
                                    <Button variant='contained' fullWidth color="primary" onClick={() => { mostrarGrafico(2); }} sx={{ display: { xs: "none", md: "flex" } }}><BarChartIcon sx={{ padding: "0 2px" }} />GRAFICO</Button>
                                    <Tooltip title="VER GRAFICO" placement='top'>
                                        <Button variant='contained' fullWidth color="primary" onClick={() => { mostrarGrafico(2); }} sx={{ display: { xs: "flex", md: "none" } }}><BarChartIcon sx={{ padding: "0 2px" }} /></Button>
                                    </Tooltip>
                                </Grid>
                            </Grid>
                            <Grid container sx={{ boxShadow: "0 4px 4px -4px rgba(0, 0, 0, 0.4)", paddingBottom: "20px" }}></Grid>
                            <Grid item xs={12} sx={{ paddingTop: "20px" }}>
                                <TableContainer>
                                    <Table size="small">
                                        <TableHead sx={{ backgroundColor: colorTable.header }}>
                                            <TableRow>
                                                <TableCell sx={{ color: colorTable.color }}>AÑO</TableCell>
                                                <TableCell sx={{ color: colorTable.color }} align='right'>VENTAS</TableCell>
                                                <TableCell sx={{ color: colorTable.color }} align='right'>CREC %</TableCell>
                                                <TableCell sx={{ color: colorTable.color }} align='right'>GASTOS</TableCell>
                                                <TableCell sx={{ color: colorTable.color }} align='right'>CREC %</TableCell>
                                                <TableCell sx={{ color: colorTable.color }} align='right'>RES.</TableCell>
                                                <TableCell sx={{ color: colorTable.color }} align='right'>CREC %</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {ventasPorMesAnho.map(detalle => {
                                                return <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }} key={detalle.anho}>
                                                    <TableCell component="th" scope="row">{detalle.anho}</TableCell>
                                                    <TableCell component="th" scope="row" align='right'>S/ {detalle.ventas}</TableCell>
                                                    <TableCell component="th" scope="row" align='right' sx={detalle.crec_ventas < 0 ? { color: "red" } : { color: "auto" }}>{detalle.crec_ventas}%</TableCell>
                                                    <TableCell component="th" scope="row" align='right'>S/ {detalle.gastos}</TableCell>
                                                    <TableCell component="th" scope="row" align='right' sx={detalle.crec_gastos < 0 ? { color: "red" } : { color: "auto" }}>{detalle.crec_gastos}%</TableCell>
                                                    <TableCell component="th" scope="row" align='right'>S/ {detalle.resumen}</TableCell>
                                                    <TableCell component="th" scope="row" align='right' sx={detalle.crec_resumen < 0 ? { color: "red" } : { color: "auto" }}>{detalle.crec_resumen}%</TableCell>
                                                </TableRow>
                                            })}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Grid>
                        </Grid>
                        {/* <Grid>
                            <PDFViewer width="100%" height={600}>
                                <ExportPDF tipo="ventaspormes" datosGeneral={ventasPorMesAnho} datosEmpresa={datosEmpresa} dataFechas={{ ventaspormes: mesVentasgastos }} />
                            </PDFViewer>
                        </Grid> */}
                    </TabPanel>
                    <TabPanel value="3">
                        <Grid container sx={{ justifyContent: "space-between" }}>
                            <Dialog fullWidth maxWidth="md" open={detallesGrafBarr.showTab3} onClose={() => setDetallesGrafBarr({ ...detallesGrafBarr, showTab3: false })} TransitionComponent={Transition} >
                                <DialogTitle>
                                    <IconButton
                                        aria-label="close"
                                        onClick={() => setDetallesGrafBarr({ ...detallesGrafBarr, showTab3: false })}
                                        sx={{
                                            position: 'absolute',
                                            right: 8,
                                            top: 8,
                                            color: (theme) => theme.palette.grey[500],
                                        }}
                                    >
                                        <CloseIcon />
                                    </IconButton>
                                </DialogTitle>
                                <DialogContent>
                                    {/* <GraficoBarra valuesGrafBarr={valuesGrafBarr} alertChartBarrAgreg={alertChartBarrAgreg} alertChartBarrRemov={alertChartBarrRemov} /> */}
                                    <GraficoLineal valuesGrafLine={valuesGrafBarr} alertChartBarrAgreg={alertChartBarrAgreg} alertChartBarrRemov={alertChartBarrRemov} />
                                </DialogContent>
                            </Dialog>
                            {/* <Grid item xs={6}></Grid> */}
                            <Grid container item xs={4} md={8} sx={{ justifyContent: { xs: 'start', md: 'start' } }}>
                                <Tooltip title="Actualizar">
                                    <Button variant='contained' color='primary' onClick={actualizarResumen} sx={{ display: { xs: "none", md: "flex" } }} ><RefreshIcon />ACTUALIZAR</Button>
                                </Tooltip>
                                <Button variant='contained' color='primary' onClick={actualizarResumen} sx={{ display: { xs: "flex", md: "none" }, height: "37px" }} ><RefreshIcon /></Button>
                            </Grid>
                            <Grid container item xs={8} md={4} spacing={0.4} sx={{ justifyContent: { xs: 'end', md: 'end' } }} >
                                <Grid item md={4}>
                                    {resumenVentasGastos.length !== 0 ? generarPdf ?
                                        <PDFDownloadLink document={<ExportPDF tipo="resumenporanho" datosGeneral={resumenVentasGastos} datosEmpresa={datosEmpresa} />} fileName="resumen-por-año">
                                            {({ loading }) => (loading ?
                                                <Grid sx={{ textDecoration: "none" }}>
                                                    <LoadingButton loading loadingPosition="start" startIcon={<Save />} variant="contained" fullWidth sx={{ display: { xs: "none", md: "flex" } }} >
                                                        GENERANDO
                                                    </LoadingButton>
                                                    <Button variant="contained" fullWidth color="error" sx={{ display: { xs: "flex", md: "none" } }}><PictureAsPdf sx={{ padding: "0 2px" }} /></Button>
                                                </Grid> :
                                                <Grid >
                                                    <Button variant="contained" fullWidth color="error" sx={{ display: { xs: "none", md: "flex" } }} ><SaveAltIcon sx={{ padding: "0 2px" }} />DESCARGAR</Button>
                                                    <Tooltip title="DESCARGAR PDF" placement="top">
                                                        <Button variant="contained" fullWidth color="error" sx={{ display: { xs: "flex", md: "none" } }}><SaveAltIcon sx={{ padding: "0 2px" }} /></Button>
                                                    </Tooltip>
                                                </Grid>
                                            )}
                                        </PDFDownloadLink> :
                                        <Grid>
                                            <Button variant="contained" fullWidth color="error" onClick={() => { setGenerarPdf(true); }} sx={{ display: { xs: "none", md: "flex" } }}><PictureAsPdf sx={{ padding: "0 2px" }} />GENERAR</Button>
                                            <Tooltip title="GENERAR PDF" placement="top">
                                                <Button variant="contained" fullWidth color="error" onClick={() => { setGenerarPdf(true); }} sx={{ display: { xs: "flex", md: "none" } }}><PictureAsPdf sx={{ padding: "0 2px" }} /></Button>
                                            </Tooltip>
                                        </Grid>
                                        :
                                        <Button variant="contained" fullWidth color="error" onClick={() => { alertPdf(); }}><PictureAsPdf sx={{ padding: "0 2px" }} />PDF</Button>
                                    }
                                </Grid>
                                <Grid item md={4}>
                                    {resumenVentasGastos.length !== 0 ?
                                        <ExportExcel data={resumenVentasGastos} nameReport="RESUMEN-POR-AÑO" tipo="resumenporanho" />
                                        :
                                        <Tooltip title="EXPORTAR EXCEL" placement='top'>
                                            <Button variant='contained' fullWidth color="success" startIcon={<img style={{ width: '20px', height: '20px' }} src={excel} />} onClick={alertExcel}>EXCEL</Button>
                                        </Tooltip>
                                    }
                                </Grid>
                                <Grid item md={4}>
                                    <Button variant='contained' fullWidth color="primary" onClick={() => { mostrarGrafico(3); }} sx={{ display: { xs: "none", md: "flex" } }}><BarChartIcon sx={{ padding: "0 2px" }} />GRAFICO</Button>
                                    <Tooltip title="VER GRAFICO" placement='top'>
                                        <Button variant='contained' fullWidth color="primary" onClick={() => { mostrarGrafico(3); }} sx={{ display: { xs: "flex", md: "none" } }}><BarChartIcon sx={{ padding: "0 2px" }} /></Button>
                                    </Tooltip>
                                </Grid>
                            </Grid>
                            <Grid container sx={{ boxShadow: "0 4px 4px -4px rgba(0, 0, 0, 0.4)", paddingBottom: "20px" }}></Grid>
                            <Grid item xs={12} sx={{ paddingTop: "20px" }}>
                                <TableContainer>
                                    <Table size="small">
                                        <TableHead sx={{ backgroundColor: colorTable.header }}>
                                            <TableRow>
                                                <TableCell sx={{ color: colorTable.color }} align='center'>AÑO</TableCell>
                                                <TableCell sx={{ color: colorTable.color }} align='right'>VENTAS</TableCell>
                                                <TableCell sx={{ color: colorTable.color }} align='right'>CREC %</TableCell>
                                                <TableCell sx={{ color: colorTable.color }} align='right'>GASTOS</TableCell>
                                                <TableCell sx={{ color: colorTable.color }} align='right'>CREC %</TableCell>
                                                <TableCell sx={{ color: colorTable.color }} align='right'>RES.</TableCell>
                                                <TableCell sx={{ color: colorTable.color }} align='right'>CREC %</TableCell>
                                                <TableCell sx={{ color: colorTable.color, Width: "40px" }} align='right' />
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {resumenVentasGastos.map(detalle => {
                                                return <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }} key={detalle.anho}>
                                                    <TableCell component="th" scope="row" align='center'>{detalle.anho}</TableCell>
                                                    <TableCell component="th" scope="row" align='right'>S/ {detalle.ventas}</TableCell>
                                                    <TableCell component="th" scope="row" align='right' sx={detalle.crec_ventas < 0 ? { color: "red" } : { color: "auto" }}>{detalle.crec_ventas}%</TableCell>
                                                    <TableCell component="th" scope="row" align='right'>S/ {detalle.gastos}</TableCell>
                                                    <TableCell component="th" scope="row" align='right' sx={detalle.crec_gastos < 0 ? { color: "red" } : { color: "auto" }}>{detalle.crec_gastos}%</TableCell>
                                                    <TableCell component="th" scope="row" align='right'>S/ {detalle.resumen}</TableCell>
                                                    <TableCell component="th" scope="row" align='right' sx={detalle.crec_ventas < 0 ? { color: "red" } : { color: "auto" }}>{detalle.crec_resumen}%</TableCell>
                                                    <TableCell component="th" scope="row" align='right'><Tooltip title='ver_pagos'><IconButton color='secondary'><PreviewIcon /></IconButton></Tooltip></TableCell>
                                                </TableRow>
                                            })}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Grid>
                        </Grid>
                    </TabPanel>
                    <TabPanel value="4">
                        <Grid container sx={{ justifyContent: "space-between" }}>
                            <Dialog fullWidth maxWidth="md" open={detallesGrafBarr.showTab4} onClose={() => setDetallesGrafBarr({ ...detallesGrafBarr, showTab4: false })} TransitionComponent={Transition} >
                                <DialogTitle>
                                    <IconButton
                                        aria-label="close"
                                        onClick={() => setDetallesGrafBarr({ ...detallesGrafBarr, showTab4: false })}
                                        sx={{
                                            position: 'absolute',
                                            right: 8,
                                            top: 8,
                                            color: (theme) => theme.palette.grey[500],
                                        }}
                                    >
                                        <CloseIcon />
                                    </IconButton>
                                </DialogTitle>
                                <DialogContent>
                                    <GraficoBarra tipo="2" valuesGrafBarr={valuesGrafBarr2} alertChartBarrAgreg={alertChartBarrAgreg} alertChartBarrRemov={alertChartBarrRemov} />
                                    {/* <GraficoLineal valuesGrafLine={valuesGrafLine} alertChartBarrAgreg={alertChartBarrAgreg} alertChartBarrRemov={alertChartBarrRemov} /> */}
                                </DialogContent>
                            </Dialog>
                            <Grid container item xs={4} md={8} sx={{ justifyContent: { xs: 'start', md: 'start' } }}>
                                <FormControl sx={{ minWidth: "100px" }}>
                                    <InputLabel id="demo-simple-select-label-tipo">AÑO</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        value={anhoTipoPago}
                                        label="AÑO"
                                        onChange={handleChangeAnhoTipo}
                                        size='small'
                                    >
                                        {anhos.map(anho => {
                                            return <MenuItem key={anho.anho} value={anho.anho}>{anho.anho}</MenuItem>
                                        })}
                                    </Select>
                                </FormControl>

                            </Grid>
                            <Grid container item xs={8} md={4} spacing={0.4} sx={{ justifyContent: { xs: 'end', md: 'end' } }}>
                                <Grid item md={4}>
                                    {resumenTipoPago.length !== 0 ? generarPdf ?
                                        <PDFDownloadLink document={<ExportPDF tipo="resumentipopago" datosGeneral={resumenTipoPago} datosEmpresa={datosEmpresa} dataFechas={{ tipopagoanho: anhoTipoPago }} />} fileName="resumen-tipo-pagos">
                                            {({ loading }) => (loading ?
                                                <Grid sx={{ textDecoration: "none" }}>
                                                    <LoadingButton loading loadingPosition="start" startIcon={<Save />} variant="contained" fullWidth sx={{ display: { xs: "none", md: "flex" } }} >
                                                        GENERANDO
                                                    </LoadingButton>
                                                    <Button variant="contained" fullWidth color="error" sx={{ display: { xs: "flex", md: "none" } }}><PictureAsPdf sx={{ padding: "0 2px" }} /></Button>
                                                </Grid> :
                                                <Grid >
                                                    <Button variant="contained" fullWidth color="error" sx={{ display: { xs: "none", md: "flex" } }} ><SaveAltIcon sx={{ padding: "0 2px" }} />DESCARGAR</Button>
                                                    <Tooltip title="DESCARGAR PDF" placement="top">
                                                        <Button variant="contained" fullWidth color="error" sx={{ display: { xs: "flex", md: "none" } }}><SaveAltIcon sx={{ padding: "0 2px" }} /></Button>
                                                    </Tooltip>
                                                </Grid>
                                            )}
                                        </PDFDownloadLink> :
                                        <Grid>
                                            <Button variant="contained" fullWidth color="error" onClick={() => { setGenerarPdf(true); }} sx={{ display: { xs: "none", md: "flex" } }}><PictureAsPdf sx={{ padding: "0 2px" }} />GENERAR</Button>
                                            <Tooltip title="GENERAR PDF" placement="top">
                                                <Button variant="contained" fullWidth color="error" onClick={() => { setGenerarPdf(true); }} sx={{ display: { xs: "flex", md: "none" } }}><PictureAsPdf sx={{ padding: "0 2px" }} /></Button>
                                            </Tooltip>
                                        </Grid>
                                        :
                                        <Button variant="contained" fullWidth color="error" onClick={() => { alertPdf(); }}><PictureAsPdf sx={{ padding: "0 2px" }} />PDF</Button>
                                    }
                                </Grid>
                                <Grid item md={4}>
                                    {resumenTipoPago.length !== 0 ?
                                        <ExportExcel data={resumenTipoPago} nameReport="RESUMEN-TIPO-PAGO" tipo="resumentipopago" dataFechas={{ tipopagoanho: anhoTipoPago }} />
                                        :
                                        <Tooltip title="EXPORTAR EXCEL" placement='top'>
                                            <Button variant='contained' color="success" startIcon={<img style={{ width: '20px', height: '20px' }} src={excel} />} onClick={alertExcel}>EXCEL</Button>
                                        </Tooltip>
                                    }
                                </Grid>
                                <Grid item md={4}>
                                    <Button variant='contained' fullWidth color="primary" onClick={() => mostrarGrafico(4)} sx={{ display: { xs: "none", md: "flex" } }}><BarChartIcon sx={{ padding: "0 2px" }} />GRAFICO</Button>
                                    <Tooltip title="VER GRAFICO" placement='top'>
                                        <Button variant='contained' fullWidth color="primary" onClick={() => mostrarGrafico(4)} sx={{ display: { xs: "flex", md: "none" } }}><BarChartIcon sx={{ padding: "0 2px" }} /></Button>
                                    </Tooltip>
                                </Grid>
                            </Grid>
                            <Grid container sx={{ boxShadow: "0 4px 4px -4px rgba(0, 0, 0, 0.4)", paddingBottom: "20px" }}></Grid>
                            <Grid item xs={12} sx={{ padding: "20px 0" }}>
                                <TableContainer>
                                    <Table size="small">
                                        <TableHead sx={{ backgroundColor: colorTable.header }} >
                                            <TableRow>
                                                <TableCell sx={{ color: colorTable.color }}>MES</TableCell>
                                                <TableCell sx={{ color: colorTable.color }} align='right'>CONTADO</TableCell>
                                                <TableCell sx={{ color: colorTable.color }} align='right'>POS</TableCell>
                                                <TableCell sx={{ color: colorTable.color }} align='right'>DEP/TRANF</TableCell>
                                                <TableCell sx={{ color: colorTable.color }} align='right'>VENTAS</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {resumenTipoPago.map(detalle => {
                                                return <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }} key={detalle.mes}>
                                                    <TableCell sx={{ width: "10%" }} component="th" scope="row" >{detalle.periodo}</TableCell>
                                                    <TableCell sx={{ width: "20%" }} component="th" scope="row" align='right'>S/ {detalle.efectivo}</TableCell>
                                                    <TableCell sx={{ width: "20%" }} component="th" scope="row" align='right'>S/ {detalle.tarjeta}</TableCell>
                                                    <TableCell sx={{ width: "20%" }} component="th" scope="row" align='right'>S/ {detalle.deposito}</TableCell>
                                                    <TableCell sx={{ width: "20%" }} component="th" scope="row" align='right'>S/ {detalle.total}</TableCell>
                                                </TableRow>
                                            })}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Grid>
                        </Grid>
                        {/* <Grid>
                            <PDFViewer width="100%" height={600}>
                                <ExportPDF tipo="resumentipopago" datosGeneral={resumenTipoPago} datosEmpresa={datosEmpresa} dataFechas={{ tipopagoanho: anhoTipoPago }} />
                            </PDFViewer>
                        </Grid> */}
                    </TabPanel>
                </TabContext>
                <Grid container spacing={2}>
                </Grid>
            </Paper>
        </Grid >

    )
}

export default VentasGastos