import { Button, Collapse, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid, IconButton, Slide, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Tooltip } from '@mui/material'
import React, { useEffect } from 'react'
import SaveOutlinedIcon from '@mui/icons-material/SaveOutlined';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import Constantes from '../Constantes';
import SearchIcon from '@mui/icons-material/Search';
import AddBoxOutlinedIcon from '@mui/icons-material/AddBoxOutlined';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
// import {Dimensions} from 'react-native';
function Proveedor(props) {
  const logedUserJSON = window.sessionStorage.getItem('logedAppUser');
  const user = JSON.parse(logedUserJSON);
  const hoy = new Date();
  const strFecha = `${hoy.getFullYear()}-${(hoy.getMonth() + 1).toString().padStart(2, '0')}-${hoy.getDate().toString().padStart(2, '0')}`;
  const [search, setSearch] = React.useState("");
  const [textDialog,setTextDialog] = React.useState('NUEVO');
  const [proveedorDB, setProveedorDB] = React.useState([]);
  const [confirmacion, setConfirmacion] = React.useState({
    show: false,
    message: "",
    title: "",
  });
  const [pregunta, setPregunta] = React.useState({
    show: false,
    message: "",
    title: "",
    action: ""
  })
  const [proveedor, setProveedor] = React.useState({
    id: -1,
    razon_social: "",
    numero_documento: "",
    direccion: "",
    telefono: "",
    observaciones: "",
    fk_log_usuario_modifica: user.usuario,
    fk_log_usuario_creador: user.usuario,
    es_activo: 1,
    codigo_old: ""
  });
  useEffect((e) => {
    const fillData = async () => {
      const proveedorFetch = await fetch(`${Constantes.RUTA_API}listar/listarProveedorActivo.php`);
      const listProveedorDB = await proveedorFetch.json();
      setProveedorDB(listProveedorDB);
    }
    fillData();
    console.log(1);
  }, []);
  const [showProveedor, setShowProveedor] = React.useState(false);

  const handleNuevoProveedor = () => {
    setTextDialog('NUEVO');
    setShowProveedor(true);
  }
  const handleChangeSearch = (e) => {
    setSearch(e.target.value.toUpperCase());
  }
  const handleCancel = () => {
    setProveedor({
      id: -1,
      razon_social: "",
      numero_documento: "",
      direccion: "",
      telefono: "",
      observaciones: "",
      fk_log_usuario_modifica: user.usuario,
      fk_log_usuario_creador: user.usuario,
      es_activo: 1,
      codigo_old: ""
    });
    setShowProveedor(false);
  }
  const handleChangeProveedor = (e) => {
    var id = e.target.id;
    let valor = e.target.value;
    setProveedor((proveedorAnt) => ({
      ...proveedorAnt,
      [id]: valor,
    }));
  }
  const editProveedor = (prov) => {
    setTextDialog('EDITAR');
    setProveedor(prov);
    setShowProveedor(true);
  }
  const saveProveedor = () => {
    setPregunta({ show: true, message: "Esta seguro que desea guardar el Proveedor", title: "Guardar", action: "S" });
  }
  const deleteProveedor = (prov) => {
    setProveedor(prov);
    setPregunta({
      show: true,
      message: "Está a punto de eliminar un proveedor, ésta accion no se puede deshacer. ¿Desea continuar?",
      title: "Eliminar",
      action: "D"
    });
  }
  const searchProveedor = async () => {
    const proveedorFetch = await fetch(`${Constantes.RUTA_API}listar/listarProveedorActivo.php`);
    const listProveedorDB = await proveedorFetch.json();
    setProveedorDB(listProveedorDB.filter(prov => prov.razon_social.includes(search)));
  }
  const procesarSolicitud = async () => {
    if (pregunta.action === "S") {
      const newProveedor = {
        id: proveedor.id,
        razon_social: proveedor.razon_social.toUpperCase(),
        numero_documento: proveedor.numero_documento.toUpperCase(),
        direccion: proveedor.direccion.toUpperCase(),
        telefono: proveedor.telefono.toUpperCase(),
        observaciones: proveedor.observaciones.toUpperCase(),
        fk_log_usuario_modifica: user.usuario,
        fk_log_usuario_creador: proveedor.fk_log_usuario_creador,
        es_activo: 1,
        codigo_old: proveedor.codigo_old.toUpperCase(),
      }
      const guardarProveedor = await fetch(`${Constantes.RUTA_API}mantenimiento/guardarProveedor.php`, {
        method: "POST",
        body: JSON.stringify(newProveedor),
      });
      const jsonProveedor = await guardarProveedor.json();
      if (jsonProveedor.guardado) {
        setConfirmacion({
          show: true,
          message: jsonProveedor.message,
          title: "GUARDADO",
        })
      } else {
        setConfirmacion({
          show: true,
          message: jsonProveedor.message,
          title: "ERROR",
        })
      }
    }
    if (pregunta.action === "D") {
      const proveedorFetch = await fetch(`${Constantes.RUTA_API}mantenimiento/eliminarProveedor.php?id=${proveedor.id}&usuario=${user.usuario}`);
      const proveedorDeleted = await proveedorFetch.json();
      if (proveedorDeleted) {
        const proveedorFetchDB = await fetch(`${Constantes.RUTA_API}listar/listarProveedorActivo.php`);
        const listProveedorDB = await proveedorFetchDB.json();
        setSearch("");
        setProveedorDB(listProveedorDB);
        setConfirmacion({
          show: true,
          message: "Proveedor eliminado con éxito.",
          title: "Eliminado",
        })
      } else {
        setConfirmacion({
          show: true,
          message: "Se produjo un error no se pudo eliminar el proveedor.",
          title: "ERROR",
        })
      }
    }
  }
  const handleClosePregunta = async () => {
    setPregunta({ show: false, message: "", title: "", action: "" });
    setConfirmacion({
      show: false,
      message: "",
      title: "",
    })
    setShowProveedor(false);
  }
  return (
    <Grid container spacing={1}>
      <Dialog open={confirmacion.show}>
        <DialogTitle>{confirmacion.title}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {confirmacion.message}
          </DialogContentText>
        </DialogContent>
        <DialogActions sx={{ alignItems: "center" }}>
          <Button variant='outlined' color='success' onClick={handleClosePregunta}>OK</Button>
        </DialogActions>
      </Dialog>
      <Dialog open={pregunta.show}>
        <DialogTitle>{pregunta.title}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {pregunta.message}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button variant='outlined' color='success' onClick={procesarSolicitud}>SI</Button>
          <Button variant='outlined' color='error' onClick={handleClosePregunta}>NO</Button>
        </DialogActions>
      </Dialog>
      <Dialog open={showProveedor}>
        <DialogTitle>{textDialog} PROVEEDOR</DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={7}>
              <TextField
                label="Razon Social:"
                fullWidth
                variant='filled'
                type="text"
                id='razon_social'
                value={proveedor.razon_social}
                onChange={handleChangeProveedor}
                inputProps={{ style: { textTransform: "uppercase" } }}
              />
            </Grid>
            <Grid item xs={12} sm={5}>
              <TextField
                label="RUC:"
                fullWidth
                variant='filled'
                type="text"
                id='numero_documento'
                value={proveedor.numero_documento}
                onChange={handleChangeProveedor}
                inputProps={{ style: { textTransform: "uppercase" } }}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextField
                label="Telefono:"
                fullWidth
                variant='filled'
                type="text"
                id='telefono'
                value={proveedor.telefono}
                onChange={handleChangeProveedor}
                inputProps={{ style: { textTransform: "uppercase" } }}
              />
            </Grid>
            <Grid item xs={12} sm={8}>
              <TextField
                label="Direccion:"
                fullWidth
                variant='filled'
                type="text"
                id='direccion'
                value={proveedor.direccion}
                onChange={handleChangeProveedor}
                inputProps={{ style: { textTransform: "uppercase" } }}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Observaciones:"
                fullWidth
                variant='filled'
                type="text"
                id='observaciones'
                value={proveedor.observaciones}
                onChange={handleChangeProveedor}
                inputProps={{ style: { textTransform: "uppercase" } }}
              />
            </Grid>
            <Grid item xs={6} align="right">
              <Button variant='outlined' color='success' startIcon={<SaveOutlinedIcon></SaveOutlinedIcon>}
                onClick={saveProveedor}>
                Guardar
              </Button>
            </Grid>
            <Grid item xs={6}>
              <Button variant='outlined' color='error' startIcon={<CancelOutlinedIcon></CancelOutlinedIcon>} onClick={handleCancel}> Cancelar</Button>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
      <Grid container item xs>
        <Grid item xs={10}>
          <TextField
            label="Razon Social:"
            fullWidth
            variant='filled'
            type="text"
            id='search'
            value={search}
            onChange={handleChangeSearch}
          />
        </Grid>
        <Grid item xs={1}>
          <Tooltip title='BUSCAR'>
            <IconButton color='primary' size='large' onClick={searchProveedor}> <SearchIcon /></IconButton>
          </Tooltip>
        </Grid>
        <Grid item xs={1}>
          <Tooltip title='NUEVO'>
            <IconButton onClick={handleNuevoProveedor} color='success' size='large' ><AddBoxOutlinedIcon /></IconButton>
          </Tooltip>
        </Grid>
        <TableContainer sx={{ maxHeight: 400 }} size="small">
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                <TableCell>RAZON SOCIAL</TableCell>
                <TableCell>RUC</TableCell>
                <TableCell>TELEFONO</TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {proveedorDB.map((prov) => (
                <TableRow id={prov.id} key={prov.id}>
                  <TableCell>{prov.razon_social}</TableCell>
                  <TableCell>{prov.numero_documento}</TableCell>
                  <TableCell>{prov.telefono}</TableCell>
                  <TableCell>
                    <IconButton onClick={() => editProveedor(prov)} color='primary'><EditIcon /></IconButton>
                    <IconButton onClick={() => deleteProveedor(prov)} color='error'><DeleteIcon /></IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
    </Grid>
  )
}

export default Proveedor;