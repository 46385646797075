import * as React from 'react';
import { IconButton, TableCell, TableRow, Link, Tooltip } from '@mui/material';
import RestoreIcon from '@mui/icons-material/Restore';

export default function FilaListarUsuarioE(props) {
    return (
        <TableRow key={props.usuario.codigo}>
            <TableCell>{props.usuario.codigo}</TableCell>
            <TableCell>{props.usuario.fk_log_perfil}</TableCell>
            <TableCell>{props.usuario.fk_log_usuario_creado_por}</TableCell>
            <TableCell>{props.usuario.deleted_at}</TableCell>
            <TableCell align="right">
                <Tooltip title="RESTAURAR USUARIO" placement='top' onClick={() => props.handleRestoreUsuario(props.usuario.codigo)}>
                    <IconButton color="info"><RestoreIcon /></IconButton>
                </Tooltip>
                {/* <IconButton color="error" onClick={() => props.handleClickDel(props.usuario.codigo)}  ><DeleteIcon /></IconButton> */}
            </TableCell>
        </TableRow>
    )
}