import * as React from 'react';
import * as FileSaver from "file-saver";
import * as XLSX from "sheetjs-style";
import { Button, Tooltip } from '@mui/material';
import excel from '../../../images/exportarPdfExcel/excel.png'

export default function ReporteCajaExcel(props) {
    const fileType = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    const fileExtension = ".xlsx";
    const datosCaja = props.datosCaja.fecha_apertura;
    const [datosReporte, setDatosReporte] = React.useState([]);
    const exportToCSV = () => {
        const csvData = props.pagosCajaCobranza;
        const setAlertGeneral = props.setAlertGeneral;
        if (csvData.length > 0) {
            let datosR = [].concat(datosReporte);
            csvData.forEach((d) => {
                datosR.push({
                    'N° pago': d.id_pago,
                    'Fecha registro': d.fecha_registro + " " + d.hora_registro,
                    'N° couta': d.cuota,
                    'Tratamiento': d.tratamiento,
                    'Cliente': d.cliente,
                    'Controles': d.controles,
                    'Atendido por': d.atendido_por,
                    'Presupuesto': d.presupuesto,
                    'Pagado': d.total_pago,
                });
            });
            datosR.push({
                'N° pago': "",
                'Fecha registro': "TOTAL",
                'N° couta': "",
                'Tratamiento': "",
                'Cliente': "",
                'Controles': "",
                'Atendido por': "",
                'Presupuesto': "",
                'Pagado': "S/" + csvData.reduce((total, item) => parseFloat(total) + parseFloat(item.total_pago), 0).toFixed(2),
            });
            setDatosReporte(datosR);
            const ws = XLSX.utils.json_to_sheet(datosR, { skipHeader: false });
            var wscols = [
                { wch: 8 },
                { wch: 20 },
                { wch: 8 },
                { wch: 40 },
                { wch: 40 },
                { wch: 8 },
                { wch: 40 },
                { wch: 10 },
                { wch: 10 },
            ];
            ws['!cols'] = wscols;
            const wb = { Sheets: { exportar: ws }, SheetNames: ['exportar'] };
            const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
            const data = new Blob([excelBuffer], { type: fileType });
            FileSaver.saveAs(data, 'RptCajaCobranza-' + datosCaja + fileExtension);
            setDatosReporte([]);
        } else {
            setAlertGeneral({ message: 'No se encontraron pagos registrados para la caja selecionada', severity: 'warning', show: true });
            setTimeout(() => { setAlertGeneral({ show: false, message: '', severity: 'warning' }) }, 3000);
        }

    };
    return <Button startIcon={<img style={{ width: '20px', height: '20px' }} src={excel} />} fullWidth variant='contained' color="success" onClick={exportToCSV}>EXCEL</Button>
}