import { Alert, Button, Collapse, Container, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid, IconButton, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, TableSortLabel, TextField, Typography } from '@mui/material';
import React, { Component } from 'react'
import Constantes from '../components/Constantes';
import "../css/mainStyles.css";
import { Box } from '@mui/system';
import { visuallyHidden } from '@mui/utils';
import FilaListarContacto from '../components/contacto/filaListarContacto';
import CloseIcon from '@mui/icons-material/Close';
import CancelIcon from '@mui/icons-material/Cancel';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ContactSupportIcon from '@mui/icons-material/ContactSupport';
import EditarContacto from '../components/contacto/editarContacto';
import { Close } from '@mui/icons-material';

class Contacto extends Component {
    constructor(props) {
        super(props);
        this.state = {
            order: "",
            orderBy: "",
            search: "",
            currentPage: 0,
            contactos: [],
            rowsPerPage: 10,
            codContactoSeleccionado: "test",
            toastSeverity: "success",
            messageToast: "",
            showToast: false,
            showDialog: false,
            showContacto: false,
            page: 0,
            rowsConPage: 10,
            codigoContacto: 10,
            filtroContacto: [],
        }
        this.confirmarEliminarContacto = this.confirmarEliminarContacto.bind(this);
        this.eliminarContacto = this.eliminarContacto.bind(this);
    }
    componentDidMount() {
        this.listarContactos();
    }

    listarContactos = async () => {
        const respuesta = await fetch(`${Constantes.RUTA_API}listar/listarContacto.php`);
        const contactos = await respuesta.json();
        this.setState({
            contactos: contactos
        })
    }
    confirmarEliminarContacto = codigo => {
        this.setState({
            codContactoSeleccionado: codigo,
            showDialog: true,
        });
    }
    async eliminarContacto() {
        const respuesta = await fetch(`${Constantes.RUTA_API}mantenimiento/eliminarContacto.php?codigo=${this.state.codContactoSeleccionado}`);
        const exitoso = await respuesta.json();
        if (exitoso.executed) {
            const newContactos = this.state.contactos.filter(conta => conta.codigo !== this.state.codContactoSeleccionado);
            this.setState({
                contactos: newContactos,
                toastSeverity: "success",
                showToast: true,
                messageToast: exitoso.message,
                showDialog: false,
            })
        } else {
            this.setState({
                toastSeverity: "error",
                showToast: true,
                messageToast: exitoso.message,
                showDialog: false,
            })
        }
        setTimeout(() => {
            this.setState({
                toastSeverity: "success",
                messageToast: "",
                showToast: false,
            })
        }, 3000);

    }
    render() {
        const headCells = [
            {
                id: 'codigo',
                numeric: false,
                disablePadding: true,
                label: 'CODIGO',
            },
            {
                id: 'apellidos_nombres',
                numeric: false,
                disablePadding: true,
                label: 'NOMBRES Y APELLIDOS',
            },
            {
                id: 'telefono_1',
                numeric: false,
                disablePadding: true,
                label: 'TELEFONO',
            },
            {
                id: 'estado',
                numeric: false,
                disablePadding: true,
                label: 'ESTADO',
            },
            {
                id: 'fk_com_referencia',
                numeric: false,
                disablePadding: true,
                label: 'REFERENCIA',
            },
        ];
        const handleChangePage = (event, newPage) => {
            this.setState({ page: newPage });
        };
        const handleChangeRowsPerPage = (event) => {
            this.setState({ rowsConPage: parseInt(event.target.value, 10), page: 0 });
        };
        const filteredContactos = () => {
            if (this.state.search === "") {
                return this.state.contactos;
            } else {
                let filtro = this.state.contactos.filter(f => f.apellidos_nombre.includes(this.state.search.toUpperCase()));
                return filtro;
            }
        }
        const onSearchChange = (evento) => {
            let valor = evento.target.value;
            if (valor !== "") {
                this.setState({
                    search: valor,
                });
            } else {
                this.setState({
                    search: '',
                    filtroContacto: []
                });
            }
        }
        const createSortHandler = (property) => (event) => {
            handleRequestSort(event, property);
        };
        const handleRequestSort = (event, property) => {
            const isAsc = this.state.orderBy === property && this.state.order === 'asc';
            this.setState({
                order: isAsc ? 'desc' : 'asc',
                orderBy: property,
            })
        };
        const dialogClienteEdit = (codigo) => {
            this.setState({ showContacto: true, codigoContacto: codigo });
        }
        return (
            <Container sx={{ marginTop: "70px", paddingBottom: "10px" }}>
                <Grid container spacing={1}>
                    <Grid item xs={12}>
                        <Dialog
                            open={this.state.showDialog}
                            onClose={() => this.setState({ showDialog: false })}
                            aria-labelledby="alert-dialog-title"
                            aria-describedby="alert-dialog-description"
                        >
                            <DialogTitle id="alert-dialog-title" sx={{ textAlign: "center" }}>
                                <ContactSupportIcon fontSize="large" color='primary' />
                            </DialogTitle>
                            <DialogContent>
                                <DialogContentText id="alert-dialog-description" style={{ textAlign: 'center' }}>
                                    {`¿Seguro que desea eliminar el contacto ${this.state.codContactoSeleccionado}?`}
                                </DialogContentText>
                            </DialogContent>
                            <DialogActions style={{ justifyContent: 'center' }}>
                                <Button onClick={this.eliminarContacto} autoFocus startIcon={<CheckCircleIcon />} variant='contained' color='success'>
                                    Aceptar
                                </Button>
                                <Button onClick={() => this.setState({ showDialog: false })} startIcon={<CancelIcon />} variant='contained' color='error'>
                                    Cancelar
                                </Button>
                            </DialogActions>
                        </Dialog>
                        <Dialog open={this.state.showContacto} maxWidth="md">
                            <DialogTitle>
                                EDITAR CONTACTO
                                <IconButton
                                    aria-label="close"
                                    onClick={() => { this.setState({ showContacto: false }) }}
                                    sx={{
                                        position: 'absolute',
                                        right: 8,
                                        top: 8,
                                        color: (theme) => theme.palette.grey[500],
                                    }}
                                >
                                    <Close />
                                </IconButton>
                            </DialogTitle>
                            <DialogContent>
                                <EditarContacto
                                    codigoContacto={this.state.codigoContacto}
                                    listarContactos={this.listarContactos}
                                    showContacto={() => this.setState({ showContacto: false })}
                                />
                            </DialogContent>
                        </Dialog>
                        <Collapse in={this.state.showToast}>
                            <Alert
                                action={
                                    <IconButton
                                        aria-label="info"
                                        color="error"
                                        size="small"
                                        onClick={() => {
                                            this.setState({
                                                showToast: false,
                                            });
                                        }}
                                    >
                                        <CloseIcon fontSize="inherit" />
                                    </IconButton>
                                }
                                sx={{ mb: 2 }}
                                severity={this.state.toastSeverity}
                            >
                                {this.state.messageToast}
                            </Alert>
                        </Collapse>
                        <Paper elevation={1} style={{ padding: '1.5em', marginTop: '0.9em' }}>
                            <Grid container spacing={1}>
                                <Grid item md={4}>
                                    <Typography variant='h5'>CONTACTOS</Typography>
                                </Grid>
                                <Grid item md={8} xs={12}>
                                    <TextField
                                        fullWidth
                                        size="small"
                                        type="text"
                                        placeholder="Buscar por nombre"
                                        value={this.state.search}
                                        onChange={onSearchChange}
                                        inputProps={{ style: { textTransform: 'uppercase' } }}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <Paper sx={{ width: '100%', mt: 1 }}>
                                        <TableContainer style={{ height: '75vh' }}>
                                            <Table stickyHeader>
                                                <TableHead>
                                                    <TableRow>
                                                        {headCells.map((headCell) => (
                                                            <TableCell
                                                                key={headCell.id}
                                                                // align={headCell.numeric ? 'right' : 'center'}
                                                                // padding={headCell.disablePadding ? 'none' : 'normal'}
                                                                style={{ fontWeight: 'bold' }}
                                                                sortDirection={this.state.orderBy === headCell.id ? this.state.order : false}
                                                            >
                                                                <TableSortLabel
                                                                    active={this.state.orderBy === headCell.id}
                                                                    direction={this.state.orderBy === headCell.id ? this.state.order : 'asc'}
                                                                    onClick={createSortHandler(headCell.id)}
                                                                >
                                                                    {headCell.label}
                                                                    {this.state.orderBy === headCell.id ? (
                                                                        <Box component="span" sx={visuallyHidden}>
                                                                            {this.state.order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                                                        </Box>
                                                                    ) : null}
                                                                </TableSortLabel>
                                                            </TableCell>
                                                        ))}
                                                        <TableCell align='center' padding='none' className='buttonTable'></TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {filteredContactos().slice(this.state.page * this.state.rowsConPage, this.state.page * this.state.rowsConPage + this.state.rowsConPage).map(contacto => {
                                                        return <FilaListarContacto key={contacto.codigo} contacto={contacto} parentDelete={this.confirmarEliminarContacto} dialogClienteEdit={dialogClienteEdit}></FilaListarContacto>;
                                                    })}
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                        <TablePagination
                                            rowsPerPageOptions={[10, 25, 50]}
                                            component="div"
                                            labelRowsPerPage="Filas por columnas"
                                            count={filteredContactos().length}
                                            rowsPerPage={this.state.rowsConPage}
                                            page={this.state.page}
                                            onPageChange={handleChangePage}
                                            onRowsPerPageChange={handleChangeRowsPerPage}
                                        />
                                    </Paper>
                                </Grid>
                            </Grid>
                        </Paper>
                    </Grid>
                </Grid>
            </Container>
        )
    }
}
export default Contacto