import { Button, Grid, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TableSortLabel, TextField, Link, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Collapse, Alert, IconButton, TablePagination, Typography } from '@mui/material';
import { Box, Container } from '@mui/system';
import AddIcon from '@mui/icons-material/Add';
import ArrowLeftIcon from '@mui/icons-material/ArrowLeft';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import React, { Component } from 'react'
import { visuallyHidden } from '@mui/utils';
import Constantes from '../components/Constantes';
import FilaListarCliente from '../components/cliente/filaListarCliente';
import PersonRemoveIcon from '@mui/icons-material/PersonRemove';
import CancelIcon from '@mui/icons-material/Cancel';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CloseIcon from '@mui/icons-material/Close';
import EditarCliente from '../components/cliente/editarCliente';
import { Close } from '@mui/icons-material';

class Cliente extends Component {
  constructor(props) {
    super(props);
    this.state = {
      order: "",
      orderBy: "",
      search: "",
      currentPage: 0,
      rowsPerPage: 10,
      clientes: [],
      codClienteSeleccionado: "test",
      toastSeverity: "success",
      showToast: false,
      messageToast: "",
      showDialog: false,
      showClienteEdit: false,
      codigoCliente: '',
      page: 0,
      rowsCliPage: 10
    }
    this.confirmarEliminarCliente = this.confirmarEliminarCliente.bind(this);
    this.eliminarCliente = this.eliminarCliente.bind(this);
  }
  componentDidMount() {
    this.listarClientes();
  }
  listarClientes = async () => {
    const respuesta = await fetch(`${Constantes.RUTA_API}listar/listarCliente.php`);
    const clientes = await respuesta.json();
    this.setState({
      clientes: clientes,
      // currentPage: 0,
      // search: "",
    })
  }
  confirmarEliminarCliente = codigo => {
    this.setState({
      codClienteSeleccionado: codigo,
      showDialog: true,
    });
    console.log(codigo);
    console.log(this.state.codClienteSeleccionado);
  }
  async eliminarCliente() {
    let date = new Date();
    let day = date.getDate();
    let month = date.getMonth() + 1;
    var strMonth = month < 10 ? `0${month}` : month;
    var strDay = day < 10 ? `0${day}` : day;
    let year = date.getFullYear();
    const fechaActual = `${year}-${strMonth}-${strDay}`;
    const respuesta = await fetch(`${Constantes.RUTA_API}mantenimiento/eliminarCliente.php?codigo=${this.state.codClienteSeleccionado}&fecha=${fechaActual}`);
    const exitoso = await respuesta.json();
    if (exitoso.executed) {
      const newClientes = this.state.clientes.filter(cliente => cliente.codigo !== this.state.codClienteSeleccionado);
      this.setState({
        clientes: newClientes,
        toastSeverity: "success",
        showToast: true,
        messageToast: exitoso.message,
        showDialog: false,
      })
    } else {
      this.setState({
        toastSeverity: "error",
        showToast: true,
        messageToast: exitoso.message,
        showDialog: false,
      })
    }
    setTimeout(() => {
      this.setState({
        toastSeverity: "success",
        messageToast: "",
        showToast: false,
      })
    }, 3000);

  }
  render() {
    const headCells = [
      {
        id: 'codigo',
        numeric: false,
        disablePadding: true,
        label: 'CODIGO',
      },
      {
        id: 'apellidos_nombres',
        numeric: false,
        disablePadding: true,
        label: 'NOMBRES Y APELLIDOS',
      },
      {
        id: 'numero_documento',
        numeric: false,
        disablePadding: true,
        label: 'Nº DOCUMENTO',
      },
      {
        id: 'telefono_1',
        numeric: false,
        disablePadding: true,
        label: 'TELEFONO',
      },
      {
        id: 'estado',
        numeric: false,
        disablePadding: true,
        label: 'ESTADO',
      },
      {
        id: 'fk_com_referencia',
        numeric: false,
        disablePadding: true,
        label: 'REFERENCIA',
      },
    ];

    function getComparator(order, orderBy) {
      return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
    }
    function descendingComparator(a, b, orderBy) {
      if (b[orderBy] < a[orderBy]) {
        return -1;
      }
      if (b[orderBy] > a[orderBy]) {
        return 1;
      }
      return 0;
    }
    function stableSort(array, comparator) {
      const stabilizedThis = array.map((el, index) => [el, index]);
      stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) {
          return order;
        }
        return a[1] - b[1];
      });
      return stabilizedThis.map((el) => el[0]);
    }
    const filteredClient = () => {
      if (this.state.search === "") {
        return this.state.clientes
      } else {
        let filtro = this.state.clientes.filter(f => f.apellidos_nombres.includes(this.state.search.toUpperCase()));
        return filtro;
      }
    }
    const handleChangePage = (event, newPage) => {
      this.setState({ page: newPage });
    };
    const handleChangeRowsPerPage = (event) => {
      this.setState({ rowsCliPage: parseInt(event.target.value, 10), page: 0 });
    };
    const onSearchChange = (evento) => {
      let valor = evento.target.value;
      this.setState({
        // currentPage: 0,
        search: valor
      });
    }
    const createSortHandler = (property) => (event) => {
      handleRequestSort(event, property);
    };
    const handleRequestSort = (event, property) => {
      const isAsc = this.state.orderBy === property && this.state.order === 'asc';
      this.setState({
        order: isAsc ? 'desc' : 'asc',
        orderBy: property,
      })
    };
    const dialogClienteEdit = (codigo) => {
      this.setState({ showClienteEdit: true, codigoCliente: codigo });
    }
    return (
      <Container sx={{ marginTop: "70px", paddingBottom: "10px" }}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Dialog
              open={this.state.showDialog}
              onClose={() => this.setState({ showDialog: false })}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description">
              <DialogTitle id="alert-dialog-title" sx={{ textAlign: "center" }}>
                <PersonRemoveIcon fontSize="large" color='primary' />
              </DialogTitle>
              <DialogContent>
                <DialogContentText id="alert-dialog-description">
                  {`¿Seguro que desea eliminar el contacto ${this.state.codClienteSeleccionado}?`}
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button onClick={this.eliminarCliente} autoFocus startIcon={<CheckCircleIcon />} variant='outlined' color='success'>
                  Aceptar
                </Button>
                <Button onClick={() => this.setState({ showDialog: false })} startIcon={<CancelIcon />} variant='outlined' color='error'>
                  Cancelar
                </Button>
              </DialogActions>
            </Dialog>
            <Dialog open={this.state.showClienteEdit} maxWidth="md">
              <DialogTitle>
                EDITAR CLIENTE
                <IconButton
                  aria-label="close"
                  onClick={() => { this.setState({ showClienteEdit: false }) }}
                  sx={{
                    position: 'absolute',
                    right: 8,
                    top: 8,
                    color: (theme) => theme.palette.grey[500],
                  }}
                >
                  <Close />
                </IconButton>
              </DialogTitle>
              <DialogContent>
                <EditarCliente
                  listarClientes={this.listarClientes}
                  codigoCliente={this.state.codigoCliente}
                  showCliente={() => this.setState({ showClienteEdit: false })}
                />
              </DialogContent>
            </Dialog>
            <Collapse in={this.state.showToast}>
              <Alert
                action={
                  <IconButton
                    aria-label="info"
                    color="error"
                    size="small"
                    onClick={() => {
                      this.setState({
                        showToast: false,
                      });
                    }}
                  >
                    <CloseIcon fontSize="inherit" />
                  </IconButton>
                }
                sx={{ mb: 2 }}
                severity={this.state.toastSeverity}
              >
                {this.state.messageToast}
              </Alert>
            </Collapse>
            <Paper elevation={1} style={{ padding: '1em' }}>
              <Grid container spacing={1}>
                <Grid item md={4}>
                  <Typography variant='h5'>CLIENTES</Typography>
                </Grid>
                <Grid item md={8} xs={12}>
                  <TextField
                    fullWidth
                    size="small"
                    type="text"
                    placeholder="Buscar por nombre"
                    value={this.state.search}
                    onChange={onSearchChange}
                    inputProps={{ style: { textTransform: 'uppercase' } }}
                  />
                </Grid>
                <Grid item md={12} xs={12}>
                  <Paper sx={{ width: '100%', mt: 1 }}>
                    <TableContainer style={{ height: '100%' }}>
                      <Table stickyHeader>
                        <TableHead>
                          <TableRow>
                            {headCells.map((headCell) => (
                              <TableCell
                                style={{ fontWeight: 'bold' }}
                                key={headCell.id}
                                sortDirection={this.state.orderBy === headCell.id ? this.state.order : false}
                              >
                                <TableSortLabel
                                  active={this.state.orderBy === headCell.id}
                                  direction={this.state.orderBy === headCell.id ? this.state.order : 'asc'}
                                  onClick={createSortHandler(headCell.id)}
                                >
                                  {headCell.label}
                                  {this.state.orderBy === headCell.id ? (
                                    <Box component="span" sx={visuallyHidden}>
                                      {this.state.order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                    </Box>
                                  ) : null}
                                </TableSortLabel>
                              </TableCell>
                            ))}
                            <TableCell align='center' padding='none'></TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {filteredClient().slice(this.state.page * this.state.rowsCliPage, this.state.page * this.state.rowsCliPage + this.state.rowsCliPage).map(cliente => {
                            return <FilaListarCliente key={cliente.codigo} cliente={cliente} parentDelete={this.confirmarEliminarCliente} dialogClienteEdit={dialogClienteEdit}></FilaListarCliente>;
                          })}
                        </TableBody>
                      </Table>
                    </TableContainer>
                    <TablePagination
                      rowsPerPageOptions={[10, 25, 50]}
                      component="div"
                      labelRowsPerPage="Filas por columnas"
                      count={filteredClient().length}
                      rowsPerPage={this.state.rowsCliPage}
                      page={this.state.page}
                      onPageChange={handleChangePage}
                      onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                  </Paper>
                </Grid>
              </Grid>
            </Paper>
          </Grid>
        </Grid>
      </Container>
    )
  }
}
export default Cliente;