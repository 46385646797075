import React, { useEffect } from 'react'
import { Route, Routes } from 'react-router-dom';
import Constantes from '../Constantes';
import Contacto from "../../screens/Contacto";
import Error404 from './error404';
import Home from "../../screens/Home";
import Cliente from "../../screens/Cliente";
import RegistroCompras from "../compras/registroCompras";
import ResumenRegistros from "../../screens/reportes/resumenRegistros";
import RepTratamientos from "../reportes/repTratamientos";
import ReportVentasGastos from "../../screens/reportes/reportVentasGastos";
import ResumenTratamientos from "../../screens/reportes/resumenTratamientos";
import RepGastos from "../reportes/repGastos";
import Perfiles from "../sistema/perfil/perfiles";
import Usuarios from "../sistema/usuario/usuarios";
import Tratamiento from '../../screens/Tratamiento';
import PagarRegistro from '../registro/pagarRegistro';
import EditarRegistro from '../registro/editarRegistro';
import ReporteRegistrosPagosDia from '../reportes/ventas/reporteRegistrosPagosDia';
import Preloader from '../../screens/preloader';

export default function RutasPermitidas({ userName }) {
    const [menUsuario, setMenUsuario] = React.useState([]);
    const [preloader, setPreloader] = React.useState(true);
    const componentList = (codigo) => {
        const routeArray = [
            { codigo: "CON", componente: <Contacto /> },
            { codigo: "CLI", componente: <Cliente /> },
            { codigo: "CMP", componente: <RegistroCompras /> },
            { codigo: "REP-1", componente: <ResumenRegistros /> },
            { codigo: "REP-2", componente: <ReportVentasGastos /> },
            { codigo: "REP-3", componente: <RepTratamientos /> },
            { codigo: "REP-4", componente: <ResumenTratamientos /> },
            { codigo: "REP-5", componente: <RepGastos /> },
            { codigo: "SYS-PER", componente: <Perfiles /> },
            { codigo: "SYS-USR", componente: <Usuarios /> },
        ];
        const filteredComponent = routeArray.filter(
            (route) => route.codigo === codigo
        );
        if (filteredComponent.length > 0) {
            return filteredComponent[0].componente;
        } else {
            return <Error404 />;
        }
    };
    useEffect(() => {
        const llenarDatos = async () => {
            const menuFetch = await fetch(`${Constantes.RUTA_API}sistema/listarMenu.php?username=${userName}`)
            const menuDB = await menuFetch.json();
            setMenUsuario(menuDB);
            setPreloader(false);
        }
        llenarDatos();
        if (preloader) {
            setTimeout(() => { setPreloader(false); }, 5000);
        }
    }, [userName])
    return (
        <Routes>
            <Route path="/" element={<Home />} />
            {menUsuario.map((menu) => (
                <Route
                    key={menu.codigo}
                    path={menu.href}
                    element={componentList(menu.codigo)}
                />
            ))}
            <Route path="tratamiento/:codigo" exact element={<Tratamiento />} />
            <Route path='tratamiento/pagar/:codigo' exact element={<PagarRegistro />} />
            <Route path='tratamiento/editar/:codigo' exact element={<EditarRegistro accion='EDITAR' />} />
            <Route path='tratamiento/agregar/:codigo' exact element={<EditarRegistro accion='NUEVO' />} />
            <Route path="reportes/registrosPorFecha" exact element={<ReporteRegistrosPagosDia />} />
            {preloader ? (<Route path="*" element={<Preloader />} />) : <Route path="*" element={<Error404 />} />}
            {/* Error404 */}
        </Routes>
    )
}
