import { Dialog, DialogContent, DialogTitle, Grid, IconButton, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material'
import React, { useEffect } from 'react'
import Constantes from '../../Constantes';
import VentasPreview from './VentasPreview';
import PageviewIcon from '@mui/icons-material/Pageview';
import CloseIcon from '@mui/icons-material/Close';
import ResumeDetail from './resumeDetail';
import GraficoLinealHome from '../../../screens/charts/graficoLinealHome';
function HomeResume() {
    const [rowsTC, setRowsTC] = React.useState([]);
    const [rowsBrk, setRowsBrk] = React.useState([]);
    const [rowsActivo, setRowsActivo] = React.useState([]);
    const [rowsActivoIC, setRowsActivoIC] = React.useState([]);
    const [rowsPerdida, setRowsPerdida] = React.useState([]);
    const [totalRowsTC, setTotalRowsTC] = React.useState(0);
    const [totalRowsBrk, setTotalRowsBrk] = React.useState(0);
    const [totalRowsActivo, setTotalRowsActivo] = React.useState(0);
    const [totalRowsActivoIC, setTotalRowsActivoIC] = React.useState(0);
    const [totalRowsPerdida, setTotalRowsPerdida] = React.useState(0);
    const [dialogDetail, setDialogDetail] = React.useState({
        show: false,
        title: "",
    });
    const [paramViewDetail, setParamViewDetail] = React.useState({});
    useEffect((e) => {
        const fillData = async () => {
            const rptarowsTC = await fetch(`${Constantes.RUTA_API}reportes/tratamientosPorEstado.php`);
            const rowsTCDB = await rptarowsTC.json();
            const rptarowsBrk = await fetch(`${Constantes.RUTA_API}reportes/tratamientosSoloBrackets.php`);
            const rowsBrkDB = await rptarowsBrk.json();
            const rptarowsActivo = await fetch(`${Constantes.RUTA_API}reportes/tratamientosSoloActivos.php`);
            const rowsActivoDB = await rptarowsActivo.json();
            const rptarowsActivoIC = await fetch(`${Constantes.RUTA_API}reportes/tratamientosSoloActivosIC.php`);
            const rowsActivoICDB = await rptarowsActivoIC.json();
            const rptarowsPerdida = await fetch(`${Constantes.RUTA_API}reportes/tratamientosPerdida.php`);
            const rowsPerdidaDB = await rptarowsPerdida.json();
            setRowsTC(rowsTCDB);
            setRowsBrk(rowsBrkDB);
            setRowsActivo(rowsActivoDB);
            setRowsActivoIC(rowsActivoICDB);
            setRowsPerdida(rowsPerdidaDB);
            setTotalRowsTC(rowsTCDB.reduce((prev, next) => prev + Number(next.total), 0));
            setTotalRowsBrk(rowsBrkDB.reduce((prev, next) => prev + Number(next.total), 0));
            setTotalRowsActivo(rowsActivoDB.reduce((prev, next) => prev + Number(next.total), 0));
            setTotalRowsActivoIC(rowsActivoICDB.reduce((prev, next) => prev + Number(next.total), 0));
            setTotalRowsPerdida(rowsPerdidaDB.reduce((prev, next) => prev + Number(next.total), 0));
        }
        fillData();
    }, []);
    
    const viewTotalCliente = (codigo, opcion, titulo) => {
        setParamViewDetail({ codigo: codigo, opcion: opcion });
        setDialogDetail({ show: true, title: titulo })
    }

    const totalFooter = (value, decimal) => {
        return (
            <TableRow sx={{ backgroundColor: '#84c0db' }}>
                <TableCell></TableCell>
                <TableCell sx={{ fontWeight: 'bold' }}>
                    TOTAL
                </TableCell >
                <TableCell align="right" sx={{ fontWeight: 'bold' }} >{Number(value).toFixed(decimal)}</TableCell>
            </TableRow>
        )
    }
    return (
        <Grid container spacing={2} sx={{ backgroundColor: ' #2d2d2d', pb: 3 }} p={2}>
            <Dialog open={dialogDetail.show} maxWidth='xl'>
                <DialogTitle>{dialogDetail.title}
                    <IconButton
                        aria-label="close"
                        onClick={() => { setDialogDetail({ show: false, title: "" }) }}
                        sx={{
                            position: 'absolute',
                            right: 8,
                            top: 8,
                            color: (theme) => theme.palette.grey[500],
                        }}
                    >
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <DialogContent>
                    <ResumeDetail paramViewDetail={paramViewDetail} />
                </DialogContent>
            </Dialog>
            <Grid item xs={12} sm={2}>
                <Typography variant='h6' align='center' color='antiquewhite'> TOTAL CLIENTES</Typography>
                <TableContainer component={Paper}>
                    <Table aria-label="Total Clientes" size="small">
                        <TableHead>
                            <TableRow>
                                <TableCell padding='checkbox'></TableCell>
                                <TableCell ></TableCell>
                                <TableCell > </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {rowsTC.map((row) => (
                                <TableRow key={row.codigo}>
                                    <TableCell onClick={() => viewTotalCliente(row.codigo, 'TC', 'TOTAL CLIENTES')} padding="none" align='center'>
                                        <IconButton color='info' size="small"> <PageviewIcon fontSize='inherit' /> </IconButton>
                                    </TableCell>
                                    <TableCell component="th" scope="row">
                                        {row.codigo}
                                    </TableCell>
                                    <TableCell align="right">{row.total}</TableCell>
                                </TableRow>
                            ))}
                            {totalFooter(totalRowsTC,0)}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Grid>
            <Grid item xs={12} sm={2}>
                <Typography variant='h6' align='center' color='antiquewhite'>SOLO BRACKETS</Typography>
                <TableContainer component={Paper}>
                    <Table aria-label="Total Clientes Brackets" size="small">
                        <TableHead>
                            <TableRow>
                                <TableCell padding='checkbox'></TableCell>
                                <TableCell></TableCell>
                                <TableCell></TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {rowsBrk.map((row) => (
                                <TableRow key={row.codigo}>
                                    <TableCell onClick={() => viewTotalCliente(row.codigo, 'BRK', 'SOLO BRACKETS')} padding="none" align='center'>
                                        <IconButton color='info' size="small"> <PageviewIcon fontSize='inherit' /> </IconButton>
                                    </TableCell>
                                    <TableCell>
                                        {row.codigo}
                                    </TableCell>
                                    <TableCell align="right">{row.total}</TableCell>
                                </TableRow>
                            ))}
                            {totalFooter(totalRowsBrk,0)}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Grid>
            <Grid container item xs={12} sm={5} spacing={1} sx={{ pb: 3 }}>
                <Grid item xs={12} align="center">
                    <Typography component="h5" variant='h5' color='antiquewhite'>DINERO POR ENTRAR</Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Typography variant='h6' align='center' color='antiquewhite'>ACTIVO</Typography>
                    <TableContainer component={Paper}>
                        <Table aria-label="Total Activos" size="small">
                            <TableHead>
                                <TableRow>
                                    <TableCell padding='checkbox'></TableCell>
                                    <TableCell ></TableCell>
                                    <TableCell align="right"> </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {rowsActivo.map((row) => (
                                    <TableRow key={row.codigo}>
                                        <TableCell onClick={() => viewTotalCliente(row.codigo, 'ACT', 'ACTIVO POR INGRESAR')} padding="none" align='center'>
                                            <IconButton color='info' size="small"> <PageviewIcon fontSize='inherit' /> </IconButton>
                                        </TableCell>
                                        <TableCell component="th" scope="row">
                                            {row.nombre}
                                        </TableCell>
                                        <TableCell align="right">{row.total}</TableCell>
                                    </TableRow>
                                ))}
                                {totalFooter(totalRowsActivo,2)}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Typography variant='h6' align='center' color='antiquewhite'>ACTIVO_IC</Typography>
                    <TableContainer component={Paper}>
                        <Table aria-label="Total activos brackets" size="small">
                            <TableHead>
                                <TableRow>
                                    <TableCell padding='checkbox'></TableCell>
                                    <TableCell ></TableCell>
                                    <TableCell align="right"> </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {rowsActivoIC.map((row) => (
                                    <TableRow key={row.codigo}>
                                        <TableCell onClick={() => viewTotalCliente(row.codigo, 'ACT_IC', 'ACTIVO_IC POR INGRESAR')} padding="none" align='center'>
                                            <IconButton color='info' size="small"> <PageviewIcon fontSize='inherit' /> </IconButton>
                                        </TableCell>
                                        <TableCell component="th" scope="row">
                                            {row.nombre}
                                        </TableCell>
                                        <TableCell align="right">{row.total}</TableCell>
                                    </TableRow>
                                ))}
                                {totalFooter(totalRowsActivoIC,2)}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>
            </Grid>
            <Grid container item xs={12} sm={3} spacing={1} sx={{ pb: 3 }}>
                <Grid item xs={12} align="center">
                    <Typography component="h5" variant='h5' color='antiquewhite'>ATENCIONES EN PERDIDA </Typography>
                </Grid>
                <Grid item xs={12}>
                    <Typography variant='h6' align='center' color='antiquewhite'> ABANDONO, SUSPENDIDO</Typography>
                    <TableContainer component={Paper}>
                        <Table aria-label="Total perdida" size="small">
                            <TableHead>
                                <TableRow>
                                    <TableCell padding='checkbox'></TableCell>
                                    <TableCell ></TableCell>
                                    <TableCell align="right"> </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {rowsPerdida.map((row) => (
                                    <TableRow key={row.codigo}>
                                        <TableCell onClick={() => viewTotalCliente(row.codigo, 'PER', 'ATENCIONES EN PERDIDA')} padding="none" align='center'>
                                            <IconButton color='info' size="small"> <PageviewIcon fontSize='inherit' /> </IconButton>
                                        </TableCell>
                                        <TableCell component="th" scope="row">
                                            {row.nombre}
                                        </TableCell>
                                        <TableCell align="right">{row.total}</TableCell>
                                    </TableRow>
                                ))}
                                {totalFooter(totalRowsPerdida,2)}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>
            </Grid>
            <Grid item xs={0} sm={3}></Grid>
            <Grid item xs={12} sm={6} sx={{ backgroundColor: "antiquewhite", borderRadius: "10px" }}>
                {/* <VentasPreview /> */}
                <GraficoLinealHome />
            </Grid>
            <Grid item xs={0} sm={3}></Grid>
        </Grid>
    )
}
export default HomeResume