import { Alert, Button, Collapse, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid, IconButton, TextField } from '@mui/material'
import React from 'react'
import SearchIcon from '@mui/icons-material/Search';
import AddBoxOutlinedIcon from '@mui/icons-material/AddBoxOutlined';
import CustomPaginationActionsTable from '../../table/PaginationTable';
import Constantes from '../../Constantes';
import CloseIcon from '@mui/icons-material/Close';
import QuestionMarkOutlinedIcon from '@mui/icons-material/QuestionMarkOutlined';
import CheckCircleOutlinedIcon from '@mui/icons-material/CheckCircleOutlined';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import DoneOutlineOutlinedIcon from '@mui/icons-material/DoneOutlineOutlined';
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';
import EditarGastos from './editarGastos'; 


function Gastos() {
  const [buscar, setBuscar] = React.useState("");
    const [rows, setRows] = React.useState([]);
    const [showAlert, setShowAlert] = React.useState(false);
    const [alertMessage, setAlertMessage] = React.useState("");
    const [dialogGasto, setDialogGasto] = React.useState({
        show:false,
        titulo:"",
        fk_cmp_gasto:"",
        accion:""
    })
    const [dialogEliminado, setDialogEliminado] = React.useState({
        show:false,
        titulo:"",
        fk_cmp_gasto:"",
        message:"",
        eliminado:false,
    })
    const columns = ['CODIGO', 'NOMBRE', 'DESCRIPCION', 'CLASIFICACION'];
    const handleChange = (event)=>{
        setBuscar(event.target.value);
    }
    const [showConfirmarEliminar, setShowConfirmarEliminar] = React.useState(false);
    const search = async () =>{
        const rptadetalles = await fetch(`${Constantes.RUTA_API}listar/listargastosBusqueda.php?search=${buscar.toUpperCase()}`)
        const detalles = await rptadetalles.json();
        if(detalles.length>0){
            setRows(detalles);
        }else{
            setRows(detalles);
            setShowAlert(true);
            setAlertMessage("No se encontraron resultados.");
            setTimeout(() => {
                setShowAlert(false);
                setAlertMessage("");
            }, 3000);
        }
    }
    const ejecutarAccion = (accion, codigo)=>{
        if(accion==="EDIT"){
            setDialogGasto({
                show:true,
                titulo:"EDITAR",
                fk_cmp_gasto: codigo,
                accion:"E",
            })
        }
        if(accion==="DELETE"){
            setDialogGasto({
                show:false,
                titulo:"DELETE",
                fk_cmp_gasto: codigo,
                accion:"D",
            })
            setShowConfirmarEliminar(true);
        }
        if(accion==="NEW"){
            setDialogGasto({
                show:true,
                titulo:"NUEVO",
                fk_cmp_gasto: codigo,
                accion:"N",
            })
        }
    }
    const eliminarGasto = async () => {
        const eliminar = await fetch(`${Constantes.RUTA_API}mantenimiento/eliminarGasto.php?codigo=${dialogGasto.fk_cmp_gasto}`);
        const rptaEliminar = await eliminar.json();        
        if(rptaEliminar.executed){
            setDialogEliminado({
                show:true,
                titulo:"ELIMINADO",
                fk_cmp_gasto:dialogGasto.fk_cmp_gasto,
                message:"Fue eliminado con exito.",
                eliminado:true,
            })
        }else{
            setDialogEliminado({
                show:true,
                titulo:"ERROR",
                fk_cmp_gasto:dialogGasto.fk_cmp_gasto,
                message:"No se pudo eliminar, tiene compras relacionadas.",
                eliminado:false,
            })
        }
        setTimeout(() => {
            cerrarEliminado();
        }, 3000);
    }
    const cerrarEliminado = () => {
        setDialogEliminado({
            show:false,
            titulo:"",
            fk_cmp_gasto:"",
            message:"",
            eliminado:false,
        })
        setShowConfirmarEliminar(false);
        search();
    }
    const cerrarGasto = () =>{
        setDialogGasto({
            show:false,
            titulo:"",
            fk_cmp_gasto: "",
            accion:"",
        })
        search();
    }
    const handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            search();
        }
      }
    return (
        <Grid container spacing={1} mt={1}>
            <Dialog open={dialogGasto.show} maxWidth='sm'>
                <DialogTitle>
                    {dialogGasto.titulo} GASTO
                </DialogTitle>
                <DialogContent>
                    <EditarGastos fk_cmp_gasto={dialogGasto.fk_cmp_gasto} parentCloseChild ={cerrarGasto} accion={dialogGasto.accion}/>
                </DialogContent>
            </Dialog>
            <Dialog open={showConfirmarEliminar}>
                <DialogTitle>
                     ELIMINAR <QuestionMarkOutlinedIcon/>
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>{`Está a punto de eliminar un gasto (${dialogGasto.fk_cmp_gasto}), esta accion no se puede deshacer. ¿Desea continuar?`}</DialogContentText>
                </DialogContent>
                <DialogActions sx={{alignContent:'center'}}>
                    <Button variant='outlined' color='success' startIcon={<CheckCircleOutlinedIcon/>} onClick={eliminarGasto}>SI</Button>
                    <Button variant='outlined' color='error' startIcon={<CancelOutlinedIcon/>} onClick={()=>setShowConfirmarEliminar(false)}>NO</Button>
                </DialogActions>
            </Dialog>
            <Dialog open={dialogEliminado.show}>
                <DialogTitle>
                    {dialogEliminado.eliminado?<DoneOutlineOutlinedIcon/>:<ErrorOutlineOutlinedIcon/>}
                    {dialogEliminado.titulo}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>{`El gasto ${dialogEliminado.fk_cmp_gasto}, ${dialogEliminado.message}`}</DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button variant='outlined' color='primary' startIcon={<CheckCircleOutlinedIcon/>} onClick={cerrarEliminado}>ACEPTAR</Button>
                </DialogActions>
            </Dialog>
            <Grid item xs={8}>
                <TextField
                    id="busqueda"
                    label="Nombre:"
                    type="text"
                    placeholder='Buscar el Gasto'
                    value={buscar}
                    fullWidth
                    size='small'
                    onChange={handleChange}
                    onKeyDown={handleKeyDown}
                    inputProps={{ style: { textTransform: "uppercase" } }}
                />
            </Grid>
            <Grid item xs={2} textAlign='center'>
                <Button onClick={search} variant='contained' startIcon={<SearchIcon fontSize='large'/>}> BUSCAR </Button>
            </Grid>
            <Grid item xs={2} textAlign='center'>
                <Button onClick={()=>ejecutarAccion("NEW", "STK2-00000000")} color='success' variant='contained' startIcon={<AddBoxOutlinedIcon fontSize='large'/>}> NUEVO </Button>
            </Grid>
            <Grid item xs={12}>
                <Collapse in={showAlert}>
                    <Alert
                    action={
                        <IconButton
                        aria-label="close"
                        color="inherit"
                        size="small"
                        onClick={() => {
                            setShowAlert(false);
                        }}
                        >
                            <CloseIcon fontSize="inherit" />
                        </IconButton>
                    }
                    sx={{ mb: 2 }}
                    severity="warning"
                    >
                        {alertMessage}
                    </Alert>
                </Collapse>
            </Grid>
            <Grid item xs={12}>
                <CustomPaginationActionsTable rows={rows} columns={columns} parentCallBack={ejecutarAccion}/>
            </Grid>
        </Grid>
    )
}

export default Gastos