import { Grid, ToggleButton, ToggleButtonGroup } from '@mui/material';
import React from 'react'
import Materiales from '../materiales/materiales';
import Equipos from '../equipos/equipos';
function Stock() {
    const [valor, setValor] = React.useState('M');
    const handleChange = (event, newValue) => {
        if(newValue){
            setValor(newValue);
        }
    };
    return (
        <Grid container spacing={1}>
            <Grid item xs={12}>
                <ToggleButtonGroup
                    color="primary"
                    value={valor}
                    exclusive
                    onChange={handleChange}
                >
                    <ToggleButton value="M">MATERIALES</ToggleButton>
                    <ToggleButton value="E">EQUIPOS/INSTRUMENTAL</ToggleButton>
                </ToggleButtonGroup>
            </Grid>
            <Grid item xs={12}>
                {valor === "M" &&  <Materiales/>}
                {valor === "E" &&  <Equipos/>}
            </Grid>            
        </Grid>
    )
}
export default Stock