
import React, { useEffect } from 'react';
import { IconButton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Autocomplete, Button, Grid, InputLabel, MenuItem, Paper, Select, Switch, TextField, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, FormControl, Typography, Chip, Tooltip } from "@mui/material";
import Constantes from '../Constantes';
import AddCircleOutlineRoundedIcon from '@mui/icons-material/AddCircleOutlineRounded';
import DeleteIcon from '@mui/icons-material/Delete';
import CloseIcon from '@mui/icons-material/Close';
import SaveIcon from '@mui/icons-material/Save';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import HomeIcon from '@mui/icons-material/Home';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import CancelIcon from '@mui/icons-material/Cancel';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { IndeterminateCheckBoxSharp } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
function ManContacto(props) {
    const hoy = new Date();
    const strHoy = `${hoy.getFullYear()}-${(hoy.getMonth() + 1).toString().padStart(2, '0')}-${hoy.getDate().toString().padStart(2, '0')}`;
    const userData = JSON.parse(window.sessionStorage.getItem("logedAppUser"));
    const [contacto, setContacto] = React.useState({
        codigo: "CON-00000000",
        apellidos_nombre: "",
        telefono_1: "",
        fk_com_referencia: "",
        fecha_registro: strHoy,
        fecha_coordinacion: strHoy,
        fecha_consulta: "",
        fk_log_usuario_creado_por: userData.usuario,
        estado_seguimiento: "REGISTRADO",
        observaciones: "",
    });
    const [nombreContacto, setNombreContacto] = React.useState({
        error: false,
        helperText: ''
    });
    const [tratamientosInteres, setTratamientosInteres] = React.useState([]);
    const [tratamientos, setTratamientos] = React.useState([]);
    const [referencias, setReferencias] = React.useState([]);
    const [toast, setToast] = React.useState({
        show: false,
        severity: "success",
        message: "",
    })
    const [tratamientoSeleccionado, setTratamientoSeleccionado] = React.useState({
        codigo: null, nombre: "", descripcion: "", fk_ven_familia_especifica: ""
    });
    const [loadingContacto, setLoadingContacto] = React.useState(false);
    useEffect((e) => {
        const filldata = async () => {
            if (props.codigo !== "CON-00000000") {
                const contactoFetch = await fetch(`${Constantes.RUTA_API}recuperar/obtenerContacto.php?codigo=${props.codigo}`);
                const contacto = await contactoFetch.json();
                setContacto(contacto);
            }
            const refRespuesta = await fetch(`${Constantes.RUTA_API}sistema/listarReferenciaLlegada.php`);
            const referenciasdb = await refRespuesta.json();
            setReferencias(referenciasdb);
            const tratamientos = await fetch(`${Constantes.RUTA_API}listar/listarTratamientosEmpresa.php?`);
            const tratamientosDB = await tratamientos.json();
            setTratamientos(tratamientosDB);
        }
        filldata();
    }, [props.codigo]);
    const saveContacto = async () => {
        if (contacto.apellidos_nombre !== "") {
            setLoadingContacto(true);
            const saveContactoFetch = await fetch(`${Constantes.RUTA_API}mantenimiento/guardarContacto.php`, {
                method: "POST",
                body: JSON.stringify({
                    codigo: contacto.codigo,
                    apellidos_nombre: contacto.apellidos_nombre.toUpperCase(),
                    telefono_1: contacto.telefono_1,
                    fk_com_referencia: contacto.fk_com_referencia.toUpperCase(),
                    fecha_registro: new Date().toJSON().slice(0, 10),
                    fecha_coordinacion: new Date().toJSON().slice(0, 10),
                    fecha_consulta: contacto.fecha_consulta,
                    fk_log_usuario_creado_por: userData.usuario,
                    estado_seguimiento: "REGISTRADO",
                    observaciones: contacto.observaciones !== null ? contacto.observaciones.toUpperCase() : '',
                }),
            });
            const resultado = await saveContactoFetch.json();
            if (resultado.guardado) {
                setLoadingContacto(false);
                const limpiarInteres = await fetch(`${Constantes.RUTA_API}mantenimiento/eliminarInteresesContacto.php?codigo=${contacto.codigo}`)
                tratamientosInteres.forEach(async (item, index) => {
                    item['linea'] = index + 1;
                    item['fk_ven_contacto'] = resultado.codigo;
                    const interesGuardado = await fetch(`${Constantes.RUTA_API}mantenimiento/guardarInteresContacto.php`, {
                        method: "POST",
                        body: JSON.stringify(item),
                    });
                });
                setToast({
                    show: true,
                    message: "El contacto se guardó correctamente.",
                    severity: "success",
                })
            } else {
                setToast({
                    show: true,
                    message: "Ocurrio un problema al guardar el contacto. intente de nuevo",
                    severity: "error",
                })
            }
            setTimeout(() => {
                setToast({
                    show: false,
                    message: "",
                    severity: "warning",
                })
                props.closeChild();
            }, 3000);
        } else {
            setNombreContacto({ error: true, helperText: 'Nombre contacto obligatorio' });
        }
    }
    const handleChange = (e) => {
        const id = e.target.id;
        let valor = e.target.value;
        if (id === 'apellidos_nombre') {
            if (valor !== null) {
                setNombreContacto({ error: false, helperText: '' });

            } else {
                setNombreContacto({ error: true, helperText: 'Nombre contacto obligatorio' });
            }
        }
        setContacto((contactoAnt) => ({
            ...contactoAnt,
            [id]: valor,
            estado_seguimiento: id === "fecha_coordinacion" && valor !== "" ? "CONTACTADO" : id === "fecha_consulta" && valor !== "" ? "CONSULTA" : "REGISTRADO",
        }))
    }
    const handleChangeReferencia = (e) => {
        setContacto((contactoAnt) => ({
            ...contactoAnt,
            fk_com_referencia: e.target.value,
        }))
    }
    const eliminarInteres = (seleccionado) => {
        setTratamientosInteres(tratamientosInteres.filter(ele => ele.linea !== seleccionado.linea));
    }
    const parentCloseChild = () => {
        props.closeChild();
    }
    const handleChangeTratamiento = (event, values) => {
        if (values !== null) {
            setTratamientoSeleccionado(values);
        } else {
            setTratamientoSeleccionado({ codigo: null, nombre: "", descripcion: "", fk_ven_familia_especifica: "" });
        }
    }
    const agregarInteres = () => {
        const tmpTratamiento = tratamientosInteres;
        if (tratamientoSeleccionado.codigo !== null) {
            if (tratamientosInteres.filter(inte => inte.fk_ven_tratamiento === tratamientoSeleccionado.codigo).length === 0) {
                tmpTratamiento.push({
                    fk_ven_contacto: contacto.codigo,
                    linea: tratamientosInteres.length + 1,
                    nombre_tratamiento: tratamientoSeleccionado.nombre,
                    fk_ven_tratamiento: tratamientoSeleccionado.codigo,
                })
                setTratamientosInteres(tmpTratamiento);
                setToast({
                    show: true,
                    message: "Tratamiento agregado correctamente.",
                    severity: "success",
                })
            } else {
                setToast({
                    show: true,
                    message: "Este tratamiento ya existe.",
                    severity: "warning",
                })
            }
        } else {
            setToast({
                show: true,
                message: "Debe selecionar un tratamiento",
                severity: "error",
            })
        }
        setTratamientoSeleccionado({ codigo: null, nombre: "", descripcion: "", fk_ven_familia_especifica: "" });
        setTimeout(() => {
            setToast({
                show: false,
                message: "",
                severity: "warning",
            })
        }, 3000);
    }
    return (
        <Grid container spacing={1}>
            <Dialog open={toast.show}>
                <DialogTitle align='center'>
                    {toast.severity === "success" ?
                        <CheckCircleOutlineIcon fontSize='large' color='success' /> :
                        <ErrorOutlineIcon fontSize='large' color='error' />
                    }
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        {toast.message}
                    </DialogContentText>
                </DialogContent>
                <DialogActions style={{ justifyContent: 'center' }}>
                    <Button onClick={() => { setToast({ show: false, message: "", severity: "warning" }) }} startIcon={<CheckCircleIcon />} variant='outlined' color='success'>Aceptar</Button>
                </DialogActions>
            </Dialog>
            <Grid item xs={6}>
                <Typography variant='h6'>
                    {contacto.codigo}
                </Typography>
            </Grid>
            <Grid item xs={6} textAlign='center'>
                <Chip label={`Estado: ${contacto.estado_seguimiento}`} color={contacto.estado_seguimiento === "REGISTRADO" ? "info" : contacto.estado_seguimiento === "CONTACTADO" ? "secondary" : "success"} variant="outlined" />
            </Grid>
            <Grid item md={12} xs={12}>
                <TextField
                    id='apellidos_nombre'
                    label="NOMBRES Y APELLIDOS:"
                    placeholder='Nombre y Apellidos'
                    variant='filled'
                    error={nombreContacto.error}
                    style={{ textTransform: "uppercase" }}
                    onChange={handleChange}
                    value={contacto.apellidos_nombre}
                    inputProps={{ style: { textTransform: "uppercase" } }}
                    fullWidth
                    margin='normal'
                    helperText={nombreContacto.helperText}
                />
            </Grid>
            <Grid item md={6} xs={12}>
                <TextField
                    id="telefono_1"
                    label="NUMERO TELEFONO:"
                    type="text"
                    value={contacto.telefono_1}
                    onChange={handleChange}
                    fullWidth
                    margin='normal'
                />
            </Grid>
            <Grid item md={6} xs={12}>
                <FormControl fullWidth margin="normal">
                    <InputLabel>Referencia Llegada</InputLabel>
                    <Select
                        labelId="fk_com_referencia"
                        id="fk_com_referencia"
                        value={contacto.fk_com_referencia}
                        label="REFERENCIA LLEGADA:"
                        onChange={handleChangeReferencia}
                    >
                        {referencias.map(referencia => {
                            return (<MenuItem key={referencia.codigo} value={referencia.codigo}>{referencia.descripcion}</MenuItem>)
                        })}
                    </Select>
                </FormControl>
            </Grid>
            <Grid item md={4} xs={12}>
                <TextField
                    margin='normal'
                    id="fecha_registro"
                    label="FECHA REGISTRO:"
                    type="date"
                    InputLabelProps={{
                        shrink: true,
                    }}
                    value={contacto.fecha_registro}
                    fullWidth
                />
            </Grid>
            <Grid item md={4} xs={12}>
                <TextField
                    margin="normal"
                    id="fecha_coordinacion"
                    label="FECHA COORDINACION:"
                    type="date"
                    InputLabelProps={{
                        shrink: true,
                    }}
                    value={contacto.fecha_coordinacion}
                    onChange={handleChange}
                    fullWidth
                />
            </Grid>
            <Grid item md={4} xs={12}>
                <TextField
                    margin='normal'
                    id="fecha_consulta"
                    label="FECHA CONSULTA:"
                    type="date"
                    InputLabelProps={{
                        shrink: true,
                    }}
                    value={contacto.fecha_consulta}
                    onChange={handleChange}
                    fullWidth
                />
            </Grid>
            <Grid item md={12} xs={12}>
                <TextField
                    margin="normal"
                    id="observaciones"
                    label="OBSERVACIONES:"
                    multiline
                    value={contacto.observaciones}
                    onChange={handleChange}
                    variant="filled"
                    fullWidth
                    maxRows={5}
                    inputProps={{ style: { textTransform: "uppercase" } }}
                />
            </Grid>
            <Grid container item spacing={1}>
                <Grid item md={10} xs={12}>
                    <Autocomplete
                        onChange={handleChangeTratamiento}
                        options={tratamientos}
                        getOptionLabel={(option) => option.nombre || ''}
                        id="tratamiento"
                        clearOnEscape
                        value={tratamientoSeleccionado || ""}
                        isOptionEqualToValue={(option, value) => option.codigo === value.codigo}
                        renderOption={(props, option) => {
                            return (
                                <li {...props} key={option.codigo}>
                                    {option.nombre}
                                </li>
                            );
                        }}
                        renderInput={(params) => (
                            <TextField {...params} label="TRATAMIENTO INTERES: " variant="standard" margin='normal' fullWidth />
                        )}
                    />
                </Grid>
                <Grid item md={1} xs={12} sx={{ marginTop: { md: '25px', xs: '0px', textAlign: 'center' } }}>
                    <LoadingButton loading={toast.show} color="primary" variant='contained' onClick={agregarInteres}><AddCircleOutlineRoundedIcon /></LoadingButton>
                </Grid>
                <Grid item md={12} xs={12}>
                    <TableContainer>
                        <Table>
                            <TableHead sx={{ backgroundColor: "#b9d8ee" }} >
                                <TableRow>
                                    <TableCell style={{ fontWeight: 'bold' }}>#</TableCell>
                                    <TableCell style={{ fontWeight: 'bold' }}>TRATAMIENTO</TableCell>
                                    <TableCell sx={{ width: "60px", fontWeight: 'bold' }}>Elim.</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {tratamientosInteres.map(tratamiento => {
                                    return (
                                        <TableRow key={tratamiento.fk_ven_tratamiento}>
                                            <TableCell> {tratamiento.linea} </TableCell>
                                            <TableCell> {tratamiento.nombre_tratamiento}</TableCell>
                                            <TableCell>
                                                <Tooltip title="ELIMINAR TRATAMIENTO" placement='top'>
                                                    <IconButton onClick={() => eliminarInteres(tratamiento)} color="error"><DeleteIcon /></IconButton>
                                                </Tooltip>
                                            </TableCell>
                                        </TableRow>
                                    )
                                })}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>
            </Grid>
            <Grid container spacing={2} style={{ justifyContent: 'center', marginTop: '10px' }}>
                <Grid item md={3} xs={6}>
                    <LoadingButton loading={loadingContacto} onClick={saveContacto} fullWidth variant="outlined" color="success" startIcon={<SaveIcon />}>
                        GUARDAR
                    </LoadingButton>
                </Grid>
                <Grid item md={3} xs={6}>
                    <Button onClick={parentCloseChild} fullWidth variant="outlined" color="error" startIcon={<CancelIcon />}>
                        CANCELAR
                    </Button>
                </Grid>
            </Grid>
        </Grid>
    )
}

export default ManContacto