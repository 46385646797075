import * as React from 'react';
import { IconButton, TableCell, TableRow, Link, Tooltip } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';

export default function FilaListarPerfil(props) {

    return (
        <TableRow key={props.perfil.codigo}>
            <TableCell>{props.perfil.codigo}</TableCell>
            <TableCell>{props.perfil.nombre}</TableCell>
            <TableCell>{props.perfil.descripcion}</TableCell>
            <TableCell align="right">
                <Tooltip title="EDITAR PERFIL" placement='top'>
                    <IconButton color="info" onClick={() => props.handleClickEdit(props.perfil.codigo)}><EditIcon /></IconButton>
                </Tooltip>
                <Tooltip title="ELIMINAR PERFIL" placement='top'>
                    <IconButton color="error" onClick={() => props.handleClickDel(props.perfil.codigo)}  ><DeleteIcon /></IconButton>
                </Tooltip>
            </TableCell>
        </TableRow>
    )
}