import { Check, Close, Visibility } from '@mui/icons-material';
import { Button, Dialog, DialogContent, DialogContentText, DialogTitle, Grid, IconButton, TextField, Typography } from '@mui/material';
import React from 'react'
import Constantes from '../../../Constantes';
import EgresoEquipo from '../egresoEquipo';

export default function DlgEgresoEquipo(props) {
    const stockAct = Number(props.cmp_equipo.stock)
    const codigoM = props.cmp_equipo.fk_cmp_equipo
    const nombreM = props.cmp_equipo.nombre
    const [data, setData] = React.useState(stockAct);
    const [dialogEgreso, setDialogEgreso] = React.useState(false);
    const [confDialogEgreso, setConfDialogEgreso] = React.useState(false);

    const guardarDataStock = async () => {
        if (data <= stockAct && data !== 0.00) {
            setConfDialogEgreso(true);
        } else {
            props.ErrConfirNuevoStock();
        }
    }
    const confirmGuardarStock = async () => {
        let usuarioData = JSON.parse(sessionStorage.getItem('logedAppUser'));
        //validar
        const guardarStock = await fetch(`${Constantes.RUTA_API}mantenimiento/guardarEquipoEgreso.php`, {
            method: 'POST',
            body: JSON.stringify({
                fk_log_usuario_creado_por: usuarioData.usuario,
                fk_cmp_equipo: codigoM,
                fecha: new Date().toJSON().slice(0, 10),
                cantidad: data,
            })
        })
        const guardarJson = await guardarStock.json();
        if (guardarJson.guardado) {
            props.search();
            props.closeNuevoEgreso();
            props.confirNuevoStock();
        }
    }
    const onchgDataEgreso = (e) => {
        // const stockAct = Number(props.cmp_equipo.stock)
        setData(Number(e.target.value));
    }
    const clkDialogVerEgresos = () => {
        setDialogEgreso(true);
    }
    return (
        <Grid container sx={{ justifyContent: 'center', textAlign: "center" }} spacing={1}>
            <Dialog open={dialogEgreso} onClose={() => { setDialogEgreso(false); props.closeNuevoEgreso(false); }} fullWidth={true} maxWidth='sm'>
                <DialogTitle>
                    <Typography color="Text.secondary"><span style={{ fontWeight: 'bold' }}> EGRESO:</span>  {nombreM} ({codigoM})</Typography>
                    <IconButton
                        aria-label="close"
                        onClick={() => { setDialogEgreso(false); props.closeNuevoEgreso(false); }}
                        sx={{
                            position: 'absolute',
                            right: 8,
                            top: 8,
                            color: (theme) => theme.palette.grey[500],
                        }}
                    >
                        <Close />
                    </IconButton>
                </DialogTitle>
                <DialogContent>
                    <EgresoEquipo cmp_equipo={props.cmp_equipo} search={props.search} cerrarDialogEgreso={props.cerrarDialogEgreso} confirNuevoStock={props.confirNuevoStock} />
                    <Grid container sx={{ justifyContent: "center" }}>
                        <Grid item md={4}>
                            <Button fullWidth variant='outlined' color='error' startIcon={<Close />} onClick={() => { setDialogEgreso(false); props.closeNuevoEgreso(false); }}>CERRAR</Button>
                        </Grid>
                    </Grid>
                </DialogContent>
            </Dialog>
            <Dialog open={confDialogEgreso} onClose={() => { setConfDialogEgreso(false); }} fullWidth={true} maxWidth='xs'>
                <DialogTitle>
                    <IconButton
                        aria-label="close"
                        onClick={() => { setConfDialogEgreso(false); }}
                        sx={{
                            position: 'absolute',
                            right: 8,
                            top: 8,
                            color: (theme) => theme.palette.grey[500],
                        }}
                    >
                        <Close />
                    </IconButton>
                </DialogTitle>
                <DialogContent sx={{ textAlign: "center" }}>
                    <DialogContentText>{`USTED ESTA EGRESANDO ${data} ${nombreM}.`}</DialogContentText>
                    <DialogContentText>{`¿CONFIRMAR EGRESO?`}</DialogContentText>
                    <Grid container sx={{ justifyContent: "center", pt: 1 }}>
                        <Grid item md={4}>
                            <Button variant='outlined' color='success' startIcon={<Check />} onClick={() => confirmGuardarStock()}>ACEPTAR</Button>
                        </Grid>
                        <Grid item md={4}>
                            <Button variant='outlined' color='error' startIcon={<Close />} onClick={() => { setConfDialogEgreso(false); }}>CANCELAR</Button>
                        </Grid>
                    </Grid>
                </DialogContent>
            </Dialog>
            <Grid item md={12} xs={12}>
                <TextField
                    size='small'
                    type="number"
                    label="Cantidad"
                    margin='normal'
                    value={data}
                    onChange={onchgDataEgreso}
                    sx={{ width: "130px" }}
                />
            </Grid>
            <Grid item md={4} xs={8}>
                <Button fullWidth variant='outlined' color='success' startIcon={<Check />} onClick={() => { guardarDataStock(); }}>Aceptar</Button>
            </Grid>
            <Grid item md={4} xs={8}>
                <Button fullWidth variant='outlined' color='error' startIcon={<Close />} onClick={() => { props.closeNuevoEgreso(); }}>Cancelar</Button>
            </Grid>
            <Grid item md={4} xs={8}>
                <Button fullWidth variant='outlined' color="warning" startIcon={<Visibility />} onClick={() => clkDialogVerEgresos()}>VER Egr.</Button>
            </Grid>
        </Grid>
    )
}
