import React, { useEffect, useState } from 'react';
import { Bar } from "react-chartjs-2";
import {
    Chart as Chartjs,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
} from "chart.js"
import html2canvas from 'html2canvas';
import { Button, Grid, Stack } from '@mui/material';

Chartjs.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
);
export default function GraficoBarra(props) {
    const imgGrafico = sessionStorage.getItem("imgGrafico");
    const valuesGrafBarr = props.valuesGrafBarr;
    const [chartData, setChartData] = useState({
        labels: [],
        datasets: [],
    });
    const [chartOptions, setChartOptions] = useState({});
    const exportImg = () => {
        window.scrollTo(0, 0);
        const input = document.getElementById("export_img");
        html2canvas(input).then(function (canvas) {
            sessionStorage.setItem("imgGrafico", canvas.toDataURL("image/jpeg", 0.9));
        });
        props.alertChartBarrAgreg();
    }
    const quitarImg = () => {
        sessionStorage.removeItem("imgGrafico");
        props.alertChartBarrRemov();
    }
    const dataSetsRet = () => {

        if (props.tipo === "1") {
            let data = [
                {
                    label: 'Ventas',
                    data: valuesGrafBarr.ventas,
                    borderColor: "rgb(75, 192, 192)",
                    backgroundColor: "rgb(75, 192, 192,0.5)",
                    // backgroundColor: transparentize(CHART_COLORS.red, 0.5),
                },
                {
                    label: 'Gastos',
                    data: valuesGrafBarr.gastos,
                    borderColor: "rgb(255, 99, 132)",
                    backgroundColor: "rgb(255, 99, 132,0.5)",
                },
                {
                    label: 'Resultados',
                    data: valuesGrafBarr.res,
                    borderColor: "rgb(54, 162, 235)",
                    backgroundColor: "rgb(54, 162, 235,0.5)",
                }
            ];
            return data;
        }
        if (props.tipo === "2") {
            let data = [
                {
                    label: 'CONTADO',
                    data: valuesGrafBarr.contado,
                    borderColor: "rgb(75, 192, 192)",
                    backgroundColor: "rgb(75, 192, 192,0.5)",
                    // backgroundColor: transparentize(CHART_COLORS.red, 0.5),
                },
                {
                    label: 'POS',
                    data: valuesGrafBarr.pos,
                    borderColor: "rgb(255, 205, 86)",
                    backgroundColor: "rgb(255, 205, 86,0.5)",
                },
                {
                    label: 'DEP/TRANF',
                    data: valuesGrafBarr.tranf,
                    borderColor: "rgb( 	0, 191, 255)",
                    backgroundColor: "rgb( 	0, 191, 255,0.5)",
                },
                {
                    label: 'VENTAS',
                    data: valuesGrafBarr.ventas,
                    borderColor: "rgb(42, 174, 42)",
                    backgroundColor: "rgb(42, 174, 42,0.5)",
                }
            ];
            return data;
        }

    }
    useEffect((e) => {
        setChartData({
            labels: valuesGrafBarr.tags,
            datasets: dataSetsRet(),
        });
        setChartOptions({
            responsive: true,
            plugins: {
                legend: {
                    position: "top"
                },
                title: {
                    display: true,
                    text: valuesGrafBarr.message,
                },
            },
        });
    }, [valuesGrafBarr]);
    return (
        <>
            {/* height: { xs: "1000px", md: "100vh" } */}
            <Grid container sx={{ justifyContent: "center" }} >
                <Grid item xs={12}>
                    <div className='export_img' id='export_img' >
                        <Bar data={chartData} options={chartOptions} />
                    </div>
                </Grid>
                <Grid item xs={12} sx={{ padding: "25px 0" }}>
                    <Stack spacing={0.3} direction="row" sx={{ justifyContent: "center" }}>
                        {imgGrafico === null &&
                            <Button variant="contained" size="small" color='success' onClick={() => exportImg()}>AGREGAR GRAFICO A PDF</Button>
                        }
                        {imgGrafico !== null &&
                            <Button variant="contained" size="small" color='warning' onClick={() => quitarImg()}>QUITAR GRAFICO DE PDF</Button>
                        }
                    </Stack>
                </Grid>
            </Grid>
        </>

    )
}
