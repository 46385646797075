import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControl, FormControlLabel, Grid, InputLabel, MenuItem, Select, Switch, TextField } from '@mui/material';
import React, { useEffect } from 'react';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { LoadingButton } from '@mui/lab';
import Constantes from '../Constantes';
import SaveIcon from '@mui/icons-material/Save';
import { CheckCircleOutline } from '@mui/icons-material';

function Trabajador(props) {
    const [tipoDocumentos, setTipoDocumentos] = React.useState([])
    const [trabajador, setTrabajador] = React.useState({
        id: 0,
        apellidos_nombres: "",
        fk_com_tipo_documento: "00",
        numero_documento: "",
        colegiatura: "",
        telefono: "",
        es_activo: 1,
        cargo: "",
        area: props.area,
    });
    const [procesando, setProcesando] = React.useState(false);
    const [showConfirmacion, setShowConfirmacion] = React.useState(false);
    const [nombreTrabajador, setNombreTrabajador] = React.useState({
        error: false,
        helpertext: ''
    });
    useEffect((e) => {
        const listarDocumento = async () => {
            const tipoDocumento = await fetch(`${Constantes.RUTA_API}sistema/listarTipoDocumento.php`)
            const tipoDocumentoDB = await tipoDocumento.json();
            setTipoDocumentos(tipoDocumentoDB);
        }
        const recuperarTrabajador = async () => {
            const recuperarTrabajador = await fetch(`${Constantes.RUTA_API}recuperar/recuperarTrabajador.php?id=${props.id}`);
            const trabajadorDB = await recuperarTrabajador.json();
            setTrabajador(trabajadorDB);
        };
        listarDocumento();
        if (props.id !== undefined) {
            recuperarTrabajador()
        } else {
            setTrabajador({
                id: 0,
                apellidos_nombres: "",
                fk_com_tipo_documento: "00",
                numero_documento: "",
                colegiatura: "",
                telefono: "",
                es_activo: 1,
                cargo: "",
                area: props.area,
            });
        }
    }, [props.id, props.area])
    const handleChangeTrabajador = (event) => {
        if (event.target.id === "apellidos_nombres") {
            if (event.target.value !== "") {
                setNombreTrabajador({ error: false, helpertext: '' });
            } else {
                setNombreTrabajador({ error: true, helpertext: 'Nombre trabajador obligario' });
            }
        }
        setTrabajador((trabAnt) => ({
            ...trabAnt,
            [event.target.id]: event.target.value,
        }));
    }
    const closeDialog = async () => {
        setShowConfirmacion(false)
        setProcesando(false);
        setTrabajador({
            id: 0,
            apellidos_nombres: "",
            fk_com_tipo_documento: "00",
            numero_documento: "",
            colegiatura: "",
            telefono: "",
            es_activo: 1,
            cargo: "",
            area: props.area,

        });
        props.parentClose();

    }
    const guardarTrabajador = async () => {
        if (trabajador.apellidos_nombres !== "") {
            setProcesando(true);
            const guardarTrabajador = await fetch(`${Constantes.RUTA_API}/mantenimiento/guardarTrabajador.php`, {
                method: "POST",
                body: JSON.stringify({
                    id: trabajador.id,
                    apellidos_nombres: trabajador.apellidos_nombres.toUpperCase(),
                    fk_com_tipo_documento: trabajador.fk_com_tipo_documento,
                    numero_documento: trabajador.numero_documento,
                    colegiatura: trabajador.colegiatura !== null ? trabajador.colegiatura.toUpperCase() : trabajador.colegiatura,
                    telefono: trabajador.telefono,
                    es_activo: trabajador.es_activo,
                    cargo: trabajador.cargo !== null ? trabajador.cargo.toUpperCase() : trabajador.cargo,
                    area: trabajador.area,
                }),
            });
            const trabDB = await guardarTrabajador.json();
            if (trabDB) {
                setShowConfirmacion(true);
                if (props.listarTrabajadores !== undefined) props.listarTrabajadores();
            }
            setTimeout(() => {
                closeDialog();
            }, 2000);
        } else {
            setNombreTrabajador({ error: true, helpertext: 'Nombre trabajador obligatorio' });
        }
    }
    const handleChangeActivo = (event) => {
        setTrabajador((trabajadorAnt) => ({
            ...trabajadorAnt,
            es_activo: event.target.checked ? 1 : 0,
        }));
    }
    const handleTipoDocumento = (e) => {
        setTrabajador((trabajadorAnt) => ({
            ...trabajadorAnt,
            fk_com_tipo_documento: e.target.value,
        }));
    }
    const handleArea = (e) => {
        setTrabajador((trabajadorAnt) => ({
            ...trabajadorAnt,
            area: e.target.value,
        }));
    }
    return (
        <Grid container spacing={1}>
            <Dialog open={showConfirmacion}>
                <DialogTitle style={{ textAlign: 'center' }}>
                    <CheckCircleOutline color="success" />
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>Trabajador Guardado con exito</DialogContentText>
                </DialogContent>
                <DialogActions style={{ justifyContent: 'center' }}>
                    <Button variant='outlined' color='success' onClick={closeDialog} startIcon={<CheckCircleIcon />}>Aceptar</Button>
                </DialogActions>
            </Dialog>
            <Grid item xs={12}>
                <div style={{ display: 'flex', justifyContent: "space-between" }}>
                    <h3>{props.id > 0 ? "EDITAR" : "NUEVO"} TRABAJADOR </h3>
                    <FormControlLabel
                        labelPlacement="start"
                        color="secondary"
                        control={
                            <Switch checked={trabajador.es_activo === 1} color="success" onChange={handleChangeActivo} name="esActivo" />
                        }
                        label={trabajador.es_activo === 1 ? "ACTIVO" : "INACTIVO"}
                    />
                </div>
            </Grid>
            <Grid item xs={12}>
                <TextField
                    label="Nombre y apellidos:"
                    fullWidth
                    variant='filled'
                    type="text"
                    id='apellidos_nombres'
                    value={trabajador.apellidos_nombres}
                    onChange={handleChangeTrabajador}
                    error={nombreTrabajador.error}
                    helperText={nombreTrabajador.helpertext}
                />
            </Grid>
            <Grid item xs={12} sm={7}>
                <FormControl fullWidth margin="normal">
                    <InputLabel>Tipo Documento:</InputLabel>
                    <Select
                        labelId="fk_com_tipo_documento"
                        id="fk_com_tipo_documento"
                        value={trabajador.fk_com_tipo_documento !== null ? trabajador.fk_com_tipo_documento : ''}
                        label="Tipo Documento:"
                        onChange={handleTipoDocumento}
                    >
                        {tipoDocumentos.map(tipo => {
                            return (<MenuItem key={tipo.codigo} value={tipo.codigo}>{tipo.descripcion_corta}</MenuItem>)
                        })}
                    </Select>
                </FormControl>
            </Grid>
            <Grid item xs={12} sm={5}>
                <TextField
                    margin='normal'
                    label="Numero Documento:"
                    fullWidth
                    variant='filled'
                    type="text"
                    id='numero_documento'
                    value={trabajador.numero_documento !== null ? trabajador.numero_documento : ''}
                    onChange={handleChangeTrabajador}
                />
            </Grid>
            {/* <Grid item xs={6} sm={4} sx={{ mt: 3 }}>
                <FormControlLabel
                    labelPlacement="start"
                    color="secondary"
                    control={
                        <Switch checked={trabajador.es_activo === 1} onChange={handleChangeActivo} name="esActivo" />
                    }
                    label={trabajador.es_activo === 1 ? "ACTIVO" : "INACTIVO"}
                />
            </Grid> */}
            <Grid item xs={12} sm={7}>
                <TextField
                    label="Cargo: "
                    fullWidth
                    variant='filled'
                    type="text"
                    id='cargo'
                    margin='normal'
                    value={trabajador.cargo !== null ? trabajador.cargo : ''}
                    onChange={handleChangeTrabajador}
                    inputProps={{ style: { textTransform: 'uppercase' } }}
                />
            </Grid>

            <Grid item xs={12} sm={5}>
                <TextField
                    margin='normal'
                    label="Telefono:"
                    fullWidth
                    variant='filled'
                    type="text"
                    id='telefono'
                    value={trabajador.telefono !== null ? trabajador.telefono : ''}
                    onChange={handleChangeTrabajador}
                />
            </Grid>
            <Grid item xs={12} sm={12}>
                <TextField
                    label="Colegiatura:"
                    fullWidth
                    variant='filled'
                    type="text"
                    id='colegiatura'
                    value={trabajador.colegiatura !== null ? trabajador.colegiatura : ''}
                    onChange={handleChangeTrabajador}
                    inputProps={{ style: { textTransform: 'uppercase' } }}
                />
            </Grid>
            {
                props.area !== "A" &&
                <Grid item xs={12} sm={4}>
                    <FormControl fullWidth>
                        <InputLabel>Area:</InputLabel>
                        <Select
                            labelId="area"
                            id="area"
                            value={trabajador.area || ""}
                            label="Area:"
                            onChange={handleArea}
                        >
                            <MenuItem key="A" value="A">ADMINISTRATIVO</MenuItem>
                            <MenuItem key="C" value="C">CONSULTORIO</MenuItem>
                        </Select>
                    </FormControl>
                </Grid>
            }
            <Grid item container xs={12} spacing={2} sx={{ mt: 1 }}>
                <Grid item xs={6} align="right">
                    <LoadingButton loading={procesando} variant="outlined" color="success" startIcon={<SaveIcon />} onClick={guardarTrabajador} >
                        Guardar
                    </LoadingButton>
                </Grid>
                <Grid item xs={6}>
                    <Button variant="outlined" color="error" startIcon={<CancelOutlinedIcon />} onClick={closeDialog}>Cancelar</Button>
                </Grid>

            </Grid>
        </Grid >
    )
}
export default Trabajador