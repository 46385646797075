import * as React from 'react';
import { IconButton, TableCell, TableRow, Tooltip } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import KeyIcon from '@mui/icons-material/Key';

export default function FilaListarUsuario(props) {

    return (
        <TableRow key={props.usuario.codigo}>
            <TableCell>{props.usuario.codigo}</TableCell>
            <TableCell>{props.usuario.fk_log_perfil}</TableCell>
            <TableCell>{props.usuario.fk_log_usuario_creado_por}</TableCell>
            <TableCell>{props.usuario.created_at}</TableCell>
            <TableCell>{props.usuario.updated_at}</TableCell>
            <TableCell align="right">
                <Tooltip title="CAMBIAR CONTRASEÑA" placement='top'>
                    <IconButton color="error" onClick={() => props.handleClickClave(props.usuario.codigo)}><KeyIcon /></IconButton>
                </Tooltip>
                <Tooltip title="EDITAR USUARIO" placement='top'>
                    <IconButton color="info" onClick={() => props.handleClickEdit(props.usuario.codigo)}><EditIcon /></IconButton>
                </Tooltip>
                <Tooltip title="ELIMINAR USUARIO" placement='top'>
                    <IconButton color="error" onClick={() => props.handleClickDel(props.usuario.codigo)}><DeleteIcon /></IconButton>
                </Tooltip>
            </TableCell>
        </TableRow>
    )
}