import { Check, Close, HelpOutline, LocalHospitalOutlined } from '@mui/icons-material';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, IconButton, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, TableSortLabel, TextField, Typography } from '@mui/material';

import React from 'react';
import Constantes from '../../../Constantes';
import FilaListarUsuario from './filaListarUsuario';
import SaveUsuario from '../saveUsuario';
import CambiarContraseña from './cambiarContraseña';

export default function Activos(props) {
    const setAlertGeneral = props.setAlertGeneral;
    const [search, setSearch] = React.useState("");
    const [usuarios, setUsuarios] = React.useState([]);
    const [order, setOrder] = React.useState("");
    const [orderBy, setOrderBy] = React.useState("");
    const [codigoUsuarioSelect, setCodigoUsuarioSelect] = React.useState('');
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    // dialog
    const [newUsuario, setNewUsuario] = React.useState(false);
    const [editUsuario, setEditUsuario] = React.useState(false);
    const [deleteUsuario, setDeleteUsuario] = React.useState(false);
    const [changeClave, setChangeClave] = React.useState(false);
    // clave input 
    const headCells = [
        {
            id: 'codigo',
            numeric: false,
            disablePadding: true,
            label: 'CODIGO',
        },
        {
            id: 'fk_log_perfil',
            numeric: false,
            disablePadding: true,
            label: 'PERFIL',
        },
        {
            id: 'fk_log_usuario_creado_por',
            numeric: false,
            disablePadding: true,
            label: 'CREADO POR',
        },
        {
            id: 'created_at',
            numeric: false,
            disablePadding: true,
            label: 'F. DE CREACION',
        },
        {
            id: 'updated_at',
            numeric: false,
            disablePadding: true,
            label: 'F. DE ACTUALIZACION',
        }
    ];
    // handle
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };
    const handleClickEdit = (codigo) => {
        setEditUsuario(true);
        setCodigoUsuarioSelect(codigo);
    }
    const handleClickDel = (codigo) => {
        setDeleteUsuario(true);
        setCodigoUsuarioSelect(codigo);
    }
    const handleClickClave = (codigo) => {
        setChangeClave(true);
        setCodigoUsuarioSelect(codigo);
    }
    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };
    // functions
    const listarUsuarios = async () => {
        const getUsuarios = await fetch(`${Constantes.RUTA_API}listar/listarUsuariosActivos.php`);
        const response = await getUsuarios.json();
        setUsuarios(response);
    }
    const createSortHandler = (property) => (event) => {
        handleRequestSort(event, property);
    };
    const searchUsuario = () => {
        if (search === "") {
            return usuarios;
        } else {
            let filtro = usuarios.filter(f => f.codigo.includes(search.toUpperCase()));
            return filtro;
        }
    }

    const handleDeleteUsuario = async () => {
        const delete_usuario = await fetch(`${Constantes.RUTA_API}mantenimiento/eliminarUsuario.php?codigo=${codigoUsuarioSelect}&fecha=${new Date().toJSON().slice(0, 10)}`);
        const response = await delete_usuario.json();
        if (response.executed) {
            setAlertGeneral({ severity: 'success', message: 'Usuario eliminado correctamente', show: true });
            setTimeout(() => {
                setAlertGeneral({ severity: 'warning', message: '', show: false });
            }, 3000);
        } else {
            setAlertGeneral({ severity: 'error', message: 'No se pudo eliminar el usuario', show: true });
            setTimeout(() => {
                setAlertGeneral({ severity: 'warning', message: '', show: false });
            }, 3000);
        }
        setDeleteUsuario(false);
        listarUsuarios();
    }

    React.useEffect(() => {
        listarUsuarios();
    }, []);
    return (
        <>
            <Grid container>
                <Grid item md={8} xs={8}>
                    <TextField
                        fullWidth
                        size="small"
                        type="text"
                        placeholder="BUSCAR POR CODIGO"
                        value={search}
                        onChange={(e) => {
                            const { value } = e.target;
                            if (value !== "") {
                                setSearch(value);
                            } else {
                                setSearch('');
                            }
                        }}
                        inputProps={{ style: { textTransform: "uppercase" } }}
                    />
                </Grid>
                {/* <Grid item md={2}></Grid> */}
                <Grid item md={2} xs={4}>
                    <Button style={{ marginLeft: '10px' }} fullWidth onClick={() => setNewUsuario(true)} startIcon={<LocalHospitalOutlined />} variant='contained' color="success">NUEVO USUARIO</Button>
                </Grid>
                <Grid item md={12}>
                    <Paper sx={{ width: '100%', mb: 2, mt: 2 }}>
                        <TableContainer sx={{ height: '65vh' }}>
                            <Table stickyHeader>
                                <TableHead>
                                    <TableRow>
                                        {headCells.map((headCell) => (
                                            <TableCell style={{ fontWeight: 'bold' }}
                                                key={headCell.id}
                                                sortDirection={orderBy === headCell.id ? order : false}
                                            >
                                                <TableSortLabel
                                                    active={orderBy === headCell.id}
                                                    direction={orderBy === headCell.id ? order : 'asc'}
                                                    onClick={createSortHandler(headCell.id)}
                                                >
                                                    {headCell.label}
                                                    {orderBy === headCell.id ? (
                                                        <Box component="span" >
                                                            {order === 'desc' ? '' : ''}
                                                        </Box>
                                                    ) : null}
                                                </TableSortLabel>
                                            </TableCell>
                                        ))}
                                        <TableCell></TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {searchUsuario().slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((usuario) => {
                                        return <FilaListarUsuario key={usuario.codigo} usuario={usuario} handleClickEdit={handleClickEdit} handleClickDel={handleClickDel} handleClickClave={handleClickClave}></FilaListarUsuario>;
                                    })}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        <TablePagination
                            rowsPerPageOptions={[10, 25, 50]}
                            component="div"
                            labelRowsPerPage="Filas por columnas"
                            count={searchUsuario.length}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                    </Paper>
                </Grid>
            </Grid>
            <Dialog open={newUsuario} fullWidth={true} maxWidth="md">
                <DialogTitle>
                    NUEVO USUARIO
                    <IconButton
                        aria-label="close"
                        onClick={() => setNewUsuario(false)}
                        sx={{
                            position: 'absolute',
                            right: 8,
                            top: 8,
                            color: (theme) => theme.palette.grey[500],
                        }}
                    >
                        <Close />
                    </IconButton>
                </DialogTitle>
                <DialogContent>
                    <SaveUsuario
                        listarUsuarios={listarUsuarios}
                        setAlertGeneral={setAlertGeneral}
                        dialogClose={() => setNewUsuario(false)}
                    />
                </DialogContent>
            </Dialog>
            <Dialog open={editUsuario} fullWidth={true} maxWidth="sm">
                <DialogTitle>
                    EDITAR USUARIO
                    <IconButton
                        aria-label="close"
                        onClick={() => setEditUsuario(false)}
                        sx={{
                            position: 'absolute',
                            right: 8,
                            top: 8,
                            color: (theme) => theme.palette.grey[500],
                        }}
                    >
                        <Close />
                    </IconButton>
                </DialogTitle>
                <DialogContent>
                    <SaveUsuario
                        codigoUsuarioSelect={codigoUsuarioSelect}
                        listarUsuarios={listarUsuarios}
                        setAlertGeneral={setAlertGeneral}
                        dialogClose={() => setEditUsuario(false)}
                    />
                </DialogContent>
            </Dialog>
            <Dialog open={changeClave} maxWidth="xs">
                <DialogTitle>
                    CAMBIAR CLAVE
                    <IconButton
                        aria-label="close"
                        onClick={() => setChangeClave(false)}
                        sx={{
                            position: 'absolute',
                            right: 8,
                            top: 8,
                            color: (theme) => theme.palette.grey[500],
                        }}
                    >
                        <Close />
                    </IconButton>
                </DialogTitle>
                <DialogContent>
                    <CambiarContraseña
                        codigoUsuarioSelect={codigoUsuarioSelect}
                        setAlertGeneral={setAlertGeneral}
                        setChangeClave={() => setChangeClave(false)}
                    />
                </DialogContent>
            </Dialog>
            <Dialog open={deleteUsuario} maxWidth="sm">
                <DialogTitle style={{ textAlign: 'center' }}>
                    <div>
                        <HelpOutline style={{ color: '#fdb924', fontSize: '80px' }} />
                    </div>
                </DialogTitle>
                <DialogContent style={{ textAlign: 'center' }}>
                    <Typography variant="h5">Eliminar Usuario</Typography>
                    ¿Esta seguro de eliminar el usuario?
                </DialogContent>
                <DialogActions style={{ justifyContent: 'center' }}>
                    <Button startIcon={<Check />} variant="contained" color="error" onClick={handleDeleteUsuario}>Aceptar</Button>
                    <Button startIcon={<Close />} variant="contained" onClick={() => setDeleteUsuario(false)}>Cancelar</Button>
                </DialogActions>
            </Dialog>
        </>
    )
}
