import { Link, Alert, Button, Collapse, Container, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid, IconButton, InputAdornment, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography, Snackbar } from '@mui/material';
import React, { Component } from 'react';
import { useParams } from 'react-router-dom';
import FilaListarDetalleRegistro from '../registro/filaListarDetalleRegistro';
import Constantes from '../Constantes';
import HomeIcon from '@mui/icons-material/Home';
import CancelIcon from '@mui/icons-material/Cancel';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import BackupTableIcon from '@mui/icons-material/BackupTable';
import CloseIcon from '@mui/icons-material/Close';
import VerPagos from './pagos/verPagos';
class PagarRegistro extends Component {
    constructor(props) {
        super(props);
        this.state = {
            cliente: {
                codigo: "",
                apellidos_nombres: "",
                numero_documento: "",
                estado: "",
                telefono_1: "",
                telefono_2: "",
                e_mail: "",
                fk_ven_contacto: "",
                fk_log_usuario_creado_por: "",
                created_at: "",
                updated_at: "",
                deleted_at: "",
                fk_com_tipo_documento: "",
                fk_com_referencia: "",
            },
            currentRegistry: {
                fk_ven_registro: "",
                fam_general: "",
                hora_registro: "",
                fam_espefica: "",
                fecha_registro: "",
                tratamiento: "",
                total_presupuesto: 0.0,
                total_pago: 0.0,
                total_deuda: 0.0,
                total_controles: 0,
                fk_ven_cliente: "",
            },
            registro_detalle: [],
            controlPayment: {
                control: 0,
                controles: 0,
                cantidad: 0,
                precio_unitario: 0,
                total_detalle: 0,
                fk_ven_registro: "",
                fecha: "",
                hora: "",
                total_pago_detalle: 0,
                tipos_pago: "-",
            },
            showDialog: false,
            pagoEfectivo: 0.00,
            pagoTarjeta: 0.00,
            pagoDeposito: 0.00,
            showToast: false,
            toastSeverity: "warning",
            messageToast: "",
            currentAction: "",
            showConfirmacion: false,
            showConfDelete: false,
            showPagos: false,
            selectedRegistroDetalle: {},
            alertGeneral: {
                show: false,
                message: '',
                severity: 'warning'
            },
            cajaAbierta: {}
        };
        this.handleChangePago = this.handleChangePago.bind(this);
        this.guardarPago = this.guardarPago.bind(this);
        this.cancelarPago = this.cancelarPago.bind(this);
        this.eliminarPago = this.eliminarPago.bind(this);
        this.hidePagos = this.hidePagos.bind(this);
    }
    handleChangeDialog() {

    }
    async componentDidMount() {
        const rptaRegistro = await fetch(`${Constantes.RUTA_API}recuperar/recuperarRegistroUI.php?codigo=${this.props.params.codigo}`)
        const registro = await rptaRegistro.json();
        const respuesta = await fetch(`${Constantes.RUTA_API}recuperar/obtenerCliente.php?codigo=${registro.fk_ven_cliente}`);
        const cliente = await respuesta.json();
        const rptaRegistroDetalle = await fetch(`${Constantes.RUTA_API}listar/listarRegistroDetalleUI.php?codigo=${registro.fk_ven_registro}`);
        const registroDetalle = await rptaRegistroDetalle.json();
        this.setState({
            cliente: cliente,
            currentRegistry: registro,
            registro_detalle: registroDetalle,
        })
    }
    confirmarPago = async (registrodetalle, accion) => {

        if (accion === "PAY") {
            const caja_abierta = await fetch(`${Constantes.RUTA_API}listar/listarCajasAbiertas.php`);
            const response_caja_abierta = await caja_abierta.json();
            if (response_caja_abierta !== false) {
                this.setState({
                    controlPayment: registrodetalle,
                    showPagos: true,
                    currentAction: accion,
                    cajaAbierta: response_caja_abierta
                });
            } else {
                this.setState({
                    alertGeneral: { message: 'No se encontro una caja abierta', severity: 'warning', show: true }
                });
                setTimeout(() => { this.setState({ alertGeneral: { show: false, message: '', severity: 'warning' } }) }, 3000);
            }
        }
        if (accion === "DELETE") {
            if (registrodetalle.total_pago_detalle > 0) {
                this.setState({
                    controlPayment: registrodetalle,
                    showConfDelete: true,
                    currentAction: accion,
                })
            } else {
                this.setState({
                    showToast: true,
                    toastSeverity: "warning",
                    messageToast: "No tiene pagos para eliminar.",
                })
            }
        }
        /*if(accion==="EDIT"){
            if(registrodetalle.total_pago_detalle>0){
                const rptaPagoDetalle = await fetch(`${Constantes.RUTA_API}recuperar/recuperarPagoDetalleRegistro.php?codigo=${registrodetalle.fk_ven_registro}`);
                const pagoDetalleJS = await rptaPagoDetalle.json();
                //console.log(pagoDetalleJS);
                pagoDetalleJS.forEach(element => {
                    if(element.tipo_pago ==="D"){
                        this.setState({
                            pagoDeposito: element.monto_capital,
                        })
                    }
                    if(element.tipo_pago ==="E"){
                        this.setState({
                            pagoEfectivo: element.monto_capital,
                        })
                    }
                    if(element.tipo_pago ==="T"){
                        this.setState({
                            pagoTarjeta: element.monto_capital,
                        })
                    }
                });
                this.setState({
                    controlPayment:registrodetalle,
                    showDialog:true,
                    currentAction:accion,
                })
            }else{
                this.setState({
                    showToast:true,
                    toastSeverity:"warning",
                    messageToast:"No tiene pagos registrados para editar.",
                })
            }
        }
        if(accion==="PAY"){
            if(registrodetalle.total_detalle - registrodetalle.total_pago_detalle >0){
                this.setState({
                    controlPayment:registrodetalle,
                    showDialog:true,
                    currentAction:accion,
                })
            }else{
                this.setState({
                    showToast:true,
                    toastSeverity:"warning",
                    messageToast:"No tiene deuda pendiente, ya esta pagado.",
                })
            }
        }
        if(accion==="DELETE"){
            if(registrodetalle.total_pago_detalle>0){
                this.setState({
                    controlPayment:registrodetalle,
                    showConfDelete:true,
                    currentAction:accion,
                })                
            }else{
                this.setState({
                    showToast:true,
                    toastSeverity:"warning",
                    messageToast:"No tiene pagos para eliminar.",                    
                })
            }
            
        }
        setTimeout(() => {
            this.setState({
                showToast:false,
                showConfDelete:false,
                toastSeverity:"success",
                messageToast:"",
            })
        }, 5000);*/
    }

    async guardarPago() {
        const hoy = new Date();
        const logedUserJSON = window.sessionStorage.getItem('logedAppUser')
        const userInfo = JSON.parse(logedUserJSON);
        var pagoOLD = 0;
        if (this.state.currentAction === "EDIT") {
            const rptaPago = await fetch(`${Constantes.RUTA_API}recuperar/recuperarPagoRegistro.php?codigo=${this.state.controlPayment.fk_ven_registro}`);
            const pagoJS = await rptaPago.json();
            pagoOLD = pagoJS.id;
        }
        const pago = {
            id: pagoOLD,
            fecha: `${hoy.getFullYear()}-${(hoy.getMonth() + 1).toString().padStart(2, '0')}-${hoy.getDate().toString().padStart(2, '0')}`,
            hora: `${hoy.getHours().toString().padStart(2, '0')}:${hoy.getMinutes().toString().padStart(2, '0')}:${hoy.getSeconds().toString().padStart(2, '0')}`,
            total: this.state.controlPayment.total_pago_detalle,
            observaciones: "",
            esta_anulado: "NO",
            fk_ven_venta: "",
            fk_log_usuario_creado_por: userInfo.usuario,
            fk_ven_cliente: this.state.cliente.codigo,
            fk_ven_registro_control: this.state.controlPayment.control,
            fk_ven_registro: this.state.controlPayment.fk_ven_registro,
            controles: this.state.controlPayment.controles,
        };
        const respuesta = await fetch(`${Constantes.RUTA_API}mantenimiento/guardarPago.php`, {
            method: "POST",
            body: JSON.stringify(pago),
        });
        const resultado = await respuesta.json();
        if (resultado.guardado) {
            var fila = 0;
            if (this.state.pagoDeposito > 0) {
                fila = fila + 1;
                const pagoDetalleDeposito = {
                    linea: fila,
                    monto_capital: this.state.pagoDeposito,
                    intereses: 0,
                    mora: 0,
                    tipo_pago: "D",
                    fecha_operacion: `${hoy.getFullYear()}-${(hoy.getMonth() + 1).toString().padStart(2, '0')}-${hoy.getDate().toString().padStart(2, '0')}`,
                    referencia: "",
                    fk_ven_pago: resultado.id,
                    fk_fin_banco: "",
                };
                const rptaDeposito = await fetch(`${Constantes.RUTA_API}mantenimiento/guardarPagoDetalle.php`, {
                    method: "POST",
                    body: JSON.stringify(pagoDetalleDeposito),
                });
            }
            if (this.state.pagoEfectivo > 0) {
                fila = fila + 1;
                const pagoDetalleEfectivo = {
                    linea: fila,
                    monto_capital: this.state.pagoEfectivo,
                    intereses: 0,
                    mora: 0,
                    tipo_pago: "E",
                    fecha_operacion: `${hoy.getFullYear()}-${(hoy.getMonth() + 1).toString().padStart(2, '0')}-${hoy.getDate().toString().padStart(2, '0')}`,
                    referencia: "",
                    fk_ven_pago: resultado.id,
                    fk_fin_banco: "",
                };
                const rptaEfectivo = await fetch(`${Constantes.RUTA_API}mantenimiento/guardarPagoDetalle.php`, {
                    method: "POST",
                    body: JSON.stringify(pagoDetalleEfectivo),
                });
            }
            if (this.state.pagoTarjeta > 0) {
                fila = fila + 1;
                const pagoDetalleTarjeta = {
                    linea: fila,
                    monto_capital: this.state.pagoTarjeta,
                    intereses: 0,
                    mora: 0,
                    tipo_pago: "T",
                    fecha_operacion: `${hoy.getFullYear()}-${(hoy.getMonth() + 1).toString().padStart(2, '0')}-${hoy.getDate().toString().padStart(2, '0')}`,
                    referencia: "",
                    fk_ven_pago: resultado.id,
                    fk_fin_banco: "",
                };
                const rptaTarjeta = await fetch(`${Constantes.RUTA_API}mantenimiento/guardarPagoDetalle.php`, {
                    method: "POST",
                    body: JSON.stringify(pagoDetalleTarjeta),
                });
            }
            const rptaRegistroDetalle = await fetch(`${Constantes.RUTA_API}listar/listarRegistroDetalleUI.php?codigo=${this.state.currentRegistry.fk_ven_registro}`);
            const registroDetalle = await rptaRegistroDetalle.json();
            console.log("hola aca estoy");
            this.setState({
                registro_detalle: registroDetalle,
                showDialog: false,
                pagoEfectivo: 0.00,
                pagoTarjeta: 0.00,
                pagoDeposito: 0.00,
                showToast: false,
                toastSeverity: "warning",
                messageToast: "",
                showConfirmacion: true,
            })
            setTimeout(() => {
                this.setState({
                    showConfirmacion: false,
                })
            }, 2000);
        }
    }
    handleChangePago(evento) {
        const clave = evento.target.id;
        let valor = evento.target.value;
        if (clave === "controles") {
            const controlPaymentActualizado = this.state.controlPayment;
            controlPaymentActualizado["controles"] = valor;
            this.setState({
                controlPayment: controlPaymentActualizado,
            });
        } else {
            var pagoEfectivo = Number(this.state.pagoEfectivo);
            var pagoTarjeta = Number(this.state.pagoTarjeta);
            var pagoDeposito = Number(this.state.pagoDeposito);

            if (clave === "pagoEfectivo") {
                pagoEfectivo = valor;
            }
            if (clave === "pagoPOS") {
                pagoTarjeta = valor;
            }
            if (clave === "pagoDeposito") {
                pagoDeposito = valor;
            }
            const controlActualizado = this.state.controlPayment;
            const total = Number(pagoEfectivo) + Number(pagoTarjeta) + Number(pagoDeposito);
            if (total > controlActualizado.total_detalle) {
                controlActualizado["total_pago_detalle"] = total;
                this.setState({
                    pagoEfectivo: pagoEfectivo,
                    pagoTarjeta: pagoTarjeta,
                    pagoDeposito: pagoDeposito,
                    controlPayment: controlActualizado,
                    showToast: true,
                    messageToast: "El total a pagar supera el presupuesto.",
                })
            }
            else {
                controlActualizado["total_pago_detalle"] = total;
                this.setState({
                    pagoEfectivo: pagoEfectivo,
                    pagoTarjeta: pagoTarjeta,
                    pagoDeposito: pagoDeposito,
                    controlPayment: controlActualizado,
                    showToast: false,
                })
            }
        }
    }
    async cancelarPago() {
        const rptaRegistroDetalle = await fetch(`${Constantes.RUTA_API}listar/listarRegistroDetalleUI.php?codigo=${this.state.currentRegistry.fk_ven_registro}`);
        const registroDetalle = await rptaRegistroDetalle.json();
        this.setState({
            registro_detalle: registroDetalle,
            pagoEfectivo: 0.00,
            pagoTarjeta: 0.00,
            pagoDeposito: 0.00,
            showToast: false,
            messageToast: "",
            showDialog: false,
        });
    }
    async eliminarPago() {
        const rptaPago = await fetch(`${Constantes.RUTA_API}recuperar/recuperarPagoRegistroDetalle.php?codigo=${this.state.controlPayment.fk_ven_registro}&control=${this.state.controlPayment.control}`);
        const pagoJS = await rptaPago.json();
        pagoJS.forEach(async element => {
            const rptaEliminarPago = await fetch(`${Constantes.RUTA_API}mantenimiento/eliminarPago.php?codigo=${element.id}`);
            const resEliminarPago = await rptaEliminarPago.json();
            if (resEliminarPago) {
                this.setState({
                    showConfirmacion: true,
                    showConfDelete: false,
                });
                const rptaRegistroDetalle = await fetch(`${Constantes.RUTA_API}listar/listarRegistroDetalleUI.php?codigo=${this.state.currentRegistry.fk_ven_registro}`);
                const registroDetalle = await rptaRegistroDetalle.json();
                this.setState({
                    registro_detalle: registroDetalle,
                })
                setTimeout(() => {
                    this.setState({
                        showConfirmacion: false,
                        registro_detalle: registroDetalle,
                        pagoEfectivo: 0.00,
                        pagoTarjeta: 0.00,
                        pagoDeposito: 0.00,
                        showToast: false,
                        messageToast: "",
                        showDialog: false,
                    });
                }, 2000);
            }
        });

        //console.log(pagoJS);

    }
    async hidePagos() {
        this.setState({
            showPagos: false,
        })
        const rptaRegistroDetalle = await fetch(`${Constantes.RUTA_API}listar/listarRegistroDetalleUI.php?codigo=${this.state.currentRegistry.fk_ven_registro}`);
        const registroDetalle = await rptaRegistroDetalle.json();
        console.log(registroDetalle);
        this.setState({
            registro_detalle: registroDetalle,
        })
    }
    render() {
        return (
            <Container sx={{ marginTop: "70px", paddingBottom: "10px" }}>
                <Snackbar open={this.state.alertGeneral.show} autoHideDuration={6000} onClose={() => this.setState({ alertGeneral: { show: false, message: '', severity: 'warning' } })} anchorOrigin={{ vertical: 'top', horizontal: 'right' }}>
                    <Alert onClose={() => this.setState({ alertGeneral: { show: false, message: '', severity: 'warning' } })} severity={this.state.alertGeneral.severity} sx={{ width: '100%' }}>
                        {this.state.alertGeneral.message}
                    </Alert>
                </Snackbar>
                <Dialog open={this.state.showPagos} fullWidth maxWidth={"md"} >
                    <DialogTitle>
                        VER PAGOS
                        <IconButton
                            aria-label="close"
                            onClick={this.hidePagos}
                            sx={{
                                position: 'absolute',
                                right: 8,
                                top: 8,
                            }}
                        >
                            <CloseIcon />
                        </IconButton>
                    </DialogTitle>
                    <DialogContent>
                        <VerPagos registro={this.state.controlPayment} cliente={this.state.cliente} cajaAbierta={this.state.cajaAbierta} />
                    </DialogContent>
                    <DialogActions> </DialogActions>
                </Dialog>
                <Dialog open={this.state.showConfirmacion}>
                    <DialogTitle>CONFIRMACION</DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            Pago {this.state.currentAction === "DELETE" ? "eliminado" : "Registrado"} con exito.
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => { this.setState({ showConfirmacion: false, }) }} startIcon={<CheckCircleIcon />} variant='outlined' color='success'>Aceptar</Button>
                    </DialogActions>
                </Dialog>
                <Dialog open={this.state.showConfDelete}>
                    <DialogTitle>CONFIRMACION</DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            ¿Está seguro de que eliminar los pagos relacionados a este detalle?. Esta accion no se puede deshacer.
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.eliminarPago} startIcon={<CheckCircleIcon />} variant='outlined' color='success'>Aceptar</Button>
                        <Button onClick={() => { this.setState({ showConfDelete: false, }) }} startIcon={<CancelIcon />} variant='outlined' color='error'>Cancelar</Button>
                    </DialogActions>
                </Dialog>
                <Dialog
                    open={this.state.showDialog}
                    onClose={() => this.setState({ showDialog: false })}
                    maxWidth='md'
                >
                    <DialogTitle id="alert-dialog-title" sx={{ textAlign: "center" }}>
                        Pagar Tratamiento
                    </DialogTitle>
                    <DialogContent>
                        <Collapse in={this.state.showToast}>
                            <Alert
                                sx={{ mb: 2 }}
                                severity={this.state.toastSeverity}
                            >
                                {this.state.messageToast}
                            </Alert>
                        </Collapse>
                        <Grid container spacing={2}>
                            <Grid item xs={4}>
                                <TextField
                                    label="Controles"
                                    fullWidth
                                    variant='filled'
                                    type="text"
                                    id='controles'
                                    value={this.state.controlPayment.controles}
                                    onChange={this.handleChangePago}
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <TextField
                                    label="Presupuesto"
                                    fullWidth
                                    variant='filled'
                                    type="number"
                                    value={this.state.controlPayment.total_detalle}
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                S/
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <TextField
                                    label="Total Pago"
                                    fullWidth
                                    variant='filled'
                                    type="text"
                                    value={this.state.controlPayment.total_pago_detalle}
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                S/
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <TextField
                                    label="POS:"
                                    fullWidth
                                    variant='filled'
                                    type="number"
                                    id='pagoPOS'
                                    value={this.state.pagoTarjeta}
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                S/
                                            </InputAdornment>
                                        ),
                                    }}
                                    onChange={this.handleChangePago}
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <TextField
                                    label="Efectivo:"
                                    fullWidth
                                    variant='filled'
                                    type="number"
                                    id='pagoEfectivo'
                                    value={this.state.pagoEfectivo}
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                S/
                                            </InputAdornment>
                                        ),
                                    }}
                                    onChange={this.handleChangePago}
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <TextField
                                    label="Deposito/Transferencia:"
                                    fullWidth
                                    variant='filled'
                                    type="number"
                                    id='pagoDeposito'
                                    value={this.state.pagoDeposito}
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                S/
                                            </InputAdornment>
                                        ),
                                    }}
                                    onChange={this.handleChangePago}
                                />
                            </Grid>
                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.guardarPago} autoFocus startIcon={<CheckCircleIcon />} variant='outlined' color='success'>
                            Aceptar
                        </Button>
                        <Button onClick={this.cancelarPago} startIcon={<CancelIcon />} variant='outlined' color='error'>
                            Cancelar
                        </Button>
                    </DialogActions>
                </Dialog>
                <Paper sx={{ marginTop: "25px", p: 2 }} elevation={3}>
                    <Typography variant='h6'>Datos de Cliente</Typography>
                    <Grid container spacing={2} >
                        <Grid item sm={4} xs={12}>
                            <TextField
                                label="codigo"
                                fullWidth
                                variant='filled'
                                margin='normal'
                                value={this.state.cliente.codigo}
                            />
                        </Grid>
                        <Grid item sm={8} xs={12}>
                            <TextField
                                label="Nombres y Apellidos"
                                fullWidth
                                variant='filled'
                                margin='normal'
                                value={this.state.cliente.apellidos_nombres}
                            />
                        </Grid>
                    </Grid>
                </Paper>
                <Paper sx={{ mt: 2, p: 3, mb: 2 }} elevation={3}>
                    <Grid container spacing={2}>
                        <Grid item sm={4} xs={12}>
                            <TextField
                                label="Codigo:"
                                fullWidth
                                variant='filled'
                                value={this.state.currentRegistry.fk_ven_registro}
                            />
                        </Grid>
                        <Grid item sm={3} xs={12}>
                            <TextField
                                id="fecha_registro"
                                label="Fecha:"
                                type="date"
                                readOnly
                                fullWidth
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                value={this.state.currentRegistry.fecha_registro}
                            />
                        </Grid>
                        <Grid item sm={3} xs={12}>
                            <TextField
                                id='hora_registro'
                                label="Hora:"
                                fullWidth
                                placeholder='00:00:00'
                                variant='filled'
                                readOnly
                                style={{ textTransform: "uppercase" }}
                                value={this.state.currentRegistry.hora_registro}
                            />
                        </Grid>
                        <Grid item xs={12} >
                            <TextField
                                label="Tratamiento:"
                                fullWidth
                                variant='filled'
                                value={this.state.currentRegistry.tratamiento}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Collapse in={this.state.showToast}>
                                <Alert
                                    action={
                                        <IconButton
                                            aria-label="info"
                                            color="error"
                                            size="small"
                                            onClick={() => {
                                                this.setState({
                                                    showToast: false,
                                                });
                                            }}
                                        >
                                            <CloseIcon fontSize="inherit" />
                                        </IconButton>
                                    }
                                    sx={{ mb: 2 }}
                                    severity={this.state.toastSeverity}
                                >
                                    {this.state.messageToast}
                                </Alert>
                            </Collapse>
                        </Grid>
                        <Grid item xs={12}>
                            <TableContainer>
                                <Table stickyHeader>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>CUOTA</TableCell>
                                            <TableCell>FECHA</TableCell>
                                            <TableCell align='right'>CANTIDAD</TableCell>
                                            <TableCell align='right'>P. UNIT.</TableCell>
                                            <TableCell align='right'>PRESUPUESTO</TableCell>
                                            <TableCell align='right'>TOTAL PAGADO</TableCell>
                                            <TableCell align='right'>DEUDA</TableCell>
                                            <TableCell>TIPO PAGO</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {
                                            this.state.registro_detalle.map(detalle => {
                                                return <FilaListarDetalleRegistro registrodetalle={detalle} key={detalle.control} parentPayment={this.confirmarPago} />
                                            })}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Grid>
                    </Grid>
                    <Grid container spacing={1} sx={{ mt: 1, justifyContent: 'center' }}>
                        <Grid item sm={4} xs={6}>
                            <Link href={`#/tratamiento/${this.state.cliente.codigo}`}>
                                <Button variant="contained" color="success" startIcon={<BackupTableIcon />}>
                                    VOLVER A REGISTROS
                                </Button>
                            </Link>
                        </Grid>
                        <Grid item sm={4} xs={6}>
                            <Link href="#">
                                <Button variant="contained" color="primary" startIcon={<HomeIcon />}>
                                    VOLVER AL INICIO
                                </Button>
                            </Link>
                        </Grid>
                    </Grid>
                </Paper>
            </Container>
        )
    }
}
// eslint-disable-next-line import/no-anonymous-default-export
export default (props) => (<PagarRegistro {...props} params={useParams()} />);

