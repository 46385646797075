import * as React from 'react';
import { Alert, Box, Button, Container, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Paper, Snackbar, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, TableSortLabel, TextField, ToggleButton, ToggleButtonGroup, Typography } from '@mui/material';
import LocalHospitalOutlinedIcon from '@mui/icons-material/LocalHospitalOutlined';
import Constantes from '../../Constantes';
import FilaListarUsuario from './activos/filaListarUsuario';
import SaveUsuario from './saveUsuario';
import { ArrowLeft, ArrowRight, Check, Close, HelpOutline } from '@mui/icons-material';
import Activos from './activos/activos';
import Eliminados from './eliminados/eliminados';

export default function Usuarios() {
    // alert
    const [alertGeneral, setAlertGeneral] = React.useState({
        show: false,
        message: '',
        severity: 'warning'
    });

    const [valor, setValor] = React.useState('A');
    const handleChange = (event, newValue) => {
        if (newValue) {
            setValor(newValue);
        }
    };
    return (
        <Container sx={{ marginTop: "70px", paddingBottom: "10px" }}>
            <Snackbar open={alertGeneral.show} onClose={() => setAlertGeneral({ show: false, message: '', severity: 'warning' })} anchorOrigin={{ vertical: 'top', horizontal: 'right' }}>
                <Alert onClose={() => setAlertGeneral({ show: false, message: '', severity: 'warning' })} severity={alertGeneral.severity} sx={{ width: '100%' }}>
                    {alertGeneral.message}
                </Alert>
            </Snackbar>
            <Grid container spacing={1}>
                <Grid item md={12}>
                    <Paper elevation={1} sx={{ p: 1 }}>
                        <Grid item md={12} xs={6}>
                            <Typography variant='h5'>USUARIOS</Typography>
                        </Grid>
                        <Grid item md={12} xs={12}>
                            <ToggleButtonGroup
                                color="primary"
                                value={valor}
                                exclusive
                                onChange={handleChange}
                            >
                                <ToggleButton value="A">ACTIVOS</ToggleButton>
                                <ToggleButton value="E">ELIMINADOS</ToggleButton>
                            </ToggleButtonGroup>
                        </Grid>
                        <Grid item md={12} xs={12} style={{ marginTop: '10px' }}>
                            {valor === "A" && <Activos setAlertGeneral={setAlertGeneral} />}
                            {valor === "E" && <Eliminados setAlertGeneral={setAlertGeneral} />}
                        </Grid>
                    </Paper>
                </Grid>
            </Grid>
        </Container >
    )
}
