import React from 'react'
import { Container } from '@mui/system'
import RepBrackets from '../../components/reportes/repBrackets'

export default function ResumenTratamientos() {

    return (
        <Container sx={{ marginTop: "70px", paddingBottom: "10px" }}>
            <RepBrackets />
        </Container>
    )
}
