import { VerticalAlignCenter } from '@mui/icons-material';
import { Card, Container, Dialog, DialogContent, DialogTitle, Grid, IconButton, Paper, Table, TableBody, TableCell, TableContainer, TableFooter, TableHead, TableRow, Tooltip } from '@mui/material';
import React, { useEffect } from 'react';
import Constantes from '../Constantes';
import PreviewIcon from '@mui/icons-material/Preview';
import { Box } from '@mui/system';
import CloseIcon from '@mui/icons-material/Close';
import RepRegistroTratamiento from './registro/repRegistroTratamiento';
import { blue } from '@mui/material/colors';
function RepGastos() {
  const [resultadoMateriales, setResultadoMateriales] = React.useState([]);
  const [resultadoEquipos, setResultadoEquipos] = React.useState([]);
  const [resultadoGastos, setResultadoGastos] = React.useState([]);
  const [verDetale, setVerDetalle] = React.useState({
    show: false,
    codigo: "",
    tipo: "",
  });
  useEffect((e) => {
    const llenarDatos = async () => {
      const repMateriales = await fetch(`${Constantes.RUTA_API}reportes/reporteComprasMateriales.php`);
      const resMateriales = await repMateriales.json();
      setResultadoMateriales(resMateriales);
      const repEquipos = await fetch(`${Constantes.RUTA_API}reportes/reporteComprasEquipo.php`);
      const resEquipos = await repEquipos.json();
      setResultadoEquipos(resEquipos);
      const repGastos = await fetch(`${Constantes.RUTA_API}reportes/reporteGastos.php`);
      const resGastos = await repGastos.json();
      setResultadoGastos(resGastos);
      console.log(`${Constantes.RUTA_API}reportes/reporteGastos.php`);
    }
    llenarDatos();
  }, []);
  const closeChild = () => {
    setVerDetalle({
      show: false,
      codigo: "",
      tipo: "",
    })
  }
  const colorTable = {
    header: blue[700],
    footer: "",
    fondo: "#EBF8FF",
    subtotal: blue[500],
    total: blue[300],
    color: "#fff"
  }
  return (
    <Container sx={{ marginTop: "70px", paddingBottom: "10px" }}>
      <Dialog open={verDetale.show} maxWidth='md'>
        <DialogTitle>
          <IconButton
            aria-label="close"
            onClick={() => (setVerDetalle({
              show: false,
              codigo: "",
              tipo: "",
            }))}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <RepRegistroTratamiento codigo={verDetale.codigo} tipo={verDetale.tipo} closeChild={closeChild} />
        </DialogContent>
      </Dialog>
      <Paper elevation={5} sx={{ mt: 1, p: 2 }}>

        <Grid container spacing={1}>
          <Grid item xs={12} md={6} sx={{ overflow: 'hidden' }} textAlign="center">
            <h4>COMPRAS MATERIALES</h4>
            <TableContainer sx={{ maxHeight: 440 }}>
              <Table stickyHeader size="small">
                <TableHead >
                  <TableRow>
                    <TableCell sx={{ bgcolor: colorTable.header, color: colorTable.color }} >MATERIAL</TableCell>
                    <TableCell sx={{ bgcolor: colorTable.header, color: colorTable.color }} align='right'>TOTAL</TableCell>
                    <TableCell sx={{ bgcolor: colorTable.header, color: colorTable.color }} align='right'>REP. %</TableCell>
                    <TableCell sx={{ bgcolor: colorTable.header, color: colorTable.color }} padding="checkbox" align='center'></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {resultadoMateriales.map(res => {
                    return <TableRow key={res.codigo} id={res.nombre}>
                      <TableCell>{res.nombre}</TableCell>
                      <TableCell align='right'>S/ {res.total}</TableCell>
                      <TableCell align='right'>{Number(res.total) === 0 ? 0 : (res.total * 100 / resultadoMateriales.reduce((total, item) => parseFloat(total) + parseFloat(item.total), 0)).toFixed(2)} %</TableCell>
                      <TableCell align='center'><Tooltip title='ver detalle'><IconButton color='secondary' onClick={() => setVerDetalle({ show: true, codigo: res.codigo, tipo: "M" })}><PreviewIcon /></IconButton></Tooltip></TableCell>
                    </TableRow>
                  })}

                </TableBody>
                {/* <TableFooter>
                  <TableRow sx={{ backgroundColor: colorTable.total }}>
                    <TableCell sx={{ color: "#fff" }} colSpan={3}>TOTAL</TableCell>
                    <TableCell sx={{ color: "#fff" }} align='right'>{resultadoMateriales.reduce((total, item) => parseFloat(total) + parseFloat(item.total), 0).toFixed(2)}</TableCell>
                  </TableRow>
                </TableFooter> */}
              </Table>
            </TableContainer>
            <TableContainer sx={{ maxHeight: 80 }}>
              <Table size='small' stickyHeader>
                <TableBody >
                  <TableRow sx={{ backgroundColor: colorTable.total }}>
                    <TableCell sx={{ color: "#fff" }} colSpan={3}>TOTAL</TableCell>
                    <TableCell sx={{ color: "#fff" }} align='right'>{resultadoMateriales.reduce((total, item) => parseFloat(total) + parseFloat(item.total), 0).toFixed(2)}</TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
          <Grid item xs={12} md={6} textAlign="center">
            <h4>COMPRAS EQUIPO</h4>
            <TableContainer sx={{ maxHeight: 440 }}>

              <Table stickyHeader size="small">
                <TableHead>
                  <TableRow sx={{ backgroundColor: "#bbb" }}>
                    <TableCell sx={{ bgcolor: colorTable.header, color: colorTable.color }} >EQUIPO</TableCell>
                    <TableCell sx={{ bgcolor: colorTable.header, color: colorTable.color }} align='right'>TOTAL</TableCell>
                    <TableCell sx={{ bgcolor: colorTable.header, color: colorTable.color }} align='right'>REP. %</TableCell>
                    <TableCell sx={{ bgcolor: colorTable.header, color: colorTable.color }} padding="checkbox" align='center'></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {resultadoEquipos.map(res => {
                    return <TableRow key={res.codigo} id={res.nombre}>
                      <TableCell >{res.nombre}</TableCell>
                      <TableCell align='right'>S/ {res.total}</TableCell>
                      <TableCell align='right'>{Number(res.total) === 0 ? 0 : (res.total * 100 / resultadoEquipos.reduce((total, item) => parseFloat(total) + parseFloat(item.total), 0)).toFixed(2)} %</TableCell>
                      <TableCell align='center'><Tooltip title='ver detalle'><IconButton color='secondary' onClick={() => setVerDetalle({ show: true, codigo: res.codigo, tipo: "E" })}><PreviewIcon /></IconButton></Tooltip></TableCell>
                    </TableRow>
                  })}
                  {/* <TableRow sx={{ backgroundColor: colorTable.total }}>
                    <TableCell sx={{ color: "#fff" }} colSpan={3}>TOTAL</TableCell>
                    <TableCell sx={{ color: "#fff" }} align='right'>{resultadoEquipos.reduce((total, item) => parseFloat(total) + parseFloat(item.total), 0).toFixed(2)}</TableCell>
                  </TableRow> */}
                </TableBody>
              </Table>
            </TableContainer>
            <TableContainer sx={{ maxHeight: 80 }}>
              <Table size='small' stickyHeader>
                <TableBody >
                  <TableRow sx={{ backgroundColor: colorTable.total }}>
                    <TableCell sx={{ color: "#fff" }} colSpan={3}>TOTAL</TableCell>
                    <TableCell sx={{ color: "#fff" }} align='right'>{resultadoEquipos.reduce((total, item) => parseFloat(total) + parseFloat(item.total), 0).toFixed(2)}</TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
          <Grid container item p={1} mt={1} xs={12} sx={{ border: "solid", borderWidth: "1px", backgroundColor: colorTable.subtotal, fontWeight: "bold", color: "antiquewhite" }}>
            <Grid item xs={0} md={3}></Grid>
            <Grid item xs={8} md={4} textAlign="center">TOTAL MATERIALES Y EQUIPOS</Grid>
            <Grid item xs={4} md={2}>S/ {Number(resultadoEquipos.reduce((total, item) => Number(total) + Number(item.total), 0) + resultadoMateriales.reduce((total, item) => Number(total) + Number(item.total), 0)).toFixed(2)}</Grid>
          </Grid>
          <Grid item xs={0} md={2}></Grid>
          <Grid item xs={12} md={8} textAlign="center">
            <h4>GASTOS</h4>
            <TableContainer sx={{ maxHeight: 440 }}>

              <Table stickyHeader size="small">
                <TableHead>
                  <TableRow sx={{ backgroundColor: "#bbb" }}>
                    <TableCell sx={{ bgcolor: colorTable.header, color: colorTable.color }}>EQUIPO</TableCell>
                    <TableCell sx={{ bgcolor: colorTable.header, color: colorTable.color }} align='right'>TOTAL</TableCell>
                    <TableCell sx={{ bgcolor: colorTable.header, color: colorTable.color }} align='right'>REP. %</TableCell>
                    <TableCell sx={{ bgcolor: colorTable.header, color: colorTable.color }} padding="checkbox" align='center'></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {resultadoGastos.map(res => {
                    return <TableRow key={res.codigo} id={res.nombre}>
                      <TableCell>{res.nombre}</TableCell>
                      <TableCell align='right'>S/ {res.total}</TableCell>
                      <TableCell align='right'>{Number(res.total) === 0 ? 0 : (res.total * 100 / resultadoGastos.reduce((total, item) => parseFloat(total) + parseFloat(item.total), 0)).toFixed(2)} %</TableCell>
                      <TableCell align='center'><Tooltip title='ver detalle'><IconButton color='secondary' onClick={() => setVerDetalle({ show: true, codigo: res.codigo, tipo: "G" })}><PreviewIcon /></IconButton></Tooltip></TableCell>
                    </TableRow>
                  })}
                  {/* <TableRow sx={{ backgroundColor: colorTable.total }}>
                    <TableCell sx={{ color: "#fff" }} colSpan={3}>TOTAL</TableCell>
                    <TableCell sx={{ color: "#fff" }} align='right'>{resultadoGastos.reduce((total, item) => parseFloat(total) + parseFloat(item.total), 0).toFixed(2)}</TableCell>
                  </TableRow> */}
                </TableBody>
              </Table>
            </TableContainer>
            <TableContainer sx={{ maxHeight: 80 }}>
              <Table size='small' stickyHeader>
                <TableBody >
                  <TableRow sx={{ backgroundColor: colorTable.total }}>
                    <TableCell sx={{ color: "#fff" }} colSpan={3}>TOTAL</TableCell>
                    <TableCell sx={{ color: "#fff" }} align='right'>{resultadoGastos.reduce((total, item) => parseFloat(total) + parseFloat(item.total), 0).toFixed(2)}</TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>

        </Grid>
      </Paper>
    </Container>

  )
}

export default RepGastos

