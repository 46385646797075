import React from 'react'
import DeleteIcon from '@mui/icons-material/Delete';
import PaidIcon from '@mui/icons-material/Paid';
import { IconButton, TableCell, TableRow, Tooltip } from '@mui/material';
import { Link } from 'react-router-dom';
function FilaListarRegistroF(props) {
    const eliminar = async() =>{
        props.parentDelete(props.registro.fk_ven_registro);
    }
    const showVerPagos = async() =>{
        props.parentPayment(props.registro);
    }
    return (
        <TableRow key={props.registro.fk_ven_registro}>
            <TableCell key={props.registro.fk_ven_registro}>{props.registro.fk_ven_registro}</TableCell>
            <TableCell>{`${props.registro.fecha_registro} ${props.registro.hora_registro}`}</TableCell>
            <TableCell>{props.registro.fam_general}</TableCell>
            <TableCell>{props.registro.tratamiento}</TableCell>
            <TableCell align='right'>{props.registro.total_controles}</TableCell>                
            <TableCell align='right'>{props.registro.total_presupuesto}</TableCell>
            <TableCell align='right'>{props.registro.total_pago}</TableCell>
            <TableCell align='right'>{props.registro.total_deuda}</TableCell>
            <TableCell align='center'>
                <Link to={`/tratamiento/pagar/${props.registro.fk_ven_registro}`} key={`${props.registro.fk_ven_registro}-pagos`}>
                    <Tooltip title='Pagar Tratamiento'>
                        <IconButton color="success" ><PaidIcon/> </IconButton>
                    </Tooltip>
                </Link>
                <Tooltip title='Eliminar Registro'>
                    <IconButton onClick={eliminar} color="error"><DeleteIcon/> </IconButton>
                </Tooltip>
            </TableCell>
        </TableRow>
    )
}

export default FilaListarRegistroF