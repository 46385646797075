import { Alert, Button, Collapse, Container, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid, IconButton, InputAdornment, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography } from '@mui/material'
import React, { Component } from 'react'
import { Link, useParams } from 'react-router-dom';
import axios from 'axios';
import Constantes from '../components/Constantes';
import AddIcon from '@mui/icons-material/Add';
import "../css/mainStyles.css";
import FilaListarRegistroF from '../components/registro/filaListarRegistroF';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import FilaListarDetalleRegistro from '../components/registro/filaListarDetalleRegistro';
import ContactSupportIcon from '@mui/icons-material/ContactSupport';
import CancelIcon from '@mui/icons-material/Cancel';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CloseIcon from '@mui/icons-material/Close';
import EditarRegistro from '../components/registro/editarRegistro';

class Tratamiento extends Component {
    constructor(props) {
        super(props);
        this.state = {
            cliente: {
                codigo: "",
                apellidos_nombres: "",
                numero_documento: "",
                estado: "",
                telefono_1: "",
                telefono_2: "",
                e_mail: "",
                fk_ven_contacto: "",
                fk_log_usuario_creado_por: "",
                created_at: "",
                updated_at: "",
                deleted_at: "",
                fk_com_tipo_documento: "",
                fk_com_referencia: "",
            },
            registros: [],
            familias: [],
            currentRegistry: {
                fk_ven_registro: "",
                fam_general: "",
                hora_registro: "",
                fam_espefica: "",
                fecha_registro: "",
                tratamiento: "",
                total_presupuesto: 0.0,
                total_pago: 0.0,
                total_deuda: 0.0,
                total_controles: 0,
                fk_ven_cliente: "",
            },
            verRegistro: false,
            pagarRegistro: false,
            registro_detalle: [],
            codRegistroSeleccionado: "",
            showDialog: false,
            showToast: false,
            messageToast: "",
            toastSeverity: "success",
            dialogNewRegistro: false,
            codigoCliente: '',
        };
        this.handleCloseView = this.handleCloseView.bind(this);
        this.handleChangeDialog = this.handleChangeDialog.bind(this);
        this.confirmarEliminarRegistro = this.confirmarEliminarRegistro.bind(this);
        this.eliminarRegistro = this.eliminarRegistro.bind(this);
    }

    async componentDidMount() {
        const respuesta = await fetch(`${Constantes.RUTA_API}recuperar/obtenerCliente.php?codigo=${this.props.params.codigo}`);
        const cliente = await respuesta.json();
        const rptaFamilias = await fetch(`${Constantes.RUTA_API}listar/listarFamiliaGeneralTratamiento.php`);
        const familias = await rptaFamilias.json();
        this.listarTramientos(this.props.params.codigo);
        this.setState({
            cliente: cliente,
            familias: familias,
        });
    };
    listarTramientos = async (codigo) => {
        const paramsQuery = {
            clientName: codigo,
        };
        axios.post(`${Constantes.RUTA_API}listar/listarRegistroCliente.php`, paramsQuery).then(res => {
            this.setState({
                registros: res.data,
            });
        })
    }
    callbackRegistro = modalOpener => {
        this.recuperarDetalles(modalOpener.registro.fk_ven_registro);
        this.setState({
            currentRegistry: modalOpener.registro,
            verRegistro: modalOpener.mostarRegistro,
        })
    };

    handleCloseView() {
        this.setState({
            verRegistro: false,
        })
    }
    handleChangeDialog(codigo) {
        this.setState({ newRegistro: true, codigoSelect: codigo });
    }
    recuperarDetalles = async (fk_ven_registro) => {
        const rptadetalles = await fetch(`${Constantes.RUTA_API}listar/listarRegistroDetalle.php?codigo=${fk_ven_registro}`)
        const detalles = await rptadetalles.json();
        this.setState({
            registro_detalle: detalles,
        });
    }
    totalPresupuesto() {
        return this.state.registros.map(({ total_presupuesto }) => total_presupuesto).reduce((sum, i) => parseFloat(sum) + parseFloat(i), 0);
    }

    totalPagado() {
        return this.state.registros.map(({ total_pago }) => total_pago).reduce((sum, i) => parseFloat(sum) + parseFloat(i), 0);
    }

    totalDeuda() {
        return this.state.registros.map(({ total_deuda }) => total_deuda).reduce((sum, i) => parseFloat(sum) + parseFloat(i), 0);
    }
    confirmarEliminarRegistro = codigo => {
        this.setState({
            codRegistroSeleccionado: codigo,
            showDialog: true,
        });
    }
    async eliminarRegistro() {
        const respuesta = await fetch(`${Constantes.RUTA_API}mantenimiento/eliminarRegistro.php?codigo=${this.state.codRegistroSeleccionado}`);
        const exitoso = await respuesta.json();
        if (exitoso.executed) {
            const newRegistro = this.state.registros.filter(reg => reg.fk_ven_registro !== this.state.codRegistroSeleccionado);
            this.setState({
                registros: newRegistro,
                toastSeverity: "success",
                showToast: true,
                messageToast: exitoso.message,
                showDialog: false,
            })
        } else {
            this.setState({
                toastSeverity: "error",
                showToast: true,
                messageToast: exitoso.message,
                showDialog: false,
            })
        }
        setTimeout(() => {
            this.setState({
                toastSeverity: "success",
                messageToast: "",
                showToast: false,
            })
        }, 3000);
    }
    render() {
        return (
            <Grid container style={{ minHeight: '90vh', justifyContent: 'center', alignContent: 'center', paddingTop: "70px" }}>
                <Dialog open={this.state.dialogNewRegistro} maxWidth="md">
                    <DialogTitle>NUEVO REGISTRO</DialogTitle>
                    <DialogContent>
                        <EditarRegistro listarTramientos={this.listarTramientos} codigoCliente={this.props.params.codigo} dialogClose={() => this.setState({ dialogNewRegistro: false })} />
                    </DialogContent>
                </Dialog>
                <Grid item md={11} sm={11} xs={11}>
                    <Collapse in={this.state.showToast}>
                        <Alert
                            action={
                                <IconButton
                                    aria-label="info"
                                    color="error"
                                    size="small"
                                    onClick={() => {
                                        this.setState({
                                            showToast: false,
                                        });
                                    }}
                                >
                                    <CloseIcon fontSize="inherit" />
                                </IconButton>
                            }
                            sx={{ mb: 2 }}
                            severity={this.state.toastSeverity}
                        >
                            {this.state.messageToast}
                        </Alert>
                    </Collapse>
                    <Dialog open={this.state.verRegistro} onClose={this.handleCloseView}>
                        <DialogTitle>VER REGISTRO</DialogTitle>
                        <DialogContent sx={{ padding: "" }}>
                            <Grid container spacing={2} sx={{ p: 3 }}>
                                <Grid item xs={4}>
                                    <TextField
                                        label="Codigo:"
                                        fullWidth
                                        variant='filled'
                                        value={this.state.currentRegistry.fk_ven_registro}
                                    />
                                </Grid>
                                <Grid item xs={4}>
                                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                                        <DatePicker
                                            label="Fecha:"
                                            readOnly
                                            value={this.state.currentRegistry.fecha_registro}
                                            renderInput={(params) => <TextField {...params} />}
                                            onChange={this.handleChangeDialog}
                                        />
                                    </LocalizationProvider>
                                </Grid>
                                <Grid item xs={4}>
                                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                                        <TimePicker
                                            label="Hora:"
                                            readOnly
                                            value={this.state.currentRegistry.hora_registro}
                                            renderInput={(params) => <TextField {...params} />}
                                            onChange={this.handleChangeDialog}
                                        />
                                    </LocalizationProvider>
                                </Grid>
                                <Grid item xs={12} >
                                    <TextField
                                        label="Tratamiento:"
                                        fullWidth
                                        variant='filled'
                                        value={this.state.currentRegistry.tratamiento}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TableContainer>
                                        <Table stickyHeader>
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell>CONTROL</TableCell>
                                                    <TableCell align='right'>CANTIDAD</TableCell>
                                                    <TableCell align='right'>P. UNIT.</TableCell>
                                                    <TableCell align='right'>TOTAL PAGADO</TableCell>
                                                    <TableCell>TIPO PAGO</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {
                                                    this.state.registro_detalle.map(detalle => {
                                                        return <FilaListarDetalleRegistro registrodetalle={detalle} key={detalle.control} accion="view" parentDelete={this.confirmarEliminarRegistro} />
                                                    })}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>

                                </Grid>
                            </Grid>
                        </DialogContent>
                        <DialogActions>
                            <Button variant='contained' color='primary' onClick={this.handleCloseView}>ACEPTAR</Button>
                        </DialogActions>
                    </Dialog>
                    <Dialog
                        open={this.state.showDialog}
                        onClose={() => this.setState({ showDialog: false })}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                    >
                        <DialogTitle id="alert-dialog-title" sx={{ textAlign: "center" }}>
                            <ContactSupportIcon fontSize="large" color='primary' />
                        </DialogTitle>
                        <DialogContent>
                            <DialogContentText id="alert-dialog-description">
                                {`¿Seguro que desea eliminar el Registro ${this.state.codRegistroSeleccionado}?. Esta acción no se puede deshacer, si el registro tiene pagos se procedera a eliminarlos tambien.`}
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={this.eliminarRegistro} autoFocus startIcon={<CheckCircleIcon />} variant='outlined' color='success'>
                                Aceptar
                            </Button>
                            <Button onClick={() => this.setState({ showDialog: false })} startIcon={<CancelIcon />} variant='outlined' color='error'>
                                Cancelar
                            </Button>
                        </DialogActions>
                    </Dialog>
                    <Paper elevation={2} sx={{ p: 2 }}>
                        <Typography variant='h5'>TRATAMIENTO</Typography>
                        <Grid container spacing={2}>
                            <Grid item md={2} sm={5} xs={12}>
                                <TextField
                                    label="codigo"
                                    fullWidth
                                    variant='filled'
                                    value={this.state.cliente.codigo}
                                />
                            </Grid>
                            <Grid item md={5} sm={7} xs={12}>
                                <TextField
                                    label="Nombres y Apellidos"
                                    fullWidth
                                    variant='filled'
                                    value={this.state.cliente.apellidos_nombres}
                                />
                            </Grid>
                            <Grid item md={3}></Grid>
                            <Grid item md={2} sm={12} xs={12} sx={{ display: 'flex', justifyContent: { sm: 'center', md: 'end', xs: 'center' }, mt: { sm: 0, md: 1.5, xs: 0 }, pt: { sm: 0, md: 0, xs: 0 } }} >
                                <Button variant='contained' color='success' startIcon={<AddIcon />} onClick={() => this.setState({ dialogNewRegistro: true })}>
                                    REGISTRAR
                                </Button>
                            </Grid>
                            <Grid item xs={12}>
                                <Paper elevation={1} sx={{ width: '100%' }}>
                                    <TableContainer>
                                        <Table stickyHeader aria-label="sticky table">
                                            <TableHead>
                                                <TableRow sx={{ fontWeight: "bold" }}>
                                                    <TableCell style={{ fontWeight: 'bold' }}>REGISTRO</TableCell>
                                                    <TableCell style={{ fontWeight: 'bold' }}>FECHA / HORA</TableCell>
                                                    <TableCell style={{ fontWeight: 'bold' }}>FAM. GENERAL</TableCell>
                                                    <TableCell style={{ fontWeight: 'bold' }}>TRATAMIENTO</TableCell>
                                                    <TableCell align='right' style={{ fontWeight: 'bold' }}># CUOTAS</TableCell>
                                                    <TableCell align='right' style={{ fontWeight: 'bold' }}>PRESUP.</TableCell>
                                                    <TableCell align='right' style={{ fontWeight: 'bold' }}>T. PAGOS</TableCell>
                                                    <TableCell align='right' style={{ fontWeight: 'bold' }}>DEUDA</TableCell>
                                                    <TableCell align='center' padding='none' className='buttonTable' style={{ fontWeight: 'bold' }}>ACCIONES</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {this.state.registros.map(registro => {
                                                    return <FilaListarRegistroF parentDelete={this.confirmarEliminarRegistro} key={registro.fk_ven_registro} registro={registro} parentPayment={this.showPagos}></FilaListarRegistroF>
                                                })}
                                                <TableRow>
                                                    <TableCell rowSpan={3} colSpan={5} />
                                                    <TableCell colSpan={3}>Total Presupuestado: </TableCell>
                                                    <TableCell align="right">{`S/ ${this.totalPresupuesto()}`}</TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell colSpan={3}>Total Pagado: </TableCell>
                                                    <TableCell align="right">{`S/ ${this.totalPagado()}`}</TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell colSpan={3}>Total Deuda: </TableCell>
                                                    <TableCell align="right">{`S/ ${this.totalDeuda()}`}</TableCell>
                                                </TableRow>
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </Paper>
                            </Grid>
                        </Grid>
                    </Paper >
                </Grid >
            </Grid >
        )
    }
}

export default (props) => (<Tratamiento {...props} params={useParams()} />);
