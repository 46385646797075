import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControl, Grid, IconButton, InputAdornment, InputLabel, MenuItem, Select, TextField, Tooltip, Typography } from '@mui/material'
import React, { useEffect } from 'react'
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { LoadingButton } from '@mui/lab';
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import MedicalInformationOutlinedIcon from '@mui/icons-material/MedicalInformationOutlined';
import Constantes from '../../Constantes';
import Trabajador from '../../sistema/trabajador';
import { Close } from '@mui/icons-material';
function EditarPago(props) {
    const hoy = new Date();
    const strFecha = `${hoy.getFullYear()}-${(hoy.getMonth() + 1).toString().padStart(2, '0')}-${hoy.getDate().toString().padStart(2, '0')}`;
    const strHora = `${hoy.getHours().toString().padStart(2, '0')}:${hoy.getMinutes().toString().padStart(2, '0')}:${hoy.getSeconds().toString().padStart(2, '0')}`;
    const [showPersonal, setShowPersonal] = React.useState(false);
    const [listRhAtendidoPor, setListRhAtendidoPor] = React.useState([]);
    const [procesando, setProcesando] = React.useState(false);
    const [confirmacion, setConfirmacion] = React.useState({
        titulo: "",
        mensaje: "",
        show: false,
    });
    const [pago, setPago] = React.useState({
        id: 0,
        fecha: strFecha,
        hora: strHora,
        total: 0,
        observaciones: "",
        esta_anulado: "NO",
        fk_ven_venta: "",
        fk_log_usuario_creado_por: "",
        fk_ven_cliente: "",
        fk_ven_registro_control: "",
        fk_ven_registro: "",
        controles: 0,
        total_presupuesto: 0,
        fk_rh_trabajador_atendido_por: 0,
        fk_ven_caja_cobranza: 0,
    });
    const [detalleEfectivo, setDetalleEfectivo] = React.useState({
        linea: 0,
        monto_capital: 0,
        intereses: 0,
        mora: 0,
        tipo_pago: "",
        fecha_operacion: "",
        referencia: "",
        fk_ven_pago: "",
        fk_fin_banco: "",
    })
    const [detalleDeposito, setDetalleDeposito] = React.useState({
        linea: 0,
        monto_capital: 0,
        intereses: 0,
        mora: 0,
        tipo_pago: "",
        fecha_operacion: "",
        referencia: "",
        fk_ven_pago: "",
        fk_fin_banco: "",
    })
    const [detalleTarjeta, setDetalleTarjeta] = React.useState({
        linea: 0,
        monto_capital: 0,
        intereses: 0,
        mora: 0,
        tipo_pago: "",
        fecha_operacion: "",
        referencia: "",
        fk_ven_pago: "",
        fk_fin_banco: "",
    })
    useEffect((e) => {
        if (props.cliente !== undefined) {
            setPago({ ...pago, fk_ven_cliente: props.cliente.codigo });
        }
        const preCharge = async () => {
            const pagosFetch = await fetch(`${Constantes.RUTA_API}listar/listarTrabajadorArea.php?area=C`);
            const pagosDB = await pagosFetch.json();
            setListRhAtendidoPor(pagosDB);
            setPago((pagoant) => ({
                ...pagoant,
                total_presupuesto: props.registro.total_detalle,
                fk_ven_cliente: props.pago.fk_ven_cliente,
            }));
            setProcesando(false);
        }
        preCharge();
    }, []);
    const handleChangePago = (event) => {
        let valor = event.target.value;
        const id = event.target.id;
        var pagoTarjeta = detalleTarjeta.monto_capital;
        var pagoEfectivo = detalleEfectivo.monto_capital;
        var pagoDeposito = detalleDeposito.monto_capital;
        if (id === "pagoPOS") {
            pagoTarjeta = Math.abs(Number(valor));
            setDetalleTarjeta((detalle) => ({
                ...detalle,
                monto_capital: Math.abs(Number(valor))
            }));
        } else if (id === "pagoDeposito") {
            pagoDeposito = Math.abs(Number(valor));
            setDetalleDeposito((detalle) => ({
                ...detalle,
                monto_capital: Math.abs(Number(valor))
            }));
        } else if (id === "pagoEfectivo") {
            pagoEfectivo = Math.abs(Number(valor));
            setDetalleEfectivo((detalle) => ({
                ...detalle,
                monto_capital: Math.abs(Number(valor))
            }));
        } else {
            setPago((pagoant) => ({
                ...pagoant,
                [id]: valor
            }));
        }
        setPago((pagoant) => ({
            ...pagoant,
            total: pagoEfectivo + pagoDeposito + pagoTarjeta
        }));
        console.log(pago);
        console.log(id);
        console.log(valor);
    }
    const constAgregarPago = async () => {
        setProcesando(true);
        const logedUserJSON = window.sessionStorage.getItem('logedAppUser');
        const user = JSON.parse(logedUserJSON);
        const pagonuevo = {
            id: pago.id,
            fecha: new Date().toJSON().slice(0, 10),
            hora: pago.hora,
            total: pago.total,
            observaciones: "",
            esta_anulado: "NO",
            fk_ven_venta: "",
            fk_log_usuario_creado_por: user.usuario,
            fk_ven_cliente: pago.fk_ven_cliente,
            fk_ven_registro_control: props.registro.control,
            fk_ven_registro: props.registro.fk_ven_registro,
            controles: pago.controles,
            fk_rh_trabajador_atendido_por: pago.fk_rh_trabajador_atendido_por,
            fk_ven_caja_cobranza: props.cajaAbierta.id
        };

        const guardarPago = await fetch(`${Constantes.RUTA_API}mantenimiento/guardarPago.php`, {
            method: "POST",
            body: JSON.stringify(pagonuevo),
        });
        const pagosRes = await guardarPago.json();
        if (pagosRes.guardado) {
            if (detalleTarjeta.monto_capital > 0) {
                const nuevoDetalleTarjeta = {
                    linea: 3,
                    monto_capital: detalleTarjeta.monto_capital,
                    intereses: 0,
                    mora: 0,
                    tipo_pago: "T",
                    fecha_operacion: pagonuevo.fecha,
                    referencia: "",
                    fk_ven_pago: pagosRes.id,
                    fk_fin_banco: "",
                }
                /*setDetalleTarjeta((detalleAnt)=>({
                    ...detalleAnt,
                    linea:3,
                    tipo_pago:"T",
                    fecha_operacion:pago.fecha,                    
                    fk_ven_pago:pagosRes.id,
                }));*/
                const guardarDetalleTarjeta = await fetch(`${Constantes.RUTA_API}mantenimiento/guardarPagoDetalle.php`, {
                    method: "POST",
                    body: JSON.stringify(nuevoDetalleTarjeta),
                });
            }
            if (detalleDeposito.monto_capital > 0) {
                const nuevoDetalleDeposito = {
                    linea: 2,
                    monto_capital: detalleDeposito.monto_capital,
                    intereses: 0,
                    mora: 0,
                    tipo_pago: "D",
                    fecha_operacion: pagonuevo.fecha,
                    referencia: "",
                    fk_ven_pago: pagosRes.id,
                    fk_fin_banco: "",
                }
                /*setDetalleDeposito((detalleAnt)=>({
                    ...detalleAnt,
                    linea:2,
                    tipo_pago:"D",
                    fecha_operacion:pago.fecha,                    
                    fk_ven_pago:pagosRes.id,                    
                }));*/
                const guardarDetalleDeposito = await fetch(`${Constantes.RUTA_API}mantenimiento/guardarPagoDetalle.php`, {
                    method: "POST",
                    body: JSON.stringify(nuevoDetalleDeposito),
                });
            }
            if (detalleEfectivo.monto_capital > 0) {
                const nuevoDetalleEfectivo = {
                    linea: 1,
                    monto_capital: detalleEfectivo.monto_capital,
                    intereses: 0,
                    mora: 0,
                    tipo_pago: "E",
                    fecha_operacion: pagonuevo.fecha,
                    referencia: "",
                    fk_ven_pago: pagosRes.id,
                    fk_fin_banco: "",
                }
                /*setDetalleEfectivo((detalleAnt)=>({
                    ...detalleAnt,
                    linea:1,
                    tipo_pago:"E",
                    fecha_operacion:pago.fecha,                    
                    fk_ven_pago:pagosRes.id,                    
                }));*/
                const guardarDetalleEfectivo = await fetch(`${Constantes.RUTA_API}mantenimiento/guardarPagoDetalle.php`, {
                    method: "POST",
                    body: JSON.stringify(nuevoDetalleEfectivo),
                });
            }
            setConfirmacion({
                titulo: "PAGO GUARDADO",
                mensaje: "el pago fue guardado con exito.",
                show: true,
            })
        }
        else {
            setConfirmacion({
                titulo: "ERROR",
                mensaje: "Ocurrió un error al guardar el pago intente de nuevo.",
                show: true,
            })
        }
        setTimeout(() => {
            setProcesando(false);
            props.parentClose();
        }, 2000);
    }
    const hidePago = () => {
        setProcesando(true);
        setPago({
            id: 0,
            fecha: strFecha,
            hora: strHora,
            total: 0,
            observaciones: "",
            esta_anulado: "NO",
            fk_ven_venta: "",
            fk_log_usuario_creado_por: "",
            fk_ven_cliente: "",
            fk_ven_registro_control: "",
            fk_ven_registro: "",
            controles: 0,
            total_presupuesto: 0,
            fk_rh_trabajador_atendido_por: 0,
        });
        setDetalleEfectivo({
            linea: 0,
            monto_capital: 0,
            intereses: 0,
            mora: 0,
            tipo_pago: "",
            fecha_operacion: "",
            referencia: "",
            fk_ven_pago: "",
            fk_fin_banco: "",
        })
        setDetalleDeposito({
            linea: 0,
            monto_capital: 0,
            intereses: 0,
            mora: 0,
            tipo_pago: "",
            fecha_operacion: "",
            referencia: "",
            fk_ven_pago: "",
            fk_fin_banco: "",
        })
        setDetalleTarjeta({
            linea: 0,
            monto_capital: 0,
            intereses: 0,
            mora: 0,
            tipo_pago: "",
            fecha_operacion: "",
            referencia: "",
            fk_ven_pago: "",
            fk_fin_banco: "",
        })
        setProcesando(false);
        props.parentClose();
    }
    const handleChangeAtendidoPor = (event) => {
        setPago((pagoAnt) => ({
            ...pagoAnt,
            fk_rh_trabajador_atendido_por: event.target.value,
        }));
    }
    const closeTrabajador = async () => {
        setShowPersonal(false);
        const pagosFetch = await fetch(`${Constantes.RUTA_API}listar/listarTrabajadorArea.php?area=C`);
        const pagosDB = await pagosFetch.json();
        setListRhAtendidoPor(pagosDB);
    }
    return (
        <>
            <Grid container spacing={2} sx={{ mt: 1 }}>
                <Dialog open={confirmacion.show}>
                    <DialogTitle>{confirmacion.titulo}</DialogTitle>
                    <DialogContent>
                        <DialogContentText>{confirmacion.mensaje}</DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button variant="outlined" color="secondary" startIcon={<CheckCircleIcon />} onClick={() => { setConfirmacion({ titulo: "", mensaje: "", show: false, }) }}>Aceptar</Button>
                    </DialogActions>
                </Dialog>
                <Dialog open={showPersonal}>
                    <DialogTitle>
                        <IconButton
                            aria-label="close"
                            onClick={() => setShowPersonal(false)}
                            sx={{
                                position: 'absolute',
                                right: 8,
                                top: 8,
                            }}
                        >
                            <Close />
                        </IconButton>
                    </DialogTitle>
                    <DialogContent>
                        <Trabajador parentClose={closeTrabajador} area={'C'} />
                    </DialogContent>
                </Dialog>
                <Grid item sm={3} xs={6}>
                    <TextField
                        label="Fecha:"
                        fullWidth
                        variant='filled'
                        type='date'
                        id='fecha'
                        value={props.cajaAbierta.fecha_apertura}
                        disabled
                        InputLabelProps={{
                            shrink: true,
                        }}
                    // onChange={handleChangePago}
                    />
                    {/* <Typography>FECHA DE CAJA: {props.cajaAbierta.fecha_apertura}</Typography> */}
                </Grid>
                <Grid item sm={3} xs={6}>
                    <TextField
                        label="Hora:"
                        fullWidth
                        variant='filled'
                        type='time'
                        id='hora'
                        value={pago.hora}
                        InputLabelProps={{
                            shrink: true,
                        }}
                        onChange={handleChangePago}
                    />
                </Grid>
                <Grid item sm={5} xs={10}>
                    <FormControl fullWidth>
                        <InputLabel id="select-AtendidoPor">Atendido por:</InputLabel>
                        <Select
                            labelId="select-AtendidoPor-lb"
                            id="select-AtendidoPorS"
                            value={pago.fk_rh_trabajador_atendido_por > 0 ? pago.fk_rh_trabajador_atendido_por : ""}
                            onChange={handleChangeAtendidoPor}
                            label="Atendido por:"
                            defaultValue=""
                        >
                            {listRhAtendidoPor.map((rh) => (
                                <MenuItem value={rh.id} key={rh.id}>{`${rh.colegiatura} | ${rh.apellidos_nombres}`}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={1}>
                    <Tooltip title="Agregar personal">
                        <IconButton color='info' size='large' onClick={() => { setShowPersonal(true) }}><MedicalInformationOutlinedIcon fontSize='large' /></IconButton>
                    </Tooltip>
                </Grid>
                <Grid item sm={4} xs={12}>
                    <TextField
                        label="POS:"
                        fullWidth
                        variant='filled'
                        type="number"
                        id='pagoPOS'
                        value={detalleTarjeta.monto_capital}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    S/
                                </InputAdornment>
                            ),
                        }}
                        onChange={handleChangePago}
                        inputProps={{ style: { textAlign: 'right' }, min: 0, step: "1" }}
                    />
                </Grid>
                <Grid item sm={4} xs={12}>
                    <TextField
                        label="Efectivo:"
                        fullWidth
                        variant='filled'
                        type="number"
                        id='pagoEfectivo'
                        value={detalleEfectivo.monto_capital}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    S/
                                </InputAdornment>
                            ),
                        }}
                        onChange={handleChangePago}
                        inputProps={{ style: { textAlign: 'right' }, min: 0, step: "1" }}
                    />
                </Grid>
                <Grid item sm={4} xs={12}>
                    <TextField
                        label="Deposito/Transferencia:"
                        fullWidth
                        variant='filled'
                        type="number"
                        id='pagoDeposito'
                        value={detalleDeposito.monto_capital}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    S/
                                </InputAdornment>
                            ),
                        }}
                        onChange={handleChangePago}
                        inputProps={{ style: { textAlign: 'right' }, min: 0, step: "1" }}
                    />
                </Grid>
                <Grid item sm={4} xs={12}>
                    <TextField
                        label="Total Pago: "
                        fullWidth
                        variant='filled'
                        type="text"
                        value={pago.total}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    S/
                                </InputAdornment>
                            ),
                        }}
                        inputProps={{ style: { textAlign: 'right' } }}
                    />
                </Grid>
                <Grid item sm={4} xs={12}>

                    <TextField
                        label="Controles"
                        fullWidth
                        variant='filled'
                        type="text"
                        id='controles'
                        value={pago.controles}
                        onChange={handleChangePago}
                    />
                </Grid>
                <Grid item sm={4} xs={12}>
                    <TextField
                        label="Presupuesto"
                        fullWidth
                        variant='filled'
                        type="text"
                        value={pago.total_presupuesto}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    S/
                                </InputAdornment>
                            ),
                        }}
                        inputProps={{ style: { textAlign: 'right' } }}
                    />
                </Grid>

            </Grid>
            <Grid container style={{ justifyContent: 'center', marginTop: '10px' }}>
                <Grid item md={2} xs={6}>
                    <LoadingButton onClick={constAgregarPago} loading={procesando} variant='outlined' color='success' startIcon={<AddCircleOutlineOutlinedIcon />}>AGREGAR</LoadingButton>
                </Grid>
                <Grid item md={2} xs={6}>
                    <LoadingButton onClick={hidePago} loading={procesando} variant='outlined' color='error' startIcon={<CancelOutlinedIcon />}>CANCELAR</LoadingButton>
                </Grid>
            </Grid>
        </>
    )
}

export default EditarPago