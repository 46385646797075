import { Alert, Autocomplete, Button, Collapse, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControl, Grid, IconButton, InputAdornment, InputLabel, MenuItem, Paper, Select, TextField, ToggleButton, ToggleButtonGroup, Tooltip } from '@mui/material';
import { Container } from '@mui/system';
import React, { Component } from 'react';
import { Link } from "@mui/material";
import { useParams } from 'react-router-dom';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import Constantes from '../Constantes';
import CloseIcon from '@mui/icons-material/Close';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import CancelIcon from '@mui/icons-material/Cancel';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import HandymanOutlinedIcon from '@mui/icons-material/HandymanOutlined';
import EditarTratamiento from '../tratamiento/editarTratamiento';
import SaveIcon from '@mui/icons-material/Save';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Close } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
class EditarRegistro extends Component {
    constructor(props) {
        super(props);
        this.state = {
            registro: {
                codigo: "REG-00000000",
                fecha_registro: '2022-01-01T00:08:00.000Z',
                hora_registro: '2022-01-01T00:08:00.000Z',
                fk_ven_cliente: "",
                fk_ven_tratamiento: "u",
            },
            tratamientoSeleccionado: {
                codigo: "u",
                nombre: " ",
                descripcion: " ",
                fk_alm_familia_especifica: "",
            },
            cliente: {
                codigo: "-",
                apellidos_nombres: "",
                numero_documento: "",
                estado: "",
                telefono_1: "",
                telefono_2: "",
                e_mail: "",
                fk_ven_contacto: "",
                fk_log_usuario_creado_por: "",
                created_at: "",
                updated_at: "",
                deleted_at: "",
                fk_com_tipo_documento: "",
                fk_com_referencia: "",
            },
            registroDetalle: {
                control: 0,
                cantidad: 0,
                precio_unitario: 0,
                total: 0,
                fk_ven_registro: "",
                fecha: "",
                hora: "",
            },
            num_control: 1,
            inicial: 0,
            monto_cuota: 0,
            total_cuotas: 0,
            tratamientos: [{
                codigo: "u",
                nombre: " ",
                descripcion: " ",
                fk_alm_familia_especifica: "",
            },],
            fam_gen_tratamiento: [],
            fam_esp_tratamiento: [],
            detallesRegistro: [],
            fk_ven_familia_general_tratamiento: "",
            fk_ven_familia_especifica_tratamiento: "",
            con_inicial: false,
            messageToast: "",
            toastSeverity: "success",
            //severity="error", "warning", "info", "success
            showToast: false,
            showAddTratamiento: false,
            accionTratamiento: "NUEVO",
            newTratamiento: {
                codigo: "NUEVO",
                nombre: "",
                descripcion: "",
                fk_ven_familia_especifica: "",
            },
            newFamGenSelected: "",
            newFamEspSelected: "",
            loadingRegistro: false
        };
        this.handleChangeFamGenTrat = this.handleChangeFamGenTrat.bind(this);
        this.handleChangeFamEspTrat = this.handleChangeFamEspTrat.bind(this);
        this.handleChageDate = this.handleChageDate.bind(this);
        this.handleChangeHour = this.handleChangeHour.bind(this);
        this.handleChageTratamiento = this.handleChageTratamiento.bind(this);
        this.handleChangeControles = this.handleChangeControles.bind(this);
        this.handleChangeSinInicial = this.handleChangeSinInicial.bind(this);
        this.componentDidMount = this.componentDidMount.bind(this);
        this.handleChangeSinInicial = this.handleChangeSinInicial.bind(this);
        this.guardarRegistro = this.guardarRegistro.bind(this);
        this.handleChangeAccionTratamiento = this.handleChangeAccionTratamiento.bind(this);
        this.hideDialogAdd = this.hideDialogAdd.bind(this);
    };

    async componentDidMount() {
        const logedUserJSON = window.sessionStorage.getItem('logedAppUser');
        const userData = JSON.parse(logedUserJSON);
        if (userData.autorized) {
            var RegistroActualizado = this.state.registro;
            const rptaCliente = this.props.accion === "EDITAR" ? await fetch(`${Constantes.RUTA_API}recuperar/obtenerCliente.php?codigo=${RegistroActualizado.fk_ven_cliente}`) : await fetch(`${Constantes.RUTA_API}recuperar/obtenerCliente.php?codigo=${this.props.params.codigo}`);
            const cliente = await rptaCliente.json();
            const famGenTratamiento = await fetch(`${Constantes.RUTA_API}listar/listarFamiliaGeneralTratamiento.php`);
            const FGTratDB = await famGenTratamiento.json();
            if (this.props.accion === "EDITAR") {
                const rptaRegistro = await fetch(`${Constantes.RUTA_API}recuperar/recuperarRegistro.php?codigo=${this.props.params.codigo}`)
                RegistroActualizado = await rptaRegistro.json();
                // hoy.setHours(parseInt(RegistroActualizado.hora_registro.substring(0, 2)),
                //     parseInt(RegistroActualizado.hora_registro.substring(3, 5)),
                //     parseInt(RegistroActualizado.hora_registro.substring(6, 8)));
                // RegistroActualizado['hora_registro'] = new Date().toLocaleTimeString();
                const tratamientoSelected = await fetch(`${Constantes.RUTA_API}recuperar/recuperarTratamiento.php?codigo=${RegistroActualizado.fk_ven_tratamiento}`);
                const dataTratamientoSelected = await tratamientoSelected.json();
                const famEspSelected = await fetch(`${Constantes.RUTA_API}recuperar/recuperarFamiliaEspecificaTratamiento.php?codigo=${dataTratamientoSelected.fk_ven_familia_especifica}`);
                const dataFamEspSelected = await famEspSelected.json();
                const famEspTratamiento = await fetch(`${Constantes.RUTA_API}listar/listarFamiliaEspecificaTratamientoXFG.php?codigo=${dataFamEspSelected.fk_ven_familia_general}`);
                const FETratDB = await famEspTratamiento.json();
                const tratamientos = await fetch(`${Constantes.RUTA_API}listar/listarTratamientosXFE.php?codigo=${dataFamEspSelected.codigo}`);
                const tratDB = await tratamientos.json();
                const famGenSelected = await fetch(`${Constantes.RUTA_API}recuperar/recuperarFamiliaGeneralTratamiento.php?codigo=${dataFamEspSelected.fk_ven_familia_general}`);
                const dataFamGenSelected = await famGenSelected.json();
                this.setState({
                    cliente: cliente,
                    fam_gen_tratamiento: FGTratDB,
                    fam_esp_tratamiento: FETratDB,
                    tratamientos: tratDB,
                    fk_ven_familia_general_tratamiento: dataFamEspSelected.fk_ven_familia_genral,
                    fk_ven_familia_especifica_tratamiento: dataFamEspSelected.codigo,
                    tratamientoSeleccionado: dataTratamientoSelected,
                    registro: { cliente: this.props.codigoCliente },
                    con_inicial: dataFamGenSelected.con_inicial,
                })
                if (this.state.con_inicial) {
                    const detallesRegistro = await fetch(`${Constantes.RUTA_API}listar/listarRegistroDetalle.php?codigo=${RegistroActualizado.codigo}`);
                    const detallesRegistroDB = await detallesRegistro.json();
                    this.setState({
                        detallesRegistro: detallesRegistroDB,
                    })
                } else {
                    const registro_detalle = await fetch(`${Constantes.RUTA_API}recuperar/recuperarRegistroDetalle.php?codigo=${RegistroActualizado.codigo}`);
                    const registro_detalleDB = await registro_detalle.json();
                    this.setState({
                        registro_detalle: registro_detalleDB,
                    })
                }
            } else {
                this.setState({
                    cliente: cliente,
                    fam_gen_tratamiento: FGTratDB,
                    registro: { ...this.state.registro, fk_ven_cliente: this.props.codigoCliente, fecha_registro: new Date().toJSON().slice(0, 10), hora_registro: new Date() },
                })
            }
        };
    }
    hideDialogAdd() {
        this.setState({ showAddTratamiento: false });
    }
    handleChange(event, values) {

    };
    handleChageTratamiento(event, values) {
        console.log(values);
        const registroActualizado = this.state.registro;
        registroActualizado['fk_ven_tratamiento'] = values.codigo;
        this.setState({
            registro: registroActualizado,
            tratamientoSeleccionado: values,
        })
    }
    handleChageDate(event) {
        const registroActualizado = this.state.registro;
        registroActualizado['fecha_registro'] = event.target.value;
        this.setState({
            registro: registroActualizado,
        })
    }
    handleChangeHour(value) {
        const registroActualizado = this.state.registro;
        registroActualizado['hora_registro'] = value;
        this.setState({
            registro: registroActualizado,
        })
    }
    async handleChangeFamGenTrat(event, famGen) {
        if (event.target) {
            const arrFamGensel = this.state.fam_gen_tratamiento.filter(fam => fam.codigo === famGen);
            if (arrFamGensel.length > 0) {
                this.setState({ con_inicial: arrFamGensel[0].con_inicial > 0 });
            } else {
                this.setState({ con_inicial: false });
            }
            const FETrat_tmp = await fetch(`${Constantes.RUTA_API}listar/listarFamiliaEspecificaTratamientoXFG.php?codigo=${famGen}`);
            const FETrat_tmpDB = await FETrat_tmp.json();
            this.setState({
                fk_ven_familia_general_tratamiento: famGen,
                fam_esp_tratamiento: FETrat_tmpDB,
                fk_ven_familia_especifica_tratamiento: "",
                tratamientos: [{
                    codigo: "",
                    nombre: "",
                    descripcion: "",
                    fk_alm_familia_especifica: "",

                },],
                tratamientoSeleccionado: {
                    codigo: "",
                    nombre: "",
                    descripcion: "",
                    fk_alm_familia_especifica: "",
                },
            })
        };
    };

    async handleChangeFamEspTrat(event) {
        if (event.target) {
            const tratamientos = await fetch(`${Constantes.RUTA_API}listar/listarTratamientosXFE.php?codigo=${event.target.value}`)
            const tratamientosDB = await tratamientos.json();
            if (tratamientosDB.length > 0) {
                const registroActualizado = this.state.registro;
                registroActualizado["fk_ven_tratamiento"] = tratamientosDB[0].codigo;
                this.setState({
                    fk_ven_familia_especifica_tratamiento: event.target.value,
                    tratamientos: tratamientosDB,
                    tratamientoSeleccionado: tratamientosDB[0],
                    registro: registroActualizado,
                })
            }
        }
    }
    handleChangeControles(evento) {
        let valor = evento.target.value;
        var inicial = Number(this.state.inicial);
        var num_control = Number(this.state.num_control);
        var monto_cuota = Number(this.state.monto_cuota);
        if (valor) {
            if (valor >= 0) {
                if (evento.target.id === 'num_controls') {
                    num_control = valor;
                    this.setState({
                        num_control: valor,
                    })
                }
                if (evento.target.id === 'inicial') {
                    inicial = valor;
                    this.setState({
                        inicial: valor,
                    })
                }
                if (evento.target.id === 'monto_cuota') {
                    monto_cuota = valor;
                    this.setState({
                        monto_cuota: valor,
                    })
                }
            } else {
                if (evento.target.id === 'num_controls') {
                    num_control = 0;
                    this.setState({
                        num_control: "",
                    })
                }
                if (evento.target.id === 'inicial') {
                    inicial = 0;
                    this.setState({
                        inicial: "",
                    })
                }
                if (evento.target.id === 'monto_cuota') {
                    monto_cuota = 0;
                    this.setState({
                        monto_cuota: "",
                    })
                }
            }
        } else {
            if (evento.target.id === 'num_controls') {
                num_control = 0;
                this.setState({
                    num_control: "",
                })
            }
            if (evento.target.id === 'inicial') {
                inicial = 0;
                this.setState({
                    inicial: "",
                })
            }
            if (evento.target.id === 'monto_cuota') {
                monto_cuota = 0;
                this.setState({
                    monto_cuota: "",
                })
            }
        }
        var total = Number(inicial) + (num_control * monto_cuota);
        this.setState({
            total_cuotas: total,
        })
    }
    handleChangeSinInicial(evento) {
        const valor = evento.target.value;
        this.setState(state => {
            var registroDetalleActualizado = state.registroDetalle;
            var cantidad = registroDetalleActualizado.cantidad;
            var precio_unitario = registroDetalleActualizado.precio_unitario;
            if (valor) {
                if (valor >= 0) {
                    if (evento.target.id === 'cantidad') {
                        cantidad = valor;
                    }
                    if (evento.target.id === 'precio_unitario') {
                        precio_unitario = valor;
                    }
                } else {
                    if (evento.target.id === 'cantidad') {
                        cantidad = 0;
                    }
                    if (evento.target.id === 'precio_unitario') {
                        precio_unitario = 0;
                    }
                }
            } else {
                if (evento.target.id === 'cantidad') {
                    cantidad = 0;
                }
                if (evento.target.id === 'precio_unitario') {
                    precio_unitario = 0;
                }
            }
            registroDetalleActualizado['cantidad'] = cantidad > 0 ? cantidad : "";
            registroDetalleActualizado['precio_unitario'] = precio_unitario > 0 ? precio_unitario : "";
            registroDetalleActualizado['total'] = cantidad * precio_unitario;
            return { registroDetalle: registroDetalleActualizado, }
        })
    }
    /*handleAgregarControles(){
        if(this.state.num_control>0){
            for (var i = 0; i <= this.state.num_control; i++) {
                const control = {
                    'control': i,
                    'cantidad':0,
                    'precio_unitario':0,
                    'total':0, 
                    'fk_ven_registro': this.state.registro.codigo,
                    'fecha':this.state.registro.fecha_registro,
                    'hora':this.state.registro.hora_registro,
                }
            }
        }
    };*/

    async guardarRegistro() {
        this.setState({ loadingRegistro: true });
        const registrado = await fetch(`${Constantes.RUTA_API}mantenimiento/guardarRegistro.php`, {
            method: "POST",
            body: JSON.stringify({
                codigo: this.state.registro.codigo,
                fecha_registro: this.state.registro.fecha_registro,
                hora_registro: this.state.registro.hora_registro.toLocaleTimeString(),
                fk_ven_cliente: this.state.registro.fk_ven_cliente,
                fk_ven_tratamiento: this.state.registro.fk_ven_tratamiento,
            }),
        })
        const registradojs = await registrado.json();
        if (registradojs.guardado) {
            if (this.state.con_inicial) {
                const inicial = this.state.inicial;
                const monto_cuota = this.state.monto_cuota;
                const num_control = this.state.num_control;
                for (var i = 0; i <= num_control; i++) {
                    if (i === 0) {
                        const element = {
                            control: i,
                            cantidad: 1,
                            precio_unitario: inicial,
                            total: inicial,
                            fk_ven_registro: registradojs.codigo,
                            fecha: this.state.registro.fecha_registro,
                            hora: this.state.registro.hora_registro.toLocaleTimeString(),
                        }
                        const detalleGuardado = await fetch(`${Constantes.RUTA_API}mantenimiento/guardarDetalleRegistro.php`, {
                            method: "POST",
                            body: JSON.stringify(element),
                        })
                    } else {
                        const element = {
                            control: i,
                            cantidad: 1,
                            precio_unitario: monto_cuota,
                            total: monto_cuota,
                            fk_ven_registro: registradojs.codigo,
                            fecha: this.state.registro.fecha_registro,
                            hora: this.state.registro.hora_registro.toLocaleTimeString(),
                        }
                        const detalleGuardado = await fetch(`${Constantes.RUTA_API}mantenimiento/guardarDetalleRegistro.php`, {
                            method: "POST",
                            body: JSON.stringify(element),
                        })
                    }
                }
            } else {
                const registroDetalleActualizado = this.state.registroDetalle;
                registroDetalleActualizado["fecha"] = this.state.registro.fecha_registro;
                registroDetalleActualizado["hora"] = this.state.registro.hora_registro.toLocaleTimeString();
                registroDetalleActualizado["fk_ven_registro"] = registradojs.codigo;
                // console.log(registroDetalleActualizado);
                const detalleGuardado = await fetch(`${Constantes.RUTA_API}mantenimiento/guardarDetalleRegistro.php`, {
                    method: "POST",
                    body: JSON.stringify(registroDetalleActualizado),
                })
                const detalleGuardadoJS = await detalleGuardado.json();
            }
            this.setState({
                loadingRegistro: true,
                messageToast: "Registro guardado con exito",
                toastSeverity: "success",
                showToast: true,
            })
            setTimeout(() => {
                this.setState({
                    registro: {
                        codigo: "REG-00000000",
                        fecha_registro: '2022-01-01T00:08:00.000Z',
                        hora_registro: '2022-01-01T00:08:00.000Z',
                        fk_ven_cliente: "",
                        fk_ven_tratamiento: "u",
                    },
                    tratamientoSeleccionado: {
                        codigo: "u",
                        nombre: " ",
                        descripcion: " ",
                        fk_alm_familia_especifica: "",
                    },
                    registroDetalle: {
                        control: 0,
                        cantidad: 0,
                        precio_unitario: 0,
                        total: 0,
                        fk_ven_registro: "",
                        fecha: "",
                        hora: "",
                    },
                    num_control: 1,
                    inicial: 0,
                    monto_cuota: 0,
                    total_cuotas: 0,
                    detallesRegistro: [],
                    fk_ven_familia_general_tratamiento: "",
                    fk_ven_familia_especifica_tratamiento: "",
                    con_inicial: false,
                    messageToast: "",
                    toastSeverity: "success",
                    showToast: false,
                });
                this.props.dialogClose();
                this.props.listarTramientos(this.props.codigoCliente);
            }, 3000);
        } else {
            this.setState({
                messageToast: "Ocurrio un error al guardar el registro",
                toastSeverity: "error",
                showToast: true,
            })
        }
    };
    handleChangeAccionTratamiento(event) {
        if (event.target) {
            console.log(event.target);
            console.log(event.target.value);
            this.setState({
                accionTratamiento: event.target.value,
            })
        }
    }
    hideDialogTratamiento = mostrar => {
        this.setState({ showAddTratamiento: mostrar });
    }
    render() {
        return (
            <Container>
                <Dialog open={this.state.showToast}>
                    <DialogTitle style={{ textAlign: 'center' }}>
                        {this.state.toastSeverity === "success" ?
                            <CheckCircleOutlineIcon color='success' fontSize='large' />
                            :
                            <ErrorOutlineIcon color='error' fontSize='large' />
                        }
                    </DialogTitle>
                    <DialogContent style={{ textAlign: 'center' }}>
                        <DialogContentText>
                            {this.state.messageToast}
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions style={{ justifyContent: 'center' }}>
                        <Button onClick={() => { this.setState({ showToast: false }) }} startIcon={<CheckCircleIcon />} variant='outlined' color='success'>Aceptar</Button>
                        <Button onClick={() => { this.setState({ showToast: false }) }} startIcon={<CancelIcon />} variant='outlined' color='error'>Cancelar</Button>
                    </DialogActions>
                </Dialog>
                <EditarTratamiento mostrar={this.state.showAddTratamiento} parentHideDialog={this.hideDialogTratamiento} />
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={3}>
                        <TextField
                            label='codigo'
                            placeholder='#'
                            variant='filled'
                            id='codigo'
                            value={this.state.registro.codigo}
                            disabled
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={12} sm={9}>
                        <TextField
                            label='Cliente:'
                            placeholder='-'
                            variant='filled'
                            style={{ textTransform: 'uppercase' }}
                            value={this.state.cliente.apellidos_nombres}
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={12} sm={4.5}>
                        <TextField
                            label='Numero Documento:'
                            placeholder='-'
                            variant='filled'
                            style={{ textTransform: 'uppercase' }}
                            value={this.state.cliente.numero_documento}
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={12} sm={3}>
                        <TextField
                            fullWidth
                            id="fecha_registro"
                            label="Fecha:"
                            type="date"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            value={this.state.registro.fecha_registro}
                            onChange={this.handleChageDate}
                        />
                    </Grid>
                    <Grid item xs={12} sm={3}>
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <TimePicker
                                label="Hora:"
                                value={this.state.registro.hora_registro}
                                renderInput={(params) => <TextField {...params} />}
                                onChange={this.handleChangeHour}
                                id='hora_registro'
                                maxWidth={"160px"}
                            />
                        </LocalizationProvider>
                    </Grid>
                    <Grid item xs={2} sm={1.5}>
                        <Tooltip title='Administrar Tratamientos'>
                            <Button variant='outlined' color='primary' onClick={() => { this.setState({ showAddTratamiento: true }) }}>
                                <HandymanOutlinedIcon fontSize='large' />
                            </Button>
                        </Tooltip>
                    </Grid>
                </Grid>
                <Grid container spacing={2} sx={{ mt: 1 }}>
                    <Grid item xs={12} sm={12}>
                        <ToggleButtonGroup color='primary' exclusive onChange={this.handleChangeFamGenTrat} value={this.state.fk_ven_familia_general_tratamiento}>
                            {this.state.fam_gen_tratamiento.map(fam_genegral_trat => {
                                return <ToggleButton value={fam_genegral_trat.codigo} key={fam_genegral_trat.codigo}> {fam_genegral_trat.nombre} </ToggleButton>
                            })}
                        </ToggleButtonGroup>
                    </Grid>
                    <Grid item sm={4} xs={4}>
                        <FormControl fullWidth margin='normal'>
                            <InputLabel id="select-fam-especifica-label">Familia especifica:</InputLabel>
                            <Select
                                labelId="select-fam-especifica-lb"
                                id="select-fam-especifica"
                                value={this.state.fk_ven_familia_especifica_tratamiento}
                                onChange={this.handleChangeFamEspTrat}
                                label="Familia especifica:"
                            >
                                {this.state.fam_esp_tratamiento.map((fe) => (
                                    <MenuItem value={fe.codigo} key={fe.codigo}>{fe.nombre}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item sm={8} xs={8}>
                        <Autocomplete
                            onChange={this.handleChageTratamiento}
                            options={this.state.tratamientos}
                            getOptionLabel={(option) => option.nombre || ''}
                            id='fk_ven_tratamiento'
                            clearOnEscape
                            fullWidth
                            value={this.state.tratamientoSeleccionado}
                            isOptionEqualToValue={(option, value) => option.codigo === value.codigo}
                            renderInput={(params) => (
                                <TextField {...params} label='Tratamiento' variant='standard' value={this.state.registro.fk_ven_tratamiento} margin="normal"
                                />
                            )}
                        />
                    </Grid>
                </Grid>
                {
                    this.state.con_inicial ?
                        <Grid container spacing={2}>
                            <Grid item xs={3}>
                                <TextField
                                    label='Inicial:'
                                    id='inicial'
                                    type='number'
                                    value={this.state.inicial}
                                    onChange={this.handleChangeControles}
                                    InputProps={{
                                        inputMode: 'numeric',
                                        pattern: '[0-9]*',
                                        startAdornment: (
                                            <InputAdornment position='start'>
                                                S/
                                            </InputAdornment>
                                        ),
                                    }}
                                    style={{ textAlign: 'right' }}
                                    fullWidth
                                    margin='normal'
                                />
                            </Grid>
                            <Grid item xs={3}>
                                <TextField
                                    label='Nº Cuotas:'
                                    id='num_controls'
                                    value={this.state.num_control}
                                    onChange={this.handleChangeControles}
                                    InputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
                                    style={{ textAlign: 'right' }}
                                    fullWidth
                                    margin='normal'
                                />
                            </Grid>
                            <Grid item xs={3}>
                                <TextField
                                    label='Monto:'
                                    id='monto_cuota'
                                    value={this.state.monto_cuota}
                                    onChange={this.handleChangeControles}
                                    InputProps={{
                                        inputMode: 'numeric',
                                        pattern: '[0-9]*',
                                        startAdornment: (
                                            <InputAdornment position='start'>
                                                S/
                                            </InputAdornment>
                                        ),
                                    }}
                                    style={{ textAlign: 'right' }}
                                    fullWidth
                                    margin='normal'
                                />
                            </Grid>
                            <Grid item xs={3}>
                                <TextField
                                    label='Total:'
                                    id='total_cuotas'
                                    value={this.state.total_cuotas}
                                    onChange={this.handleChangeControles}
                                    InputProps={{
                                        inputMode: 'numeric',
                                        pattern: '[0-9]*',
                                        startAdornment: (
                                            <InputAdornment position='start'>
                                                S/
                                            </InputAdornment>
                                        ),
                                    }}
                                    style={{ textAlign: 'right' }}
                                    fullWidth
                                    margin='normal'
                                />
                            </Grid>
                        </Grid>
                        :
                        <Grid container spacing={2}>
                            <Grid item xs={4}>
                                <TextField
                                    label='Cantidad:'
                                    id='cantidad'
                                    fullWidth
                                    type="number"
                                    sx={{ textAlign: "right" }}
                                    inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
                                    onChange={this.handleChangeSinInicial}
                                    value={this.state.registroDetalle.cantidad}
                                    margin='normal'
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <TextField
                                    label='Precio unitario:'
                                    id='precio_unitario'
                                    fullWidth
                                    type="text"
                                    InputProps={{
                                        inputMode: 'numeric',
                                        pattern: '[0-9]*',
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                S/
                                            </InputAdornment>
                                        ),
                                    }}
                                    onChange={this.handleChangeSinInicial}
                                    value={this.state.registroDetalle.precio_unitario}
                                    margin='normal'
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <TextField
                                    label='Total: '
                                    id='total'
                                    fullWidth
                                    type="number"
                                    InputProps={{
                                        inputMode: 'numeric',
                                        pattern: '[0-9]*',
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                S/
                                            </InputAdornment>
                                        ),
                                    }}
                                    value={this.state.registroDetalle.total}
                                    margin='normal'
                                />
                            </Grid>
                        </Grid>
                }
                <Grid container spacing={2} sx={{ mt: 1, justifyContent: 'center' }}>
                    <Grid item sm={3} xs={6}>
                        <LoadingButton loading={this.state.loadingRegistro} fullWidth variant='outlined' color='success' onClick={this.guardarRegistro} startIcon={<SaveIcon />}>GUARDAR</LoadingButton>
                    </Grid>
                    <Grid item sm={3} xs={6}>
                        <Button fullWidth variant='outlined' color='error' startIcon={<Close />} onClick={this.props.dialogClose}>CANCELAR</Button>
                    </Grid>
                </Grid>
            </Container>
        )
    }
}
export default (props) => (<EditarRegistro {...props} params={useParams()} />);