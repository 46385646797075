import { Alert, Button, Collapse, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid, IconButton, Snackbar, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material'
import React, { useEffect } from 'react';
import Constantes from '../../Constantes';
import DeleteIcon from '@mui/icons-material/Delete';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import VerDetallesPagos from './verDetallesPagos';
import EditarPago from './editarPago';
import MoneyOffIcon from '@mui/icons-material/MoneyOff';
import CancelIcon from '@mui/icons-material/Cancel';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import LoadingButton from '@mui/lab/LoadingButton';
import ControlSinPago from './controlSinPago';
function VerPagos(props) {
    const cajaAbierta = props.cajaAbierta;
    const [showAddPago, setShowAddPago] = React.useState(false);
    const [showConfirmacionDelete, setShowConfirmacionDelete] = React.useState(false);
    const [listPagos, setListPagos] = React.useState([]);
    const [pagoActual, setPagoActual] = React.useState({
        id: 0,
        fecha: "",
        hora: "",
        total: 0,
        observaciones: "",
        esta_anulado: "NO",
        fk_ven_venta: "",
        fk_log_usuario_creado_por: "",
        fk_ven_cliente: props.cliente.codigo,
        fk_ven_registro_control: props.registro.fk_ven_registro_control,
        fk_ven_registro: props.registro.fk_ven_registro,
        controles: 0,
        total_presupuesto: 0,
    });
    const [controlPayment, setControlPayment] = React.useState({
        control: 0,
        controles: 0,
        cantidad: 0,
        precio_unitario: 0,
        total_detalle: 0,
        fk_ven_registro: "",
        fecha: "",
        hora: "",
        total_pago_detalle: 0,
        tipos_pago: "-",
    });
    const [showEditarPago, setShowEditarPago] = React.useState(false);
    const [showPagoCero, setShowPagoCero] = React.useState(false);
    useEffect((e) => {
        const fillPagos = async () => {
            const pagosFetch = await fetch(`${Constantes.RUTA_API}listar/listarPagosRegistroDetalle.php?codigo=${props.registro.fk_ven_registro}&linea=${props.registro.control}`);
            const pagosDB = await pagosFetch.json();
            setListPagos(pagosDB);
            setControlPayment(props.registro);
        }
        fillPagos();
    }, [props.registro]);
    const agregarPago = async () => {
        console.log(Number(controlPayment.total_detalle));
        console.log(Number(controlPayment.total_pago_detalle));
        if (Number(controlPayment.total_detalle) > Number(controlPayment.total_pago_detalle) || Number(controlPayment.total_pago_detalle) === 0) {
            setShowEditarPago(true);
            setShowAddPago(true);
        }
    }
    const agregarPagoCero = () => {
        setShowAddPago(true);
        setShowPagoCero(true);
    }
    const confirmarEliminarPago = (pago) => {
        const pagoActualizado = pagoActual;
        pagoActualizado["id"] = pago.id;
        setPagoActual(pagoActualizado);
        setShowConfirmacionDelete(true);
    }
    const eliminarPago = async () => {
        setShowConfirmacionDelete(false);
        const rptaEliminarPago = await fetch(`${Constantes.RUTA_API}mantenimiento/eliminarPago.php?codigo=${pagoActual.id}`);
        const resEliminarPago = await rptaEliminarPago.json();
        if (resEliminarPago) {
            const pagosFetch = await fetch(`${Constantes.RUTA_API}listar/listarPagosRegistroDetalle.php?codigo=${props.registro.fk_ven_registro}&linea=${props.registro.control}`);
            const pagosDB = await pagosFetch.json();
            setListPagos(pagosDB);
        }
    }
    const closeAgregarPago = async () => {
        setShowEditarPago(false);
        setShowPagoCero(false);
        setShowAddPago(false);
        setPagoActual({
            id: 0,
            fecha: "",
            hora: "",
            total: 0,
            observaciones: "",
            esta_anulado: "NO",
            fk_ven_venta: "",
            fk_log_usuario_creado_por: "",
            fk_ven_cliente: props.registro.fk_ven_cliente,
            fk_ven_registro_control: props.registro.fk_ven_registro_control,
            fk_ven_registro: props.registro.fk_ven_registro,
            controles: 0,
        })
        const pagosFetch = await fetch(`${Constantes.RUTA_API}listar/listarPagosRegistroDetalle.php?codigo=${props.registro.fk_ven_registro}&linea=${props.registro.control}`);
        const pagosDB = await pagosFetch.json();
        setListPagos(pagosDB);
    }
    return (
        <Grid container spacing={1}>
            <Dialog open={showConfirmacionDelete}>
                <DialogTitle>CONFIRMAR</DialogTitle>
                <DialogContent>
                    <DialogContentText>¿Esta seguro de querer eliminar el pago seleccionado?. Esta operacion no se puede deshacer.</DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button variant='outlined' color='success' onClick={eliminarPago} startIcon={<CheckCircleIcon />}>Aceptar</Button>
                    <Button variant='outlined' color='error' onClick={() => { setShowConfirmacionDelete(false) }} startIcon={<CancelIcon />}>Cancelar</Button>
                </DialogActions>
            </Dialog>
            <Collapse in={showEditarPago}>
                <EditarPago pago={pagoActual} parentClose={closeAgregarPago} registro={props.registro} cliente={props.cliente} cajaAbierta={cajaAbierta} />
            </Collapse>
            <Collapse in={showPagoCero}>
                <ControlSinPago pago={pagoActual} parentClose={closeAgregarPago} registro={props.registro} cliente={props.cliente} cajaAbierta={cajaAbierta} />
            </Collapse>
            <Grid item sm={4} xs={12} sx={{ textAlign: { sm: 'start', xs: 'center' } }}>
                <LoadingButton loading={showAddPago} loadingPosition="start" variant='contained' color="success" onClick={agregarPago} startIcon={<AttachMoneyIcon />}>Agregar Pago </LoadingButton>
            </Grid>
            <Grid item sm={6} xs={12} sx={{ textAlign: { sm: 'start', xs: 'center' } }}>
                <LoadingButton loading={showAddPago} loadingPosition="start" variant='contained' color="warning" onClick={agregarPagoCero} startIcon={<MoneyOffIcon />}> Agregar Control sin pago</LoadingButton>
            </Grid>
            <Grid item xs={12}>
                <TableContainer>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell style={{ fontWeight: 'bold' }}>ID</TableCell>
                                <TableCell style={{ fontWeight: 'bold' }}>FECHA</TableCell>
                                <TableCell style={{ fontWeight: 'bold' }}>HORA</TableCell>
                                <TableCell style={{ fontWeight: 'bold' }}>TOTAL</TableCell>
                                <TableCell style={{ fontWeight: 'bold' }}>TIPO</TableCell>
                                <TableCell style={{ fontWeight: 'bold' }}></TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {listPagos.map(pago => {
                                return <VerDetallesPagos key={pago.id} pago={pago} parentDeleter={confirmarEliminarPago} />
                            })}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Grid>
        </Grid>
    )
}

export default VerPagos