import { Grid, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, TextField } from '@mui/material'
import React, { useEffect } from 'react'
import Constantes from '../../Constantes';
function ResumeDetail(props) {
  const opcion = props.paramViewDetail.opcion;
  const codigo = props.paramViewDetail.codigo;
  const [resultado, setResultado] = React.useState([])
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [page, setPage] = React.useState(0);
  const [busqueda, setBusqueda] = React.useState("");
  useEffect((e) => {
    const fillData = async () => {
      switch (opcion) {
        case 'TC':
          const rptarowsTC = await fetch(`${Constantes.RUTA_API}reportes/tratamientoClientePorEstado.php?estado=${codigo}`);
          const rowsTCDB = await rptarowsTC.json();
          setResultado(rowsTCDB);
          break;
        case 'BRK':
          const rptarowsBrk = await fetch(`${Constantes.RUTA_API}reportes/tratamientosSoloBracketsPorEstado.php?estado=${codigo}`);
        const rowsBrkDB = await rptarowsBrk.json();
        setResultado(rowsBrkDB);
          break;
          case 'ACT':
            const rptarowsActivo = await fetch(`${Constantes.RUTA_API}reportes/tratamientosSoloActivosPorFG.php?codigo=${codigo}`);
            const rowsActivoDB = await rptarowsActivo.json();
            setResultado(rowsActivoDB.filter(l => l.pendiente > 0));
          break;
          case 'ACT_IC':
            const rptarowsActivoIC = await fetch(`${Constantes.RUTA_API}reportes/tratamientosSoloActivosICPorFG.php?codigo=${codigo}`);
            const rowsActivoICDB = await rptarowsActivoIC.json();
            setResultado(rowsActivoICDB.filter(l => l.pendiente > 0));
          break;
          case 'PER':
            const rptarowsPer = await fetch(`${Constantes.RUTA_API}reportes/tratamientosPerdidaPorFG.php?codigo=${codigo}`);
            const rowsPerDB = await rptarowsPer.json();
            setResultado(rowsPerDB.filter(l => l.pendiente > 0));
          break;
        default:
          console.log("nada");
      }
    }
    fillData();
  }, [codigo, opcion]);
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const handleChangeBusqueda = (e) => {
    setBusqueda(e.target.value.toUpperCase());
    setPage(0);
  }
  return (
    <Grid container spacing={2} sx={{ paddingTop: "10px" }} >
      <Grid item xs={12} sm={10}>
        <TextField
          id="busqueda"
          label="Paciente:"
          type="text"
          value={busqueda}
          fullWidth
          size='small'
          onChange={handleChangeBusqueda}
        />
      </Grid>
      {(opcion === 'ACT' || opcion === 'ACT_IC' || opcion === 'PER') && (
        <Grid item xs={12}>
          <TableContainer sx={{ maxHeight: 440 }}>
            <Table size="small" stickyHeader>
              <TableHead>
                <TableRow >
                  <TableCell align='center' sx={{ backgroundColor: '#b9d8ee' }}>CLIENTE</TableCell>
                  <TableCell align='center' sx={{ backgroundColor: '#b9d8ee' }}>TRATAMIENTO</TableCell>
                  <TableCell align='center' sx={{ backgroundColor: '#b9d8ee' }}>ESTADO</TableCell>
                  <TableCell align='center' sx={{ backgroundColor: '#b9d8ee' }}>FECHA REG.</TableCell>
                  <TableCell align='right' sx={{ backgroundColor: '#b9d8ee' }}>PRECIO</TableCell>
                  <TableCell align='right' sx={{ backgroundColor: '#b9d8ee' }}>PAGADO</TableCell>
                  <TableCell align='right' sx={{ backgroundColor: '#b9d8ee' }}>PENDIENTE</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {resultado.filter(l => l.apellidos_nombres.includes(busqueda)).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => (
                  <TableRow key={row.cod_registro}>
                    <TableCell align='left'>{row.apellidos_nombres}</TableCell>
                    <TableCell align='left'>{row.tratamiento}</TableCell>
                    <TableCell align='center'>{row.estado}</TableCell>
                    <TableCell align='center'>{row.fecha_registro}</TableCell>
                    <TableCell align='right'>S/{row.precio}</TableCell>
                    <TableCell align='right'>S/{row.pagado}</TableCell>
                    <TableCell align='right'>S/{row.pendiente}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[10, 25, 50]}
            component="div"
            count={resultado.filter(l => l.apellidos_nombres.includes(busqueda)).length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Grid>
      )}
      {(opcion === 'TC' || opcion === 'BRK') && (
        <Grid item xs={12}>
          <TableContainer sx={{ maxHeight: 440 }}>
            <Table size="small" stickyHeader>
              <TableHead>
                <TableRow >
                  <TableCell align='center' sx={{ backgroundColor: '#b9d8ee' }}>CODIGO</TableCell>
                  <TableCell align='center' sx={{ backgroundColor: '#b9d8ee' }}>CLIENTE</TableCell>
                  <TableCell align='center' sx={{ backgroundColor: '#b9d8ee' }}>ULTIMA ATENCION</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {resultado.filter(l => l.apellidos_nombres.includes(busqueda)).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => (
                  <TableRow key={row.cod_registro}>
                    <TableCell align='left'>{row.cod_cliente}</TableCell>
                    <TableCell align='left'>{row.apellidos_nombres}</TableCell>
                    <TableCell align='center'>{row.ultima_atencion}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[10, 25, 50]}
            component="div"
            count={resultado.filter(l => l.apellidos_nombres.includes(busqueda)).length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Grid>
      )}
    </Grid>
  )
}

export default ResumeDetail