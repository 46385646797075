import { Alert, Button, Collapse, FormControl, Grid, IconButton, InputLabel, MenuItem, Select, TextField } from '@mui/material';
import { style, textTransform } from '@mui/system';
import React, { useEffect } from 'react'
import Constantes from '../../Constantes';
import CloseIcon from '@mui/icons-material/Close';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import CheckCircleOutlineOutlinedIcon from '@mui/icons-material/CheckCircleOutlineOutlined';
import { LoadingButton } from '@mui/lab';
function EditarGastos(props) {
  const [gasto, setGasto] = React.useState({
    codigo: "DTO-00000000",
    descripcion: "",
    nombre: "",
    fk_cmp_clasificacion: ""
  });
  const [clasificaciones, setClasificaciones] = React.useState([]);
  const [mensajeGuardado, setMensajeGuardado] = React.useState({
    show: false,
    mensaje: "",
    severity: "warning",
  });
  useEffect((e) => {
    const fillData = async () => {
      const clasificacionesFetch = await fetch(`${Constantes.RUTA_API}listar/listarClasificaciones.php`)
      const clasificacionesDB = await clasificacionesFetch.json();
      setClasificaciones(clasificacionesDB);
      if (props.accion === 'E') {
        const gastoFetch = await fetch(`${Constantes.RUTA_API}recuperar/recuperarGasto.php?codigo=${props.fk_cmp_gasto}`)
        const gastoDB = await gastoFetch.json();
        setGasto(gastoDB);
      }
    }
    fillData();
  }, [props]);

  const handleChangeGasto = (e) => {
    setGasto((gastoAnt) => ({
      ...gastoAnt,
      [e.target.id]: e.target.value,
    }));
  };
  const saveGasto = async () => {
    const newGasto = {
      codigo: gasto.codigo.toUpperCase(),
      descripcion: gasto.descripcion.toUpperCase(),
      nombre: gasto.nombre.toUpperCase(),
      fk_cmp_clasificacion: gasto.fk_cmp_clasificacion.toUpperCase()
    }
    const gastoFetch = await fetch(`${Constantes.RUTA_API}mantenimiento/guardarGasto.php`, {
      method: "POST",
      body: JSON.stringify(newGasto),
    })
    const gastoDB = await gastoFetch.json();
    if (gastoDB) {
      setMensajeGuardado({
        show: true,
        mensaje: "gasto guardado con exito",
        severity: "success"
      })
      setTimeout(() => {
        setMensajeGuardado({
          show: false,
          mensaje: "",
          severity: "warning"
        })
        props.parentCloseChild();
      }, 3000);
    } else {
      setMensajeGuardado({
        show: true,
        mensaje: "No se pudo guardar el gasto. Intente de nuevo.",
        severity: "error"
      })
      setTimeout(() => {
        setMensajeGuardado({
          show: false,
          mensaje: "",
          severity: "warning"
        })
      }, 3000);
    }
  }
  const handleChangeSelect = (e) => {
    setGasto((gastoAnt) => ({
      ...gastoAnt,
      fk_cmp_clasificacion: e.target.value,
    }));
  };
  const closeGasto = () => {
    props.parentCloseChild()
  }
  // const handleKeyUp = (e) =>{
  //   console.log(e);
  //   e.key = e.key.toUpperCase();
  // }
  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <Collapse in={mensajeGuardado.show}>
          <Alert
            action={
              <IconButton
                aria-label="close"
                color="inherit"
                size="small"
                onClick={() => {
                  setMensajeGuardado(false);
                }}
              >
                <CloseIcon fontSize="inherit" />
              </IconButton>
            }
            sx={{ mb: 2 }}
            severity={mensajeGuardado.severity}
          >
            {mensajeGuardado.mensaje}
          </Alert>
        </Collapse>
      </Grid>
      <Grid item xs={6}>
        <TextField
          label="codigo:"
          fullWidth
          variant='filled'
          type="text"
          id='codigo'
          value={gasto.codigo}
        />
      </Grid>
      <Grid item xs={6}>
        <FormControl fullWidth>
          <InputLabel id="select-clasificacion">Clasificación:</InputLabel>
          <Select
            labelId="select-clasificacion-lb"
            id="select-clasificacion-S"
            value={gasto.fk_cmp_clasificacion}
            onChange={handleChangeSelect}
            label="Clasificación:"
            defaultValue=""
          >
            {clasificaciones.map((clas) => (
              <MenuItem value={clas.codigo} key={clas.codigo}>{clas.nombre}</MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={12}>
        <TextField
          label="Nombre:"
          fullWidth
          variant='filled'
          type="text"
          id='nombre'
          margin="normal"
          value={gasto.nombre}
          onChange={handleChangeGasto}
          placeholder='Nombre del gasto.'
          inputProps={{ style: { textTransform: "uppercase" } }}
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          label="Descripción:"
          fullWidth
          variant='filled'
          type="text"
          id='descripcion'
          margin="normal"
          value={gasto.descripcion}
          onChange={handleChangeGasto}
          multiline
          placeholder='Descripción del gasto.'
          inputProps={{ style: { textTransform: "uppercase" } }}
        />
      </Grid>
      <Grid container item xs={12} spacing={2}>
        <Grid item xs={6} textAlign='right'>
          <LoadingButton loading={mensajeGuardado.show} variant='outlined' color='success' onClick={saveGasto} startIcon={<CheckCircleOutlineOutlinedIcon />}>ACEPTAR</LoadingButton>
        </Grid>
        <Grid item xs={6}>
          <Button variant='outlined' color='error' onClick={closeGasto} startIcon={<CancelOutlinedIcon />}>CANCELAR</Button>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default EditarGastos