import React, { useEffect } from 'react'
import { blue, grey } from '@mui/material/colors';
import { styled, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import MuiDrawer from '@mui/material/Drawer';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import CssBaseline from '@mui/material/CssBaseline';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import KeyIcon from '@mui/icons-material/Key';
import Constantes from './Constantes';
import logo from '../images/logo.png';
import { Button, Collapse, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import { Contacts, Description, Group, ShoppingCart, ExpandLess, ExpandMore, CancelOutlined, CheckCircleOutlined, Close, Home as HomeIcon } from '@mui/icons-material';
import ErrorIcon from '@mui/icons-material/Error';
import PointOfSaleIcon from '@mui/icons-material/PointOfSale';
import SettingsSuggestIcon from '@mui/icons-material/SettingsSuggest';
import DifferenceIcon from '@mui/icons-material/Difference';
import ArticleIcon from '@mui/icons-material/Article';
import HealingIcon from '@mui/icons-material/Healing';
import MedicationLiquidIcon from '@mui/icons-material/MedicationLiquid';
import TollIcon from '@mui/icons-material/Toll';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import LogoutSharpIcon from '@mui/icons-material/LogoutSharp';
import CambiarContraseña from './sistema/usuario/activos/cambiarContraseña';
import Caja from './caja/caja';
import { Link } from 'react-router-dom';
const drawerWidth = 280;

const openedMixin = (theme) => ({
    width: drawerWidth,
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: 'hidden',
});

const closedMixin = (theme) => ({
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    // width: `calc(${theme.spacing(0)} + 1px)`,
    width: '0px',
    [theme.breakpoints.up('sm')]: {
        width: `calc(${theme.spacing(8)} + 1px)`,
    },
});

const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    }),
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
    ({ theme, open }) => ({
        width: drawerWidth,
        flexShrink: 0,
        whiteSpace: 'nowrap',
        boxSizing: 'border-box',
        ...(open && {
            ...openedMixin(theme),
            '& .MuiDrawer-paper': openedMixin(theme),
        }),
        ...(!open && {
            ...closedMixin(theme),
            '& .MuiDrawer-paper': closedMixin(theme),
        }),
    }),
);


function NewAppBar(props) {
    const theme = useTheme();
    const [open, setOpen] = React.useState(false);
    const [userData, setUserData] = React.useState({
        userName: "",
        businessName: "",
    })
    const [menUsuario, setMenUsuario] = React.useState([]);
    const [showSubMenuREP, setShowSubMenuREP] = React.useState(false);
    const [showSubMenuSYS, setShowSubMenuSYS] = React.useState(false);
    const [showPaswordChange, setShowPaswordChange] = React.useState(false);
    const [showMessageConfirmacion, setShowMessageConfirmacion] = React.useState({ severity: 'warning', message: '', show: false });
    const [showConfirmacion, setShowConfirmacion] = React.useState(false);
    const [showCaja, setShowCaja] = React.useState(false);

    // const [showSubMenuReportes, setShowSubMenuReportes] = React.useState(false);
    // const [showSubMenuReportes, setShowSubMenuReportes] = React.useState(false);

    useEffect(() => {
        const llenarDatos = async () => {
            const razonSocialParamFetch = await fetch(`${Constantes.RUTA_API}recuperar/recuperarParametro.php?codigo=RAZON_SOCIAL`);
            const razonSocialParamBD = await razonSocialParamFetch.json();
            // console.log(razonSocialParamBD);
            const menuFetch = await fetch(`${Constantes.RUTA_API}sistema/listarMenu.php?username=${props.userName}`)
            const menuDB = await menuFetch.json();
            setMenUsuario(menuDB);
            setUserData({
                userName: props.userName,
                businessName: razonSocialParamBD.valor_texto,
            })
        }
        llenarDatos();
    }, [props.userName])
    const handleDrawerOpen = () => {
        setOpen(true);
    };

    const handleDrawerClose = () => {
        setOpen(false);
        setShowSubMenuREP(false);
        setShowSubMenuSYS(false);
    };
    const handlePasswordChange = () => {
        setOpen(false);
        setShowSubMenuREP(false);
        setShowSubMenuSYS(false);
        setShowPaswordChange(true);
    }
    const iconList = (codigo) => {
        const iconArray = [
            { codigo: 'CON', icono: <Contacts /> },
            { codigo: 'CLI', icono: <Group /> },
            { codigo: 'REP', icono: <Description /> },
            { codigo: 'CMP', icono: <ShoppingCart /> },
            { codigo: 'CAJ', icono: <PointOfSaleIcon /> },
            { codigo: 'SYS', icono: <SettingsSuggestIcon /> },
            { codigo: 'REP-1', icono: <ArticleIcon /> },
            { codigo: 'REP-2', icono: <DifferenceIcon /> },
            { codigo: 'REP-3', icono: <HealingIcon /> },
            { codigo: 'REP-4', icono: <MedicationLiquidIcon /> },
            { codigo: 'REP-5', icono: <TollIcon /> },
            { codigo: 'SYS-PER', icono: <PeopleAltIcon /> },
            { codigo: 'SYS-USR', icono: <ManageAccountsIcon /> },
        ];
        const filteredIcon = iconArray.filter(icon => icon.codigo === codigo);
        if (filteredIcon.length > 0) {
            return filteredIcon[0].icono;
        } else {
            return <ErrorIcon />
        }

    }
    const handleClick = (valor) => {
        if (valor === 'SYS' || valor === 'REP' || valor === 'CAJ') {
            if (open) {
                setShowSubMenuREP(!showSubMenuREP ? valor === 'REP' : false);
                setShowSubMenuSYS(!showSubMenuSYS ? valor === 'SYS' : false);
                setShowCaja(valor === 'CAJ');
            } else {
                setOpen(!open);
            }
        }
    }
    const hadleOpenSubMenu = (valor) => {
        if (valor === 'REP') {
            return showSubMenuREP;
        }
        if (valor === 'SYS') {
            return showSubMenuSYS;
        }
        else {
            return false;
        }
    }

    const handleLogOut = () => {
        window.sessionStorage.removeItem('logedAppUser')
        window.location.reload(false);
    }

    return (
        <Box>
            <Dialog open={showPaswordChange} maxWidth='xs'>
                <DialogTitle>
                    CAMBIAR CLAVE
                    <IconButton
                        sx={{
                            position: 'absolute',
                            right: 8,
                            top: 8,
                            color: (theme) => theme.palette.grey[500],
                        }}
                        onClick={() => setShowPaswordChange(false)}
                    >
                        <Close fontSize="inherit" />
                    </IconButton>
                </DialogTitle>
                <DialogContent>
                    <CambiarContraseña
                        codigoUsuarioSelect={userData.userName}
                        setAlertGeneral={setShowMessageConfirmacion}
                        setChangeClave={() => setShowPaswordChange(false)}
                    />
                </DialogContent>
            </Dialog>
            <Dialog open={showConfirmacion}>
                <DialogTitle> </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        ¿Esta seguro de cerrar la sesión actual?
                    </DialogContentText>
                </DialogContent>
                <DialogActions sx={{ textAlign: 'center' }}>
                    <Button onClick={() => handleLogOut()} startIcon={<CheckCircleOutlined />} variant='outlined' color='success'>
                        SI
                    </Button>
                    <Button onClick={() => setShowConfirmacion(false)} startIcon={<CancelOutlined />} variant='outlined' color='error'>
                        NO
                    </Button>
                </DialogActions>
            </Dialog>
            <Dialog open={showMessageConfirmacion.show}>
                <DialogTitle></DialogTitle>
            </Dialog>
            <CssBaseline />
            <AppBar position="fixed" open={open}>
                <Toolbar>
                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        onClick={handleDrawerOpen}
                        edge="start"
                        sx={{
                            marginRight: 5,
                            ...(open && { display: 'none' }),
                        }}
                    >
                        <MenuIcon />
                    </IconButton>
                    <Link to='/' style={{ textDecoration: 'none' }} underline='none' color='inherit'>
                        <img alt={userData.userName} src={logo} style={{ width: '30px' }} />
                    </Link>
                    <Link to='/' className="linkDefault" sx={{ ml: 3 }} style={{ textDecoration: 'none', color: grey[50] }}>
                        <Typography variant="h6" noWrap component="div" >
                            {userData.businessName}
                        </Typography>
                    </Link>
                </Toolbar>
            </AppBar>
            <Drawer variant="permanent" open={open} >
                <DrawerHeader>
                    <IconButton onClick={handleDrawerClose} sx={{ ml: 'auto' }}>
                        {theme.direction === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon />}
                    </IconButton>
                </DrawerHeader>
                <Divider />
                <List>
                    <ListItem disablePadding sx={{ display: 'block', ":hover": { backgroundColor: blue[200] } }}>
                        <Link to='/' style={{ textDecoration: 'none', color: grey[800] }} underline='none' color='inherit'>
                            <ListItemButton
                                sx={{
                                    minHeight: 48,
                                    justifyContent: open ? 'initial' : 'center',
                                    px: 2.5,
                                }}
                            >
                                <ListItemIcon
                                    sx={{
                                        minWidth: 0,
                                        mr: open ? 3 : 'auto',
                                        justifyContent: 'center',
                                    }}
                                >
                                    <HomeIcon />
                                </ListItemIcon>
                                <ListItemText primary='Inicio' sx={{ opacity: open ? 1 : 0 }} />
                            </ListItemButton>
                        </Link>
                    </ListItem>
                </List>
                <List>
                    {menUsuario.filter(l => l.fk_log_menu_padre === null).map((padre) => (
                        menUsuario.filter(ele => ele.fk_log_menu_padre === padre.codigo).length > 0 ?
                            <ListItem key={padre.codigo} disablePadding sx={{ display: 'block', color: grey[800], ":hover": { backgroundColor: blue[200] } }}>
                                <ListItemButton
                                    sx={{
                                        minHeight: 48,
                                        justifyContent: open ? 'initial' : 'center',
                                        px: 2.5,
                                    }}
                                    onClick={() => handleClick(padre.codigo)}
                                    key={`${padre.codigo}-IB`}
                                >
                                    <ListItemIcon
                                        sx={{
                                            minWidth: 0,
                                            mr: open ? 3 : 'auto',
                                            justifyContent: 'center',
                                        }}
                                        key={`${padre.codigo}-Icon`}
                                    >
                                        {iconList(padre.codigo)}
                                    </ListItemIcon>
                                    <ListItemText key={`${padre.codigo}-txt`} primary={padre.nombre} sx={{ opacity: open ? 1 : 0 }} />
                                    {hadleOpenSubMenu(padre.codigo) ? <ExpandLess className="textDrawer" /> : <ExpandMore className="textDrawer" />}
                                </ListItemButton>
                                <Collapse in={hadleOpenSubMenu(padre.codigo)} timeout="auto" unmountOnExit onClick={() => setOpen(false)} key={`${padre.codigo}-collapse`}>
                                    <List>
                                        {menUsuario.filter(ele => ele.fk_log_menu_padre === padre.codigo).map((hijo) => (
                                            <ListItem key={hijo.codigo} disablePadding sx={{ display: 'block', ml: 2, ":hover": { backgroundColor: blue[100] } }}>
                                                <Link to={hijo.href} key={`${hijo.codigo}-link`} style={{ textDecoration: 'none', color: grey[800] }} className="linkDefaultDrawer">
                                                    <ListItemButton
                                                        sx={{
                                                            minHeight: 48,
                                                            justifyContent: open ? 'initial' : 'center',
                                                            px: 2.5,
                                                        }}
                                                        onClick={() => handleDrawerClose()}
                                                        key={`${hijo.codigo}-IBSM`}
                                                    >
                                                        <ListItemIcon
                                                            sx={{
                                                                minWidth: 0,
                                                                mr: open ? 3 : 'auto',
                                                                justifyContent: 'center',
                                                            }}
                                                            key={`${hijo.codigo}-Icon`}
                                                        >
                                                            {iconList(hijo.codigo)}
                                                        </ListItemIcon>
                                                        <ListItemText key={`${hijo.codigo}-txt`} primary={hijo.nombre} />
                                                    </ListItemButton>
                                                </Link>
                                            </ListItem>
                                        ))}
                                    </List>
                                </Collapse>
                            </ListItem> :
                            <ListItem key={padre.codigo} disablePadding sx={{ display: 'block', ":hover": { backgroundColor: blue[200] } }}>
                                {padre.href !== null &&
                                    <Link to={padre.href}
                                        style={{ textDecoration: 'none', color: grey[800] }}
                                        className="linkDefaultDrawer">
                                        <ListItemButton
                                            sx={{
                                                minHeight: 48,
                                                justifyContent: open ? 'initial' : 'center',
                                                px: 2.5,
                                            }}
                                            onClick={() => handleClick(padre.codigo)}
                                            key={`${padre.codigo}-IB`}
                                        >
                                            <ListItemIcon
                                                sx={{
                                                    minWidth: 0,
                                                    mr: open ? 3 : 'auto',
                                                    justifyContent: 'center',
                                                }}
                                            >
                                                {iconList(padre.codigo)}
                                            </ListItemIcon>
                                            <ListItemText primary={padre.nombre} sx={{ opacity: open ? 1 : 0 }} />
                                        </ListItemButton>
                                    </Link>
                                }
                                {padre.href === null &&
                                    <ListItemButton
                                        sx={{
                                            minHeight: 48,
                                            justifyContent: open ? 'initial' : 'center',
                                            px: 2.5,
                                        }}
                                        onClick={() => handleClick(padre.codigo)}
                                        key={`${padre.codigo}-IB`}
                                    >
                                        <ListItemIcon
                                            sx={{
                                                minWidth: 0,
                                                mr: open ? 3 : 'auto',
                                                justifyContent: 'center',
                                            }}
                                        >
                                            {iconList(padre.codigo)}
                                        </ListItemIcon>
                                        <ListItemText primary={padre.nombre} sx={{ opacity: open ? 1 : 0 }} />
                                    </ListItemButton>
                                }
                            </ListItem>
                    ))}
                </List>
                <Divider />
                <List sx={{ mt: 'auto' }}>
                    <ListItem disablePadding sx={{ display: 'block', color: grey[800], ":hover": { backgroundColor: blue[200] } }}>
                        <ListItemButton
                            sx={{
                                minHeight: 48,
                                justifyContent: open ? 'initial' : 'center',
                                px: 2.5,
                            }}
                            onClick={handlePasswordChange}
                        >
                            <ListItemIcon
                                sx={{
                                    minWidth: 0,
                                    mr: open ? 3 : 'auto',
                                    justifyContent: 'center',
                                }}
                            >
                                <KeyIcon />
                            </ListItemIcon>
                            <ListItemText primary='Cambiar Contraseña' sx={{ opacity: open ? 1 : 0 }} />
                        </ListItemButton>
                    </ListItem>
                    <ListItem disablePadding sx={{ display: 'block', color: grey[800], ":hover": { backgroundColor: blue[200] } }}>
                        <ListItemButton
                            sx={{
                                minHeight: 48,
                                justifyContent: open ? 'initial' : 'center',
                                px: 2.5,
                            }}
                            onClick={() => setShowConfirmacion(true)}
                        >
                            <ListItemIcon
                                sx={{
                                    minWidth: 0,
                                    mr: open ? 3 : 'auto',
                                    justifyContent: 'center',
                                }}
                            >
                                <LogoutSharpIcon />
                            </ListItemIcon>
                            <ListItemText primary='Cerrar Sesión' sx={{ opacity: open ? 1 : 0 }} />
                        </ListItemButton>
                    </ListItem>
                </List>
            </Drawer>
            <Dialog open={showCaja} fullWidth={true} maxWidth="md">
                <DialogTitle>
                    CAJA COBRANZA
                    <IconButton
                        aria-label="close"
                        onClick={() => setShowCaja(false)}
                        sx={{
                            position: 'absolute',
                            right: 8,
                            top: 8,
                            color: (theme) => theme.palette.grey[500],
                        }}
                    >
                        <Close />
                    </IconButton>
                </DialogTitle>
                <DialogContent>
                    <Caja />
                </DialogContent>
            </Dialog>
        </Box >
    )
}

export default NewAppBar