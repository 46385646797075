import { FormControl, Grid, InputLabel, MenuItem, Select, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material'
import { Container } from '@mui/system';
import { blue } from '@mui/material/colors';
import React, { useEffect } from 'react';
import Constantes from '../Constantes';


function RepTratamientos() {
    const [famGeneral, setFamGeneral] = React.useState([]);
    const [currentFG, setCurrentFG] = React.useState("");
    const [currentFG2, setCurrentFG2] = React.useState("");
    const [famEspecifica, setFamEspecifica] = React.useState([]);
    const [famEspecifica2, setFamEspecifica2] = React.useState([]);
    const [currentFE, setCurrentFE] = React.useState("");
    const [currentFE2, setCurrentFE2] = React.useState("");
    const [tratamiento, setTratamiento] = React.useState([]);
    const [tratamiento2, setTratamiento2] = React.useState([]);
    const [currentTratamiento, setCurrentTratamiento] = React.useState("");
    const [currentTratamiento2, setCurrentTratamiento2] = React.useState("");
    const [resultados, setResultados] = React.useState([]);
    const [resultados2, setResultados2] = React.useState([]);
    const [resultadosAnhos, setResultadosAnhos] = React.useState([]);
    const [resultadosAnhos2, setResultadosAnhos2] = React.useState([]);
    const [anhoReporte, setAnhoReporte] = React.useState(2022);
    const [anhos, setAnhos] = React.useState([])
    // const [altertIssues, setAltertIssues] = React.useState({
    //     show:false,
    //     message:"",
    //     severity:"success",
    // })
    useEffect((e) => {
        const llenarFG = async () => {
            const listarFG = await fetch(`${Constantes.RUTA_API}listar/listarFamiliaGeneralTratamiento.php`);
            const listaFG = await listarFG.json();
            setFamGeneral(listaFG);
            const anhosRes = await fetch(`${Constantes.RUTA_API}listar/listarAnhosPagos.php`);
            const anhosResDB = await anhosRes.json();
            setAnhos(anhosResDB);
        }
        llenarFG();
    }, []);

    const handleChangeFG = async (e) => {
        setCurrentFG(e.target.value);
        const listarFE = await fetch(`${Constantes.RUTA_API}listar/listarFamiliaEspecificaTratamientoXFG.php?codigo=${e.target.value}`);
        const listaFE = await listarFE.json();
        setFamEspecifica(listaFE);
    };
    const handleChangeFG2 = async (e) => {
        setCurrentFG2(e.target.value);
        const listarFE = await fetch(`${Constantes.RUTA_API}listar/listarFamiliaEspecificaTratamientoXFG.php?codigo=${e.target.value}`);
        const listaFE = await listarFE.json();
        setFamEspecifica2(listaFE);
    }
    const handleChangeFE = async (e) => {
        setCurrentFE(e.target.value);
        const listarTratamiento = await fetch(`${Constantes.RUTA_API}listar/listarTratamientosXFE.php?codigo=${e.target.value}`);
        const listaTratamiento = await listarTratamiento.json();
        setTratamiento(listaTratamiento);
    };
    const handleChangeFE2 = async (e) => {
        setCurrentFE2(e.target.value);
        const listarTratamiento = await fetch(`${Constantes.RUTA_API}listar/listarTratamientosXFE.php?codigo=${e.target.value}`);
        const listaTratamiento = await listarTratamiento.json();
        setTratamiento2(listaTratamiento);
    };
    const handleChangeTratamiento = async (e) => {
        setCurrentTratamiento(e.target.value);
        if (anhoReporte) {
            const resultadoReporte = await fetch(`${Constantes.RUTA_API}reportes/reporteVentasTratamiento.php?tratamiento=${e.target.value}&anho=${anhoReporte}`);
            const resultadoReporteDB = await resultadoReporte.json();
            setResultados(resultadoReporteDB);
            const resultadoReporteAnual = await fetch(`${Constantes.RUTA_API}reportes/reporteVentasTratamientoAnual.php?tratamiento=${e.target.value}`);
            const resultadoReporteAnualDB = await resultadoReporteAnual.json();
            setResultadosAnhos(resultadoReporteAnualDB);
        }

    }
    const handleChangeTratamiento2 = async (e) => {
        setCurrentTratamiento2(e.target.value);
        if (anhoReporte) {
            const resultadoReporte = await fetch(`${Constantes.RUTA_API}reportes/reporteVentasTratamiento.php?tratamiento=${e.target.value}&anho=${anhoReporte}`);
            const resultadoReporteDB = await resultadoReporte.json();
            setResultados2(resultadoReporteDB);
            const resultadoReporteAnual = await fetch(`${Constantes.RUTA_API}reportes/reporteVentasTratamientoAnual.php?tratamiento=${e.target.value}`);
            const resultadoReporteAnualDB = await resultadoReporteAnual.json();
            setResultadosAnhos2(resultadoReporteAnualDB);
        }

    }
    const handleChangeAnho = async (e) => {
        setAnhoReporte(e.target.value);
        if (currentTratamiento) {
            const resultadoReporte = await fetch(`${Constantes.RUTA_API}reportes/reporteVentasTratamiento.php?tratamiento=${currentTratamiento}&anho=${e.target.value}`);
            const resultadoReporteDB = await resultadoReporte.json();
            setResultados(resultadoReporteDB);
            const resultadoReporteAnual = await fetch(`${Constantes.RUTA_API}reportes/reporteVentasTratamientoAnual.php?tratamiento=${currentTratamiento}`);
            const resultadoReporteAnualDB = await resultadoReporteAnual.json();
            setResultadosAnhos(resultadoReporteAnualDB);
        }
        if (currentTratamiento2) {
            const resultadoReporte = await fetch(`${Constantes.RUTA_API}reportes/reporteVentasTratamiento.php?tratamiento=${currentTratamiento2}&anho=${e.target.value}`);
            const resultadoReporteDB = await resultadoReporte.json();
            setResultados2(resultadoReporteDB);
            const resultadoReporteAnual = await fetch(`${Constantes.RUTA_API}reportes/reporteVentasTratamientoAnual.php?tratamiento=${currentTratamiento2}`);
            const resultadoReporteAnualDB = await resultadoReporteAnual.json();
            setResultadosAnhos2(resultadoReporteAnualDB);
        }
    }
    const colorTable = {
        header: blue[700],
        footer: "",
        fondo: "#EBF8FF",
        subtotal: blue[200],
        total: blue[300],
        color: "#fff"
    }

    return (
        <Container sx={{ marginTop: "70px", paddingBottom: "10px" }}>
            <Grid container spacing={1} sx={{ mt: 2 }}>
                <Grid item xs={6} md={2}>
                    <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">AÑO</InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={anhoReporte}
                            label="AÑO"
                            onChange={handleChangeAnho}
                            size='small'
                        >
                            {anhos.map(anho => {
                                return <MenuItem key={anho.anho} value={anho.anho}>{anho.anho}</MenuItem>
                            })}
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={10}>
                </Grid>
                <Grid item xs={12} md={6}>
                    <Grid container spacing={2}>
                        <Grid item xs={6}>
                            <FormControl fullWidth>
                                <InputLabel id="fam_gen">Familia General:</InputLabel>
                                <Select
                                    labelId="fam_gen"
                                    id="fam_gen"
                                    value={currentFG}
                                    label="FamGen"
                                    onChange={handleChangeFG}
                                    size='small'
                                >
                                    {famGeneral.map(fg => {
                                        return <MenuItem key={fg.codigo} value={fg.codigo}>{fg.nombre}</MenuItem>
                                    })}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={6}>
                            <FormControl fullWidth>
                                <InputLabel id="fam_esp">Familia Especifica:</InputLabel>
                                <Select
                                    labelId="Familia Especifica:"
                                    id="fam_esp"
                                    value={currentFE}
                                    label="FamEsp"
                                    onChange={handleChangeFE}
                                    size='small'
                                >
                                    {famEspecifica.map(fe => {
                                        return <MenuItem key={fe.codigo} value={fe.codigo}>{fe.nombre}</MenuItem>
                                    })}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12}>
                            <FormControl fullWidth>
                                <InputLabel id="tratamiento">Tratamiento:</InputLabel>
                                <Select
                                    labelId="tratamiento"
                                    id="tratamiento"
                                    value={currentTratamiento}
                                    label="Tratamiento"
                                    onChange={handleChangeTratamiento}
                                    size='small'
                                >
                                    {tratamiento.map(trat => {
                                        return <MenuItem key={trat.codigo} value={trat.codigo}>{trat.nombre}</MenuItem>
                                    })}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={6} md={6}>
                            <TableContainer>
                                <Table size="small">
                                    <TableHead>
                                        <TableRow sx={{ bgcolor: colorTable.header }}>
                                            <TableCell sx={{ color: colorTable.color }}>MES</TableCell>
                                            <TableCell sx={{ color: colorTable.color }}>VENTAS</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {resultados.map(res => {
                                            return <TableRow key={res.mes} id={res.mes} sx={{ bgcolor: colorTable.fondo }}>
                                                <TableCell>{res.periodo}</TableCell>
                                                <TableCell align='right'> {res.total}</TableCell>
                                            </TableRow>
                                        })}
                                        <TableRow sx={{ backgroundColor: colorTable.total }}>
                                            <TableCell sx={{ color: colorTable.color }}><b>TOTAL</b></TableCell>
                                            <TableCell sx={{ color: colorTable.color }} align='right'><b>S/ {resultados.reduce((total, item) => parseFloat(total) + parseFloat(item.total), 0).toFixed(2)}</b></TableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Grid>
                        <Grid item xs={12} sm={6} md={6}>
                            <TableContainer>
                                <Table size="small">
                                    <TableHead>
                                        <TableRow sx={{ bgcolor: colorTable.header }}>
                                            <TableCell sx={{ color: colorTable.color }}>AÑO</TableCell>
                                            <TableCell sx={{ color: colorTable.color }}>VENTAS</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {resultadosAnhos.map(res => {
                                            return <TableRow key={res.anho} id={res.anho} sx={{ bgcolor: colorTable.fondo }}>
                                                <TableCell>{res.anho}</TableCell>
                                                <TableCell align='right'>S/ {res.total}</TableCell>
                                            </TableRow>
                                        })}
                                        <TableRow sx={{ backgroundColor: colorTable.total }}>
                                            <TableCell sx={{ color: colorTable.color }}><b>TOTAL</b></TableCell>
                                            <TableCell sx={{ color: colorTable.color }} align='right'><b>S/ {resultadosAnhos.reduce((total, item) => parseFloat(total) + parseFloat(item.total), 0).toFixed(2)}</b></TableCell>
                                        </TableRow>
                                    </TableBody>

                                </Table>
                            </TableContainer>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} md={6}>
                    <Grid container spacing={2}>
                        <Grid item xs={6}>
                            <FormControl fullWidth>
                                <InputLabel id="fam_gen">Familia General:</InputLabel>
                                <Select
                                    labelId="fam_gen"
                                    id="fam_gen"
                                    value={currentFG2}
                                    label="FamGen"
                                    onChange={handleChangeFG2}
                                    size='small'
                                >
                                    {famGeneral.map(fg => {
                                        return <MenuItem key={fg.codigo} value={fg.codigo}>{fg.nombre}</MenuItem>
                                    })}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={6}>
                            <FormControl fullWidth>
                                <InputLabel id="fam_esp">Familia Especifica:</InputLabel>
                                <Select
                                    labelId="fam_esp"
                                    id="fam_esp"
                                    value={currentFE2}
                                    label="FamEsp"
                                    onChange={handleChangeFE2}
                                    size='small'
                                >
                                    {famEspecifica2.map(fe => {
                                        return <MenuItem key={fe.codigo} value={fe.codigo}>{fe.nombre}</MenuItem>
                                    })}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12}>
                            <FormControl fullWidth>
                                <InputLabel id="tratamiento">Tratamiento:</InputLabel>
                                <Select
                                    labelId="tratamiento"
                                    id="tratamiento"
                                    value={currentTratamiento2}
                                    label="Tratamiento"
                                    onChange={handleChangeTratamiento2}
                                    size='small'
                                >
                                    {tratamiento2.map(trat => {
                                        return <MenuItem key={trat.codigo} value={trat.codigo}>{trat.nombre}</MenuItem>
                                    })}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={6} md={6}>
                            <TableContainer>
                                <Table size="small">
                                    <TableHead>
                                        {/* <TableRow sx={{ backgroundColor: blue[700] }}> */}
                                        <TableRow sx={{ backgroundColor: colorTable.header }}>
                                            <TableCell sx={{ color: colorTable.color }}>MES</TableCell>
                                            <TableCell sx={{ color: colorTable.color }}>VENTAS</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {resultados2.map(res => {
                                            return <TableRow key={res.mes} id={res.mes} sx={{ bgcolor: colorTable.fondo }}>
                                                <TableCell>{res.periodo}</TableCell>
                                                <TableCell align='right'>S/ {res.total}</TableCell>
                                            </TableRow>
                                        })}
                                        {/* <TableRow sx={{ backgroundColor: "#1976D2" }}> */}
                                        <TableRow sx={{ backgroundColor: colorTable.total }}>
                                            <TableCell sx={{ color: "#fff" }}><b>TOTAL</b></TableCell>
                                            <TableCell sx={{ color: "#fff" }} align='right'><b>S/ {resultados2.reduce((total, item) => parseFloat(total) + parseFloat(item.total), 0).toFixed(2)}</b></TableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Grid>
                        <Grid item xs={12} sm={6} md={6}>
                            <TableContainer>
                                <Table size="small">
                                    <TableHead>
                                        <TableRow sx={{ backgroundColor: colorTable.header }}>
                                            <TableCell sx={{ color: colorTable.color }}>AÑO</TableCell>
                                            <TableCell sx={{ color: colorTable.color }}>VENTAS</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {resultadosAnhos2.map(res => {
                                            return <TableRow key={res.anho} id={res.anho} sx={{ bgcolor: colorTable.fondo }}>
                                                <TableCell>{res.anho}</TableCell>
                                                <TableCell align='right'>S/ {res.total}</TableCell>
                                            </TableRow>
                                        })}
                                        <TableRow sx={{ backgroundColor: colorTable.total }}>
                                            <TableCell sx={{ color: "#fff" }}><b>TOTAL</b></TableCell>
                                            <TableCell sx={{ color: "#fff" }} align='right'><b>S/ {resultadosAnhos2.reduce((total, item) => parseFloat(total) + parseFloat(item.total), 0).toFixed(2)}</b></TableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Grid>
                    </Grid>
                </Grid>



            </Grid>
        </Container>
    )
}

export default RepTratamientos
