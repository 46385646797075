import { Button, Grid, IconButton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Tooltip } from '@mui/material'
import React from 'react'
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import Constantes from '../Constantes';

function BuscarCompras({ childSeleccion }) {
    const [resultado, setResultado] = React.useState([]);
    const [searchParams, setsearchParams] = React.useState({
        razon_social: "",
        fecha: "",
    });

    const handleChangeParams = (e) => {
        setsearchParams((paramsAnt) => ({
            ...paramsAnt,
            [e.target.id]: e.target.value.toUpperCase(),
        }));
    };

    const buscarCompras = async () => {
        const fetchResultado = await fetch(`${Constantes.RUTA_API}listar/listarComprasBusqueda.php`, {
            method: "POST",
            body: JSON.stringify(searchParams),
        });
        const resultadosJSON = await fetchResultado.json();
        // console.log(resultadosJSON);
        setResultado(resultadosJSON);
    };

    return (
        <Grid container>
            <Grid item xs={7}>
                <TextField
                    label="Razon Social Proveedor:"
                    fullWidth
                    variant='filled'
                    type="text"
                    id='razon_social'
                    value={searchParams.razon_social}
                    onChange={handleChangeParams}
                />
            </Grid>
            <Grid item xs={4}>
                <TextField
                    label="Fecha:"
                    fullWidth
                    variant='filled'
                    type="date"
                    id='fecha'
                    value={searchParams.fecha}
                    onChange={handleChangeParams}
                />
            </Grid>
            <Grid item xs={1}>
                <Tooltip title='Buscar'>
                    <Button variant='contained' startIcon={<SearchOutlinedIcon />} onClick={buscarCompras}></Button>
                </Tooltip>
            </Grid>
            <TableContainer>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Razon Social</TableCell>
                            <TableCell>Fecha</TableCell>
                            <TableCell>Comprobante</TableCell>
                            <TableCell>Total Pagado</TableCell>
                            <TableCell></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {resultado.map((resultado) => {
                            return (
                                <TableRow key={resultado.id}>
                                    <TableCell>{resultado.razon_social}</TableCell>
                                    <TableCell>{resultado.fecha}</TableCell>
                                    <TableCell>{resultado.comprobante}</TableCell>
                                    <TableCell align='right'>S/ {resultado.total_a_pagar}</TableCell>
                                    <TableCell>
                                        <Tooltip title='Editar Compra'>
                                            <IconButton color='info' onClick={() => (childSeleccion(resultado.id))}><EditOutlinedIcon /></IconButton>
                                        </Tooltip>
                                    </TableCell>
                                </TableRow>
                            )
                        })}
                    </TableBody>
                </Table>
            </TableContainer>

        </Grid>
    )
}

export default BuscarCompras