import { Alert, Autocomplete, Button, Collapse, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControl, Grid, IconButton, InputAdornment, InputLabel, MenuItem, Modal, Paper, Select, TextField, Tooltip, Link as LinkM, Container, Slide } from '@mui/material'
import React, { Component, useEffect } from 'react'
import axios from 'axios';
import Constantes from '../components/Constantes';
import { Link } from 'react-router-dom';
import "../css/screensStyle.css";
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import CancelIcon from '@mui/icons-material/Cancel';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CloseIcon from '@mui/icons-material/Close';
import PersonAddAltIcon from '@mui/icons-material/PersonAddAlt';
import PersonAddAlt1Icon from '@mui/icons-material/PersonAddAlt1';
import HealingIcon from '@mui/icons-material/Healing';
import EditarTratamiento from '../components/tratamiento/editarTratamiento';
import ListAltIcon from '@mui/icons-material/ListAlt';
import Trabajadores from '../components/sistema/trabajadores';
import MedicalInformationOutlinedIcon from '@mui/icons-material/MedicalInformationOutlined';
import HomeResume from '../components/reportes/generic/homeResume';
import BarChartIcon from '@mui/icons-material/BarChart';
import ManContacto from '../components/contacto/manContacto';
import EditarCliente from '../components/cliente/editarCliente';
import { Close } from '@mui/icons-material';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

class Home extends Component {
    constructor(props) {
        super(props);
        this.state = {
            clientes: [],
            cliente: {
                codigo: "",
                apellidos_nombres: "",
                numero_documento: "",
                estado: "",
                telefono_1: "",
                telefono_2: "",
                e_mail: "",
                fk_ven_contacto: "",
                fk_log_usuario_creado_por: "",
                created_at: "",
                updated_at: "",
                deleted_at: "",
                fk_com_tipo_documento: "",
                fk_com_referencia: "",
                fk_log_usuario_actualizado_por: "",
            },
            deuda: 0,
            showDialog: false,
            showToast: false,
            toastSeverity: "error",
            messageToast: "",
            estadoActualizado: "",
            showAddTratamiento: false,
            showPersonal: false,
            showResume: false,
            showContacto: false,
            showCliente: false,
        }
        this.handleChange = this.handleChange.bind(this);
        this.closeDialoghandleSave = this.closeDialoghandleSave.bind(this);
        this.handleChangeEstado = this.handleChangeEstado.bind(this);
    }

    async componentDidMount() {
        const respuesta = await fetch(`${Constantes.RUTA_API}listar/listarContactoCliente.php`);
        const clientes = await respuesta.json();
        this.setState({
            clientes: clientes,
        });


    }

    async handleChange(event, values) {
        if (values) {
            this.setState({
                cliente: values,
                estadoActualizado: values.estado,
            })
            const deudaFetch = await fetch(`${Constantes.RUTA_API}sistema/recuperarDeudaCliente.php?codigo=${values.codigo}`);
            const deudaDB = await deudaFetch.json();
            this.setState({ deuda: deudaDB.deuda });
        } else {
            this.setState({
                cliente: {
                    codigo: "",
                    apellidos_nombres: "",
                    numero_documento: "",
                    estado: "",
                    telefono_1: "",
                    telefono_2: "",
                    e_mail: "",
                    fk_ven_contacto: "",
                    fk_log_usuario_creado_por: "",
                    created_at: "",
                    updated_at: "",
                    deleted_at: "",
                    fk_com_tipo_documento: "",
                    fk_com_referencia: "",
                    fk_log_usuario_actualizado_por: "",
                },
                deuda: 0.00,
            })
        }
    }
    messageError() {
        var respuesta = "";
        if (this.state.cliente.codigo === "") {
            respuesta = "Debe seleccionar un cliente para continuar."
        } else if (this.state.cliente.codigo === this.state.cliente.fk_ven_contacto) {
            respuesta = "Ha seleccionado un contacto debe de registrar el cliente para proceder.";
        } else if (this.state.cliente.estado === "ACTIVO"
            || this.state.cliente.estado === "ACTIVO_IC") {
            respuesta = "";
        } else {
            respuesta = "El Cliente debe estar activo para continuar."
        }
        return <p> {respuesta}</p>;
    }
    async closeDialoghandleSave() {
        const logedUserJSON = window.sessionStorage.getItem('logedAppUser');
        const userData = JSON.parse(logedUserJSON);
        const clienteActualizado = this.state.cliente;
        clienteActualizado["fk_log_usuario_actualizado_por"] = userData.usuario;
        clienteActualizado["estado"] = this.state.estadoActualizado;
        const hoy = new Date();
        clienteActualizado["updated_at"] = `${hoy.getFullYear()}-${(hoy.getMonth() + 1).toString().padStart(2, '0')}-${hoy.getDate().toString().padStart(2, '0')}`;
        const respuesta = await fetch(`${Constantes.RUTA_API}mantenimiento/guardarCliente.php`, {
            method: "POST",
            body: JSON.stringify(clienteActualizado),
        });
        const resultado = await respuesta.json();
        console.log(resultado);
        if (resultado.guardado) {
            this.setState({
                toastSeverity: "success",
                showToast: true,
                messageToast: "Se actualizo correctamente el estado del cliente",
            })
        } else {
            this.setState({
                toastSeverity: "error",
                showToast: true,
                messageToast: "Ocurrió un error al intentar guardar el cliente, intente de nuevo",
            })
        }
        this.setState({
            showDialog: false,
        })
        setTimeout(() => {
            this.setState({
                toastSeverity: "error",
                messageToast: "",
                showToast: false,
            })
        }, 2000);




    }
    closeDialoghandleCancel() {
        this.setState({
            showDialog: false,
            estadoActualizado: this.state.cliente.estado,
        })
    }
    handleChangeEstado(evento) {
        let valor = evento.target.value;
        this.setState({
            estadoActualizado: valor,
        })
    }
    hideDialogTratamiento = mostrar => {
        this.setState({ showAddTratamiento: mostrar });
    }
    cerrarContactos = () => {
        this.setState({ showContacto: false });
    }
    render() {
        const Estados = ["SUSPENDIDO", "ACTIVO", "ACTIVO_IC", "CULMINADO", "ABANDONO", "CONSULTA", "RETIRO"]
        return (
            <Grid container style={{ minHeight: '80vh', justifyContent: 'center', alignContent: 'center' }}>
                <Dialog fullScreen open={this.state.showResume} TransitionComponent={Transition}>
                    <DialogTitle>
                        RESUMEN OPERATIVO
                        <IconButton
                            aria-label="close"
                            onClick={() => { this.setState({ showResume: false }) }}
                            sx={{
                                position: 'absolute',
                                right: 8,
                                top: 8,
                                color: (theme) => theme.palette.grey[500],
                            }}
                        >
                            <CloseIcon />
                        </IconButton>
                    </DialogTitle>
                    <DialogContent>
                        <HomeResume />
                    </DialogContent>
                </Dialog>
                <Dialog open={this.state.showPersonal} maxWidth='md'>
                    <DialogTitle>
                        PERSONAL
                        <IconButton
                            aria-label="close"
                            onClick={() => { this.setState({ showPersonal: false }) }}
                            sx={{
                                position: 'absolute',
                                right: 8,
                                top: 8,
                                color: (theme) => theme.palette.grey[500],
                            }}
                        >
                            <CloseIcon />
                        </IconButton>
                    </DialogTitle>
                    <DialogContent>
                        <Trabajadores soloActivos={false} />
                    </DialogContent>
                </Dialog>
                <Dialog open={this.state.showContacto}>
                    <DialogTitle>
                        NUEVO CONTACTO
                        <IconButton
                            aria-label="close"
                            onClick={() => { this.setState({ showContacto: false }) }}
                            sx={{
                                position: 'absolute',
                                right: 8,
                                top: 8,
                                color: (theme) => theme.palette.grey[500],
                            }}
                        >
                            <CloseIcon />
                        </IconButton>
                    </DialogTitle>
                    <DialogContent>
                        <ManContacto codigo="CON-00000000" closeChild={this.cerrarContactos} />
                    </DialogContent>
                </Dialog>
                <Dialog open={this.state.showCliente} maxWidth='md'>
                    <DialogTitle>
                        NUEVO CLIENTE
                        <IconButton
                            aria-label="close"
                            onClick={() => { this.setState({ showCliente: false }) }}
                            sx={{
                                position: 'absolute',
                                right: 8,
                                top: 8,
                                color: (theme) => theme.palette.grey[500],
                            }}
                        >
                            <Close />
                        </IconButton>
                    </DialogTitle>
                    <DialogContent>
                        <EditarCliente
                            showCliente={() => this.setState({ showCliente: false })}
                        />
                    </DialogContent>
                </Dialog>
                <EditarTratamiento mostrar={this.state.showAddTratamiento} parentHideDialog={this.hideDialogTratamiento} />
                <Grid item md={8} xs={11}>
                    <Paper elevation={4} sx={{ p: 3 }} >
                        <Collapse in={this.state.showToast}>
                            <Alert
                                action={
                                    <IconButton
                                        aria-label="info"
                                        color="error"
                                        size="small"
                                        onClick={() => {
                                            this.setState({
                                                showToast: false,
                                            });
                                        }}
                                    >
                                        <CloseIcon fontSize="inherit" />
                                    </IconButton>
                                }
                                sx={{ mb: 2 }}
                                severity={this.state.toastSeverity}
                            >
                                {this.state.messageToast}
                            </Alert>
                        </Collapse>
                        <Dialog open={this.state.showDialog} onClose={() => { this.setState({ showDialog: false }) }}>
                            <DialogTitle>Actualizar estado</DialogTitle>
                            <DialogContent>
                                <DialogContentText>
                                    Ingrese el nuevo <b>ESTADO</b> para el cliente.
                                </DialogContentText>
                                <br />
                                <FormControl fullWidth>
                                    <InputLabel>Estado:</InputLabel>
                                    <Select
                                        labelId="estado"
                                        id="estado"
                                        value={this.state.estadoActualizado}
                                        label="Estado:"
                                        onChange={this.handleChangeEstado}
                                    >
                                        {Estados.map(estado => {
                                            return (<MenuItem key={estado} value={estado}>{estado}</MenuItem>)
                                        })}
                                    </Select>
                                </FormControl>
                            </DialogContent>
                            <DialogActions style={{ justifyContent: 'center' }}>
                                <Button onClick={this.closeDialoghandleSave} startIcon={<CheckCircleIcon />} variant='contained' color='success'>Guardar</Button>
                                <Button onClick={() => { this.setState({ showDialog: false }) }} startIcon={<CancelIcon />} variant='contained' color='error'>Cancelar</Button>
                            </DialogActions>
                        </Dialog>
                        <Grid container spacing={1}>
                            <Grid item xs={11}>
                                <Grid container spacing={1} >
                                    <Grid item xs={12} sx={{ display: { xs: 'block', md: 'none', textAlign: 'center' } }}>
                                        <Tooltip title="Agregar contacto">
                                            <IconButton size="large" color='primary' onClick={() => { this.setState({ showContacto: true }) }}><PersonAddAltIcon fontSize='large' /></IconButton>
                                        </Tooltip>
                                        <Tooltip title="Agregar cliente">
                                            <IconButton size="large" color='success' onClick={() => { this.setState({ showCliente: true }) }}><PersonAddAlt1Icon fontSize='large' /></IconButton>
                                        </Tooltip>
                                        <Tooltip title="Editar tratamientos">
                                            <IconButton size="large" color='secondary' onClick={() => { this.setState({ showAddTratamiento: true }) }}><HealingIcon fontSize='large' /></IconButton>
                                        </Tooltip>
                                        <Tooltip title="Reporte del día">
                                            <LinkM href="#/reportes/registrosPorFecha" underline='none' color='inherit'>
                                                <IconButton size="large" color='warning'><ListAltIcon fontSize='large' /></IconButton>
                                            </LinkM>
                                        </Tooltip>
                                        <Tooltip title="Personal">
                                            <IconButton size="large" color='info' onClick={() => { this.setState({ showPersonal: true }) }}><MedicalInformationOutlinedIcon fontSize='large' /></IconButton>
                                        </Tooltip>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Autocomplete
                                            onChange={this.handleChange}
                                            options={this.state.clientes}
                                            getOptionLabel={(option) => option.apellidos_nombres}
                                            renderOption={(props, option) => {
                                                return (
                                                    <li {...props} key={option.codigo}>
                                                        {option.apellidos_nombres}
                                                    </li>
                                                );
                                            }}
                                            id="apellidos_nombres"
                                            clearOnEscape
                                            renderInput={(params) => (
                                                <TextField {...params} label="Cliente" variant="standard" />
                                            )}
                                        />
                                    </Grid>
                                    <Grid item xs={11} sm={7} sx={{ mt: 2 }}>
                                        <TextField label="Estado"
                                            fullWidth type="text"
                                            value={this.state.cliente.estado === "" ? 'SIN ESTADO' : this.state.cliente.estado} disabled />
                                    </Grid>
                                    <Grid item xs={1} sx={{ mt: 2 }}>
                                        <Tooltip title="Actualizar estado">
                                            <IconButton size="large" color='primary' onClick={() => { if (this.state.cliente.codigo !== "" && this.state.cliente.codigo !== this.state.cliente.fk_ven_contacto) { this.setState({ showDialog: true }) } }}><EditOutlinedIcon fontSize='large' /></IconButton>
                                        </Tooltip>
                                    </Grid>
                                    <Grid item xs={11} sm={4} sx={{ mt: 2 }}>
                                        <TextField
                                            label="Deuda"
                                            fullWidth
                                            type="text"
                                            value={this.state.deuda}
                                            InputProps={{
                                                startAdornment: (
                                                    <InputAdornment position="start">
                                                        S/
                                                    </InputAdornment>
                                                ),
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={12} align='center'>
                                        <Link to={
                                            this.state.cliente.codigo !== "" &&
                                                (this.state.cliente.estado === "ACTIVO"
                                                    || this.state.cliente.estado === "ACTIVO_IC"
                                                    || this.state.cliente.estado === "CONSULTA") ?
                                                `tratamiento/${this.state.cliente.codigo}` :
                                                `/`} style={{ textDecoration: 'none' }}>
                                            <Button variant='contained'>Registrar Tratamiento</Button>
                                        </Link>
                                    </Grid>
                                    <Grid item xs={12} className="alertMessage" >
                                        {this.messageError()}
                                    </Grid>
                                    <Grid item xs={12} align="center">
                                        <Button variant='contained' color='secondary' startIcon={<BarChartIcon />} onClick={() => { this.setState({ showResume: true }); }}> VER RESUMEN </Button>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item md={1} sx={{ display: { xs: 'none', md: 'block' } }}>
                                <Tooltip title="Agregar contacto">
                                    <IconButton size="large" color='primary' onClick={() => { this.setState({ showContacto: true }) }}><PersonAddAltIcon fontSize='large' /></IconButton>
                                </Tooltip>
                                <Tooltip title="Agregar cliente">
                                    <IconButton size="large" color='success' onClick={() => { this.setState({ showCliente: true }) }}><PersonAddAlt1Icon fontSize='large' /></IconButton>
                                </Tooltip>
                                <Tooltip title="Editar tratamientos">
                                    <IconButton size="large" color='secondary' onClick={() => { this.setState({ showAddTratamiento: true }) }}><HealingIcon fontSize='large' /></IconButton>
                                </Tooltip>
                                <Tooltip title="Reporte del día">
                                    <LinkM href="#/reportes/registrosPorFecha" underline='none' color='inherit'>
                                        <IconButton size="large" color='warning'><ListAltIcon fontSize='large' /></IconButton>
                                    </LinkM>
                                </Tooltip>
                                <Tooltip title="Personal">
                                    <IconButton size="large" color='info' onClick={() => { this.setState({ showPersonal: true }) }}><MedicalInformationOutlinedIcon fontSize='large' /></IconButton>
                                </Tooltip>
                            </Grid>
                        </Grid>
                    </Paper>
                </Grid>
            </Grid>
        )
    }
}

export default Home
