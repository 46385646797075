import { Grid, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, Typography } from '@mui/material'
import React from 'react'
import { useEffect } from 'react';
import Constantes from '../../Constantes';
import PriceCheckOutlinedIcon from '@mui/icons-material/PriceCheckOutlined';
import PlaylistAddCheckOutlinedIcon from '@mui/icons-material/PlaylistAddCheckOutlined';
import { Box } from '@mui/system';

function ViewDetallePagos(props) {
    const anho = props.anho;
    const mes = props.mes;
    const [resPagos, setResPagos] = React.useState([]);
    const [resCompras, setResCompras] = React.useState([]);
    const [resGastos, setResGastos] = React.useState([]);
    const [rowsPerPagePagos, setRowsPerPagePagos] = React.useState(10);
    const [pagePagos, setPagePagos] = React.useState(0);    
    const [rowsPerPageCompras, setRowsPerPageCompras] = React.useState(10);
    const [pageCompras, setPageCompras] = React.useState(0);    
    const [rowsPerPageGastos, setRowsPerPageGastos] = React.useState(10);
    const [pageGastos, setPageGastos] = React.useState(0);
    useEffect(() => {
        const cargarResultado = async() =>{
            const fetchResPago = await fetch(`${Constantes.RUTA_API}reportes/detallePagosPorMes.php?anho=${anho}&mes=${mes}`)
            const resPagoDB = await fetchResPago.json();
            setResPagos(resPagoDB);
            const fetchResCompra = await fetch(`${Constantes.RUTA_API}reportes/detalleComprasPorMes.php?anho=${anho}&mes=${mes}`)
            const resCompraDB = await fetchResCompra.json();
            setResCompras(resCompraDB);
            const fetchResGasto = await fetch(`${Constantes.RUTA_API}reportes/detalleGastosPorMes.php?anho=${anho}&mes=${mes}`)            
            const resGastoDB = await fetchResGasto.json();
            setResGastos(resGastoDB);
        }
        cargarResultado();
    }, [anho, mes])
    const handleChangePagePagos = (event, newPage) => {
        setPagePagos(newPage);
    };    
    const handleChangeRowsPerPagePagos = (event) => {
        setRowsPerPagePagos(parseInt(event.target.value, 10));
        setPagePagos(0);
    };
    const handleChangePageCompras = (event, newPage) => {
        setPageCompras(newPage);
    };    
    const handleChangeRowsPerPageCompras = (event) => {
        setRowsPerPageCompras(parseInt(event.target.value, 10));
        setPageCompras(0);
    };
    const handleChangePageGastos = (event, newPage) => {
        setPageGastos(newPage);
    };    

    const handleChangeRowsPerPageGastos = (event) => {
        setRowsPerPageGastos(parseInt(event.target.value, 10));
        setPageGastos(0);
    };
    return (
        <Grid container spacing={1}>
            <Grid item xs={12} sm={4} sx={{border:'1px'}}>
                <Box sx={{border:"1px", borderRadius:"4px", borderColor:"#444444"}}>
                    <Typography sx={{ fontWeight:'bold' }} align='center'>PAGOS</Typography>
                    <TableContainer>
                        {/* cliente, fecha_registro, id_pago, fecha_pago, total_pago, cod_registro, cuota */}
                        <Table>
                            <TableHead>
                                <TableRow sx={{backgroundColor:'#CCC'}}>
                                    <TableCell>PACIENTE</TableCell>
                                    <TableCell>REGISTRO</TableCell>
                                    <TableCell>CTA</TableCell>
                                    <TableCell>F. REG.</TableCell>
                                    <TableCell>F. PAGO</TableCell>
                                    <TableCell>TOTAL</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {resPagos.slice(pagePagos * rowsPerPagePagos, pagePagos * rowsPerPagePagos + rowsPerPagePagos).map(detalle =>{
                                    return <TableRow key={detalle.id_pago}>
                                        <TableCell key={`${detalle.id_pago}-${detalle.cliente}`}>{detalle.cliente}</TableCell>
                                        <TableCell>{detalle.cod_registro}</TableCell>
                                        <TableCell>{detalle.cuota}</TableCell>
                                        <TableCell>{detalle.fecha_registro}</TableCell>
                                        <TableCell>{detalle.fecha_pago}</TableCell>
                                        <TableCell>{detalle.total_pago}</TableCell>
                                    </TableRow>
                                })}
                            </TableBody> 
                        </Table>
                    </TableContainer>
                    <TablePagination
                        rowsPerPageOptions={[10, 25]}
                        component="div"
                        count={resPagos.length}
                        rowsPerPage={rowsPerPagePagos}
                        page={pagePagos}
                        onPageChange={handleChangePagePagos}
                        onRowsPerPageChange={handleChangeRowsPerPagePagos}
                    />
                </Box>                
            </Grid>
            <Grid item xs={12} sm={4}>
                <Typography sx={{ fontWeight:'bold' }} align='center'>COMPRAS</Typography>
                <TableContainer>
                    <Table>
                    {/* proveedor, fecha, id_compra, comprobante, total_a_pagar */}
                        <TableHead>
                            <TableRow sx={{backgroundColor:'#CCC'}}>
                                <TableCell>PROVEEDOR</TableCell>
                                <TableCell>COMPROBANTE</TableCell>                                
                                <TableCell>FECHA</TableCell>                                
                                <TableCell>TOTAL</TableCell>                                
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {resCompras.slice(pageCompras * rowsPerPageCompras, pageCompras * rowsPerPageCompras + rowsPerPageCompras).map(detalle =>{
                                return <TableRow key={detalle.id_compra}>
                                    <TableCell>{detalle.proveedor}</TableCell>
                                    <TableCell>{detalle.comprobante}</TableCell>
                                    <TableCell>{detalle.fecha}</TableCell>
                                    <TableCell>{detalle.total_a_pagar}</TableCell>                                    
                                </TableRow>
                            })}
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={[10, 25]}
                    component="div"
                    count={resCompras.length}
                    rowsPerPage={rowsPerPageCompras}
                    page={pageCompras}
                    onPageChange={handleChangePageCompras}
                    onRowsPerPageChange={handleChangeRowsPerPageCompras}
                />
            </Grid>
            <Grid item xs={12} sm={4}>
                <Typography sx={{ fontWeight:'bold' }} align='center'>GASTOS</Typography>
                <TableContainer>
                {/* codigo, fecha, referencia, total, gasto */}
                    <Table>
                        <TableHead>
                            <TableRow sx={{backgroundColor:'#CCC'}}>
                                <TableCell>GASTO</TableCell>
                                <TableCell>REFERENCIA</TableCell>                                
                                <TableCell>FECHA</TableCell>                                
                                <TableCell>TOTAL</TableCell>                                
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {resGastos.slice(pageGastos * rowsPerPageGastos, pageGastos * rowsPerPageGastos + rowsPerPageGastos).map(detalle =>{
                                return <TableRow key={detalle.codigo}>
                                    <TableCell>{detalle.gasto}</TableCell>
                                    <TableCell>{detalle.referencia}</TableCell>
                                    <TableCell>{detalle.fecha}</TableCell>
                                    <TableCell>{detalle.total}</TableCell>
                                </TableRow>
                            })}
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={[10, 25]}
                    component="div"
                    count={resGastos.length}
                    rowsPerPage={rowsPerPageGastos}
                    page={pageCompras}
                    onPageChange={handleChangePageGastos}
                    onRowsPerPageChange={handleChangeRowsPerPageGastos}
                />
            </Grid>
            
        </Grid>
    )
}
export default ViewDetallePagos
