import { Button, Dialog, DialogContent, DialogTitle, Grid, IconButton, InputAdornment, MenuItem, TextField, Tooltip } from '@mui/material'
import React, { useEffect } from 'react'

// icons
import PersonAddAltIcon from '@mui/icons-material/PersonAddAlt';
import Constantes from '../../Constantes';
import Trabajador from '../trabajador';
import { LoadingButton } from '@mui/lab';
import { CheckCircleOutline, Close } from '@mui/icons-material';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import Visibility from '@mui/icons-material/Visibility';
export default function SaveUsuario(props) {
  const setAlertGeneral = props.setAlertGeneral;
  const [usuarioSubmit, setUsuarioSubmit] = React.useState({
    codigo: '',
    fk_log_perfil: '',
    fk_rh_trabajador: '',
    fk_log_usuario_creado_por: '',
    clave: '',
    created_at: '',
    updated_at: '',
    deleted_at: ''
  });
  const [claveInput, setClaveInput] = React.useState({
    type: 'password',
    show: true,
    claveConfirm: ''
  });
  // validaciones
  const [usuarioCodigo, setUsuarioCodigo] = React.useState({
    error: false,
    helpertext: ''
  });
  const [usuarioPerfil, setUsuarioPerfil] = React.useState({
    error: false,
    helpertext: ''
  });
  const [usuarioTrabajador, setUsuarioTrabajador] = React.useState({
    error: false,
    helpertext: ''
  });
  // 
  const [perfiles, setPerfiles] = React.useState([]);
  const [trabajadores, setTrabajadores] = React.useState([]);
  // loading button
  const [loading, setLoading] = React.useState(false);
  // dialog
  const [addTrabajador, setAddTrabajador] = React.useState(false);
  // handle
  const handleCodigo = (e) => {
    let valor = e.target.value;

    if (valor !== "") {
      let valorF = valor.replace(/ /g, "");
      setUsuarioSubmit({ ...usuarioSubmit, codigo: valorF.toUpperCase() });
      setUsuarioCodigo({ error: false, helpertext: '' });
    } else {
      setUsuarioSubmit({ ...usuarioSubmit, codigo: '' });
      setUsuarioCodigo({ error: true, helpertext: 'Codigo usuario obligatorio' });
    }
  }
  const handlePerfil = (e, values) => {
    setUsuarioPerfil({ error: false, helpertext: '' });
    setUsuarioSubmit({ ...usuarioSubmit, fk_log_perfil: values.props.value });
  }
  const handleTrabajador = (e, values) => {
    setUsuarioTrabajador({ error: false, helpertext: '' });
    setUsuarioSubmit({ ...usuarioSubmit, fk_rh_trabajador: values.props.value });
  }
  // functions
  const saveUsuario = async () => {
    if (usuarioSubmit.codigo === "" || usuarioSubmit.fk_log_perfil === "" || usuarioSubmit.fk_rh_trabajador === "") {
      if (usuarioSubmit.codigo === "") {
        setUsuarioCodigo({ error: true, helpertext: 'Codigo usuario obligatorio' });
      }
      if (usuarioSubmit.fk_log_perfil === "") {
        setUsuarioPerfil({ error: true, helpertext: 'Perfil usuario obligatorio' });
      }
      if (usuarioSubmit.fk_rh_trabajador === "") {
        setUsuarioTrabajador({ error: true, helpertext: 'Trabajador usuario obligatorio' });
      }

    } else {
      setLoading(true);
      const saveusuario = await fetch(`${Constantes.RUTA_API}mantenimiento/guardarUsuario.php`, {
        method: 'POST',
        body: JSON.stringify({
          codigo: usuarioSubmit.codigo.toUpperCase(),
          fk_log_perfil: usuarioSubmit.fk_log_perfil,
          fk_rh_trabajador: usuarioSubmit.fk_rh_trabajador,
          fk_log_usuario_creado_por: usuarioSubmit.fk_log_usuario_creado_por,
          clave: usuarioSubmit.clave,
          created_at: props.codigoUsuarioSelect === undefined ? new Date().toJSON().slice(0, 10) : usuarioSubmit.created_at,
          updated_at: new Date().toJSON().slice(0, 10),
        })
      });
      const response = await saveusuario.json();
      if (response) {
        if (props.codigoUsuarioSelect === undefined) {
          setAlertGeneral({ severity: 'success', message: 'Usuario guardardo correctamente', show: true });
          setTimeout(() => {
            setAlertGeneral({ severity: 'warning', message: '', show: false });
          }, 3000);
        } else {
          setAlertGeneral({ severity: 'success', message: 'Usuario actualizado correctamente', show: true });
          setTimeout(() => {
            setAlertGeneral({ severity: 'warning', message: '', show: false });
          }, 3000);
        }
      } else {
        if (props.codigoUsuarioSelect === undefined) {
          setAlertGeneral({ severity: 'error', message: 'Usuario no se puedo guardar', show: true });
          setTimeout(() => {
            setAlertGeneral({ severity: 'warning', message: '', show: false });
          }, 3000);
        } else {
          setAlertGeneral({ severity: 'error', message: 'Usuario no se puedo actualiza', show: true });
          setTimeout(() => {
            setAlertGeneral({ severity: 'warning', message: '', show: false });
          }, 3000);
        }
      }
      props.listarUsuarios();
      props.dialogClose();
    }
  }
  const handleClickShowPassword = () => {
    if (claveInput.type === "password") {
      setClaveInput({ ...claveInput, show: !claveInput.show, type: 'text' });
    } else {
      setClaveInput({ ...claveInput, show: !claveInput.show, type: 'password' });
    }
  }
  const listarTrabajadores = async () => {
    const getTrabajador = await fetch(`${Constantes.RUTA_API}listar/listarTrabajadorArea.php?area=A`);
    const response = await getTrabajador.json();
    setTrabajadores(response);
  }
  useEffect(() => {
    let usuario_sesion = JSON.parse(sessionStorage.getItem('logedAppUser'));
    setUsuarioSubmit({ ...usuarioSubmit, fk_log_usuario_creado_por: usuario_sesion.usuario });
    const listarPerfiles = async () => {
      const getPerfiles = await fetch(`${Constantes.RUTA_API}listar/listarPerfil.php`);
      const response = await getPerfiles.json();
      setPerfiles(response);
    }
    listarPerfiles();
    listarTrabajadores();
    if (props.codigoUsuarioSelect != undefined) {
      const recuperarUsuario = async () => {
        const getUsuario = await fetch(`${Constantes.RUTA_API}recuperar/recuperarUsuario.php?codigo=${props.codigoUsuarioSelect}`);
        const response = await getUsuario.json();
        setUsuarioSubmit(response);
      }
      recuperarUsuario();
    }
  }, []);
  return (
    <>
      <Grid container spacing={2}>
        <Grid item md={11}>
          <TextField
            name="codigo"
            placeholder='Codigo'
            variant='filled'
            label="CODIGO"
            margin='normal'
            fullWidth
            inputProps={{ style: { textTransform: "uppercase" }, maxLength: '20' }}
            value={usuarioSubmit ? usuarioSubmit.codigo : ''}
            onChange={handleCodigo}
            disabled={props.codigoUsuarioSelect !== undefined ? true : false}
            error={usuarioCodigo.error}
            helperText={usuarioCodigo.helpertext}
          />
        </Grid>
        <Grid item md={1} style={{ marginTop: '25px' }}>
          <Tooltip title="NUEVO TRABAJADOR" placement='top'>
            <IconButton onClick={() => setAddTrabajador(true)}>
              <PersonAddAltIcon color="success" />
            </IconButton>
          </Tooltip>
        </Grid>
        <Grid item md={6}>
          <TextField
            style={{ textAlign: 'start' }}
            select
            variant='filled'
            fullWidth
            value={usuarioSubmit ? usuarioSubmit.fk_log_perfil : 0}
            label="PERFILES"
            onChange={handlePerfil}
            error={usuarioPerfil.error}
            helperText={usuarioPerfil.helpertext}
          >
            {perfiles.map((option) => (
              <MenuItem key={option.codigo} value={option.codigo}>
                {option.nombre}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
        <Grid item md={6}>
          <TextField
            style={{ textAlign: 'start' }}
            select
            variant='filled'
            fullWidth
            value={usuarioSubmit.fk_rh_trabajador !== null ? usuarioSubmit.fk_rh_trabajador : 0}
            label="TRABAJADOR"
            onChange={handleTrabajador}
            error={usuarioTrabajador.error}
            helperText={usuarioTrabajador.helpertext}
          >
            {trabajadores.map((option) => (
              <MenuItem key={option.id} value={option.id}>
                {option.apellidos_nombres}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
        {
          props.codigoUsuarioSelect === undefined &&
          <>
            <Grid item md={6}>
              <TextField
                label="CLAVE"
                placeholder='Clave'
                fullWidth
                variant='standard'
                error={usuarioSubmit.clave !== claveInput.claveConfirm}
                helperText={usuarioSubmit.clave !== claveInput.claveConfirm ? 'Las claves no coinciden' : ''}
                onChange={(e) => {
                  const { value } = e.target;
                  setUsuarioSubmit({ ...usuarioSubmit, clave: value });
                }}
                type={claveInput.type}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        onClick={handleClickShowPassword}
                        edge="end"
                      >
                        {claveInput.show ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  )
                }}
              />
            </Grid>
            <Grid item md={6}>
              <TextField
                label="CONFIRMAR CLAVE"
                placeholder='Confirmar clave'
                fullWidth
                variant='standard'
                error={usuarioSubmit.clave !== claveInput.claveConfirm}
                helperText={usuarioSubmit.clave !== claveInput.claveConfirm ? 'Las claves no coinciden' : ''}
                onChange={(e) => {
                  const { value } = e.target;
                  setClaveInput({ ...claveInput, claveConfirm: value });
                }}
                type={claveInput.type}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        onClick={handleClickShowPassword}
                        edge="end"
                      >
                        {claveInput.show ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  )
                }}
              />
            </Grid>
          </>
        }

      </Grid>
      <Grid container style={{ justifyContent: 'center', marginTop: '15px' }}>
        <Grid item sm={3} xs={6}>
          <LoadingButton fullWidth startIcon={<CheckCircleOutline />} variant="outlined" color="success" onClick={saveUsuario} loading={loading}>
            GUARDAR
          </LoadingButton>
        </Grid>
        <Grid item sm={3} xs={6} style={{ marginLeft: '6px' }}>
          <Button fullWidth startIcon={<Close />} onClick={() => props.dialogClose()} variant="outlined" color="error">
            CANCELAR
          </Button>
        </Grid>
      </Grid>
      <Dialog open={addTrabajador} onClose={() => setAddTrabajador(false)} fullWidth={true} maxWidth="sm">
        <DialogTitle>
          <IconButton
            aria-label="close"
            onClick={() => setAddTrabajador(false)}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <Close />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <Trabajador
            listarTrabajadores={listarTrabajadores}
            parentClose={() => setAddTrabajador(false)}
          />
        </DialogContent>
      </Dialog>
    </>
  )
}
