import React from 'react'
import { Page, Text, Image, Document, StyleSheet, View } from "@react-pdf/renderer";
import Logo from '../../../images/logo.png';

const styles = StyleSheet.create({
  body: {
    paddingTop: 35,
    paddingBottom: 65,
    paddingHorizontal: 35,
  },
  title: {
    fontSize: 20,
    fontWeight: 'bold',
    // textAlign: 'center',
    // fontFamily: 'Oswald'
  },
  author: {
    fontSize: 12,
    textAlign: 'center',
    marginBottom: 40,
  },
  subtitle: {
    fontSize: 18,
    margin: 12,
    fontFamily: 'Oswald'
  },
  text: {
    margin: 12,
    fontSize: 14,
    textAlign: 'justify',
    fontFamily: 'Times-Roman'
  },
  image: {
    marginVertical: 10,
    marginHorizontal: 50,
    width: '45px',
    height: '45px'
  },
  header: {
    fontSize: 12,
    marginBottom: 20,
    textAlign: 'center',
    color: 'grey',
  },
  pageNumber: {
    position: 'absolute',
    fontSize: 8,
    bottom: 30,
    left: 0,
    right: 40,
    textAlign: 'right',
    color: 'grey',
  },
});
function ExportPDF({ tipo = "", paciente = "", dataFechas = {}, datosGeneral = [], datosGeneralFam = [], datosEmpresa = [] }) {
  const estilosTablaPdf = { padding: "4px 0" }
  const meses = [
    { mes: 1, strMes: 'ENERO' },
    { mes: 2, strMes: 'FEBRERO' },
    { mes: 3, strMes: 'MARZO' },
    { mes: 4, strMes: 'ABRIL' },
    { mes: 5, strMes: 'MAYO' },
    { mes: 6, strMes: 'JUNIO' },
    { mes: 7, strMes: 'JULIO' },
    { mes: 8, strMes: 'AGOSTO' },
    { mes: 9, strMes: 'SEPTIEMBRE' },
    { mes: 10, strMes: 'OCTUBRE' },
    { mes: 11, strMes: 'NOVIEMBRE' },
    { mes: 12, strMes: 'DICIEMBRE' },
  ];
  const imgGrafico = sessionStorage.getItem("imgGrafico");

  return (
    <Document>
      {tipo === "resReg" && paciente === "" &&
        // ===========por Fechas ===========
        <Page style={styles.body} orientation="landscape">
          <View style={{ display: 'fixed', flexDirection: 'row', paddingBottom: "10px" }} fixed>
            <View style={{ display: 1 }}>
              <Image
                style={{ width: '55px', height: '50px', padding: "0 5px" }}
                src={Logo}
              />
            </View>
            <View style={{ display: 2, marginHorizontal: '15px', width: '60%' }} >
              <Text style={{ fontSize: '16px' }}>{datosEmpresa.razon_social}</Text>
              <Text style={{ fontSize: '10px', marginTop: '5px' }}>{datosEmpresa.ruc}</Text>
              <Text style={{ fontSize: '10px', marginTop: '5px' }}>{"Av. Demo Mz. Demo"}</Text>
            </View>
            <View style={{ display: 3 }}>
              <Text style={{ fontSize: '16px' }}>REPORTE RESUMEN REGISTROS</Text>
              <View style={{ display: 'flex', flexDirection: 'row', padding: "10px 0" }}>
                <View style={{ display: 1, width: '150px', margin: '2px' }}>
                  <Text style={{ fontSize: '10px' }}>DESDE:  {dataFechas.fechaDesde}{paciente}</Text>
                </View>
                <View style={{ display: 2, width: '150px', margin: '2px' }}>
                  <Text style={{ fontSize: '10px' }}>HASTA:  {dataFechas.fechaHasta}</Text>
                </View>
              </View>
            </View>
          </View>
          {/* <Text style={{ marginTop: '25px', marginBottom: '10px', fontSize: '10px' }}>TRABAJADOR: {}</Text> */}
          <View style={{ display: 'flex', flexDirection: 'row', color: '#fff', backgroundColor: '#1976D2', padding: "4px 0" }} fixed>
            <View style={{ display: 1, width: '5px', margin: '2px' }}>
              <Text style={{ fontSize: '8px' }}></Text>
            </View>
            <View style={{ display: 2, width: '320px', margin: '2px' }}>
              <Text style={{ fontSize: '8px' }}>PACIENTE</Text>
            </View>
            <View style={{ display: 3, width: '230px', margin: '2px' }}>
              <Text style={{ fontSize: '8px' }}>TRATAMIENTO</Text>
            </View>
            <View style={{ display: 4, width: '80px', margin: '2px', bgcolor: "yellow" }}>
              <Text style={{ fontSize: '8px', textAlign: 'center' }}>CONTROL</Text>
            </View>
            <View style={{ display: 5, width: '100px', margin: '2px' }}>
              <Text style={{ fontSize: '8px', textAlign: 'right' }}>CONTADO</Text>
            </View>
            <View style={{ display: 6, width: '100px', margin: '2px' }}>
              <Text style={{ fontSize: '8px', textAlign: 'right' }}>TARJETA</Text>
            </View>
            <View style={{ display: 7, width: '100px', margin: '2px' }}>
              <Text style={{ fontSize: '8px', textAlign: 'right' }}>DEPOSITO</Text>
            </View>
            <View style={{ display: 8, width: '110px', margin: '2px' }}>
              <Text style={{ fontSize: '8px', textAlign: 'center' }}>FECHA</Text>
            </View>
            <View style={{ display: 9, width: '210px', margin: '2px' }}>
              <Text style={{ fontSize: '8px' }}>FAMILIA</Text>
            </View>
          </View>
          {datosGeneral.length !== 0 &&
            datosGeneral.map((m, index) => (
              <div key={index}>
                <View key={index} style={{ display: 'flex', flexDirection: 'row', backgroundColor: '#EBF8FF', padding: estilosTablaPdf.padding }}>
                  <View style={{ display: 1, width: '5px', margin: '2px' }}>
                    <Text style={{ fontSize: '8pt' }}></Text>
                  </View>
                  <View style={{ display: 2, width: '320px', margin: '2px' }}>
                    <Text style={{ fontSize: '8pt' }}>{m.paciente}</Text>
                  </View>
                  <View style={{ display: 3, width: '230px', margin: '2px' }}>
                    <Text style={{ fontSize: '8pt' }}>{m.tratamiento}</Text>
                  </View>
                  <View style={{ display: 4, width: '80px', margin: '2px', bgcolor: "red" }}>
                    <Text style={{ fontSize: '8pt', textAlign: 'center' }}>{m.controles}</Text>
                  </View>
                  <View style={{ display: 5, width: '100px', margin: '2px' }}>
                    <Text style={{ fontSize: '8pt', textAlign: 'right' }}>{m.efectivo}</Text>
                  </View>
                  <View style={{ display: 6, width: '100px', margin: '2px' }}>
                    <Text style={{ fontSize: '8pt', textAlign: 'right' }}>{m.tarjeta}</Text>
                  </View>
                  <View style={{ display: 7, width: '100px', margin: '2px' }}>
                    <Text style={{ fontSize: '8pt', textAlign: 'right' }}>{m.deposito}</Text>
                  </View>
                  <View style={{ display: 8, width: '110px', margin: '2px' }}>
                    <Text style={{ fontSize: '8pt', textAlign: 'center' }}>{m.fecha}</Text>
                  </View>
                  <View style={{ display: 9, width: '210px', margin: '2px' }}>
                    <Text style={{ fontSize: '8pt' }}>{m.familia}</Text>
                  </View>
                  <br />
                </View>
              </div>
            ))
          }
          <Text style={{ fontSize: 8, left: 40, right: 0, textAlign: 'left', bottom: 30, position: 'absolute', color: 'grey', }} fixed>Fecha: {new Date().toJSON().slice(0, 10)}</Text>
          <Text style={{ fontSize: 8, left: 130, right: 0, textAlign: 'left', bottom: 30, position: 'absolute', color: 'grey', }} fixed>Hora: {new Date().toLocaleTimeString()}</Text>
          <Text style={styles.pageNumber} render={({ pageNumber, totalPages }) => (
            `Pag ${pageNumber}`
          )} fixed />
        </Page>
      }
      {tipo === "resReg" && paciente !== "" &&
        // ===========por pacientes ===========
        <Page style={styles.body} orientation="landscape">
          <View style={{ display: 'fixed', flexDirection: 'row', paddingBottom: "10px" }} fixed>
            <View style={{ display: 1 }}>
              <Image
                style={{ width: '55px', height: '50px', padding: "0 5px" }}
                src={Logo}
              />
            </View>
            <View style={{ display: 2, marginHorizontal: '15px', width: '60%' }} >
              <Text style={{ fontSize: '16px' }}>{datosEmpresa.razon_social}</Text>
              <Text style={{ fontSize: '10px', marginTop: '5px' }}>{datosEmpresa.ruc}</Text>
              <Text style={{ fontSize: '10px', marginTop: '5px' }}>{"Av. Demo Mz. Demo"}</Text>
              {/* <Text style={{ fontSize: '10px', marginTop: '5px' }}>{datosEmpresa.direccion}</Text> */}
            </View>
            <View style={{ display: 3 }}>
              <Text style={{ fontSize: '16px' }}>REPORTE RESUMEN REGISTROS</Text>
              <View style={{ display: 'flex', flexDirection: 'row', padding: "10px 0" }}>
                <View style={{ display: 1, width: '250px', margin: '2px' }}>
                  <Text style={{ fontSize: '10px' }}>PACIENTE: {paciente}</Text>
                </View>
                <View style={{ display: 2, width: '50px', margin: '2px' }}>
                  <Text style={{ fontSize: '10px' }}></Text>
                </View>
              </View>
            </View>
          </View>
          {/* <Text style={{ marginTop: '25px', marginBottom: '10px', fontSize: '10px' }}>TRABAJADOR: {}</Text> */}
          <View style={{ display: 'flex', flexDirection: 'row', color: '#fff', backgroundColor: '#1976D2', padding: "4px 0" }} fixed>
            <View style={{ display: 1, width: '10px', margin: '2px' }}>
              <Text style={{ fontSize: '8px' }}></Text>
            </View>
            <View style={{ display: 2, width: '230px', margin: '2px' }}>
              <Text style={{ fontSize: '8px' }}>TRATAMIENTO</Text>
            </View>
            <View style={{ display: 3, width: '100px', margin: '2px' }}>
              <Text style={{ fontSize: '8px', textAlign: 'center' }}>CONTROL</Text>
            </View>
            <View style={{ display: 4, width: '100px', margin: '2px' }}>
              <Text style={{ fontSize: '8px', textAlign: 'right' }}>CONTADO</Text>
            </View>
            <View style={{ display: 5, width: '100px', margin: '2px' }}>
              <Text style={{ fontSize: '8px', textAlign: 'right' }}>TARJETA</Text>
            </View>
            <View style={{ display: 6, width: '100px', margin: '2px' }}>
              <Text style={{ fontSize: '8px', textAlign: 'right' }}>DEPOSITO</Text>
            </View>
            <View style={{ display: 7, width: '110px', margin: '2px' }}>
              <Text style={{ fontSize: '8px', textAlign: 'center' }}>FECHA</Text>
            </View>
            <View style={{ display: 8, width: '210px', margin: '2px' }}>
              <Text style={{ fontSize: '8px' }}>FAMILIA</Text>
            </View>
          </View>
          {datosGeneral.length !== 0 &&
            datosGeneral.map((m, index) => (
              <div key={index}>
                <View key={index} style={{ display: 'flex', flexDirection: 'row', backgroundColor: '#EBF8FF', padding: estilosTablaPdf.padding }}>
                  <View style={{ display: 1, width: '10px', margin: '2px' }}>
                    <Text style={{ fontSize: '8pt' }}></Text>
                  </View>
                  <View style={{ display: 2, width: '230px', margin: '2px' }}>
                    <Text style={{ fontSize: '8pt' }}>{m.tratamiento}</Text>
                  </View>
                  <View style={{ display: 3, width: '100px', margin: '2px' }}>
                    <Text style={{ fontSize: '8pt', textAlign: 'center' }}>{m.controles}</Text>
                  </View>
                  <View style={{ display: 4, width: '100px', margin: '2px' }}>
                    <Text style={{ fontSize: '8pt', textAlign: 'right' }}>S/ {m.efectivo}</Text>
                  </View>
                  <View style={{ display: 5, width: '100px', margin: '2px' }}>
                    <Text style={{ fontSize: '8pt', textAlign: 'right' }}>S/ {m.tarjeta}</Text>
                  </View>
                  <View style={{ display: 6, width: '100px', margin: '2px' }}>
                    <Text style={{ fontSize: '8pt', textAlign: 'right' }}>S/ {m.deposito}</Text>
                  </View>
                  <View style={{ display: 7, width: '110px', margin: '2px' }}>
                    <Text style={{ fontSize: '8pt', textAlign: 'center' }}>{m.fecha}</Text>
                  </View>
                  <View style={{ display: 8, width: '210px', margin: '2px' }}>
                    <Text style={{ fontSize: '8pt' }}>{m.familia}</Text>
                  </View>
                  <br />
                </View>
              </div>
            ))
          }
          <Text style={{ fontSize: 8, left: 40, right: 0, textAlign: 'left', bottom: 30, position: 'absolute', color: 'grey', }} fixed>Fecha: {new Date().toJSON().slice(0, 10)}</Text>
          <Text style={{ fontSize: 8, left: 130, right: 0, textAlign: 'left', bottom: 30, position: 'absolute', color: 'grey', }} fixed>Hora: {new Date().toLocaleTimeString()}</Text>
          <Text style={styles.pageNumber} render={({ pageNumber, totalPages }) => (
            `Pag ${pageNumber}`
          )} fixed />
        </Page>
      }
      {tipo === "ventgastxanho" &&
        <>
          <Page style={styles.body} orientation="portrait">
            <View style={{ display: 'fixed', flexDirection: 'row', paddingBottom: "10px" }} fixed>
              <View style={{ display: 1 }}>
                <Image
                  style={{ width: '55px', height: '50px', padding: "0 5px" }}
                  src={Logo}
                />
              </View>
              <View style={{ display: 2, marginHorizontal: '15px', width: '50%' }} >
                <Text style={{ fontSize: '15px' }}>{datosEmpresa.razon_social}</Text>
                <Text style={{ fontSize: '10px', marginTop: '5px' }}>{datosEmpresa.ruc}</Text>
                <Text style={{ fontSize: '10px', marginTop: '5px' }}>{"Av. Demo Mz. Demo"}</Text>
                {/* <Text style={{ fontSize: '10px', marginTop: '5px' }}>{datosEmpresa.direccion}</Text> */}
              </View>
              <View style={{ display: 3 }}>
                <Text style={{ fontSize: '15px' }}>REPORTE VENTAS Y GASTOS</Text>
                <View style={{ display: 'flex', flexDirection: 'row', padding: "10px 0" }}>
                  <View style={{ display: 1, width: '100px', margin: '2px' }}>
                    <Text style={{ fontSize: '10px' }}></Text>
                  </View>
                  <View style={{ display: 2, width: '150px', margin: '2px' }}>
                    <Text style={{ fontSize: '10px' }}>AÑO:  {dataFechas.ventaAnho}</Text>
                  </View>
                </View>
              </View>
            </View>
            {/* <Text style={{ marginTop: '25px', marginBottom: '10px', fontSize: '10px' }}>TRABAJADOR: {}</Text> */}
            <View style={{ display: 'flex', flexDirection: 'row', color: '#fff', backgroundColor: '#1976D2', padding: "4px 0" }} fixed>
              <View style={{ display: 1, width: '10px', margin: '2px' }}>
                <Text style={{ fontSize: '8px' }}></Text>
              </View>
              <View style={{ display: 2, width: '90px', margin: '2px' }}>
                <Text style={{ fontSize: '8px' }}>MES</Text>
              </View>
              <View style={{ display: 3, width: '130px', margin: '2px' }}>
                <Text style={{ fontSize: '8px', textAlign: 'right' }}>VENTAS</Text>
              </View>
              <View style={{ display: 4, width: '100px', margin: '2px' }}>
                <Text style={{ fontSize: '8px', textAlign: 'right' }}>CREC %</Text>
              </View>
              <View style={{ display: 5, width: '100px', margin: '2px' }}>
                <Text style={{ fontSize: '8px', textAlign: 'right' }}>GASTOS</Text>
              </View>
              <View style={{ display: 6, width: '100px', margin: '2px' }}>
                <Text style={{ fontSize: '8px', textAlign: 'right' }}>CREC %</Text>
              </View>
              <View style={{ display: 7, width: '100px', margin: '2px' }}>
                <Text style={{ fontSize: '8px', textAlign: 'right' }}>RESUMEN</Text>
              </View>
              <View style={{ display: 8, width: '110px', margin: '2px' }}>
                <Text style={{ fontSize: '8px', textAlign: 'right' }}>CREC %</Text>
              </View>
              <View style={{ display: 9, width: '10px', margin: '2px' }}>
                <Text style={{ fontSize: '8px' }}></Text>
              </View>
            </View>
            {datosGeneral.length !== 0 &&
              datosGeneral.map((m, index) => (
                <div key={index}>
                  <View key={index} style={{ display: 'flex', flexDirection: 'row', backgroundColor: '#EBF8FF', padding: estilosTablaPdf.padding }}>
                    <View style={{ display: 1, width: '10px', margin: '2px' }}>
                      <Text style={{ fontSize: '8pt' }}></Text>
                    </View>
                    <View style={{ display: 2, width: '90px', margin: '2px' }}>
                      <Text style={{ fontSize: '8pt' }}>{m.periodo}</Text>
                    </View>
                    <View style={{ display: 3, width: '130px', margin: '2px' }}>
                      <Text style={{ fontSize: '8pt', textAlign: 'right' }}>S/ {m.ventas}</Text>
                    </View>
                    <View style={{ display: 4, width: '100px', margin: '2px' }}>
                      {m.crec_ventas < 0 ?
                        <Text style={{ fontSize: '8pt', textAlign: 'right', color: "red" }} >{m.crec_ventas} %</Text> :
                        <Text style={{ fontSize: '8pt', textAlign: 'right' }} >{m.crec_ventas} %</Text>}
                    </View>
                    <View style={{ display: 5, width: '100px', margin: '2px' }}>
                      <Text style={{ fontSize: '8pt', textAlign: 'right' }}>S/ {m.gastos}</Text>
                    </View>
                    <View style={{ display: 6, width: '100px', margin: '2px' }}>
                      {m.crec_gastos < 0 ?
                        <Text style={{ fontSize: '8pt', textAlign: 'right', color: "red" }} >{m.crec_gastos}%</Text> :
                        <Text style={{ fontSize: '8pt', textAlign: 'right' }}>{m.crec_gastos} %</Text>}
                    </View>
                    <View style={{ display: 7, width: '100px', margin: '2px' }}>
                      <Text style={{ fontSize: '8pt', textAlign: 'right' }}>S/ {m.resumen}</Text>
                    </View>
                    <View style={{ display: 8, width: '110px', margin: '2px' }}>
                      {m.crec_resumen < 0 ?
                        <Text style={{ fontSize: '8pt', textAlign: 'right', color: "red" }} >{m.crec_resumen} %</Text> :
                        <Text style={{ fontSize: '8pt', textAlign: 'right' }}>{m.crec_resumen} %</Text>}
                    </View>
                    <View style={{ display: 9, width: '10px', margin: '2px' }}>
                      <Text style={{ fontSize: '8pt' }}></Text>
                    </View>
                    <br />
                  </View>
                </div>
              ))
            }

            <Text style={{ fontSize: 8, left: 40, right: 0, textAlign: 'left', bottom: 30, position: 'absolute', color: 'grey', }} fixed>Fecha: {new Date().toJSON().slice(0, 10)}</Text>
            <Text style={{ fontSize: 8, left: 130, right: 0, textAlign: 'left', bottom: 30, position: 'absolute', color: 'grey', }} fixed>Hora: {new Date().toLocaleTimeString()}</Text>
            <Text style={styles.pageNumber} render={({ pageNumber, totalPages }) => (
              `Pag ${pageNumber}`
            )} fixed />
          </Page>

          {
            imgGrafico !== null &&
            <Page style={styles.body} orientation="portrait">
              <View style={{ display: 'fixed', flexDirection: 'row', paddingBottom: "10px" }} fixed>
                <View style={{ display: 1 }}>
                  <Image
                    style={{ width: '55px', height: '50px', padding: "0 5px" }}
                    src={Logo}
                  />
                </View>
                <View style={{ display: 2, marginHorizontal: '15px', width: '45%' }} >
                  <Text style={{ fontSize: '15px' }}>{datosEmpresa.razon_social}</Text>
                  <Text style={{ fontSize: '10px', marginTop: '5px' }}>{datosEmpresa.ruc}</Text>
                  <Text style={{ fontSize: '10px', marginTop: '5px' }}>{"Av. Demo Mz. Demo"}</Text>
                </View>
                <View style={{ display: 3 }}>
                  <Text style={{ fontSize: '15px' }}>REPORTE VENTAS Y GASTOS</Text>
                  <View style={{ display: 'flex', flexDirection: 'row', padding: "10px 0" }}>
                    <View style={{ display: 1, width: '45px', margin: '2px' }}>
                      <Text style={{ fontSize: '10px' }}></Text>
                    </View>
                    <View style={{ display: 2, width: '150px', margin: '2px' }}>
                      <Text style={{ fontSize: '10px' }}>EN GRAFICO DE BARRAS</Text>
                    </View>
                  </View>
                </View>
              </View>

              <View style={{ display: 'fixed', flexDirection: 'row', paddingTop: "10px" }} fixed>
                <View style={{ display: 1, textAlign: "center" }}>
                  <Image
                    style={{ width: '500px', height: '300px', padding: "0 5px" }}
                    src={imgGrafico}
                  />
                </View>
              </View>
              <Text style={{ fontSize: 8, left: 40, right: 0, textAlign: 'left', bottom: 30, position: 'absolute', color: 'grey', }} fixed>Fecha: {new Date().toJSON().slice(0, 10)}</Text>
              <Text style={{ fontSize: 8, left: 130, right: 0, textAlign: 'left', bottom: 30, position: 'absolute', color: 'grey', }} fixed>Hora: {new Date().toLocaleTimeString()}</Text>
              <Text style={styles.pageNumber} render={({ pageNumber, totalPages }) => (
                `Pag ${pageNumber}`
              )} fixed />
            </Page>
          }


        </>
      }
      {tipo === "ventaspormes" &&
        <>
          <Page style={styles.body} orientation="portrait">
            <View style={{ display: 'fixed', flexDirection: 'row', paddingBottom: "10px" }} fixed>
              <View style={{ display: 1 }}>
                <Image
                  style={{ width: '55px', height: '50px', padding: "0 5px" }}
                  src={Logo}
                />
              </View>
              <View style={{ display: 2, marginHorizontal: '15px', width: '40%' }} >
                <Text style={{ fontSize: '15px' }}>{datosEmpresa.razon_social}</Text>
                <Text style={{ fontSize: '10px', marginTop: '5px' }}>{datosEmpresa.ruc}</Text>
                <Text style={{ fontSize: '10px', marginTop: '5px' }}>{"Av. Demo Mz. Demo"}</Text>
              </View>
              <View style={{ display: 3 }}>
                <Text style={{ fontSize: '15px' }}>REPORTES DE VENTAS POR MES</Text>
                <View style={{ display: 'flex', flexDirection: 'row', padding: "10px 0" }}>
                  <View style={{ display: 1, width: '100px', margin: '2px' }}>
                    <Text style={{ fontSize: '10px' }}></Text>
                  </View>
                  <View style={{ display: 2, width: '150px', margin: '2px' }}>
                    {meses.filter(m => m.mes === dataFechas.ventaspormes).map(m => {
                      return <Text key={m.mes} style={{ fontSize: '10px' }}>{m.strMes}</Text>
                    })}
                  </View>


                </View>
              </View>
            </View>
            {/* <Text style={{ marginTop: '25px', marginBottom: '10px', fontSize: '10px' }}>TRABAJADOR: {}</Text> */}
            <View style={{ display: 'flex', flexDirection: 'row', color: '#fff', backgroundColor: '#1976D2', padding: "4px 0" }} fixed>
              <View style={{ display: 1, width: '10px', margin: '2px' }}>
                <Text style={{ fontSize: '8px' }}></Text>
              </View>
              <View style={{ display: 2, width: '90px', margin: '2px' }}>
                <Text style={{ fontSize: '8px' }}>AÑO</Text>
              </View>
              <View style={{ display: 3, width: '130px', margin: '2px' }}>
                <Text style={{ fontSize: '8px', textAlign: 'right' }}>VENTAS</Text>
              </View>
              <View style={{ display: 4, width: '100px', margin: '2px' }}>
                <Text style={{ fontSize: '8px', textAlign: 'right' }}>CREC %</Text>
              </View>
              <View style={{ display: 5, width: '100px', margin: '2px' }}>
                <Text style={{ fontSize: '8px', textAlign: 'right' }}>GASTOS</Text>
              </View>
              <View style={{ display: 6, width: '100px', margin: '2px' }}>
                <Text style={{ fontSize: '8px', textAlign: 'right' }}>CREC %</Text>
              </View>
              <View style={{ display: 7, width: '100px', margin: '2px' }}>
                <Text style={{ fontSize: '8px', textAlign: 'right' }}>RESUMEN</Text>
              </View>
              <View style={{ display: 8, width: '110px', margin: '2px' }}>
                <Text style={{ fontSize: '8px', textAlign: 'right' }}>CREC %</Text>
              </View>
              <View style={{ display: 9, width: '10px', margin: '2px' }}>
                <Text style={{ fontSize: '8pt' }}></Text>
              </View>
            </View>
            {datosGeneral.length !== 0 &&
              datosGeneral.map((m, index) => (
                <div key={index}>
                  <View key={index} style={{ display: 'flex', flexDirection: 'row', backgroundColor: '#EBF8FF', padding: estilosTablaPdf.padding }}>
                    <View style={{ display: 1, width: '10px', margin: '2px' }}>
                      <Text style={{ fontSize: '8pt' }}></Text>
                    </View>
                    <View style={{ display: 2, width: '90px', margin: '2px' }}>
                      <Text style={{ fontSize: '8pt' }}>{m.anho}</Text>
                    </View>
                    <View style={{ display: 3, width: '130px', margin: '2px' }}>
                      <Text style={{ fontSize: '8pt', textAlign: 'right' }}>S/ {m.ventas}</Text>
                    </View>
                    <View style={{ display: 4, width: '100px', margin: '2px' }}>
                      {m.crec_ventas < 0 ?
                        <Text style={{ fontSize: '8pt', textAlign: 'right', color: "red" }} >{m.crec_ventas}%</Text> :
                        <Text style={{ fontSize: '8pt', textAlign: 'right' }}>{m.crec_ventas} %</Text>}
                    </View>
                    <View style={{ display: 5, width: '100px', margin: '2px' }}>
                      <Text style={{ fontSize: '8pt', textAlign: 'right' }}>S/ {m.gastos}</Text>
                    </View>
                    <View style={{ display: 6, width: '100px', margin: '2px' }}>
                      {m.crec_gastos < 0 ?
                        <Text style={{ fontSize: '8pt', textAlign: 'right', color: "red" }} >{m.crec_gastos}%</Text> :
                        <Text style={{ fontSize: '8pt', textAlign: 'right' }}>{m.crec_gastos} %</Text>}
                    </View>
                    <View style={{ display: 7, width: '100px', margin: '2px' }}>
                      <Text style={{ fontSize: '8pt', textAlign: 'right' }}>S/ {m.resumen}</Text>
                    </View>
                    <View style={{ display: 8, width: '110px', margin: '2px' }}>
                      {m.crec_resumen < 0 ?
                        <Text style={{ fontSize: '8pt', textAlign: 'right', color: "red" }} >{m.crec_resumen}%</Text> :
                        <Text style={{ fontSize: '8pt', textAlign: 'right' }}>{m.crec_resumen} %</Text>}
                    </View>
                    <View style={{ display: 9, width: '10px', margin: '2px' }}>
                      <Text style={{ fontSize: '8pt' }}></Text>
                    </View>
                    <br />
                  </View>
                </div>
              ))
            }
            <Text style={{ fontSize: 8, left: 40, right: 0, textAlign: 'left', bottom: 30, position: 'absolute', color: 'grey', }} fixed>Fecha: {new Date().toJSON().slice(0, 10)}</Text>
            <Text style={{ fontSize: 8, left: 130, right: 0, textAlign: 'left', bottom: 30, position: 'absolute', color: 'grey', }} fixed>Hora: {new Date().toLocaleTimeString()}</Text>
            <Text style={styles.pageNumber} render={({ pageNumber, totalPages }) => (
              `Pag ${pageNumber}`
            )} fixed />
          </Page>
          {
            imgGrafico !== null &&
            <Page style={styles.body} orientation="portrait">
              <View style={{ display: 'fixed', flexDirection: 'row', paddingBottom: "10px" }} fixed>
                <View style={{ display: 1 }}>
                  <Image
                    style={{ width: '55px', height: '50px', padding: "0 5px" }}
                    src={Logo}
                  />
                </View>
                <View style={{ display: 2, marginHorizontal: '15px', width: '60%' }} >
                  <Text style={{ fontSize: '15px' }}>{datosEmpresa.razon_social}</Text>
                  <Text style={{ fontSize: '10px', marginTop: '5px' }}>{datosEmpresa.ruc}</Text>
                  <Text style={{ fontSize: '10px', marginTop: '5px' }}>{"Av. Demo Mz. Demo"}</Text>
                </View>
                <View style={{ display: 3 }}>
                  <Text style={{ fontSize: '15px' }}>REPORTE POR MES</Text>
                  <View style={{ display: 'flex', flexDirection: 'row', padding: "10px 0" }}>
                    <View style={{ display: 1, width: '5px', margin: '2px' }}>
                      <Text style={{ fontSize: '10px' }}></Text>
                    </View>
                    <View style={{ display: 1, width: '150px', margin: '2px' }}>
                      <Text style={{ fontSize: '10px' }}>EN GRAFICO DE BARRAS</Text>
                    </View>
                  </View>
                </View>
              </View>

              <View style={{ display: 'fixed', flexDirection: 'row', paddingTop: "10px" }} fixed>
                <View style={{ display: 1, textAlign: "center" }}>
                  <Image
                    style={{ width: '500px', height: '300px', padding: "0 5px" }}
                    src={imgGrafico}
                  />
                </View>
              </View>
              <Text style={{ fontSize: 8, left: 40, right: 0, textAlign: 'left', bottom: 30, position: 'absolute', color: 'grey', }} fixed>Fecha: {new Date().toJSON().slice(0, 10)}</Text>
              <Text style={{ fontSize: 8, left: 130, right: 0, textAlign: 'left', bottom: 30, position: 'absolute', color: 'grey', }} fixed>Hora: {new Date().toLocaleTimeString()}</Text>
              <Text style={styles.pageNumber} render={({ pageNumber, totalPages }) => (
                `Pag ${pageNumber}`
              )} fixed />
            </Page>
          }
        </>
      }
      {tipo === "resumenporanho" &&
        <>
          <Page style={styles.body} orientation="portrait">
            <View style={{ display: 'fixed', flexDirection: 'row', paddingBottom: "10px" }} fixed>
              <View style={{ display: 1 }}>
                <Image
                  style={{ width: '55px', height: '50px', padding: "0 5px" }}
                  src={Logo}
                />
              </View>
              <View style={{ display: 2, marginHorizontal: '15px', width: '45%' }} >
                <Text style={{ fontSize: '15px' }}>{datosEmpresa.razon_social}</Text>
                <Text style={{ fontSize: '10px', marginTop: '5px' }}>{datosEmpresa.ruc}</Text>
                <Text style={{ fontSize: '10px', marginTop: '5px' }}>{"Av. Demo Mz. Demo"}</Text>
                {/* <Text style={{ fontSize: '10px', marginTop: '5px' }}>{datosEmpresa.direccion}</Text> */}
              </View>
              <View style={{ display: 3 }}>
                <Text style={{ fontSize: '15px' }}>REPORTE RESUMEN POR AÑO</Text>
                <View style={{ display: 'flex', flexDirection: 'row', padding: "10px 0" }}>

                  <View style={{ display: 1, width: '100px', margin: '2px' }}>
                    <Text style={{ fontSize: '10px' }}></Text>
                  </View>
                  <View style={{ display: 2, width: '150px', margin: '2px' }}>
                    <Text style={{ fontSize: '10px' }}>GENERAL</Text>
                  </View>
                </View>
              </View>
            </View>
            {/* <Text style={{ marginTop: '25px', marginBottom: '10px', fontSize: '10px' }}>TRABAJADOR: {}</Text> */}
            <View style={{ display: 'flex', flexDirection: 'row', color: '#fff', backgroundColor: '#1976D2', padding: "4px 0" }} fixed>
              <View style={{ display: 1, width: '90px', margin: '2px' }}>
                <Text style={{ fontSize: '8px', textAlign: 'center' }}>AÑO</Text>
              </View>
              <View style={{ display: 2, width: '130px', margin: '2px' }}>
                <Text style={{ fontSize: '8px', textAlign: 'right' }}>VENTAS</Text>
              </View>
              <View style={{ display: 3, width: '100px', margin: '2px' }}>
                <Text style={{ fontSize: '8px', textAlign: 'right' }}>CREC %</Text>
              </View>
              <View style={{ display: 4, width: '100px', margin: '2px' }}>
                <Text style={{ fontSize: '8px', textAlign: 'right' }}>GASTOS</Text>
              </View>
              <View style={{ display: 5, width: '100px', margin: '2px' }}>
                <Text style={{ fontSize: '8px', textAlign: 'right' }}>CREC %</Text>
              </View>
              <View style={{ display: 6, width: '100px', margin: '2px' }}>
                <Text style={{ fontSize: '8px', textAlign: 'right' }}>RESUMEN</Text>
              </View>
              <View style={{ display: 7, width: '110px', margin: '2px' }}>
                <Text style={{ fontSize: '8px', textAlign: 'right' }}>CREC %</Text>
              </View>
              <View style={{ display: 8, width: '10px', margin: '2px' }}>
                <Text style={{ fontSize: '8px' }}></Text>
              </View>
            </View>

            {datosGeneral.length !== 0 &&
              datosGeneral.map((m, index) => (
                <div key={index}>
                  <View key={index} style={{ display: 'flex', flexDirection: 'row', backgroundColor: '#EBF8FF', padding: estilosTablaPdf.padding }}>
                    <View style={{ display: 1, width: '90px', margin: '2px' }}>
                      <Text style={{ fontSize: '8pt', textAlign: 'center' }}>{m.anho}</Text>
                    </View>
                    <View style={{ display: 2, width: '130px', margin: '2px' }}>
                      <Text style={{ fontSize: '8pt', textAlign: 'right' }}>S/ {m.ventas}</Text>
                    </View>
                    <View style={{ display: 3, width: '100px', margin: '2px' }}>

                      {m.crec_ventas < 0 ?
                        <Text style={{ fontSize: '8pt', textAlign: 'right', color: "red" }} >{m.crec_ventas}%</Text> :
                        <Text style={{ fontSize: '8pt', textAlign: 'right' }}>{m.crec_ventas} %</Text>}
                    </View>
                    <View style={{ display: 4, width: '100px', margin: '2px' }}>
                      <Text style={{ fontSize: '8pt', textAlign: 'right' }}>S/ {m.gastos}</Text>
                    </View>
                    <View style={{ display: 5, width: '100px', margin: '2px' }}>
                      {m.crec_gastos < 0 ?
                        <Text style={{ fontSize: '8pt', textAlign: 'right', color: "red" }} >{m.crec_gastos}%</Text> :
                        <Text style={{ fontSize: '8pt', textAlign: 'right' }}>{m.crec_gastos} %</Text>}
                    </View>
                    <View style={{ display: 6, width: '100px', margin: '2px' }}>
                      <Text style={{ fontSize: '8pt', textAlign: 'right' }}>S/ {m.resumen}</Text>
                    </View>
                    <View style={{ display: 7, width: '110px', margin: '2px' }}>
                      {m.crec_resumen < 0 ?
                        <Text style={{ fontSize: '8pt', textAlign: 'right', color: "red" }} >{m.crec_resumen}%</Text> :
                        <Text style={{ fontSize: '8pt', textAlign: 'right' }}>{m.crec_resumen} %</Text>}
                    </View>
                    <View style={{ display: 8, width: '10px', margin: '2px' }}>
                      <Text style={{ fontSize: '8px' }}></Text>
                    </View>
                    <br />
                  </View>
                </div>
              ))
            }
            <Text style={{ fontSize: 8, left: 40, right: 0, textAlign: 'left', bottom: 30, position: 'absolute', color: 'grey', }} fixed>Fecha: {new Date().toJSON().slice(0, 10)}</Text>
            <Text style={{ fontSize: 8, left: 130, right: 0, textAlign: 'left', bottom: 30, position: 'absolute', color: 'grey', }} fixed>Hora: {new Date().toLocaleTimeString()}</Text>
            <Text style={styles.pageNumber} render={({ pageNumber, totalPages }) => (
              `Pag ${pageNumber}`
            )} fixed />
          </Page>
          {
            imgGrafico !== null &&
            <Page style={styles.body} orientation="portrait">
              <View style={{ display: 'fixed', flexDirection: 'row', paddingBottom: "10px" }} fixed>
                <View style={{ display: 1 }}>
                  <Image
                    style={{ width: '55px', height: '50px', padding: "0 5px" }}
                    src={Logo}
                  />
                </View>
                <View style={{ display: 2, marginHorizontal: '15px', width: '40%' }} >
                  <Text style={{ fontSize: '15px' }}>{datosEmpresa.razon_social}</Text>
                  <Text style={{ fontSize: '10px', marginTop: '5px' }}>{datosEmpresa.ruc}</Text>
                  <Text style={{ fontSize: '10px', marginTop: '5px' }}>{"Av. Demo Mz. Demo"}</Text>
                </View>
                <View style={{ display: 3 }}>
                  <Text style={{ fontSize: '15px' }}>REPORTE RESUMEN POR AÑO</Text>
                  <View style={{ display: 'flex', flexDirection: 'row', padding: "10px 0" }}>
                    <View style={{ display: 1, width: '50px', margin: '2px' }}>
                      <Text style={{ fontSize: '10px' }}></Text>
                    </View>
                    <View style={{ display: 1, width: '150px', margin: '2px' }}>
                      <Text style={{ fontSize: '10px' }}>EN GRAFICO DE LINEAS</Text>
                    </View>
                  </View>
                </View>
              </View>

              <View style={{ display: 'fixed', flexDirection: 'row', paddingTop: "10px" }} fixed>
                <View style={{ display: 1, textAlign: "center" }}>
                  <Image
                    style={{ width: '500px', height: '300px', padding: "0 5px" }}
                    src={imgGrafico}
                  />
                </View>
              </View>
              <Text style={{ fontSize: 8, left: 40, right: 0, textAlign: 'left', bottom: 30, position: 'absolute', color: 'grey', }} fixed>Fecha: {new Date().toJSON().slice(0, 10)}</Text>
              <Text style={{ fontSize: 8, left: 130, right: 0, textAlign: 'left', bottom: 30, position: 'absolute', color: 'grey', }} fixed>Hora: {new Date().toLocaleTimeString()}</Text>
              <Text style={styles.pageNumber} render={({ pageNumber, totalPages }) => (
                `Pag ${pageNumber}`
              )} fixed />
            </Page>
          }
        </>
      }
      {tipo === "resumentipopago" &&
        <>
          <Page style={styles.body} orientation="portrait">
            <View style={{ display: 'fixed', flexDirection: 'row', paddingBottom: "10px" }} fixed>
              <View style={{ display: 1 }}>
                <Image
                  style={{ width: '55px', height: '50px', padding: "0 5px" }}
                  src={Logo}
                />
              </View>
              <View style={{ display: 2, marginHorizontal: '15px', width: '38%' }} >
                <Text style={{ fontSize: '15px' }}>{datosEmpresa.razon_social}</Text>
                <Text style={{ fontSize: '10px', marginTop: '5px' }}>{datosEmpresa.ruc}</Text>
                <Text style={{ fontSize: '10px', marginTop: '5px' }}>{"Av. Demo Mz. Demo"}</Text>
                {/* <Text style={{ fontSize: '10px', marginTop: '5px' }}>{datosEmpresa.direccion}</Text> */}
              </View>
              <View style={{ display: 3 }}>
                <Text style={{ fontSize: '15px' }}>REPORTE RESUMEN TIPO DE PAGO</Text>
                <View style={{ display: 'flex', flexDirection: 'row', padding: "10px 0" }}>
                  <View style={{ display: 1, width: '100px', margin: '2px' }}>
                    <Text style={{ fontSize: '10px' }}></Text>
                  </View>
                  <View style={{ display: 2, width: '150px', margin: '2px' }}>
                    <Text style={{ fontSize: '10px' }}>AÑO: {dataFechas.tipopagoanho}</Text>
                  </View>

                </View>
              </View>
            </View>
            {/* <Text style={{ marginTop: '25px', marginBottom: '10px', fontSize: '10px' }}>TRABAJADOR: {}</Text> */}
            <View style={{ display: 'flex', flexDirection: 'row', color: '#fff', backgroundColor: '#1976D2', padding: "4px 0" }} fixed>
              <View style={{ display: 1, width: '15px', margin: '2px' }}>
                <Text style={{ fontSize: '8px' }}></Text>
              </View>
              <View style={{ display: 2, width: '90px', margin: '2px' }}>
                <Text style={{ fontSize: '8px' }}>MES</Text>
              </View>
              <View style={{ display: 3, width: '130px', margin: '2px' }}>
                <Text style={{ fontSize: '8px', textAlign: 'right' }}>CONTADO</Text>
              </View>
              <View style={{ display: 4, width: '100px', margin: '2px' }}>
                <Text style={{ fontSize: '8px', textAlign: 'right' }}>POS</Text>
              </View>
              <View style={{ display: 5, width: '100px', margin: '2px' }}>
                <Text style={{ fontSize: '8px', textAlign: 'right' }}>DEP/TRANF</Text>
              </View>
              <View style={{ display: 6, width: '100px', margin: '2px' }}>
                <Text style={{ fontSize: '8px', textAlign: 'right' }}>VENTAS</Text>
              </View>
              <View style={{ display: 7, width: '50px', margin: '2px' }}>
                <Text style={{ fontSize: '8px' }}></Text>
              </View>
            </View>
            {datosGeneral.length !== 0 &&
              datosGeneral.map((m, index) => (
                <div key={index}>
                  <View key={index} style={{ display: 'flex', flexDirection: 'row', backgroundColor: '#EBF8FF', padding: estilosTablaPdf.padding }}>
                    <View style={{ display: 1, width: '15px', margin: '2px' }}>
                      <Text style={{ fontSize: '8pt' }}></Text>
                    </View>
                    <View style={{ display: 2, width: '90px', margin: '2px' }}>
                      <Text style={{ fontSize: '8pt' }}>{m.periodo}</Text>
                    </View>
                    <View style={{ display: 3, width: '130px', margin: '2px' }}>
                      <Text style={{ fontSize: '8pt', textAlign: 'right' }}>S/ {m.efectivo}</Text>
                    </View>
                    <View style={{ display: 4, width: '100px', margin: '2px' }}>
                      <Text style={{ fontSize: '8pt', textAlign: 'right' }}>S/ {m.tarjeta}</Text>
                    </View>
                    <View style={{ display: 5, width: '100px', margin: '2px' }}>
                      <Text style={{ fontSize: '8pt', textAlign: 'right' }}>S/ {m.deposito}</Text>
                    </View>
                    <View style={{ display: 6, width: '100px', margin: '2px' }}>
                      <Text style={{ fontSize: '8pt', textAlign: 'right' }}>S/ {m.total}</Text>
                    </View>
                    <View style={{ display: 7, width: '50px', margin: '2px' }}>
                      <Text style={{ fontSize: '8px' }}></Text>
                    </View>
                    <br />
                  </View>
                </div>
              ))
            }
            <Text style={{ fontSize: 8, left: 40, right: 0, textAlign: 'left', bottom: 30, position: 'absolute', color: 'grey', }} fixed>Fecha: {new Date().toJSON().slice(0, 10)}</Text>
            <Text style={{ fontSize: 8, left: 130, right: 0, textAlign: 'left', bottom: 30, position: 'absolute', color: 'grey', }} fixed>Hora: {new Date().toLocaleTimeString()}</Text>
            <Text style={styles.pageNumber} render={({ pageNumber, totalPages }) => (
              `Pag ${pageNumber}`
            )} fixed />
          </Page>
          {
            imgGrafico !== null &&
            <Page style={styles.body} orientation="portrait">
              <View style={{ display: 'fixed', flexDirection: 'row', paddingBottom: "10px" }} fixed>
                <View style={{ display: 1 }}>
                  <Image
                    style={{ width: '55px', height: '50px', padding: "0 5px" }}
                    src={Logo}
                  />
                </View>
                <View style={{ display: 2, marginHorizontal: '15px', width: '35%' }} >
                  <Text style={{ fontSize: '15px' }}>{datosEmpresa.razon_social}</Text>
                  <Text style={{ fontSize: '10px', marginTop: '5px' }}>{datosEmpresa.ruc}</Text>
                  <Text style={{ fontSize: '10px', marginTop: '5px' }}>{"Av. Demo Mz. Demo"}</Text>
                </View>
                <View style={{ display: 3 }}>
                  <Text style={{ fontSize: '15px' }}>REPORTE RESUMEN TIPO DE PAGO</Text>
                  <View style={{ display: 'flex', flexDirection: 'row', padding: "10px 0" }}>
                    <View style={{ display: 1, width: '50px', margin: '2px' }}>
                      <Text style={{ fontSize: '10px' }}></Text>
                    </View>
                    <View style={{ display: 1, width: '150px', margin: '2px' }}>
                      <Text style={{ fontSize: '10px' }}>EN GRAFICO DE BARRAS</Text>
                    </View>
                  </View>
                </View>
              </View>

              <View style={{ display: 'fixed', flexDirection: 'row', paddingTop: "10px" }} fixed>
                <View style={{ display: 1, textAlign: "center" }}>
                  <Image
                    style={{ width: '500px', height: '300px', padding: "0 5px" }}
                    src={imgGrafico}
                  />
                </View>
              </View>
              <Text style={{ fontSize: 8, left: 40, right: 0, textAlign: 'left', bottom: 30, position: 'absolute', color: 'grey', }} fixed>Fecha: {new Date().toJSON().slice(0, 10)}</Text>
              <Text style={{ fontSize: 8, left: 130, right: 0, textAlign: 'left', bottom: 30, position: 'absolute', color: 'grey', }} fixed>Hora: {new Date().toLocaleTimeString()}</Text>
              <Text style={styles.pageNumber} render={({ pageNumber, totalPages }) => (
                `Pag ${pageNumber}`
              )} fixed />
            </Page>
          }
        </>
      }
      {/* =======exportar reporte de registros por fecha======= */
        tipo === "registrosporfecha" &&
        <Page style={styles.body} orientation="landscape">
          <View style={{ display: 'fixed', flexDirection: 'row', paddingBottom: "10px" }} fixed>
            <View style={{ display: 1 }}>
              <Image
                style={{ width: '55px', height: '50px', padding: "0 5px" }}
                src={Logo}
              />
            </View>
            <View style={{ display: 2, marginHorizontal: '15px', width: '65%' }} >
              <Text style={{ fontSize: '15px' }}>{datosEmpresa.razon_social}</Text>
              <Text style={{ fontSize: '10px', marginTop: '5px' }}>{datosEmpresa.ruc}</Text>
              <Text style={{ fontSize: '10px', marginTop: '5px' }}>{"Av. Demo Mz. Demo"}</Text>
              {/* <Text style={{ fontSize: '10px', marginTop: '5px' }}>{datosEmpresa.direccion}</Text> */}
            </View>
            <View style={{ display: 3 }}>
              <Text style={{ fontSize: '15px' }}>REPORTE DE REGISTROS</Text>
              <View style={{ display: 'flex', flexDirection: 'row', padding: "10px 0" }}>
                <View style={{ display: 1, width: '50px', margin: '2px' }}>
                  <Text style={{ fontSize: '10px' }}></Text>
                </View>
                <View style={{ display: 2, width: '150px', margin: '2px' }}>
                  <Text style={{ fontSize: '10px' }}>FECHA: {dataFechas.fecha}</Text>
                  {/* <Text style={{ fontSize: '10px' }}>FECHA: {"20-20-2022"}</Text> */}
                </View>

              </View>
            </View>
          </View>
          {/* <Text style={{ marginTop: '25px', marginBottom: '10px', fontSize: '10px' }}>TRABAJADOR: {}</Text> */}
          <View style={{ display: 'flex', flexDirection: 'row', color: '#fff', backgroundColor: '#1976D2', padding: "4px 0" }} fixed>
            <View style={{ display: 1, width: '10px', margin: '2px' }}>
              <Text style={{ fontSize: '8px' }}></Text>
            </View>
            <View style={{ display: 2, width: '280px', margin: '2px' }}>
              <Text style={{ fontSize: '8px' }}>PACIENTE</Text>
            </View>
            <View style={{ display: 3, width: '200px', margin: '2px' }}>
              <Text style={{ fontSize: '8px' }}>FAMILIA</Text>
            </View>
            <View style={{ display: 4, width: '210px', margin: '2px' }}>
              <Text style={{ fontSize: '8px' }}>TRATAMIENTO</Text>
            </View>
            <View style={{ display: 5, width: '80px', margin: '2px' }}>
              <Text style={{ fontSize: '8px', textAlign: "center" }}>CONTROL</Text>
            </View>
            <View style={{ display: 6, width: '80px', margin: '2px' }}>
              <Text style={{ fontSize: '8px', textAlign: "right" }}>CONTADO</Text>
            </View>
            <View style={{ display: 7, width: '80px', margin: '2px' }}>
              <Text style={{ fontSize: '8px', textAlign: "right" }}>TARJETA</Text>
            </View>
            <View style={{ display: 8, width: '80px', margin: '2px' }}>
              <Text style={{ fontSize: '8px', textAlign: "right" }}>DEPOSITO</Text>
            </View>
            <View style={{ display: 9, width: '10px', margin: '2px' }}>
              <Text style={{ fontSize: '8px' }}></Text>
            </View>
          </View>
          {datosGeneral.length !== 0 &&
            datosGeneral.map((m, index) => (
              <div key={index}>
                <View key={index} style={{ display: 'flex', flexDirection: 'row', backgroundColor: '#EBF8FF', padding: estilosTablaPdf.padding }}>
                  <View style={{ display: 1, width: '10px', margin: '2px' }}>
                    <Text style={{ fontSize: '8pt' }}></Text>
                  </View>
                  <View style={{ display: 2, width: '280px', margin: '2px' }}>
                    <Text style={{ fontSize: '8pt' }}>{m.paciente}</Text>
                  </View>
                  <View style={{ display: 3, width: '200px', margin: '2px' }}>
                    <Text style={{ fontSize: '8pt' }}>{m.familia}</Text>
                  </View>
                  <View style={{ display: 4, width: '210px', margin: '2px' }}>
                    <Text style={{ fontSize: '8pt' }}>{m.tratamiento}</Text>
                  </View>
                  <View style={{ display: 5, width: '80px', margin: '2px' }}>
                    <Text style={{ fontSize: '8pt', textAlign: "center" }}>{m.controles}</Text>
                  </View>
                  <View style={{ display: 6, width: '80px', margin: '2px' }}>
                    <Text style={{ fontSize: '8pt', textAlign: "right" }}>S/ {m.efectivo}</Text>
                  </View>
                  <View style={{ display: 7, width: '80px', margin: '2px' }}>
                    <Text style={{ fontSize: '8pt', textAlign: "right" }}>S/ {m.tarjeta}</Text>
                  </View>
                  <View style={{ display: 8, width: '80px', margin: '2px' }}>
                    <Text style={{ fontSize: '8pt', textAlign: "right" }}>S/ {m.deposito}</Text>
                  </View>
                  <View style={{ display: 9, width: '10px', margin: '2px' }}>
                    <Text style={{ fontSize: '8px' }}></Text>
                  </View>
                  <br />
                </View>
              </div>
            ))
          }
          <View style={{ display: 'flex', flexDirection: 'row', backgroundColor: '#6FAEEE', padding: estilosTablaPdf.padding, color: "#fff" }}>
            <View style={{ display: 1, width: '490px', margin: '2px' }}>
              <Text style={{ fontSize: '8pt' }}></Text>
            </View>
            <View style={{ display: 2, width: '290px', margin: '2px' }}>
              <Text style={{ fontSize: '8pt' }}>SUBTOTALES</Text>
            </View>
            <View style={{ display: 3, width: '80px', margin: '2px' }}>
              <Text style={{ fontSize: '8pt', textAlign: "right" }}>S/ {datosGeneral.reduce((total, item) => parseFloat(total) + parseFloat(item.efectivo), 0).toFixed(2)}</Text>
            </View>
            <View style={{ display: 4, width: '80px', margin: '2px' }}>
              <Text style={{ fontSize: '8pt', textAlign: "right" }}>S/ {datosGeneral.reduce((total, item) => parseFloat(total) + parseFloat(item.tarjeta), 0).toFixed(2)}</Text>
            </View>
            <View style={{ display: 5, width: '80px', margin: '2px' }}>
              <Text style={{ fontSize: '8pt', textAlign: "right" }}>S/ {datosGeneral.reduce((total, item) => parseFloat(total) + parseFloat(item.deposito), 0).toFixed(2)}</Text>
            </View>
            <View style={{ display: 6, width: '10px', margin: '2px' }}>
              <Text style={{ fontSize: '8px' }}></Text>
            </View>
          </View>
          <View style={{ display: 'flex', flexDirection: 'row', backgroundColor: '#5B9AC8', padding: estilosTablaPdf.padding, color: "#fff" }}>
            <View style={{ display: 1, width: '490px', margin: '2px' }}>
              <Text style={{ fontSize: '8pt' }}></Text>
            </View>
            <View style={{ display: 2, width: '290px', margin: '2px' }}>
              <Text style={{ fontSize: '8pt' }}>TOTAL</Text>
            </View>
            <View style={{ display: 3, width: '80px', margin: '2px' }}>
              <Text style={{ fontSize: '8pt' }}></Text>
            </View>
            <View style={{ display: 4, width: '80px', margin: '2px' }}>
              <Text style={{ fontSize: '8pt', textAlign: "right" }}>S/ {datosGeneral.reduce((total, item) => parseFloat(total) + parseFloat(item.deposito) + parseFloat(item.efectivo) + parseFloat(item.tarjeta), 0).toFixed(2)}</Text>
            </View>
            <View style={{ display: 5, width: '80px', margin: '2px' }}>
              <Text style={{ fontSize: '8pt' }}></Text>
            </View>
            <View style={{ display: 6, width: '10px', margin: '2px' }}>
              <Text style={{ fontSize: '8px' }}></Text>
            </View>
          </View>

          <Text style={{ fontSize: 8, left: 40, right: 0, textAlign: 'left', bottom: 30, position: 'absolute', color: 'grey', }} fixed>Fecha: {new Date().toJSON().slice(0, 10)}</Text>
          <Text style={{ fontSize: 8, left: 130, right: 0, textAlign: 'left', bottom: 30, position: 'absolute', color: 'grey', }} fixed>Hora: {new Date().toLocaleTimeString()}</Text>
          <Text style={styles.pageNumber} render={({ pageNumber, totalPages }) => (
            `Pag ${pageNumber}`
          )} fixed />
        </Page>
      }

      {/* =======exportar reporte Brackets======= */
        tipo === "PREB" &&
        <Page style={styles.body} orientation="portrait">
          <View style={{ display: 'fixed', flexDirection: 'row', paddingBottom: "10px" }} fixed>
            <View style={{ display: 1 }}>
              <Image
                style={{ width: '55px', height: '50px', padding: "0 5px" }}
                src={Logo}
              />
            </View>
            <View style={{ display: 2, marginHorizontal: '15px', width: '65%' }} >
              <Text style={{ fontSize: '15px' }}>{datosEmpresa.razon_social}</Text>
              <Text style={{ fontSize: '10px', marginTop: '5px' }}>{datosEmpresa.ruc}</Text>
              <Text style={{ fontSize: '10px', marginTop: '5px' }}>{"Av. Demo Mz. Demo"}</Text>
              {/* <Text style={{ fontSize: '10px', marginTop: '5px' }}>{datosEmpresa.direccion}</Text> */}
            </View>
            <View style={{ display: 3 }}>
              <Text style={{ fontSize: '15px' }}>REPORTE TRATAMIENTO </Text>
              <View style={{ display: 'flex', flexDirection: 'row', padding: "10px 0" }}>
                <View style={{ display: 1, width: '80px', margin: '2px' }}>
                  <Text style={{ fontSize: '10px' }}></Text>
                </View>
                <View style={{ display: 2, width: '150px', margin: '2px' }}>
                  <Text style={{ fontSize: '10px' }}>PRE-BRACKEO</Text>
                </View>
              </View>
            </View>
          </View>
          {/* <Text style={{ marginTop: '25px', marginBottom: '10px', fontSize: '10px' }}>TRABAJADOR: {}</Text> */}
          <View style={{ display: 'flex', flexDirection: 'row', color: '#fff', backgroundColor: '#1976D2', padding: "4px 0" }} fixed>
            <View style={{ display: 1, width: '10px', margin: '2px' }}>
              <Text style={{ fontSize: '8px' }}></Text>
            </View>
            <View style={{ display: 2, width: '180px', margin: '2px' }}>
              <Text style={{ fontSize: '8px' }}>TRATAMIENTO</Text>
            </View>
            <View style={{ display: 3, width: '100px', margin: '2px' }}>
              <Text style={{ fontSize: '8px' }}>PRIMERA</Text>
            </View>
            <View style={{ display: 4, width: '70px', margin: '2px' }}>
              <Text style={{ fontSize: '8px' }}>ULTIMA</Text>
            </View>
            <View style={{ display: 5, width: '100px', margin: '2px' }}>
              <Text style={{ fontSize: '8px', textAlign: 'right' }}>VENTAS</Text>
            </View>
            <View style={{ display: 6, width: '80px', margin: '2px' }}>
              <Text style={{ fontSize: '8px', textAlign: 'right' }}>REP %</Text>
            </View>
            <View style={{ display: 7, width: '10px', margin: '2px' }}>
              <Text style={{ fontSize: '8px' }}></Text>
            </View>
          </View>
          {
            datosGeneral.length !== 0 &&
            datosGeneral.filter(res => res.fk_ven_familia_general === "PREB").map((m, index) => (
              <div key={index}>
                <View key={index} style={{ display: 'flex', flexDirection: 'row', backgroundColor: '#EBF8FF', padding: estilosTablaPdf.padding }}>
                  <View style={{ display: 1, width: '10px', margin: '2px' }}>
                    <Text style={{ fontSize: '8pt' }}></Text>
                  </View>
                  <View style={{ display: 2, width: '180px', margin: '2px' }}>
                    <Text style={{ fontSize: '8pt' }}>{m.nombre}</Text>
                  </View>
                  <View style={{ display: 3, width: '100px', margin: '2px' }}>
                    <Text style={{ fontSize: '8pt' }}>{m.primera}</Text>
                  </View>
                  <View style={{ display: 4, width: '80px', margin: '2px' }}>
                    <Text style={{ fontSize: '8pt' }}>{m.ultima}</Text>
                  </View>
                  <View style={{ display: 5, width: '100px', margin: '2px' }}>
                    <Text style={{ fontSize: '8pt', textAlign: 'right' }}>S/ {m.total}</Text>
                  </View>
                  <View style={{ display: 6, width: '80px', margin: '2px' }}>
                    <Text style={{ fontSize: '8pt', textAlign: 'right' }}>{(m.total * 100 / datosGeneral.filter(res => res.fk_ven_familia_general === "PREB").reduce((total, item) => parseFloat(total) + parseFloat(item.total), 0)).toFixed(2)} %</Text>
                  </View>
                  <View style={{ display: 7, width: '10px', margin: '2px' }}>
                    <Text style={{ fontSize: '8px' }}></Text>
                  </View>
                </View>
              </div>
            ))
          }
          <View style={{ display: 'flex', flexDirection: 'row', color: '#fff', backgroundColor: '#5B9AC8', padding: estilosTablaPdf.padding }}>
            <View style={{ display: 1, width: '10px', margin: '2px' }}>
              <Text style={{ fontSize: '8pt' }}></Text>
            </View>
            <View style={{ display: 2, width: '180px', margin: '2px' }}>
              <Text style={{ fontSize: '8pt' }}>TOTAL</Text>
            </View>
            <View style={{ display: 3, width: '300px', margin: '2px' }}>
              <Text style={{ fontSize: '8pt' }}></Text>
            </View>
            <View style={{ display: 6, width: '80px', margin: '2px' }}>
              <Text style={{ fontSize: '8pt', textAlign: 'right' }}>S/ {datosGeneral.filter(res => res.fk_ven_familia_general === "PREB").reduce((total, item) => parseFloat(total) + parseFloat(item.total), 0).toFixed(2)}</Text>
            </View>
            <View style={{ display: 7, width: '10px', margin: '2px' }}>
              <Text style={{ fontSize: '8px' }}></Text>
            </View>
          </View>

          <Text style={{ fontSize: 8, left: 40, right: 0, textAlign: 'left', bottom: 30, position: 'absolute', color: 'grey', }} fixed>Fecha: {new Date().toJSON().slice(0, 10)}</Text>
          <Text style={{ fontSize: 8, left: 130, right: 0, textAlign: 'left', bottom: 30, position: 'absolute', color: 'grey', }} fixed>Hora: {new Date().toLocaleTimeString()}</Text>
          <Text style={styles.pageNumber} render={({ pageNumber, totalPages }) => (
            `Pag ${pageNumber}`
          )} fixed />
        </Page>
      }
      {tipo === "BRAC" &&
        <Page style={styles.body} orientation="portrait">
          <View style={{ display: 'fixed', flexDirection: 'row', paddingBottom: "10px" }} fixed>
            <View style={{ display: 1 }}>
              <Image
                style={{ width: '55px', height: '50px', padding: "0 5px" }}
                src={Logo}
              />
            </View>
            <View style={{ display: 2, marginHorizontal: '15px', width: '65%' }} >
              <Text style={{ fontSize: '15px' }}>{datosEmpresa.razon_social}</Text>
              <Text style={{ fontSize: '10px', marginTop: '5px' }}>{datosEmpresa.ruc}</Text>
              <Text style={{ fontSize: '10px', marginTop: '5px' }}>{"Av. Demo Mz. Demo"}</Text>
              {/* <Text style={{ fontSize: '10px', marginTop: '5px' }}>{datosEmpresa.direccion}</Text> */}
            </View>
            <View style={{ display: 3 }}>
              <Text style={{ fontSize: '15px' }}>REPORTE TRATAMIENTO </Text>
              <View style={{ display: 'flex', flexDirection: 'row', padding: "10px 0" }}>
                <View style={{ display: 1, width: '80px', margin: '2px' }}>
                  <Text style={{ fontSize: '10px' }}></Text>
                </View>
                <View style={{ display: 2, width: '150px', margin: '2px' }}>
                  <Text style={{ fontSize: '10px' }}>BRACKEO</Text>
                </View>
              </View>
            </View>
          </View>
          {/* <Text style={{ marginTop: '25px', marginBottom: '10px', fontSize: '10px' }}>TRABAJADOR: {}</Text> */}
          <View style={{ display: 'flex', flexDirection: 'row', color: '#fff', backgroundColor: '#1976D2', padding: "4px 0" }} fixed>
            <View style={{ display: 1, width: '10px', margin: '2px' }}>
              <Text style={{ fontSize: '8px' }}></Text>
            </View>
            <View style={{ display: 2, width: '180px', margin: '2px' }}>
              <Text style={{ fontSize: '8px' }}>TRATAMIENTO</Text>
            </View>
            <View style={{ display: 3, width: '100px', margin: '2px' }}>
              <Text style={{ fontSize: '8px', textAlign: "center" }}>PRIMERA</Text>
            </View>
            <View style={{ display: 4, width: '100px', margin: '2px' }}>
              <Text style={{ fontSize: '8px', textAlign: "center" }}>ULTIMA</Text>
            </View>
            <View style={{ display: 5, width: '100px', margin: '2px' }}>
              <Text style={{ fontSize: '8px', textAlign: "right" }}>VENTAS</Text>
            </View>
            <View style={{ display: 6, width: '80px', margin: '2px' }}>
              <Text style={{ fontSize: '8px', textAlign: "right" }}>REP %</Text>
            </View>
            <View style={{ display: 7, width: '10px', margin: '2px' }}>
              <Text style={{ fontSize: '8px' }}></Text>
            </View>
          </View>
          {
            datosGeneral.length !== 0 &&
            datosGeneral.filter(res => res.fk_ven_familia_general === "BRAC").map((m, index) => (
              <div key={index}>
                <View key={index} style={{ display: 'flex', flexDirection: 'row', backgroundColor: '#EBF8FF', padding: estilosTablaPdf.padding }}>
                  <View style={{ display: 1, width: '10px', margin: '2px' }}>
                    <Text style={{ fontSize: '8pt' }}></Text>
                  </View>
                  <View style={{ display: 2, width: '180px', margin: '2px' }}>
                    <Text style={{ fontSize: '8pt' }}>{m.nombre}</Text>
                  </View>
                  <View style={{ display: 3, width: '100px', margin: '2px' }}>
                    <Text style={{ fontSize: '8pt', textAlign: "center" }}>{m.primera}</Text>
                  </View>
                  <View style={{ display: 4, width: '100px', margin: '2px' }}>
                    <Text style={{ fontSize: '8pt', textAlign: "center" }}>{m.ultima}</Text>
                  </View>
                  <View style={{ display: 5, width: '100px', margin: '2px' }}>
                    <Text style={{ fontSize: '8pt', textAlign: "right" }}>S/ {m.total}</Text>
                  </View>
                  <View style={{ display: 6, width: '80px', margin: '2px' }}>
                    <Text style={{ fontSize: '8pt', textAlign: "right" }}>{(m.total * 100 / datosGeneral.filter(res => res.fk_ven_familia_general === "BRAC").reduce((total, item) => parseFloat(total) + parseFloat(item.total), 0)).toFixed(2)} %</Text>
                  </View>
                  <View style={{ display: 7, width: '10px', margin: '2px' }}>
                    <Text style={{ fontSize: '8px' }}></Text>
                  </View>
                </View>
              </div>
            ))
          }
          <View style={{ display: 'flex', flexDirection: 'row', color: '#fff', backgroundColor: '#5B9AC8', padding: estilosTablaPdf.padding }}>
            <View style={{ display: 1, width: '10px', margin: '2px' }}>
              <Text style={{ fontSize: '8pt' }}></Text>
            </View>
            <View style={{ display: 2, width: '180px', margin: '2px' }}>
              <Text style={{ fontSize: '8pt' }}>TOTAL</Text>
            </View>
            <View style={{ display: 3, width: '300px', margin: '2px' }}>
              <Text style={{ fontSize: '8pt' }}></Text>
            </View>
            <View style={{ display: 4, width: '80px', margin: '2px' }}>
              <Text style={{ fontSize: '8pt', textAlign: "right" }}>S/ {datosGeneral.filter(res => res.fk_ven_familia_general === "BRAC").reduce((total, item) => parseFloat(total) + parseFloat(item.total), 0).toFixed(2)}</Text>
            </View>
            <View style={{ display: 5, width: '10px', margin: '2px' }}>
              <Text style={{ fontSize: '8px' }}></Text>
            </View>
          </View>

          <Text style={{ fontSize: 8, left: 40, right: 0, textAlign: 'left', bottom: 30, position: 'absolute', color: 'grey', }} fixed>Fecha: {new Date().toJSON().slice(0, 10)}</Text>
          <Text style={{ fontSize: 8, left: 130, right: 0, textAlign: 'left', bottom: 30, position: 'absolute', color: 'grey', }} fixed>Hora: {new Date().toLocaleTimeString()}</Text>
          <Text style={styles.pageNumber} render={({ pageNumber, totalPages }) => (
            `Pag ${pageNumber}`
          )} fixed />
        </Page>
      }
      {tipo === "OTRO" &&
        <Page style={styles.body} orientation="portrait">
          <View style={{ display: 'fixed', flexDirection: 'row', paddingBottom: "10px" }} fixed>
            <View style={{ display: 1 }}>
              <Image
                style={{ width: '55px', height: '50px', padding: "0 5px" }}
                src={Logo}
              />
            </View>
            <View style={{ display: 2, marginHorizontal: '15px', width: '65%' }} >
              <Text style={{ fontSize: '15px' }}>{datosEmpresa.razon_social}</Text>
              <Text style={{ fontSize: '10px', marginTop: '5px' }}>{datosEmpresa.ruc}</Text>
              <Text style={{ fontSize: '10px', marginTop: '5px' }}>{"Av. Demo Mz. Demo"}</Text>
              {/* <Text style={{ fontSize: '10px', marginTop: '5px' }}>{datosEmpresa.direccion}</Text> */}
            </View>
            <View style={{ display: 3 }}>
              <Text style={{ fontSize: '15px' }}>REPORTE TRATAMIENTO </Text>
              <View style={{ display: 'flex', flexDirection: 'row', padding: "10px 0" }}>
                <View style={{ display: 1, width: '100px', margin: '2px' }}>
                  <Text style={{ fontSize: '10px' }}></Text>
                </View>
                <View style={{ display: 2, width: '150px', margin: '2px' }}>
                  <Text style={{ fontSize: '10px' }}>OTROS</Text>
                </View>
              </View>
            </View>
          </View>
          {/* <Text style={{ marginTop: '25px', marginBottom: '10px', fontSize: '10px' }}>TRABAJADOR: {}</Text> */}
          <View style={{ display: 'flex', flexDirection: 'row', color: '#fff', backgroundColor: '#1976D2', padding: "4px 0" }} fixed>
            <View style={{ display: 1, width: '10px', margin: '2px' }}>
              <Text style={{ fontSize: '8px' }}></Text>
            </View>
            <View style={{ display: 2, width: '180px', margin: '2px' }}>
              <Text style={{ fontSize: '8px' }}>TRATAMIENTO</Text>
            </View>
            <View style={{ display: 3, width: '100px', margin: '2px' }}>
              <Text style={{ fontSize: '8px', textAlign: "center" }}>PRIMERA</Text>
            </View>
            <View style={{ display: 4, width: '100px', margin: '2px' }}>
              <Text style={{ fontSize: '8px', textAlign: "center" }}>ULTIMA</Text>
            </View>
            <View style={{ display: 5, width: '100px', margin: '2px' }}>
              <Text style={{ fontSize: '8px', textAlign: "right" }}>VENTAS</Text>
            </View>
            <View style={{ display: 6, width: '80px', margin: '2px' }}>
              <Text style={{ fontSize: '8px', textAlign: "right" }}>REP %</Text>
            </View>
            <View style={{ display: 7, width: '10px', margin: '2px' }}>
              <Text style={{ fontSize: '8px' }}></Text>
            </View>
          </View>
          {
            datosGeneral.length !== 0 &&
            datosGeneral.filter(res => res.fk_ven_familia_general === "OTRO").map((m, index) => (
              <div key={index}>
                <View key={index} style={{ display: 'flex', flexDirection: 'row', backgroundColor: '#EBF8FF', padding: estilosTablaPdf.padding }}>
                  <View style={{ display: 1, width: '10px', margin: '2px' }}>
                    <Text style={{ fontSize: '8pt' }}></Text>
                  </View>
                  <View style={{ display: 2, width: '180px', margin: '2px' }}>
                    <Text style={{ fontSize: '8pt' }}>{m.nombre}</Text>
                  </View>
                  <View style={{ display: 3, width: '100px', margin: '2px' }}>
                    <Text style={{ fontSize: '8pt', textAlign: "center" }}>{m.primera}</Text>
                  </View>
                  <View style={{ display: 4, width: '100px', margin: '2px' }}>
                    <Text style={{ fontSize: '8pt', textAlign: "center" }}>{m.ultima}</Text>
                  </View>
                  <View style={{ display: 5, width: '100px', margin: '2px' }}>
                    <Text style={{ fontSize: '8pt', textAlign: "right" }}>S/ {m.total}</Text>
                  </View>
                  <View style={{ display: 6, width: '80px', margin: '2px' }}>
                    <Text style={{ fontSize: '8pt', textAlign: "right" }}>{(m.total * 100 / datosGeneral.filter(res => res.fk_ven_familia_general === "OTRO").reduce((total, item) => parseFloat(total) + parseFloat(item.total), 0)).toFixed(2)} %</Text>
                  </View>
                  <View style={{ display: 7, width: '10px', margin: '2px' }}>
                    <Text style={{ fontSize: '8px' }}></Text>
                  </View>
                </View>
              </div>
            ))
          }
          <View style={{ display: 'flex', flexDirection: 'row', color: '#fff', backgroundColor: '#5B9AC8', padding: estilosTablaPdf.padding }}>
            <View style={{ display: 1, width: '10px', margin: '2px' }}>
              <Text style={{ fontSize: '8pt' }}></Text>
            </View>
            <View style={{ display: 2, width: '180px', margin: '2px' }}>
              <Text style={{ fontSize: '8pt' }}>TOTAL</Text>
            </View>
            <View style={{ display: 3, width: '300px', margin: '2px' }}>
              <Text style={{ fontSize: '8pt' }}></Text>
            </View>
            <View style={{ display: 4, width: '80px', margin: '2px' }}>
              <Text style={{ fontSize: '8pt', textAlign: "right" }}>S/ {datosGeneral.filter(res => res.fk_ven_familia_general === "OTRO").reduce((total, item) => parseFloat(total) + parseFloat(item.total), 0).toFixed(2)}</Text>
            </View>
            <View style={{ display: 5, width: '10px', margin: '2px' }}>
              <Text style={{ fontSize: '8px' }}></Text>
            </View>
          </View>

          <Text style={{ fontSize: 8, left: 40, right: 0, textAlign: 'left', bottom: 30, position: 'absolute', color: 'grey', }} fixed>Fecha: {new Date().toJSON().slice(0, 10)}</Text>
          <Text style={{ fontSize: 8, left: 130, right: 0, textAlign: 'left', bottom: 30, position: 'absolute', color: 'grey', }} fixed>Hora: {new Date().toLocaleTimeString()}</Text>
          <Text style={styles.pageNumber} render={({ pageNumber, totalPages }) => (
            `Pag ${pageNumber}`
          )} fixed />
        </Page>
      }
      {tipo === "RESE" &&
        <Page style={styles.body} orientation="portrait">
          <View style={{ display: 'fixed', flexDirection: 'row', paddingBottom: "10px" }} fixed>
            <View style={{ display: 1 }}>
              <Image
                style={{ width: '55px', height: '50px', padding: "0 5px" }}
                src={Logo}
              />
            </View>
            <View style={{ display: 2, marginHorizontal: '15px', width: '50%' }} >
              <Text style={{ fontSize: '15px' }}>{datosEmpresa.razon_social}</Text>
              <Text style={{ fontSize: '10px', marginTop: '5px' }}>{datosEmpresa.ruc}</Text>
              <Text style={{ fontSize: '10px', marginTop: '5px' }}>{"Av. Demo Mz. Demo"}</Text>

            </View>
            <View style={{ display: 3 }}>
              <Text style={{ fontSize: '15px' }}>REPORTE TRATAMIENTO </Text>
              <View style={{ display: 'flex', flexDirection: 'row', padding: "10px 0" }}>
                <View style={{ display: 1, width: '40px', margin: '2px' }}>
                  <Text style={{ fontSize: '10px' }}></Text>
                </View>
                <View style={{ display: 2, width: '150px', margin: '2px' }}>
                  <Text style={{ fontSize: '10px' }}>RESEMENTADO</Text>
                </View>
              </View>
            </View>
          </View>
          <View style={{ display: 'flex', flexDirection: 'row', color: '#fff', backgroundColor: '#1976D2', padding: "4px 0" }} fixed>
            <View style={{ display: 1, width: '10px', margin: '2px' }}>
              <Text style={{ fontSize: '8px' }}></Text>
            </View>
            <View style={{ display: 2, width: '180px', margin: '2px' }}>
              <Text style={{ fontSize: '8px' }}>TRATAMIENTO</Text>
            </View>
            <View style={{ display: 3, width: '100px', margin: '2px' }}>
              <Text style={{ fontSize: '8px', textAlign: "center" }}>PRIMERA</Text>
            </View>
            <View style={{ display: 4, width: '100px', margin: '2px' }}>
              <Text style={{ fontSize: '8px', textAlign: "center" }}>ULTIMA</Text>
            </View>
            <View style={{ display: 5, width: '100px', margin: '2px' }}>
              <Text style={{ fontSize: '8px', textAlign: "right" }}>VENTAS</Text>
            </View>
            <View style={{ display: 6, width: '80px', margin: '2px' }}>
              <Text style={{ fontSize: '8px', textAlign: "right" }}>REP %</Text>
            </View>
            <View style={{ display: 7, width: '10px', margin: '2px' }}>
              <Text style={{ fontSize: '8px' }}></Text>
            </View>
          </View>
          {
            datosGeneral.length !== 0 &&
            datosGeneral.filter(res => res.fk_ven_familia_general === "RESE").map((m, index) => (
              <div key={index}>
                <View key={index} style={{ display: 'flex', flexDirection: 'row', backgroundColor: '#EBF8FF', padding: estilosTablaPdf.padding }}>
                  <View style={{ display: 1, width: '10px', margin: '2px' }}>
                    <Text style={{ fontSize: '8pt' }}></Text>
                  </View>
                  <View style={{ display: 2, width: '180px', margin: '2px' }}>
                    <Text style={{ fontSize: '8pt' }}>{m.nombre}</Text>
                  </View>
                  <View style={{ display: 3, width: '100px', margin: '2px' }}>
                    <Text style={{ fontSize: '8pt', textAlign: "center" }}>{m.primera}</Text>
                  </View>
                  <View style={{ display: 4, width: '100px', margin: '2px' }}>
                    <Text style={{ fontSize: '8pt', textAlign: "center" }}>{m.ultima}</Text>
                  </View>
                  <View style={{ display: 5, width: '100px', margin: '2px' }}>
                    <Text style={{ fontSize: '8pt', textAlign: "right" }}>S/ {m.total}</Text>
                  </View>
                  <View style={{ display: 6, width: '80px', margin: '2px' }}>
                    <Text style={{ fontSize: '8pt', textAlign: "right" }}>{(m.total * 100 / datosGeneral.filter(res => res.fk_ven_familia_general === "RESE").reduce((total, item) => parseFloat(total) + parseFloat(item.total), 0)).toFixed(2)} %</Text>
                  </View>
                  <View style={{ display: 7, width: '10px', margin: '2px' }}>
                    <Text style={{ fontSize: '8px' }}></Text>
                  </View>
                </View>
              </div>
            ))
          }
          <View style={{ display: 'flex', flexDirection: 'row', color: '#fff', backgroundColor: '#1976D2', padding: estilosTablaPdf.padding }}>
            <View style={{ display: 1, width: '10px', margin: '2px' }}>
              <Text style={{ fontSize: '8pt' }}></Text>
            </View>
            <View style={{ display: 2, width: '180px', margin: '2px' }}>
              <Text style={{ fontSize: '8pt' }}>TOTAL</Text>
            </View>
            <View style={{ display: 3, width: '300px', margin: '2px' }}>
              <Text style={{ fontSize: '8pt' }}></Text>
            </View>
            <View style={{ display: 4, width: '80px', margin: '2px' }}>
              <Text style={{ fontSize: '8pt', textAlign: "right" }}>S/ {datosGeneral.filter(res => res.fk_ven_familia_general === "RESE").reduce((total, item) => parseFloat(total) + parseFloat(item.total), 0).toFixed(2)}</Text>
            </View>
            <View style={{ display: 5, width: '10px', margin: '2px' }}>
              <Text style={{ fontSize: '8px' }}></Text>
            </View>
          </View>

          <Text style={{ fontSize: 8, left: 40, right: 0, textAlign: 'left', bottom: 30, position: 'absolute', color: 'grey', }} fixed>Fecha: {new Date().toJSON().slice(0, 10)}</Text>
          <Text style={{ fontSize: 8, left: 130, right: 0, textAlign: 'left', bottom: 30, position: 'absolute', color: 'grey', }} fixed>Hora: {new Date().toLocaleTimeString()}</Text>
          <Text style={styles.pageNumber} render={({ pageNumber, totalPages }) => (
            `Pag ${pageNumber}`
          )} fixed />
        </Page>
      }
    </Document >
  )
}
export default ExportPDF
