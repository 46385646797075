import { IconButton, TableCell, TableRow, Link, Tooltip } from "@mui/material";
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import React from "react";

class FilaListarContacto extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            eliminado: false,
        };
        this.eliminar = this.eliminar.bind(this);
    }
    editar = () => {
        this.props.parentEdit(this.props.contacto.codigo)
    }
    eliminar = async () => {
        this.props.parentDelete(this.props.contacto.codigo);
    }

    render() {
        if (this.state.eliminado) {
            return null;
        }
        return (
            <TableRow key={this.props.contacto.codigo}>
                <TableCell>{this.props.contacto.codigo}</TableCell>
                <TableCell>{this.props.contacto.apellidos_nombre}</TableCell>
                <TableCell>{this.props.contacto.telefono_1}</TableCell>
                <TableCell>{this.props.contacto.estado_seguimiento}</TableCell>
                <TableCell>{this.props.contacto.fk_com_referencia}</TableCell>
                <TableCell align="right">
                    <Tooltip title="EDITAR CONTACTO" placement="top">
                        <IconButton color="info" onClick={() => this.props.dialogClienteEdit(this.props.contacto.codigo)}>
                            <EditIcon />
                        </IconButton>
                    </Tooltip>
                    <Tooltip title="ELIMINAR CONTACTO" placement="top">
                        <IconButton color="error" onClick={this.eliminar} >
                            <DeleteIcon />
                        </IconButton>
                    </Tooltip>
                </TableCell>
            </TableRow>
        );
    }
}
export default FilaListarContacto;