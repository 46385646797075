import { AddCircle, Check, Close, ConstructionOutlined, Delete } from '@mui/icons-material';
import { Alert, Button, Dialog, DialogContent, DialogTitle, Grid, IconButton, Paper, Snackbar, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, Tooltip } from '@mui/material'
import React from 'react'
import Constantes from '../../Constantes';
export default function EgresoEquipo(props) {
    const fk_cmp_equipo = props.cmp_equipo.fk_cmp_equipo;
    const [listarDatosEgreso, setListarDatosEgreso] = React.useState([]);
    const [dialogEliminar, setDialogEliminar] = React.useState({
        show: false,
        id: null,
    });
    const [alertGeneral, setAlertGeneral] = React.useState({
        show: false,
        message: '',
        severity: 'warning'
    });
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(5);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };
    React.useEffect(() => {
        if (fk_cmp_equipo !== "") {
            cargarDatos();
        }
    }, []);
    const cargarDatos = async () => {
        const response = await fetch(`${Constantes.RUTA_API}/listar/listarEquipoEgreso.php?fk_cmp_equipo=${fk_cmp_equipo}`);
        const rptaGetDatos = await response.json();
        setListarDatosEgreso(rptaGetDatos);
    }
    const confirEliminarStock = async () => {
        const eliminarStock = await fetch(`${Constantes.RUTA_API}mantenimiento/eliminarEquipoEgreso.php?id=${dialogEliminar.id}`)
        const response = eliminarStock.json();
        props.search();
        cargarDatos();
        setDialogEliminar({
            show: false,
            id: null,
        });
        setAlertGeneral({ message: 'Egreso eliminado correctamente', severity: 'success', show: true });
        setTimeout(() => {
            setAlertGeneral({ message: '', severity: 'warning', show: false });
        }, 3000);
    }
    const openDialogDelete = (id) => {
        setDialogEliminar({
            show: true,
            id: id,
        });
    }
    return (
        <Grid>
            <Snackbar open={alertGeneral.show} onClose={() => setAlertGeneral({ show: false, message: '', severity: 'warning' })} anchorOrigin={{ vertical: 'top', horizontal: 'right' }}>
                <Alert onClose={() => setAlertGeneral({ show: false, message: '', severity: 'warning' })} severity={alertGeneral.severity} sx={{ width: '100%' }}>
                    {alertGeneral.message}
                </Alert>
            </Snackbar>
            {/* <Dialog open={dialogNewEgreso} onClose={() => { setDialogNewEgreso(false); }} maxWidth="xs">
                <DialogTitle sx={{ textAlign: "center" }}>
                    INGRESE LA CANTIDAD A EGRESAR
                </DialogTitle>
                <DialogContent>
                    <DlgEgresoEquipo closeNuevoEgreso={closeNuevoEgreso} cerrarDialogEgreso={props.cerrarDialogEgreso} cmp_equipo={props.cmp_equipo} search={props.search} cargarDatos={cargarDatos} confirNuevoStock={props.confirNuevoStock} ErrConfirNuevoStock={ErrConfirNuevoStock} />
                </DialogContent>
            </Dialog> */}


            <Dialog open={dialogEliminar.show} onClose={() => setDialogEliminar({ ...dialogEliminar, show: false, id: null })} maxWidth="xs">
                <DialogTitle sx={{ textAlign: "center" }}>
                    ELIMINAR EGRESO
                </DialogTitle>
                <DialogContent>
                    <Grid container sx={{ justifyContent: "center" }}>
                        <Grid item sm={6}>
                            <Button variant='outlined' color='success' startIcon={<Check />} onClick={() => confirEliminarStock()}>ACEPTAR</Button>
                        </Grid>
                        <Grid item sm={6}>
                            <Button variant='outlined' color='error' startIcon={<Close />} onClick={() => setDialogEliminar({ ...dialogEliminar, show: false, id: null })}>CANCELAR</Button>
                        </Grid>
                    </Grid>
                </DialogContent>
            </Dialog>
            <Grid item md={12}>
                <Paper sx={{ mb: 2, mt: 2 }}>
                    <TableContainer sx={{ maxHeight: 300 }}>
                        <Table size="small" stickyHeader>
                            <TableHead size="small">
                                <TableRow>
                                    <TableCell align="right" sx={{ bgcolor: "#1976D2", color: "#fff" }}>CANTIDAD</TableCell>
                                    <TableCell align="center" sx={{ bgcolor: "#1976D2", color: "#fff" }}>FECHA</TableCell>
                                    <TableCell sx={{ bgcolor: "#1976D2", color: "#fff" }}></TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {listarDatosEgreso &&
                                    (rowsPerPage > 0
                                        ? listarDatosEgreso.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                        : listarDatosEgreso
                                    ).map((row, i) => (
                                        <TableRow
                                            key={i}
                                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                        >
                                            <TableCell component="th" scope="row" align="right" width={"10%"}>{row.cantidad}</TableCell>
                                            <TableCell align="center">{row.fecha}</TableCell>
                                            <TableCell align="center">
                                                <Tooltip title="ELIMINAR EGRESO" placement='top'>
                                                    <IconButton size='small' color="error" onClick={() => { openDialogDelete(row.id); }}><Delete fontSize='small' /></IconButton>
                                                </Tooltip>
                                            </TableCell>
                                        </TableRow>
                                    ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <TablePagination
                        rowsPerPageOptions={[5, 10, 25, 50]}
                        component="div"
                        labelRowsPerPage="Filas por columnas"
                        count={listarDatosEgreso.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                </Paper >
            </Grid >
        </Grid>
    )
}
