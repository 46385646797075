import { Alert, Grid, Paper, Snackbar, Typography } from '@mui/material'
import { Container } from '@mui/system'
import React from 'react'
import ReporteRegistrosPagos from '../../components/reportes/ventas/reporteRegistrosPagos'

export default function ResumenRegistros() {
    const [alertGeneralPdf, setAlertGeneralPdf] = React.useState({
        open: false,
        type: 'warning',
        text: ''
    });
    // pdf alerta
    const alertPdf = () => {
        setAlertGeneralPdf({
            open: true,
            type: 'error',
            text: 'No existen datos para exportar'
        })
        setTimeout(() => {
            setAlertGeneralPdf({
                open: false,
                type: 'warning',
                text: ''
            })
        }, 2700);
    }
    const alertExcel = () => {
        setAlertGeneralPdf({
            open: true,
            type: 'error',
            text: 'No existen datos para exportar'
        })
        setTimeout(() => {
            setAlertGeneralPdf({
                open: false,
                type: 'warning',
                text: ''
            })
        }, 2700);
    }
    return (
        <Container sx={{ marginTop: "70px", paddingBottom: "10px" }}>
            <Snackbar open={alertGeneralPdf.open} autoHideDuration={5000} onClose={() => setAlertGeneralPdf({ ...alertGeneralPdf, open: false })} anchorOrigin={{ vertical: 'top', horizontal: 'right' }}>
                <Alert onClose={() => setAlertGeneralPdf({ ...alertGeneralPdf, open: false })} severity={alertGeneralPdf.type} sx={{ width: '100%' }}>
                    {alertGeneralPdf.text}
                </Alert>
            </Snackbar>
            <ReporteRegistrosPagos alertPdf={alertPdf} alertExcel={alertExcel} />
        </Container>
    )
}
