import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControl, Grid, InputAdornment, InputLabel, MenuItem, Select, TextField, Tooltip } from '@mui/material'
import React, { useEffect } from 'react'
import Constantes from '../../Constantes';
import SaveOutlinedIcon from '@mui/icons-material/SaveOutlined';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import CheckCircleOutlinedIcon from '@mui/icons-material/CheckCircleOutlined';
import { LoadingButton } from '@mui/lab';

function EditarEquipos(props) {
    const [loadingEquipo, setLoadingEquipo] = React.useState(false);
    const [equipo, setEquipo] = React.useState({
        codigo: "STK2-00000000",
        nombre: "",
        descripcion: "",
        stock_minimo: 0,
        fk_alm_unidad: "",
        stock_actual: 0,
        vida_util: 0,
        tipo: "",
    })
    const tipoEquipo = ['COMPLEMENTO', 'EQUIPO', 'INSTRUMENTO', 'MUEBLE',]

    const [confirmacion, setConfirmacion] = React.useState({
        show: false,
        message: "",
        title: "",
    });
    const [unidades, setUnidades] = React.useState([]);

    useEffect((e) => {
        const fillUnidades = async () => {
            const unidadesFetch = await fetch(`${Constantes.RUTA_API}listar/listarUnidades.php`);
            const unidadesDB = await unidadesFetch.json();
            setUnidades(unidadesDB);
            if (props.fk_cmp_equipo === "STK2-00000000") {
                setEquipo({
                    codigo: "STK2-00000000",
                    nombre: "",
                    descripcion: "",
                    stock_minimo: 0,
                    fk_alm_unidad: "",
                    stock_actual: 0,
                    vida_util: 0,
                    tipo: "",
                })
            } else {
                const equipoFetch = await fetch(`${Constantes.RUTA_API}recuperar/recuperarEquipo.php?codigo=${props.fk_cmp_equipo}`);
                const equipoDB = await equipoFetch.json();
                setEquipo({
                    codigo: equipoDB.codigo,
                    nombre: equipoDB.nombre,
                    descripcion: equipoDB.descripcion,
                    stock_minimo: equipoDB.stock_minimo,
                    fk_alm_unidad: equipoDB.fk_alm_unidad,
                    stock_actual: equipoDB.stock_actual,
                    vida_util: equipoDB.vida_util,
                    tipo: equipoDB.tipo,
                })
            }
        }
        fillUnidades();
    }, [props.fk_cmp_equipo]);
    const handleChange = (event) => {
        let id = event.target.id;
        let valor = event.target.value;
        if (id === "nombre" || id === "descripcion") {
            setEquipo((equipoAnt) => ({
                ...equipoAnt,
                [id]: valor,
            }));
        } else {
            setEquipo((equipoAnt) => ({
                ...equipoAnt,
                [id]: Number(valor),
            }));
        }
    }
    const handleCombo = (event) => {
        if (event.target) {
            let valor = event.target.value;
            let id = event.explicitOriginalTarget.id;
            setEquipo((equipoAnt) => ({
                ...equipoAnt,
                [id]: valor,
            }));
        }
    }
    const guardarEquipo = async () => {
        setLoadingEquipo(true);
        const guardarEquipo = await fetch(`${Constantes.RUTA_API}mantenimiento/guardarEquipo.php`, {
            method: "POST",
            body: JSON.stringify({
                codigo: equipo.codigo,
                nombre: equipo.nombre.toUpperCase(),
                descripcion: equipo.descripcion.toUpperCase(),
                stock_minimo: equipo.stock_minimo,
                fk_alm_unidad: equipo.fk_alm_unidad,
                stock_actual: equipo.stock_actual,
                vida_util: equipo.vida_util,
                tipo: equipo.tipo,
            }),
        });
        const jsonEquipo = await guardarEquipo.json();
        if (jsonEquipo.guardado) {
            setLoadingEquipo(false);
            props.search();
            setConfirmacion({
                show: true,
                message: `El equipo/instrumental ${jsonEquipo.codigo} fue guardado con exito.`,
                title: "GUARDADO",
            });
            setTimeout(() => {
                setConfirmacion({
                    show: false,
                    message: "",
                    title: "",
                });
                parentCloseChild();
            }, 3000);
        } else {
            setConfirmacion({
                show: true,
                message: `Hubo un problema al intentar guardar el equipo/intrumental, intente de nuevo. Si el problema persiste contacte al administrador.`,
                title: "ERROR",
            });
        }
    }
    const parentCloseChild = () => {
        props.parentCloseChild();
        setEquipo({
            codigo: "STK2-00000000",
            nombre: "",
            descripcion: "",
            stock_minimo: 0,
            fk_alm_unidad: "",
            stock_actual: 0,
            vida_util: 0,
            tipo: "",
        })
    }
    const closeConfirmacion = () => {
        setConfirmacion({
            show: false,
            message: "",
            title: "",
        });
        parentCloseChild();
    }
    return (
        <Grid container spacing={1}>
            <Dialog open={confirmacion.show}>
                <DialogTitle>
                    {confirmacion.title}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>{confirmacion.message}</DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button startIcon={<CheckCircleOutlinedIcon />} variant='outlined' color='primary' onClick={() => closeConfirmacion(confirmacion.title)}>OK</Button>
                </DialogActions>
            </Dialog>
            <Grid item xs={4}>
                <TextField
                    id='codigo'
                    label="Codigo:"
                    placeholder='STK-00000000'
                    variant='filled'
                    type='text'
                    fullWidth
                    style={{ textTransform: "uppercase" }}
                    value={equipo.codigo}
                    disabled
                />
            </Grid>
            <Grid item xs={8}>
                <TextField
                    id='nombre'
                    label="Nombre:"
                    variant='filled'
                    type='text'
                    style={{ textTransform: "uppercase" }}
                    onChange={handleChange}
                    value={equipo.nombre}
                    fullWidth
                    inputProps={{
                        style: { textTransform: "uppercase" }
                    }}
                />
            </Grid>
            <Grid item xs={7} md={4}>
                <FormControl fullWidth margin="normal">
                    <InputLabel>Tipo:</InputLabel>
                    <Select
                        labelId="tipo"
                        id="tipo"
                        value={equipo ? equipo.tipo : ''}
                        label="Tipo:"
                        onChange={handleCombo}
                    >
                        {tipoEquipo.map(tipo => {
                            return (<MenuItem key={tipo} value={tipo} id='tipo'>{tipo}</MenuItem>)
                        })}
                    </Select>
                </FormControl>
            </Grid>
            <Grid item xs={5} md={2}>
                <TextField
                    margin="normal"
                    id='stock_minimo'
                    label="Stock minimo: "
                    variant='filled'
                    type='number'
                    onChange={handleChange}
                    value={equipo.stock_minimo}
                    inputProps={{ style: { textAlign: 'right' }, min: "0", step: "1" }}
                />
            </Grid>
            <Grid item xs={7} md={4}>
                <FormControl fullWidth margin="normal">
                    <InputLabel>Unidad:</InputLabel>
                    <Select
                        labelId="fk_alm_unidad"
                        id="fk_alm_unidad"
                        value={equipo ? equipo.fk_alm_unidad : ''}
                        label="Unidad:"
                        onChange={handleCombo}
                    >
                        {unidades.map(uni => {
                            return (<MenuItem key={uni.codigo} value={uni.codigo} id='fk_alm_unidad'>{uni.nombre}</MenuItem>)
                        })}
                    </Select>
                </FormControl>
            </Grid>
            {/* <Grid item xs={2}>
                <TextField
                    margin="normal"
                    id='stock_actual'
                    label="Stock Actual:"
                    variant='filled'
                    type='number'
                    value={equipo.stock_actual}
                    onChange={handleChange}
                    inputProps={{ style: { textAlign: 'right' }, min: "0", step: "1" }}
                />
            </Grid> */}
            <Grid item xs={5} md={2}>
                <TextField
                    margin="normal"
                    id='vida_util'
                    label="Vida Util:"
                    variant='filled'
                    type='number'
                    onChange={handleChange}
                    value={equipo.vida_util}
                    inputProps={{ style: { textAlign: 'right' }, min: "0", step: "1", }}
                    InputProps={{ endAdornment: (<InputAdornment position="start"> meses</InputAdornment>), }}
                />
            </Grid>
            <Grid item xs={12}>
                <TextField
                    margin="normal"
                    id='descripcion'
                    label="Descripcion:"
                    variant='filled'
                    type='text'
                    onChange={handleChange}
                    value={equipo.descripcion}
                    fullWidth
                    multiline
                    maxRows={4}
                    inputProps={{
                        style: { textTransform: "uppercase" }
                    }}
                />
            </Grid>
            <Grid container sx={{ justifyContent: 'center', mt: 2 }} spacing={1}>
                <Grid item sm={4} xs={6} md={2}>
                    <LoadingButton loading={loadingEquipo} fullWidth variant='outlined' color='success' startIcon={<SaveOutlinedIcon />} onClick={guardarEquipo}>GUARDAR</LoadingButton>
                </Grid>
                <Grid item sm={4} xs={6} md={2}>
                    <Button fullWidth variant='outlined' color='error' startIcon={<CancelOutlinedIcon />} onClick={parentCloseChild}>CANCELAR</Button>
                </Grid>
            </Grid>
        </Grid>
    )
}
export default EditarEquipos