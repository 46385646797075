import { Alert, Button, Chip, Collapse, Dialog, DialogContent, DialogTitle, Grid, IconButton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Tooltip } from '@mui/material';
import React, { useEffect } from 'react';
import Trabajador from './trabajador';
import Constantes from '../Constantes';
import SearchIcon from '@mui/icons-material/Search';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import CloseIcon from '@mui/icons-material/Close';
import AddBoxOutlinedIcon from '@mui/icons-material/AddBoxOutlined';
import { Close } from '@mui/icons-material';
function Trabajadores(props) {
    const [trabajadores, setTrabajadores] = React.useState([]);
    const [searchApellidosNombres, setSearchApellidosNombres] = React.useState("");
    const [trabajadorSeleccionado, setTrabajadorSeleccionado] = React.useState({
        id: 0,
        apellidos_nombres: "",
        numero_documento: "",
        colegiatura: "",
        es_activo: 1,
    })
    const [eliminado, setEliminado] = React.useState({
        show: false,
        message: "",
        severity: "info",
    });
    const [newTrabajador, setNewTrabajador] = React.useState(false);
    const [editTrabajador, setEditTrabajador] = React.useState(false);
    useEffect((e) => {
        const filTrabajadoresin = async () => {
            if (props.soloActivos) {
                const listarTrabajadores = await fetch(`${Constantes.RUTA_API}/listar/listarTrabajadorActivo.php?search=${searchApellidosNombres}`);
                const trabajadoresDB = await listarTrabajadores.json();
                setTrabajadores(trabajadoresDB);
            } else {
                const listarTrabajadores = await fetch(`${Constantes.RUTA_API}/listar/listarTrabajador.php?search=${searchApellidosNombres}`);
                const trabajadoresDB = await listarTrabajadores.json();
                setTrabajadores(trabajadoresDB);
            }
        }
        filTrabajadoresin()
    }, [props.soloActivos]);
    const filterTrabajadores = async () => {
        if (props.soloActivos) {
            const listarTrabajadores = await fetch(`${Constantes.RUTA_API}/listar/listarTrabajadorActivo.php?search=${searchApellidosNombres}`);
            const trabajadoresDB = await listarTrabajadores.json();
            setTrabajadores(trabajadoresDB);
        } else {
            const listarTrabajadores = await fetch(`${Constantes.RUTA_API}/listar/listarTrabajador.php?search=${searchApellidosNombres}`);
            const trabajadoresDB = await listarTrabajadores.json();
            setTrabajadores(trabajadoresDB);
        }
    }
    const handleChangeSearch = (e) => {
        setSearchApellidosNombres(e.target.value);
    }
    const handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            filterTrabajadores();
        }
    }
    const closeTrabajador = () => {
        setNewTrabajador(false);
        setEditTrabajador(false);
        setSearchApellidosNombres("")
        filterTrabajadores();
    }
    const editarTrabajador = (trab) => {
        setTrabajadorSeleccionado(trab);
        setEditTrabajador(true);
    }
    const eliminarTrabajador = async (trab) => {
        const eliminarTrabajador = await fetch(`${Constantes.RUTA_API}/mantenimiento/eliminarTrabajador.php?id=${trab.id}`);
        const eliminado = await eliminarTrabajador.json();
        if (eliminado.executed) {
            setEliminado({
                show: true,
                message: "Trabajador eliminado con éxito.",
                severity: "success",
            });
        } else {
            setEliminado({
                show: true,
                message: "Ocurrio un problema al eliminar, intente de nuevo.",
                severity: "error",
            });
        }
        filterTrabajadores();
        setTimeout(() => {
            setEliminado({
                show: false,
                message: "",
                severity: "info"
            });
        }, 3000);
    }
    const nuevoTrabajador = () => {
        setTrabajadorSeleccionado({
            id: 0,
            apellidos_nombres: "",
            numero_documento: "",
            colegiatura: "",
            es_activo: 1,
        })
        setNewTrabajador(true);
    }
    return (
        <>
            <Dialog open={newTrabajador}>
                <DialogTitle>
                    <IconButton
                        aria-label="close"
                        onClick={() => setNewTrabajador(false)}
                        sx={{
                            position: 'absolute',
                            right: 8,
                            top: 8,
                            color: (theme) => theme.palette.grey[500],
                        }}
                    >
                        <Close />
                    </IconButton>
                </DialogTitle>
                <DialogContent>
                    <Trabajador parentClose={closeTrabajador} />
                </DialogContent>
            </Dialog>
            <Dialog open={editTrabajador}>
                <DialogTitle>
                    <IconButton
                        aria-label="close"
                        onClick={() => setEditTrabajador(false)}
                        sx={{
                            position: 'absolute',
                            right: 8,
                            top: 8,
                            color: (theme) => theme.palette.grey[500],
                        }}
                    >
                        <Close />
                    </IconButton>
                </DialogTitle>
                <DialogContent>
                    <Trabajador parentClose={closeTrabajador} id={trabajadorSeleccionado.id} />
                </DialogContent>
            </Dialog>
            <Collapse in={eliminado.show}>
                <Alert severity={eliminado.severity} action={
                    <IconButton aria-label="close" color="inherit" onClick={() => { setEliminado({ show: false, message: "", severity: "info" }); }}>
                        <CloseIcon fontSize="inherit" />
                    </IconButton>
                }>
                    {eliminado.message}
                </Alert>
            </Collapse>
            <Grid container spacing={1} >
                {/* <Collapse >
                <Grid item xs={12}>
                    <Trabajador parentClose={closeTrabajador} id={trabajadorSeleccionado.id} />
                </Grid>
            </Collapse> */}
                <Grid item sm={8} xs={12}>
                    <TextField
                        label="Buscar nombre:"
                        fullWidth
                        variant='filled'
                        type="text"
                        id='search'
                        value={searchApellidosNombres}
                        onChange={handleChangeSearch}
                        onKeyDown={handleKeyDown}
                    />
                </Grid>
                <Grid item sm={2} xs={6} sx={{ mt: 1 }}>
                    <Button fullWidth variant='contained' color='primary' startIcon={<SearchIcon />} onClick={filterTrabajadores}>BUSCAR</Button>
                </Grid>
                <Grid item sm={2} xs={6} sx={{ mt: 1 }}>
                    <Button fullWidth variant='contained' color='success' startIcon={<AddBoxOutlinedIcon />} onClick={nuevoTrabajador}>NUEVO</Button>
                </Grid>
                <Grid item xs={12}>
                    <Grid container>
                        <TableContainer>
                            <Table stickyHeader>
                                <TableHead>
                                    <TableRow>
                                        <TableCell style={{ fontWeight: 'bold' }}>NOMBRES Y APELLIDOS</TableCell>
                                        <TableCell style={{ fontWeight: 'bold' }}>NUMERO DOCUMENTO</TableCell>
                                        <TableCell style={{ fontWeight: 'bold' }}>COLEGIATURA</TableCell>
                                        <TableCell style={{ fontWeight: 'bold' }}>ESTADO</TableCell>
                                        <TableCell></TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {trabajadores.map((trab) => (
                                        <TableRow key={trab.id}>
                                            <TableCell>{trab.apellidos_nombres}</TableCell>
                                            <TableCell>{trab.numero_documento}</TableCell>
                                            <TableCell>{trab.colegiatura}</TableCell>
                                            <TableCell>{trab.es_activo > 0 ?
                                                <Chip label="ACTIVO" color="success" size="small" /> :
                                                <Chip label="INACTIVO" color="error" size="small" />}</TableCell>
                                            <TableCell>
                                                <Tooltip title='Editar'>
                                                    <IconButton onClick={() => editarTrabajador(trab)} color='secondary'><EditIcon /></IconButton>
                                                </Tooltip>
                                                <Tooltip title='Eliminar'>
                                                    <IconButton onClick={() => eliminarTrabajador(trab)} color='error'><DeleteIcon /></IconButton>
                                                </Tooltip>
                                            </TableCell>
                                        </TableRow>
                                    ))}

                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Grid>
                </Grid>
            </Grid>
        </>
    )
}
export default Trabajadores
