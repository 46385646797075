import { Grid, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material'
import React, { useEffect } from 'react'
import Constantes from '../../Constantes';

function RepRegistroTratamiento(props) {
    const [resultados, setResultados] = React.useState([]);

    useEffect((e) => {
        const llenarDatos = async () => {
            if (props.tipo === "E") {
                const resultadosFetch = await fetch(`${Constantes.RUTA_API}reportes/repComprasPorEquipo.php?codigo=${props.codigo}`);
                const resultadoDB = await resultadosFetch.json();
                setResultados(resultadoDB);
            }
            if (props.tipo === "M") {
                const resultadosFetch = await fetch(`${Constantes.RUTA_API}reportes/repComprasPorMaterial.php?codigo=${props.codigo}`);
                const resultadoDB = await resultadosFetch.json();
                setResultados(resultadoDB);
            }
            if (props.tipo === "G") {
                const resultadosFetch = await fetch(`${Constantes.RUTA_API}reportes/repRegistroPorGasto.php?codigo=${props.codigo}`);
                const resultadoDB = await resultadosFetch.json();
                setResultados(resultadoDB);
            }
        }
        llenarDatos();
    }, [props]);
    const closeDialog = () => {
        props.closeChild();
    }
    return (
        <Grid container sx={{ paddingBottom: "10px" }}>
            <TableContainer sx={{ maxHeight: 440 }}>
                <Table stickyHeader>
                    <TableHead>
                        <TableRow>
                            <TableCell>REFERENCIA</TableCell>
                            <TableCell>FECHA</TableCell>
                            <TableCell>CANT.</TableCell>
                            <TableCell>P.U.</TableCell>
                            <TableCell>TOTAL</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {resultados.map((res) => {
                            return (
                                <TableRow key={res.codigo}>
                                    <TableCell>{res.referencia}</TableCell>
                                    <TableCell>{res.fecha}</TableCell>
                                    <TableCell>{res.cantidad}</TableCell>
                                    <TableCell>{res.precio_unitario}</TableCell>
                                    <TableCell>{res.total_a_pagar}</TableCell>
                                </TableRow>
                            )
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
        </Grid>

    )
}

export default RepRegistroTratamiento