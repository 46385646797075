import { Button, Grid, Paper, TextField, Link, FormControlLabel, Checkbox, ListItemButton, Collapse, Alert, IconButton, Typography } from '@mui/material';
import React from 'react'
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { Close } from '@mui/icons-material';
import Constantes from '../../Constantes';
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';

export default function SavePerfil(props) {
  const accion = props.accion;
  const [loading, setLoading] = React.useState(false);
  const [perfilSubmit, setPerfilSubmit] = React.useState({
    codigo: '',
    nombre: '',
    descripcion: ''
  });
  const [menuArray, setMenuArray] = React.useState([]);
  const [alertaCodigo, setAlertaCodigo] = React.useState(false);

  const [openCollSys, setOpenCollSys] = React.useState();
  const [openCollRep, setOpenCollRep] = React.useState();
  const [perfilDetalleMenu, setPerfilDetalleMenu] = React.useState({ fk_log_perfil: "", fk_log_menu: "" });

  const funCollCheckP = (dato) => {
    if (dato === "SYS") { setOpenCollSys(!openCollSys); }
    if (dato === "REP") { setOpenCollRep(!openCollRep); }
  }
  const handleCodigo = (e) => {
    let valor = e.target.value;
    let valorF = valor.replace(/ /g, "");
    setPerfilSubmit({ ...perfilSubmit, codigo: valorF.toUpperCase() });
  }
  const handlePerfil = (e) => {
    const { name, value } = e.target;
    setPerfilSubmit({ ...perfilSubmit, [name]: value });
  }
  const handleChangeChecked = (e, menu) => {
    if (perfilSubmit.codigo !== "") {
      const tempArray = menuArray.slice();
      var indice = -1;
      var indicePadre = -1;
      tempArray.forEach((element, i) => {
        if (element.codigo === menu.fk_log_menu_padre) {
          indicePadre = i;
        }
        if (element.codigo === menu.codigo) {
          indice = i;
        }
      });
      tempArray[indice].checked = e.target.checked ? 1 : 0;
      if (indicePadre >= 0) {
        tempArray[indicePadre].checked = tempArray.filter(h => h.fk_log_menu_padre === tempArray[indicePadre].codigo && h.checked === 1).length > 0 ? 1 : 0;
      } else {
        tempArray.forEach(hijo => {
          if (hijo.fk_log_menu_padre === menu.codigo) {
            hijo.checked = e.target.checked ? 1 : 0;
          }
        })
      }
      setMenuArray(tempArray);
      let menuGuardarArray = [];
      menuArray.forEach(menuG => {
        if (menuG.checked === 1 && menuG.checked !== undefined) {
          var codigoMenu = menuG.codigo;
          var menuGuardarArrayElement = {
            fk_log_perfil: props.codigoPerfilSelect ? props.codigoPerfilSelect : perfilSubmit.codigo,
            fk_log_menu: codigoMenu,
          }
          menuGuardarArray.push(menuGuardarArrayElement);
        };
      });
      setPerfilDetalleMenu(menuGuardarArray);
    } else {
      setAlertaCodigo(true);
      setTimeout(() => { setAlertaCodigo(false) }, 2500);
    }
  }
  const cargarMenus = async () => {
    const menuFetch = await fetch(`${Constantes.RUTA_API}sistema/listarMenuAll.php`);
    const menuJSON = await menuFetch.json();
    if (props.codigoPerfilSelect !== undefined) {
      const perfilMenusFetch = await fetch(`${Constantes.RUTA_API}listar/listarPerfilDetalle.php?fk_log_perfil=${props.codigoPerfilSelect}`);
      const perfilMenusFetchDB = await perfilMenusFetch.json();
      menuJSON.forEach((m, i) => {
        perfilMenusFetchDB.forEach((f) => {
          if (f.fk_log_menu === m.codigo) {
            menuJSON[i].checked = 1;
          }
        })
      })
      setMenuArray(menuJSON);
    } else {
      setMenuArray(menuJSON);
    }
  }

  const fntSetPerfil = async () => {
    if (props.codigoPerfilSelect !== undefined) {
      if (perfilSubmit.codigo !== "" && perfilSubmit.nombre !== "") {
        setLoading(true);
        const savePerfil = await fetch(`${Constantes.RUTA_API}mantenimiento/guardarPerfil.php`, {
          method: 'POST',
          body: JSON.stringify({
            codigo: perfilSubmit.codigo.toUpperCase(),
            nombre: perfilSubmit.nombre.toUpperCase(),
            descripcion: perfilSubmit.descripcion.toUpperCase()
          })
        });
        if (perfilDetalleMenu.fk_log_perfil !== "") {
          if (props.codigoPerfilSelect) {
            const deleteDetalle = await fetch(`${Constantes.RUTA_API}mantenimiento/eliminarTodoPerfil.php?codigo=${props.codigoPerfilSelect}`);
            perfilDetalleMenu.forEach(async (menuPerfil) => {
              const savePerfilMenu = await fetch(`${Constantes.RUTA_API}mantenimiento/guardarPerfilDetalle.php`, {
                method: "POST",
                body: JSON.stringify({
                  fk_log_perfil: menuPerfil.fk_log_perfil,
                  fk_log_menu: menuPerfil.fk_log_menu,
                }),
              });
            });
          } else {
            perfilDetalleMenu.forEach(async (menuPerfil) => {
              const savePerfilMenu = await fetch(`${Constantes.RUTA_API}mantenimiento/guardarPerfilDetalle.php`, {
                method: "POST",
                body: JSON.stringify({
                  fk_log_perfil: menuPerfil.fk_log_perfil,
                  fk_log_menu: menuPerfil.fk_log_menu,
                }),
              });
            });
          }
        }
        props.dialogClose();
        props.listarPerfiles();
        props.setAlertGeneral({ message: 'Perfil actualizado correctamente', severity: 'success', show: true });
        setTimeout(() => {
          props.setAlertGeneral({ severity: 'warning', message: '', show: false });
        }, 3000);
      } else {
        props.setAlertGeneral({ message: 'Campo vacios', severity: 'warning', show: true });
        setTimeout(() => {
          props.setAlertGeneral({ severity: 'warning', message: '', show: false });
        }, 3000);
      }
    } else {
      if (perfilSubmit.codigo !== "" && perfilSubmit.nombre !== "" && perfilDetalleMenu.fk_log_menu !== "") {
        setLoading(true);
        const savePerfil = await fetch(`${Constantes.RUTA_API}mantenimiento/guardarPerfil.php`, {
          method: 'POST',
          body: JSON.stringify({
            codigo: perfilSubmit.codigo.toUpperCase(),
            nombre: perfilSubmit.nombre.toUpperCase(),
            descripcion: perfilSubmit.descripcion.toUpperCase()
          })
        });
        if (perfilDetalleMenu.fk_log_perfil !== "") {
          if (props.fntCodigoPerfil != undefined) {
            const deleteDetalle = await fetch(`${Constantes.RUTA_API}mantenimiento/eliminarTodoPerfil.php?codigo=${props.codigoPerfilSelect}`);
            perfilDetalleMenu.forEach(async (menuPerfil) => {
              const savePerfilMenu = await fetch(`${Constantes.RUTA_API}mantenimiento/guardarPerfilDetalle.php`, {
                method: "POST",
                body: JSON.stringify({
                  fk_log_perfil: menuPerfil.fk_log_perfil,
                  fk_log_menu: menuPerfil.fk_log_menu,
                }),
              });
            });
          } else {
            perfilDetalleMenu.forEach(async (menuPerfil) => {
              const savePerfilMenu = await fetch(`${Constantes.RUTA_API}mantenimiento/guardarPerfilDetalle.php`, {
                method: "POST",
                body: JSON.stringify({
                  fk_log_perfil: menuPerfil.fk_log_perfil,
                  fk_log_menu: menuPerfil.fk_log_menu,
                }),
              });
            });
          }
        }
      } else {
        props.setAlertGeneral({ message: 'Campo vacios', severity: 'warning', show: true });
        setTimeout(() => {
          props.setAlertGeneral({ severity: 'warning', message: '', show: false });
        }, 3000);
      }
      props.dialogClose();
      props.listarPerfiles();
      if (props.codigoPerfilSelect === undefined) {
        props.setAlertGeneral({ message: 'Perfil guardado correctamente', severity: 'success', show: true });
        setTimeout(() => {
          props.setAlertGeneral({ severity: 'warning', message: '', show: false });
        }, 3000);
      }
    }
  }
  const recuperarPerfil = async () => {
    const perfilGet = await fetch(`${Constantes.RUTA_API}recuperar/recuperarPerfil.php?codigo=${props.codigoPerfilSelect}`);
    const response = await perfilGet.json();
    setPerfilSubmit(response);
  }
  React.useEffect(() => {
    cargarMenus();
    if (props.codigoPerfilSelect !== undefined) {
      recuperarPerfil();
      cargarMenus();
    }
  }, []);
  return (
    <>
      <Collapse in={alertaCodigo}>
        <Alert severity="warning"
          action={
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={() => {
                setAlertaCodigo(false);
              }}
            >
              <Close fontSize="inherit" />
            </IconButton>
          }
        >
          Codigo Obligatorio
        </Alert>
      </Collapse>
      <Grid container spacing={2}>
        <Grid item md={6} xs={12}>
          <Typography>DATOS DE PERFIL</Typography>
          <TextField
            name='codigo'
            label="CODIGO:"
            placeholder='Codigo'
            variant='filled'
            fullWidth
            margin="normal"
            inputProps={{ style: { textTransform: "uppercase" }, maxLength: '20' }}
            onChange={handleCodigo}
            value={perfilSubmit ? perfilSubmit.codigo : ''}
            disabled={props.codigoPerfilSelect !== undefined ? true : false}
          />
          <TextField
            label="NOMBRE:"
            placeholder='Nombre'
            variant='filled'
            fullWidth
            name="nombre"
            margin="normal"
            inputProps={{ style: { textTransform: "uppercase" } }}
            onChange={handlePerfil}
            value={perfilSubmit ? perfilSubmit.nombre : ''}
          />
          <TextField
            label="DESCRIPCION:"
            placeholder='Descripcion'
            type="text"
            multiline
            rows={2}
            fullWidth
            name="descripcion"
            margin="normal"
            inputProps={{ style: { textTransform: "uppercase" } }}
            value={perfilSubmit ? perfilSubmit.descripcion : ''}
            onChange={handlePerfil}
          />
        </Grid>
        <Grid item md={6} xs={12}>
          <Typography>MENU</Typography>
          {menuArray.filter(m => m.fk_log_menu_padre === null).map((padre, index) => (
            <Grid item xs={12} key={index} >
              <ListItemButton onClick={() => funCollCheckP(padre.codigo)}>
                {
                  padre.codigo === "SYS" && openCollSys ||
                    padre.codigo === "REP" && openCollRep
                    ? <ExpandLess /> : padre.codigo === "CAJ" || padre.codigo === "CLI" || padre.codigo === "CON" || padre.codigo === "CMP" ? <div style={{ width: '24px' }}></div> : <ExpandMore />}
                <FormControlLabel
                  label={padre.nombre}
                  control={<Checkbox key={padre.codigo} checked={padre.checked === 1} onChange={(e) => handleChangeChecked(e, padre)} />}
                />
              </ListItemButton>
              <Grid container>
                <Grid item xs={12}>
                  {menuArray.filter(mh => mh.fk_log_menu_padre === padre.codigo).map((h, index) => (
                    <Collapse key={index}
                      in={padre.codigo === "SYS" ? openCollSys :
                        padre.codigo === "REP" ? openCollRep : ''}
                      timeout="auto" unmountOnExit>
                      <Grid item xs={12} sx={{ display: "flex", direction: "column", ml: 8 }}>
                        <FormControlLabel
                          label={h.nombre}
                          control={<Checkbox key={h.codigo} checked={h.checked === 1} onChange={(e) => handleChangeChecked(e, h)} />}
                        />
                      </Grid>
                    </Collapse>
                  ))}
                </Grid>
              </Grid>
            </Grid>
          ))}
        </Grid>
      </Grid>
      <Grid container spacing={2} sx={{ marginTop: "20px", justifyContent: 'center', textAlign: 'center' }} >
        <Grid item md={6} xs={12}>
          <LoadingButton startIcon={<CheckCircleOutlineIcon />} variant="contained" color="success" onClick={fntSetPerfil} loading={loading}>
            GUARDAR
          </LoadingButton>
          <Button startIcon={<Close />} style={{ marginLeft: '6px' }} onClick={() => props.dialogClose()} variant="contained" color="error">
            CANCELAR
          </Button>
        </Grid>
      </Grid>
    </>
  )
}
