import { Paper } from '@mui/material'
import { Container } from '@mui/system'
import React from 'react'
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import Stock from './tabs/stock';
import Compra from './tabs/compra';
import OtrosGastos from './tabs/otrosGastos';
function RegistroCompras() {
    const [value, setValue] = React.useState('1');
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    return (
        <Container sx={{ marginTop: "80px", paddingBottom: "10px" }}>
            <Paper elevation={3}>
                <TabContext value={value}>
                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                        <TabList onChange={handleChange} aria-label="API tabs">
                            <Tab label="Stock" value="1" />
                            <Tab label="Compra" value="2" />
                            <Tab label="Otros Gastos" value="3" />
                        </TabList>
                    </Box>
                    <TabPanel value="1">
                        <Stock />
                    </TabPanel>
                    <TabPanel value="2">
                        <Compra />
                    </TabPanel>
                    <TabPanel value="3">
                        <OtrosGastos />
                    </TabPanel>
                </TabContext>
            </Paper>
        </Container>
    )
}

export default RegistroCompras
