import { Collapse, IconButton, TableCell, TableRow } from '@mui/material';
import React, { useEffect } from 'react';
import Constantes from '../../Constantes';
import EditarPago from './editarPago';
import DeleteIcon from '@mui/icons-material/Delete';

function VerDetallesPagos(props) {
    const [pago, setPago] = React.useState({
        id:0,
        fecha:"",
        hora:"",
        total:"",
        tipo:""
    });
    const [showCollapse, setShowCollapse] = React.useState(false);
    useEffect((e)=>{
        setPago(props.pago);
    });
    const eliminar = () =>{
        props.parentDeleter(pago);
    }
    return (
        <TableRow>
            <TableCell>{pago.id}</TableCell>
            <TableCell>{pago.fecha}</TableCell>
            <TableCell>{pago.hora}</TableCell>
            <TableCell>{pago.total}</TableCell>
            <TableCell>{pago.tipos_pago}</TableCell>
            <TableCell>
                <IconButton color="error" onClick={eliminar} ><DeleteIcon /></IconButton>
            </TableCell>
        </TableRow>
  )
}

export default VerDetallesPagos
