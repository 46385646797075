import React from 'react';
import * as FileSaver from "file-saver";
import * as XLSX from "sheetjs-style";
import { Button, Tooltip } from '@mui/material';
import excel from '../../../images/exportarPdfExcel/excel.png'

export default function ExportExcel(props) {
    const fileType = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    const fileExtension = ".xlsx";
    const [datosReporte, setDatosReporte] = React.useState([]);

    const exportToCSV = () => {
        if (props.tipo === "resumenRegistros") {
            const csvData = props.data;
            let datosR = [].concat(datosReporte);
            csvData.forEach((d) => {
                datosR.push({
                    'PACIENTE': d.paciente,
                    'TRATAMIENTO': d.tratamiento,
                    'CONTROL': d.controles,
                    'CONTADO': d.efectivo,
                    'TARJETA': d.tarjeta,
                    'DEPOSITO': d.deposito,
                    'FECHA': d.fecha,
                    'FAMILIA': d.familia,
                });
            });
            setDatosReporte(datosR);
            // console.log(datosR)
            const ws = XLSX.utils.json_to_sheet(datosR, { skipHeader: false });
            var wscols = [
                { wch: 25 },
                { wch: 25 },
                { wch: 10 },
                { wch: 10 },
                { wch: 10 },
                { wch: 10 },
                { wch: 20 },
                { wch: 25 },
            ];
            ws['!cols'] = wscols;
            const wb = { Sheets: { exportar: ws }, SheetNames: ['exportar'] };
            const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
            const data = new Blob([excelBuffer], { type: fileType });
            FileSaver.saveAs(data, props.nameReport + fileExtension);
            setDatosReporte([]);
        }
        if (props.tipo === "ventasgastosanho") {
            const csvData = props.data;
            let datosR = [].concat(datosReporte);
            csvData.forEach((d) => {
                datosR.push({
                    'MES': d.periodo,
                    'VENTAS': d.ventas,
                    'CREC %': d.crec_ventas,
                    'GASTOS': d.gastos,
                    'CREC %.': d.crec_gastos,
                    'RES.': d.resumen,
                    'CREC %,': d.crec_resumen,
                });
            });
            setDatosReporte(datosR);
            // console.log(datosR)
            const ws = XLSX.utils.json_to_sheet(datosR, { skipHeader: false });
            var wscols = [
                { wch: 15 },
                { wch: 15 },
                { wch: 10 },
                { wch: 10 },
                { wch: 10 },
                { wch: 10 },
                { wch: 10 },
            ];
            ws['!cols'] = wscols;
            const wb = { Sheets: { exportar: ws }, SheetNames: ['exportar'] };
            const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
            const data = new Blob([excelBuffer], { type: fileType });
            FileSaver.saveAs(data, props.nameReport + fileExtension);
            setDatosReporte([]);
        }
        if (props.tipo === "ventaspormes") {
            const csvData = props.data;
            let datosR = [].concat(datosReporte);
            csvData.forEach((d) => {
                datosR.push({
                    'AÑO': d.anho,
                    'VENTAS': d.ventas,
                    'CREC %': d.crec_ventas,
                    'GASTOS': d.gastos,
                    'CREC %.': d.crec_gastos,
                    'RES.': d.resumen,
                    'CREC %,': d.crec_resumen,
                });
            });
            setDatosReporte(datosR);
            // console.log(datosR)
            const ws = XLSX.utils.json_to_sheet(datosR, { skipHeader: false });
            var wscols = [
                { wch: 10 },
                { wch: 15 },
                { wch: 10 },
                { wch: 10 },
                { wch: 10 },
                { wch: 10 },
                { wch: 10 },
            ];
            ws['!cols'] = wscols;
            const wb = { Sheets: { exportar: ws }, SheetNames: ['exportar'] };
            const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
            const data = new Blob([excelBuffer], { type: fileType });
            FileSaver.saveAs(data, props.nameReport + fileExtension);
            setDatosReporte([]);
        }
        if (props.tipo === "resumenporanho") {
            const csvData = props.data;
            let datosR = [].concat(datosReporte);
            csvData.forEach((d) => {
                datosR.push({
                    'AÑO': d.anho,
                    'VENTAS': d.ventas,
                    'CREC %': d.crec_ventas,
                    'GASTOS': d.gastos,
                    'CREC %.': d.crec_gastos,
                    'RES.': d.resumen,
                    'CREC %,': d.crec_resumen,
                });
            });
            setDatosReporte(datosR);
            // console.log(datosR)
            const ws = XLSX.utils.json_to_sheet(datosR, { skipHeader: false });
            var wscols = [
                { wch: 10 },
                { wch: 15 },
                { wch: 10 },
                { wch: 10 },
                { wch: 10 },
                { wch: 10 },
                { wch: 10 },
            ];
            ws['!cols'] = wscols;
            const wb = { Sheets: { exportar: ws }, SheetNames: ['exportar'] };
            const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
            const data = new Blob([excelBuffer], { type: fileType });
            FileSaver.saveAs(data, props.nameReport + fileExtension);
            setDatosReporte([]);
        }
        if (props.tipo === "resumentipopago") {
            const csvData = props.data;
            let datosR = [].concat(datosReporte);
            csvData.forEach((d) => {
                datosR.push({
                    'MES': d.periodo,
                    'CONTADO': d.efectivo,
                    'POS': d.tarjeta,
                    'DEP/TRANF': d.deposito,
                    'VENTAS': d.total,
                });
            });
            setDatosReporte(datosR);
            // console.log(datosR)
            const ws = XLSX.utils.json_to_sheet(datosR, { skipHeader: false });
            var wscols = [
                { wch: 10 },
                { wch: 15 },
                { wch: 10 },
                { wch: 10 },
                { wch: 10 },
            ];
            ws['!cols'] = wscols;
            const wb = { Sheets: { exportar: ws }, SheetNames: ['exportar'] };
            const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
            const data = new Blob([excelBuffer], { type: fileType });
            FileSaver.saveAs(data, props.nameReport + props.dataFechas.tipopagoanho + fileExtension);
            setDatosReporte([]);
        }
        // reporte tratamientos
        if (props.tipo === "PREB") {
            const csvData = props.data;
            let datosR = [].concat(datosReporte);
            csvData.filter(res => res.fk_ven_familia_general === "PREB").forEach((d) => {
                datosR.push({
                    'TRATAMIENTO': d.nombre,
                    'PRIMERA': d.primera,
                    'ULTIMA': d.ultima,
                    'VENTAS': d.total,
                    'REP %': (d.total * 100 / csvData.filter(res => res.fk_ven_familia_general === "PREB").reduce((total, item) => parseFloat(total) + parseFloat(item.total), 0)).toFixed(2),
                });
            });
            datosR.push({
                'TRATAMIENTO': 'TOTAL',
                'PRIMERA': '',
                'ULTIMA': '',
                'VENTAS': '',
                'REP %': 'S/' + csvData.filter(res => res.fk_ven_familia_general === "PREB").reduce((total, item) => parseFloat(total) + parseFloat(item.total), 0).toFixed(2),
            });
            setDatosReporte(datosR);
            console.log(datosR)

            const ws = XLSX.utils.json_to_sheet(datosR, { skipHeader: false });
            var wscols = [
                { wch: 25 },
                { wch: 15 },
                { wch: 10 },
                { wch: 10 },
                { wch: 10 },
            ];
            ws['!cols'] = wscols;
            const wb = { Sheets: { exportar: ws }, SheetNames: ['exportar'] };
            const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
            const data = new Blob([excelBuffer], { type: fileType });
            FileSaver.saveAs(data, props.nameReport + fileExtension);
            setDatosReporte([]);
        }
        if (props.tipo === "BRAC") {
            const csvData = props.data;
            let datosR = [].concat(datosReporte);
            csvData.filter(res => res.fk_ven_familia_general === "BRAC").forEach((d) => {
                datosR.push({
                    'TRATAMIENTO': d.nombre,
                    'PRIMERA': d.primera,
                    'ULTIMA': d.ultima,
                    'VENTAS': d.total,
                    'REP %': (d.total * 100 / csvData.filter(res => res.fk_ven_familia_general === "BRAC").reduce((total, item) => parseFloat(total) + parseFloat(item.total), 0)).toFixed(2),
                });
            });
            datosR.push({
                'TRATAMIENTO': 'TOTAL',
                'PRIMERA': '',
                'ULTIMA': '',
                'VENTAS': '',
                'REP %': 'S/' + csvData.filter(res => res.fk_ven_familia_general === "BRAC").reduce((total, item) => parseFloat(total) + parseFloat(item.total), 0).toFixed(2),
            });
            setDatosReporte(datosR);
            console.log(datosR)

            const ws = XLSX.utils.json_to_sheet(datosR, { skipHeader: false });
            var wscols = [
                { wch: 25 },
                { wch: 15 },
                { wch: 10 },
                { wch: 10 },
                { wch: 10 },
            ];
            ws['!cols'] = wscols;
            const wb = { Sheets: { exportar: ws }, SheetNames: ['exportar'] };
            const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
            const data = new Blob([excelBuffer], { type: fileType });
            FileSaver.saveAs(data, props.nameReport + fileExtension);
            setDatosReporte([]);
        }
        if (props.tipo === "OTRO") {
            const csvData = props.data;
            let datosR = [].concat(datosReporte);
            csvData.filter(res => res.fk_ven_familia_general === "OTRO").forEach((d) => {
                datosR.push({
                    'TRATAMIENTO': d.nombre,
                    'PRIMERA': d.primera,
                    'ULTIMA': d.ultima,
                    'VENTAS': d.total,
                    'REP %': (d.total * 100 / csvData.filter(res => res.fk_ven_familia_general === "OTRO").reduce((total, item) => parseFloat(total) + parseFloat(item.total), 0)).toFixed(2),
                });
            });
            datosR.push({
                'TRATAMIENTO': 'TOTAL',
                'PRIMERA': '',
                'ULTIMA': '',
                'VENTAS': '',
                'REP %': 'S/' + csvData.filter(res => res.fk_ven_familia_general === "OTRO").reduce((total, item) => parseFloat(total) + parseFloat(item.total), 0).toFixed(2),
            });
            setDatosReporte(datosR);
            console.log(datosR)

            const ws = XLSX.utils.json_to_sheet(datosR, { skipHeader: false });
            var wscols = [
                { wch: 25 },
                { wch: 15 },
                { wch: 10 },
                { wch: 10 },
                { wch: 10 },
            ];
            ws['!cols'] = wscols;
            const wb = { Sheets: { exportar: ws }, SheetNames: ['exportar'] };
            const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
            const data = new Blob([excelBuffer], { type: fileType });
            FileSaver.saveAs(data, props.nameReport + fileExtension);
            setDatosReporte([]);
        }
        if (props.tipo === "RESE") {
            const csvData = props.data;
            let datosR = [].concat(datosReporte);
            csvData.filter(res => res.fk_ven_familia_general === "RESE").forEach((d) => {
                datosR.push({
                    'TRATAMIENTO': d.nombre,
                    'PRIMERA': d.primera,
                    'ULTIMA': d.ultima,
                    'VENTAS': d.total,
                    'REP %': (d.total * 100 / csvData.filter(res => res.fk_ven_familia_general === "RESE").reduce((total, item) => parseFloat(total) + parseFloat(item.total), 0)).toFixed(2),
                });
            });
            datosR.push({
                'TRATAMIENTO': 'TOTAL',
                'PRIMERA': '',
                'ULTIMA': '',
                'VENTAS': '',
                'REP %': 'S/' + csvData.filter(res => res.fk_ven_familia_general === "RESE").reduce((total, item) => parseFloat(total) + parseFloat(item.total), 0).toFixed(2),
            });
            setDatosReporte(datosR);
            console.log(datosR)

            const ws = XLSX.utils.json_to_sheet(datosR, { skipHeader: false });
            var wscols = [
                { wch: 25 },
                { wch: 15 },
                { wch: 10 },
                { wch: 10 },
                { wch: 10 },
            ];
            ws['!cols'] = wscols;
            const wb = { Sheets: { exportar: ws }, SheetNames: ['exportar'] };
            const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
            const data = new Blob([excelBuffer], { type: fileType });
            FileSaver.saveAs(data, props.nameReport + fileExtension);
            setDatosReporte([]);
        }
    };

    return (
        <>
            <Button startIcon={<img style={{ width: '20px', height: '20px' }} src={excel} />} sx={{ display: { xs: "none", md: "flex" } }} fullWidth variant='contained' color="success" onClick={exportToCSV}>EXCEL</Button>
            <Tooltip title="EXPORTAR EXCEL" placement='top'>
                <Button startIcon={<img style={{ width: '20px', height: '24px' }} src={excel} />} sx={{ display: { xs: "flex", md: "none" } }} fullWidth variant='contained' color="success" onClick={exportToCSV}></Button>
            </Tooltip>
        </>

    )
}
