import React, { useState } from 'react';
import './App.css';
import Login from './screens/Login';
import { Box } from '@mui/system';
import NewAppBar from './components/newAppBar';
import RutasPermitidas from './components/routes/RutasPermitidas';
import axios from 'axios';
import Constantes from './components/Constantes';
import { Route, Routes } from 'react-router-dom';

export default function App() {
  const userJson = JSON.parse(sessionStorage.getItem('logedAppUser'));
  const [logeado, setLogeado] = useState(userJson);
  const validacionLogueado = (userJson) => {
    setLogeado(userJson);
  }
  return (
    <>
      {
        logeado ?
          <Box>
            <NewAppBar userName={userJson.usuario} />
            <RutasPermitidas userName={userJson.usuario} />
          </Box>
          :
          <Routes>
            <Route path="*" element={<Login parentCallback={validacionLogueado} />} />
          </Routes>
      }
    </>
  )
}
