import * as React from 'react';

import { Page, Text, Image, Document, StyleSheet, View } from "@react-pdf/renderer";
import Logo from '../../../images/logo.png';
import Constantes from '../../Constantes';

const styles = StyleSheet.create({
    body: {
        paddingTop: 35,
        paddingBottom: 65,
        paddingHorizontal: 35,
    },
    title: {
        fontSize: 20,
        fontWeight: 'bold',
        // textAlign: 'center',
        // fontFamily: 'Oswald'
    },
    author: {
        fontSize: 12,
        textAlign: 'center',
        marginBottom: 40,
    },
    subtitle: {
        fontSize: 18,
        margin: 12,
        fontFamily: 'Oswald'
    },
    text: {
        margin: 12,
        fontSize: 14,
        textAlign: 'justify',
        fontFamily: 'Times-Roman'
    },
    image: {
        marginVertical: 10,
        marginHorizontal: 50,
        width: '45px',
        height: '45px'
    },
    header: {
        fontSize: 12,
        marginBottom: 20,
        textAlign: 'center',
        color: 'grey',
    },
    pageNumber: {
        position: 'absolute',
        fontSize: 8,
        bottom: 30,
        left: 0,
        right: 40,
        textAlign: 'right',
        color: 'grey',
    },
});
export default function ReporteCajaPdf(props) {
    const datosEmpresa = props.datosEmpresa;
    const datosCaja = props.datosCaja;
    const estilosTablaPdf = { padding: "4px 0" };
    const pagosCajaCobranza = props.pagosCajaCobranza;
    const tiposPago = props.tiposPago;
    let total_efectivo = 0;
    let total_deposito = 0;
    let total_pos = 0;
    const efectivo = tiposPago.filter(f => f.tipo_pago === "E");
    const deposito = tiposPago.filter(f => f.tipo_pago === "D");
    const pos = tiposPago.filter(f => f.tipo_pago === "T");

    efectivo.length > 0 ? efectivo.forEach(e => total_efectivo = e.total) : total_efectivo = 0;
    deposito.length > 0 ? deposito.forEach(e => total_deposito = e.total) : total_deposito = 0;
    pos.length > 0 ? pos.forEach(e => total_pos = e.total) : total_pos = 0;

    return (
        <Document>
            <Page style={styles.body} orientation="landscape">
                <View style={{ display: 'fixed', flexDirection: 'row', paddingBottom: "10px" }} fixed>
                    <View style={{ display: 1 }}>
                        <Image
                            style={{ width: '55px', height: '50px', padding: "0 5px" }}
                            src={Logo}
                        />
                    </View>
                    <View style={{ display: 2, marginHorizontal: '15px', width: '50%' }} >
                        <Text style={{ fontSize: '16px' }}>{datosEmpresa.razon_social}</Text>
                        <Text style={{ fontSize: '10px', marginTop: '5px' }}>{datosEmpresa.ruc}</Text>
                        <Text style={{ fontSize: '10px', marginTop: '5px' }}>{"Av. Demo Mz. Demo"}</Text>
                    </View>
                    <View style={{ display: 3 }}>
                        <Text style={{ fontSize: '16px' }}>REPORTE RESUMEN CAJA COBRANZA</Text>
                        <View style={{ display: 'flex', flexDirection: 'row', padding: "10px 0" }}>
                            <View style={{ display: 1, width: '150px', margin: '2px' }}>
                                <Text style={{ fontSize: '10px' }}>Fecha apertura:  {datosCaja.fecha_apertura}</Text>
                            </View>
                        </View>
                    </View>
                </View>
                <View style={{ display: 'flex', flexDirection: 'row', color: '#fff', backgroundColor: '#1976D2', padding: "4px 0" }} fixed>
                    <View style={{ display: 1, width: '50x', margin: '2px' }}>
                        <Text style={{ fontSize: '8px', textAlign: 'center' }}>N° Pago</Text>
                    </View>
                    <View style={{ display: 2, width: '100x', margin: '2px' }}>
                        <Text style={{ fontSize: '8px', textAlign: 'center' }}>F. registro</Text>
                    </View>
                    <View style={{ display: 3, width: '50px', margin: '2px' }}>
                        <Text style={{ fontSize: '8px', textAlign: 'center' }}>N° Couta</Text>
                    </View>
                    <View style={{ display: 4, width: '200px', margin: '2px', bgcolor: "yellow" }}>
                        <Text style={{ fontSize: '8px' }}>Tratamiento</Text>
                    </View>
                    <View style={{ display: 5, width: '200px', margin: '2px' }}>
                        <Text style={{ fontSize: '8px' }}>Cliente</Text>
                    </View>
                    <View style={{ display: 6, width: '50px', margin: '2px', bgcolor: "yellow" }}>
                        <Text style={{ fontSize: '8px', textAlign: 'center' }}>Controles</Text>
                    </View>
                    <View style={{ display: 7, width: '200px', margin: '2px' }}>
                        <Text style={{ fontSize: '8px' }}>Atentido por</Text>
                    </View>
                    <View style={{ display: 8, width: '100px', margin: '2px' }}>
                        <Text style={{ fontSize: '8px', textAlign: 'center' }}>Presupuesto</Text>
                    </View>
                    <View style={{ display: 9, width: '100px', margin: '2px' }}>
                        <Text style={{ fontSize: '8px', textAlign: 'center' }}>Pagado</Text>
                    </View>
                </View>
                {pagosCajaCobranza.length !== 0 &&
                    pagosCajaCobranza.map((m, index) => (
                        <div key={index}>
                            <View key={index} style={{ display: 'flex', flexDirection: 'row', backgroundColor: '#EBF8FF', padding: estilosTablaPdf.padding }}>
                                <View style={{ display: 1, width: '50x', margin: '2px' }}>
                                    <Text style={{ fontSize: '8pt', textAlign: 'center' }}>{m.id_pago}</Text>
                                </View>
                                <View style={{ display: 2, width: '100px', margin: '2px' }}>
                                    <Text style={{ fontSize: '8pt', textAlign: 'center' }}>{m.fecha_registro + " " + m.hora_registro}</Text>
                                </View>
                                <View style={{ display: 3, width: '50px', margin: '2px' }}>
                                    <Text style={{ fontSize: '8pt', textAlign: 'center' }}>{m.cuota}</Text>
                                </View>
                                <View style={{ display: 4, width: '200px', margin: '2px' }}>
                                    <Text style={{ fontSize: '8pt' }}>{m.tratamiento}</Text>
                                </View>
                                <View style={{ display: 5, width: '200px', margin: '2px' }}>
                                    <Text style={{ fontSize: '8pt' }}>{m.cliente}</Text>
                                </View>
                                <View style={{ display: 6, width: '50px', margin: '2px', bgcolor: "red" }}>
                                    <Text style={{ fontSize: '8pt', textAlign: 'center' }}>{m.controles}</Text>
                                </View>
                                <View style={{ display: 7, width: '200px', margin: '2px' }}>
                                    <Text style={{ fontSize: '8pt' }}>{m.atendido_por}</Text>
                                </View>
                                <View style={{ display: 8, width: '100px', margin: '2px' }}>
                                    <Text style={{ fontSize: '8pt', textAlign: 'center' }}>{m.presupuesto}</Text>
                                </View>
                                <View style={{ display: 9, width: '100px', margin: '2px' }}>
                                    <Text style={{ fontSize: '8pt', textAlign: 'center' }}>{m.total_pago}</Text>
                                </View>
                                <br />
                            </View>
                        </div>
                    ))
                }
                <View style={{ display: 'flex', flexDirection: 'row', color: '#fff', backgroundColor: '#1976D2', padding: "4px 0" }}>
                    <View style={{ display: 1, width: '2px', margin: '2px' }}>
                        <Text style={{ fontSize: '8pt' }}></Text>
                    </View>
                    <View style={{ display: 2, width: '50px', margin: '2px' }}>
                        <Text style={{ fontSize: '8pt' }}>TOTAL</Text>
                    </View>
                    <View style={{ display: 3, width: '750px', margin: '2px' }}>
                        <Text style={{ fontSize: '8pt' }}></Text>
                    </View>
                    <View style={{ display: 4, width: '80px', margin: '2px' }}>
                        <Text style={{ fontSize: '8pt', textAlign: 'right' }}>S/ {pagosCajaCobranza.reduce((total, item) => parseFloat(total) + parseFloat(item.total_pago), 0).toFixed(2)}</Text>
                    </View>
                    <View style={{ display: 5, width: '10px', margin: '2px' }}>
                        <Text style={{ fontSize: '8pt', textAlign: 'right' }}></Text>
                    </View>
                </View>
                <Text style={{ fontSize: 8, left: 40, right: 0, textAlign: 'left', bottom: 30, position: 'absolute', color: 'grey', }} fixed>Fecha: {new Date().toJSON().slice(0, 10)}</Text>
                <Text style={{ fontSize: 8, left: 130, right: 0, textAlign: 'left', bottom: 30, position: 'absolute', color: 'grey', }} fixed>Hora: {new Date().toLocaleTimeString()}</Text>
                <Text style={styles.pageNumber} render={({ pageNumber, totalPages }) => (
                    `Pag ${pageNumber}`
                )} fixed />
            </Page>
            <Page style={styles.body} orientation="landscape">
                <View style={{ display: 'fixed', flexDirection: 'row', paddingBottom: "10px" }} fixed>
                    <View style={{ display: 1 }}>
                        <Image
                            style={{ width: '55px', height: '50px', padding: "0 5px" }}
                            src={Logo}
                        />
                    </View>
                    <View style={{ display: 2, marginHorizontal: '15px', width: '50%' }} >
                        <Text style={{ fontSize: '16px' }}>{datosEmpresa.razon_social}</Text>
                        <Text style={{ fontSize: '10px', marginTop: '5px' }}>{datosEmpresa.ruc}</Text>
                        <Text style={{ fontSize: '10px', marginTop: '5px' }}>{"Av. Demo Mz. Demo"}</Text>
                    </View>
                    <View style={{ display: 3 }}>
                        <Text style={{ fontSize: '16px' }}>REPORTE RESUMEN CAJA COBRANZA</Text>
                        <View style={{ display: 'flex', flexDirection: 'row', padding: "10px 0" }}>
                            <View style={{ display: 1, width: '150px', margin: '2px' }}>
                                <Text style={{ fontSize: '10px' }}>Fecha apertura:  {datosCaja.fecha_apertura}</Text>
                            </View>
                        </View>
                    </View>
                </View>
                <div style={{ display: 'flex', flexDirection: 'row', marginTop: '20px' }}>
                    <div style={{ width: '250px', marginHorizontal: '85px' }}>
                        <View style={{ display: 'flex', flexDirection: 'row', color: '#fff', backgroundColor: '#1976D2' }}>
                            <View style={{ display: 1, width: '100%', margin: '2px', textAlign: 'center' }}>
                                <Text style={{ fontSize: '8px' }}>RESUMEN DE CAJA</Text>
                            </View>
                        </View>
                        <View style={{ display: 'flex', flexDirection: 'row', backgroundColor: '#EBF8FF' }} >
                            <View style={{ display: 1, width: '250px', margin: '5px' }}>
                                <Text style={{ fontSize: '8px' }}>TOTAL EFECTIVO</Text>
                            </View>
                            <View style={{ display: 2, width: '250px', margin: '2px', textAlign: 'right' }}>
                                <Text style={{ fontSize: '8px' }}>S/. {total_efectivo}</Text>
                            </View>
                        </View>
                        <View style={{ display: 'flex', flexDirection: 'row', backgroundColor: '#EBF8FF' }} >
                            <View style={{ display: 1, width: '250px', margin: '5px' }}>
                                <Text style={{ fontSize: '8px' }}>TOTAL POS</Text>
                            </View>
                            <View style={{ display: 2, width: '250px', margin: '2px', textAlign: 'right' }}>
                                <Text style={{ fontSize: '8px' }}>S/. {total_pos}</Text>
                            </View>
                        </View>
                        <View style={{ display: 'flex', flexDirection: 'row', backgroundColor: '#EBF8FF' }} >
                            <View style={{ display: 1, width: '250px', margin: '5px' }}>
                                <Text style={{ fontSize: '8px' }}>TOTAL DEPOSITO</Text>
                            </View>
                            <View style={{ display: 2, width: '250px', margin: '2px', textAlign: 'right' }}>
                                <Text style={{ fontSize: '8px' }}>S/. {total_deposito}</Text>
                            </View>
                        </View>
                        <View style={{ display: 'flex', flexDirection: 'row', backgroundColor: '#1976D2', color: '#fff' }} >
                            <View style={{ display: 1, width: '250px', margin: '5px' }}>
                                <Text style={{ fontSize: '8px' }}>TOTAL</Text>
                            </View>
                            <View style={{ display: 2, width: '250px', margin: '2px', textAlign: 'right' }}>
                                <Text style={{ fontSize: '8px' }}>S/. {pagosCajaCobranza.reduce((total, item) => parseFloat(total) + parseFloat(item.total_pago), 0).toFixed(2)}</Text>
                            </View>
                        </View>
                    </div>
                    <div style={{ width: '250px', marginLeft: '20px' }}>
                        <View style={{ display: 'flex', flexDirection: 'row', color: '#fff', backgroundColor: '#1976D2' }} >
                            <View style={{ display: 1, width: '100%', margin: '2px', textAlign: 'center' }}>
                                <Text style={{ fontSize: '8px' }}>RESUMEN DE EFECTIVO</Text>
                            </View>
                        </View>
                        <View style={{ display: 'flex', flexDirection: 'row', backgroundColor: '#EBF8FF' }}>
                            <View style={{ display: 1, width: '250px', margin: '5px' }}>
                                <Text style={{ fontSize: '8px' }}>EFECTIVO A RECEPCIONAR</Text>
                            </View>
                            <View style={{ display: 2, width: '250px', margin: '2px' }}>
                                <Text style={{ fontSize: '8px' }}>S/. {total_efectivo}</Text>
                            </View>
                        </View>
                    </div>
                </div>
                <Text style={{ fontSize: 8, left: 40, right: 0, textAlign: 'left', bottom: 30, position: 'absolute', color: 'grey', }} fixed>Fecha: {new Date().toJSON().slice(0, 10)}</Text>
                <Text style={{ fontSize: 8, left: 130, right: 0, textAlign: 'left', bottom: 30, position: 'absolute', color: 'grey', }} fixed>Hora: {new Date().toLocaleTimeString()}</Text>
                <Text style={styles.pageNumber} render={({ pageNumber, totalPages }) => (
                    `Pag ${pageNumber}`
                )} fixed />
            </Page>
        </Document >
    )
}