import { Button, Chip, Grid, Typography } from '@mui/material'
import React from 'react'
import { useNavigate } from 'react-router-dom';
// import img404 from '../img/404.svg';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import SendIcon from '@mui/icons-material/Send';
export default function Error404() {
  const navigate = useNavigate();
  return (
    <Grid container style={{ justifyContent: 'center', minHeight: '80vh' }}>
      <Grid item md={12} style={{ textAlign: 'center', margin: 'auto', padding: '0.5em' }} xs={12} >
        <Typography className='error404' variant='h1'>404</Typography>
        <Typography variant='h5'>Lo sentimos, pero no se encontró la página solicitada</Typography>
        <Grid container spacing={2} style={{ marginTop: '20px' }}>
          <Grid item md={4}></Grid>
          <Grid item md={2}>
            <Button fullWidth startIcon={<ArrowBackIosIcon />} onClick={() => navigate("/")} className='btn404'>REGRESAR</Button>
          </Grid>
          <Grid item md={2}>
            <Button fullWidth endIcon={<SendIcon />} className='btn404Outlined'>CONTACTAR</Button>
          </Grid>
          <Grid item md={4}></Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}
