import { LoadingButton } from '@mui/lab';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControl, Grid, IconButton, InputAdornment, InputLabel, MenuItem, Select, TextField, Tooltip } from '@mui/material'
import React, { useEffect } from 'react'
import Constantes from '../../Constantes';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import MedicalInformationOutlinedIcon from '@mui/icons-material/MedicalInformationOutlined';
import Trabajador from '../../sistema/trabajador';
function ControlSinPago(props) {
    const hoy = new Date();
    const strFecha = `${hoy.getFullYear()}-${(hoy.getMonth() + 1).toString().padStart(2, '0')}-${hoy.getDate().toString().padStart(2, '0')}`;
    const strHora = `${hoy.getHours().toString().padStart(2, '0')}:${hoy.getMinutes().toString().padStart(2, '0')}:${hoy.getSeconds().toString().padStart(2, '0')}`;
    const [procesando, setProcesando] = React.useState(false);
    const [showPersonal, setShowPersonal] = React.useState(false);
    const [listRhAtendidoPor, setListRhAtendidoPor] = React.useState([]);
    const [confirmacion, setConfirmacion] = React.useState({
        titulo: "",
        mensaje: "",
        show: false,
    });
    const [pago, setPago] = React.useState({
        id: 0,
        fecha: strFecha,
        hora: strHora,
        total: 0,
        observaciones: "",
        esta_anulado: "NO",
        fk_ven_venta: "",
        fk_log_usuario_creado_por: "",
        fk_ven_cliente: "",
        fk_ven_registro_control: "",
        fk_ven_registro: "",
        controles: 0,
        total_presupuesto: 0,
        fk_rh_trabajador_atendido_por: 0,
        fk_ven_caja_cobranza: 0,
    });
    useEffect((e) => {
        if (props.cliente !== undefined) {
            setPago({ ...pago, fk_ven_cliente: props.cliente.codigo });
        }
        const cargarPresupuesto = async () => {
            const pagosFetch = await fetch(`${Constantes.RUTA_API}listar/listarTrabajadorArea.php?area=C`);
            const pagosDB = await pagosFetch.json();
            setListRhAtendidoPor(pagosDB);
            setPago((pagoant) => ({
                ...pagoant,
                total_presupuesto: props.registro.total_detalle,
                fk_ven_cliente: props.pago.fk_ven_cliente,
            }));
            setProcesando(false);
        }
        cargarPresupuesto();
        // setPago({ ...pago, fecha: new Date().toLocaleTimeString() });
    }, [props.registro.total_detalle, props.pago.fk_ven_cliente]);
    const handleChangePago = (event) => {
        let valor = event.target.value;
        const id = event.target.id;
        setPago((pagoant) => ({
            ...pagoant,
            [id]: valor
        }));
    }
    const constAgregarPago = async () => {
        setProcesando(true);
        const logedUserJSON = window.sessionStorage.getItem('logedAppUser');
        const user = JSON.parse(logedUserJSON);
        const pagonuevo = {
            id: pago.id,
            fecha: new Date().toJSON().slice(0, 10),
            hora: new Date().toLocaleTimeString(),
            total: pago.total,
            observaciones: "",
            esta_anulado: "NO",
            fk_ven_venta: "",
            fk_log_usuario_creado_por: user.usuario,
            fk_ven_cliente: pago.fk_ven_cliente,
            fk_ven_registro_control: props.registro.control,
            fk_ven_registro: props.registro.fk_ven_registro,
            controles: pago.controles,
            fk_rh_trabajador_atendido_por: pago.fk_rh_trabajador_atendido_por,
            fk_ven_caja_cobranza: props.cajaAbierta.id
        };
        const guardarPago = await fetch(`${Constantes.RUTA_API}mantenimiento/guardarPago.php`, {
            method: "POST",
            body: JSON.stringify(pagonuevo),
        });
        const pagosRes = await guardarPago.json();
        if (pagosRes.guardado) {
            const controlSinPago = {
                linea: 1,
                monto_capital: 0,
                intereses: 0,
                mora: 0,
                tipo_pago: "E",
                fecha_operacion: pago.fecha,
                referencia: "",
                fk_ven_pago: pagosRes.id,
                fk_fin_banco: "",
            }
            const guardarDetalleEfectivo = await fetch(`${Constantes.RUTA_API}mantenimiento/guardarPagoDetalle.php`, {
                method: "POST",
                body: JSON.stringify(controlSinPago),
            });
            setConfirmacion({
                titulo: "PAGO GUARDADO",
                mensaje: "el pago fue guardado con exito.",
                show: true,
            })
        } else {
            setConfirmacion({
                titulo: "ERROR",
                mensaje: "Ocurrió un error al guardar el pago intente de nuevo.",
                show: true,
            })
        }
        setTimeout(() => {
            setProcesando(false);
            props.parentClose();
        }, 2000);
    }
    const hidePago = () => {
        setProcesando(true);
        setPago({
            id: 0,
            fecha: strFecha,
            hora: strHora,
            total: 0,
            observaciones: "",
            esta_anulado: "NO",
            fk_ven_venta: "",
            fk_log_usuario_creado_por: "",
            fk_ven_cliente: "",
            fk_ven_registro_control: "",
            fk_ven_registro: "",
            controles: 0,
            total_presupuesto: 0,
            fk_rh_trabajador_atendido_por: 0,
        });
        setProcesando(false);
        props.parentClose();
    }
    const handleChangeAtendidoPor = (event) => {
        setPago((pagoAnt) => ({
            ...pagoAnt,
            fk_rh_trabajador_atendido_por: event.target.value,
        }));
    }
    const closeTrabajador = async () => {
        setShowPersonal(false);
        const pagosFetch = await fetch(`${Constantes.RUTA_API}listar/listarTrabajadorArea.php?area=C`);
        const pagosDB = await pagosFetch.json();
        setListRhAtendidoPor(pagosDB);
    }
    return (
        <Grid container spacing={2} sx={{ mt: 1 }}>
            <Dialog open={showPersonal}>
                <DialogContent>
                    <Trabajador parentClose={closeTrabajador} area={'A'} />
                </DialogContent>
            </Dialog>
            <Dialog open={confirmacion.show}>
                <DialogTitle>{confirmacion.titulo}</DialogTitle>
                <DialogContent>
                    <DialogContentText>{confirmacion.mensaje}</DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button variant="outlined" color="secondary" startIcon={<CheckCircleIcon />} onClick={() => { setConfirmacion({ titulo: "", mensaje: "", show: false, }) }}>Aceptar</Button>
                </DialogActions>
            </Dialog>
            <Grid item xs={4}>
                <TextField
                    label="Fecha: "
                    fullWidth
                    variant='filled'
                    type='date'
                    id='fecha'
                    value={props.cajaAbierta.fecha_apertura}
                    // onChange={handleChangePago}
                    disabled
                />
            </Grid>
            {/* <Grid item xs={4}>
                <TextField
                    label="Fecha"
                    fullWidth
                    variant='filled'
                    type='date'
                    id='fecha'
                    value={pago.fecha}
                    // onChange={handleChangePago}
                    disabled
                />
            </Grid> */}
            <Grid item xs={4}>
                <TextField
                    label="Controles"
                    fullWidth
                    variant='filled'
                    type="text"
                    id='controles'
                    value={pago.controles}
                    onChange={handleChangePago}
                />
            </Grid>
            <Grid item xs={7}>
                <FormControl fullWidth>
                    <InputLabel id="select-AtendidoPor">Atendido por:</InputLabel>
                    <Select
                        labelId="select-AtendidoPor-lb"
                        id="select-AtendidoPorS"
                        value={pago.fk_rh_trabajador_atendido_por>0?pago.fk_rh_trabajador_atendido_por:""}
                        onChange={handleChangeAtendidoPor}
                        fullWidth
                        label="Atendido por:"
                        defaultValue=""
                    >
                        {listRhAtendidoPor.map((rh) => (
                            <MenuItem value={rh.id} key={rh.id}>{`${rh.colegiatura} | ${rh.apellidos_nombres}`}</MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </Grid>
            <Grid item xs={1}>
                <Tooltip title="Agregar personal">
                    <IconButton color='info' size='large' onClick={() => { setShowPersonal(true) }}><MedicalInformationOutlinedIcon fontSize='large' /></IconButton>
                </Tooltip>
            </Grid>
            <Grid item xs={4}>
                <TextField
                    label="Presupuesto"
                    fullWidth
                    variant='filled'
                    type="number"
                    value={pago.total_presupuesto}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                S/
                            </InputAdornment>
                        ),
                    }}
                />
            </Grid>

            <Grid item xs={6} align="right">
                <LoadingButton onClick={constAgregarPago} loading={procesando} variant='outlined' color='success'>AGREGAR</LoadingButton>
            </Grid>
            <Grid item xs={6} >
                <LoadingButton onClick={hidePago} loading={procesando} variant='outlined' color='error'>CANCELAR</LoadingButton>
            </Grid>
        </Grid>
    )
}

export default ControlSinPago