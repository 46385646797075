import { Check, Close, HelpOutline } from '@mui/icons-material';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, TextField, Typography } from '@mui/material';
import React from 'react'
import Constantes from '../../../Constantes';
import FilaListarUsuarioE from './filaListarUsuarioE';

export default function Eliminados(props) {
    const setAlertGeneral = props.setAlertGeneral;
    const [usuarios, setUsuarios] = React.useState([]);
    const [search, setSearch] = React.useState("");
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [codigoUsuarioSelect, setCodigoUsuarioSelect] = React.useState('');
    // dialog 
    const [restoreUsuario, setRestoreUsuario] = React.useState(false);

    const headCells = [
        {
            id: 'codigo',
            numeric: false,
            disablePadding: true,
            label: 'CODIGO',
        },
        {
            id: 'fk_log_perfil',
            numeric: false,
            disablePadding: true,
            label: 'PERFIL',
        },
        {
            id: 'fk_log_usuario_creado_por',
            numeric: false,
            disablePadding: true,
            label: 'CREADO POR',
        },
        {
            id: 'deleted_at',
            numeric: false,
            disablePadding: true,
            label: 'F. DE ELIMINACION',
        }
    ];
    // handle
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };
    const handleRestoreUsuario = (codigo) => {
        setRestoreUsuario(true);
        setCodigoUsuarioSelect(codigo);
    }
    // functions
    const listarUsuarios = async () => {
        const getUsuarios = await fetch(`${Constantes.RUTA_API}listar/listarUsuariosEliminados.php`);
        const response = await getUsuarios.json();
        setUsuarios(response);
    }
    const searchUsuario = () => {
        if (search === "") {
            return usuarios
        } else {
            let filtro = usuarios.filter(f => f.codigo.includes(search.toUpperCase()));
            return filtro;
        }
    }
    const restoreUsuarioCodigo = async () => {
        const restore_usuario = await fetch(`${Constantes.RUTA_API}mantenimiento/restaurarUsuario.php?codigo=${codigoUsuarioSelect}`);
        const response = await restore_usuario.json();
        if (response) {
            setAlertGeneral({ severity: 'success', message: 'Usuario restaurado', show: true });
            setTimeout(() => {
                setAlertGeneral({ severity: 'warning', message: '', show: false });
            }, 3000);
        } else {
            setAlertGeneral({ severity: 'error', message: 'No se pudo restaurar el usuario', show: true });
            setTimeout(() => {
                setAlertGeneral({ severity: 'warning', message: '', show: false });
            }, 3000);
        }
        setRestoreUsuario(false);
        listarUsuarios();
    }
    React.useEffect(() => {
        listarUsuarios();
    }, []);
    return (
        <Grid container>
            <Grid item xs={8}>
                <TextField
                    fullWidth
                    size="small"
                    type="text"
                    placeholder="BUSCAR POR CODIGO"
                    value={search}
                    onChange={(e) => {
                        const { value } = e.target;
                        if (value !== "") {
                            setSearch(value);
                        } else {
                            setSearch('');
                        }
                    }}
                    inputProps={{ style: { textTransform: "uppercase" } }}
                />
            </Grid>
            <Grid item md={2}></Grid>
            <Grid item md={12}>
                <Paper sx={{ width: '100%', mb: 2, mt: 2 }}>
                    <TableContainer sx={{ height: '65vh' }}>
                        <Table stickyHeader>
                            <TableHead>
                                <TableRow>
                                    {headCells.map((headCell) => (
                                        <TableCell style={{ fontWeight: 'bold' }}
                                            key={headCell.id}
                                        >
                                            {headCell.label}
                                        </TableCell>
                                    ))}
                                    <TableCell></TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {searchUsuario().map((usuario) => {
                                    return <FilaListarUsuarioE key={usuario.codigo} usuario={usuario} handleRestoreUsuario={handleRestoreUsuario}></FilaListarUsuarioE>;
                                })}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <TablePagination
                        rowsPerPageOptions={[10, 25, 50]}
                        component="div"
                        labelRowsPerPage="Filas por columnas"
                        count={usuarios.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                </Paper>
            </Grid>
            <Dialog open={restoreUsuario} onClose={() => setRestoreUsuario(false)}>
                <DialogTitle style={{ textAlign: 'center' }}>
                    <div>
                        <HelpOutline style={{ color: '#fdb924', fontSize: '80px' }} />
                    </div>
                </DialogTitle>
                <DialogContent style={{ textAlign: 'center' }}>
                    <Typography variant="h5">Restaurar Usuario</Typography>
                    ¿Esta seguro de restaurar el usuario?
                </DialogContent>
                <DialogActions style={{ justifyContent: 'center' }}>
                    <Button startIcon={<Check />} variant="contained" color="error" onClick={restoreUsuarioCodigo}>Aceptar</Button>
                    <Button startIcon={<Close />} variant="contained" onClick={() => setRestoreUsuario(false)}>Cancelar</Button>
                </DialogActions>
            </Dialog>
        </Grid>
    )
}
