import { Line } from "react-chartjs-2";
import React, { useEffect, useMemo, useState } from 'react';
import {
    Chart as Chartjs,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Filler,
    BarElement,
    Title,
    Tooltip,
    Legend,
} from "chart.js"
import html2canvas from 'html2canvas';
import { Button, Grid, Stack } from '@mui/material';
import Constantes from "../../components/Constantes";
Chartjs.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Filler,
    BarElement,
    Title,
    Tooltip,
    Legend,
);

export default function GraficoLinealHome() {
    // const [anhoVentasgastos, setAnhoVentasGastos] = React.useState(2022);
    const [valuesGraf, setValuesGraf] = React.useState({
        ventas: [],
        gastos: [],
        tags: [],
        message: "",
    });

    useEffect((e) => {
        const cargarData = async () => {
            // grafico lineal   
            const fecha = new Date();
            // setAnhoVentasGastos(fecha.getFullYear());
            const porMes = await fetch(`${Constantes.RUTA_API}reportes/reporteVentasGastosAnho.php?anho=${fecha.getFullYear()}`);
            const porMesDB = await porMes.json();
            var valuesTmpVent = [];
            var valuesTmpGast = [];
            var valuesTmpMeses = [];
            porMesDB.forEach(el => {
                valuesTmpVent.push(Number(el.ventas));
                valuesTmpGast.push(Number(el.gastos));
                valuesTmpMeses.push((el.periodo).substr(0, 3));
            })
            setValuesGraf({ ...valuesGraf, ventas: valuesTmpVent, gastos: valuesTmpGast, tags: valuesTmpMeses, message: `VENTAS Y GASTOS AÑO ${fecha.getFullYear()}` });
        }
        cargarData();
    }, []);

    const options = {
        responsive: true,
        plugins: {
            legend: {
                position: "top"
            },
            title: {
                display: true,
                text: valuesGraf.message,
            },
        },
    }
    const data = useMemo(function () {
        return {
            labels: valuesGraf.tags,
            datasets: [
                {
                    label: 'VENTAS',
                    data: valuesGraf.ventas,
                    borderColor: "rgb(75, 192, 192)",
                    backgroundColor: "rgb(75, 192, 192,0.5)",
                    fill: false,
                },
                {
                    label: 'GASTOS',
                    data: valuesGraf.gastos,
                    borderColor: "rgb(255, 99, 132)",
                    backgroundColor: "rgb(255, 99, 132,0.5)",
                    fill: false,
                }
            ]
        }
    }, [valuesGraf]);
    return (
        <Line data={data} options={options} />
    )
}
