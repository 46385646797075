import { Alert, Autocomplete, Button, Collapse, Dialog, DialogContent, DialogTitle, FormControl, Grid, IconButton, InputAdornment, InputLabel, MenuItem, Select, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, ToggleButton, ToggleButtonGroup, Tooltip } from '@mui/material';
import React, { useEffect } from 'react';
import ContentPasteSearchIcon from '@mui/icons-material/ContentPasteSearch';
import CleaningServicesIcon from '@mui/icons-material/CleaningServices';
import Constantes from '../../Constantes';
import SaveOutlinedIcon from '@mui/icons-material/SaveOutlined';
import AddBusinessIcon from '@mui/icons-material/AddBusiness';
import AddBoxOutlinedIcon from '@mui/icons-material/AddBoxOutlined';
import Proveedor from '../proveedor';
import CloseIcon from '@mui/icons-material/Close';
import BuscarCompras from '../buscarCompras';
import { DeleteOutline, ExitToAppOutlined } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';


function Compra() {
  const hoy = new Date();
  const strFecha = `${hoy.getFullYear()}-${(hoy.getMonth() + 1).toString().padStart(2, '0')}-${hoy.getDate().toString().padStart(2, '0')}`;
  const [tipoDocumentoDB, setTipoDocumentoDB] = React.useState([]);
  const [loadingCompra, setLoadingCompra] = React.useState(false);
  const [alertCompra, setAlertCompra] = React.useState({
    show: false,
    message: "",
    severity: "success",
  })
  const [canDelete, setCanDelete] = React.useState(false);
  const [compra, setCompra] = React.useState({
    id: -1,
    fecha: strFecha,
    base_imponible: 0,
    impuestos: 0,
    total_a_pagar: 0,
    tipo: 'M',
    fk_cmp_proveedor: -1,
    created_at: strFecha,
    updated_at: strFecha,
    deleted_at: "",
    serie: "",
    numero: 0,
    fk_con_tipo_documento: "00",
  })
  const [proveedorseleccionado, setProveedorSeleccionado] = React.useState({
    id: 0,
    razon_social: "",
    numero_documento: "",
    direccion: "",
    telefono: "",
    es_activo: 1,
    observaciones: "",
    fk_log_usuario_modifica: "",
    fk_log_usuario_creador: "",
    created_at: strFecha,
    uploaded_at: strFecha,
    deleted_at: strFecha,
  });
  const [proveedorDB, setProveedorDB] = React.useState([]);
  const [materialDB, setMaterialDB] = React.useState([]);
  const [equipoDB, setEquipoDB] = React.useState([]);
  const [detallesCompra, setDetallesCompra] = React.useState([]);
  const [equipoSeleccionado, setEquipoSeleccionado] = React.useState({
    codigo: "",
    descripcion: "",
    stock_minimo: 0,
    nombre: "",
    fk_alm_unidad: "",
    stock_actual: 0,
    tipo: "",
    vida_util: 0
  });
  const [materialSeleccionado, setMaterialSeleccionado] = React.useState({
    codigo: "",
    nombre: "",
    descripcion: "",
    stock_minimo: "",
    fk_alm_unidad: "",
    stock_actual: 0,
    cantidad_especifica: 0,
    fk_alm_unidad_especifica: "",
  });
  const [showProveedor, SetShowProveedor] = React.useState(false);
  const [showBusquedaCompras, setShowBusquedaCompras] = React.useState(false);

  useEffect(() => {
    const fillData = async () => {
      const tipoDocFetch = await fetch(`${Constantes.RUTA_API}listar/listarTipoDocumentoCompra.php`);
      const tipoDocDB = await tipoDocFetch.json();
      setTipoDocumentoDB(tipoDocDB);
      const materialesFetch = await fetch(`${Constantes.RUTA_API}listar/listarMateriales.php`);
      const materialesDB = await materialesFetch.json();
      setMaterialDB(materialesDB);
      const equiposFetch = await fetch(`${Constantes.RUTA_API}listar/listarEquipos.php`);
      const equiposDB = await equiposFetch.json();
      setEquipoDB(equiposDB);
    }
    fillData();
    listProveedor();
  }, []);

  const listProveedor = async () => {
    const proveedorFetch = await fetch(`${Constantes.RUTA_API}listar/listarProveedorActivo.php`);
    const listProveedorDB = await proveedorFetch.json();
    setProveedorDB(listProveedorDB);
  }
  const handleChangeTipo = (event, newValue) => {
    if (newValue) {
      clearForm();
      setCompra((compraAnt) => ({
        ...compraAnt,
        tipo: newValue,
      }));
    }
  };
  const handleChangeCompra = (e) => {
    const id = e.target.id;
    let valor = e.target.value;
    setCompra((compraAnt) => ({
      ...compraAnt,
      [id]: valor,
    }));
  }
  const handleChangeTipoDoc = (e) => {
    setCompra((compraAnt) => ({
      ...compraAnt,
      fk_con_tipo_documento: e.target.value,
    }));
  }
  const handleChangeProveedor = (e, value) => {
    if (value) {
      setProveedorSeleccionado(value);
      setCompra((compraAnt) => ({
        ...compraAnt,
        fk_cmp_proveedor: value.id,
      }));
    } else {
      setProveedorSeleccionado({
        id: 0,
        razon_social: "",
        numero_documento: "",
        direccion: "",
        telefono: "",
        es_activo: 1,
        observaciones: "",
        fk_log_usuario_modifica: "",
        fk_log_usuario_creador: "",
        created_at: strFecha,
        uploaded_at: strFecha,
        deleted_at: strFecha,
      });
      setCompra((compraAnt) => ({
        ...compraAnt,
        fk_cmp_proveedor: 0,
      }));
    }
  }
  const maintanceProveedor = () => {
    SetShowProveedor(true);
  }
  const handleCloseProveedor = () => {
    SetShowProveedor(false);
    listProveedor();
  }
  const handleChangeDetalle = (e, value) => {
    if (value) {
      if (compra.tipo === "E") {
        setEquipoSeleccionado(value);
      } else {
        setMaterialSeleccionado(value);
      }
    }
  }
  const clearForm = () => {
    setCompra({
      id: -1,
      fecha: strFecha,
      base_imponible: 0,
      impuestos: 0,
      total_a_pagar: 0,
      tipo: 'M',
      fk_cmp_proveedor: 0,
      created_at: strFecha,
      updated_at: strFecha,
      deleted_at: "",
      serie: "",
      numero: 0,
      fk_con_tipo_documento: "00",
    });
    setDetallesCompra([]);
    setProveedorSeleccionado({
      id: 0,
      razon_social: "",
      numero_documento: "",
      direccion: "",
      telefono: "",
      es_activo: 1,
      observaciones: "",
      fk_log_usuario_modifica: "",
      fk_log_usuario_creador: "",
      created_at: strFecha,
      uploaded_at: strFecha,
      deleted_at: strFecha,
    });
    setEquipoSeleccionado({
      codigo: "",
      descripcion: "",
      stock_minimo: 0,
      nombre: "",
      fk_alm_unidad: "",
      stock_actual: 0,
      tipo: "",
      vida_util: 0,
    });
    setMaterialSeleccionado({
      codigo: "",
      nombre: "",
      descripcion: "",
      stock_minimo: "",
      fk_alm_unidad: "",
      stock_actual: 0,
      cantidad_especifica: 0,
      fk_alm_unidad_especifica: "",
    });
    setAlertCompra({
      show: false,
      message: "",
      severity: "warning",
    })
    setCanDelete(false);
  }
  const addDetalleCompra = () => {
    if (compra.tipo === "M") {
      if (materialSeleccionado.codigo !== "") {
        if (detallesCompra.filter(det => det.fk_cmp_materiales === materialSeleccionado.codigo).length <= 0) {
          detallesCompra.push({
            linea: detallesCompra.length + 1,
            cantidad: 0,
            precio_unitario: 0,
            base_imponible: 0,
            impuestos: 0,
            total_a_pagar: 0,
            fk_cmp_equipo: "",
            fk_cmp_materiales: materialSeleccionado.codigo,
            fk_cmp_compra: compra.id,
            descripcion: materialSeleccionado.nombre,
          })
        }
      }
    } else {
      if (equipoSeleccionado.codigo !== "") {
        if (detallesCompra.filter(det => det.fk_cmp_equipo === equipoSeleccionado.codigo).length <= 0) {
          detallesCompra.push({
            linea: detallesCompra.length + 1,
            cantidad: 0,
            precio_unitario: 0,
            base_imponible: 0,
            impuestos: 0,
            total_a_pagar: 0,
            fk_cmp_equipo: equipoSeleccionado.codigo,
            fk_cmp_materiales: "",
            fk_cmp_compra: compra.id,
            descripcion: equipoSeleccionado.nombre,
          })
        }
      }
    }
    setEquipoSeleccionado({
      codigo: "",
      descripcion: "",
      stock_minimo: 0,
      nombre: "",
      fk_alm_unidad: "",
      stock_actual: 0,
      tipo: "",
      vida_util: 0
    });
    setMaterialSeleccionado({
      codigo: "",
      nombre: "",
      descripcion: "",
      stock_minimo: "",
      fk_alm_unidad: "",
      stock_actual: 0,
      cantidad_especifica: 0,
      fk_alm_unidad_especifica: "",
    });
  }
  const handleChangeDetalleCompra = (e, index) => {
    const { id, value } = e.target;
    if (!isNaN(value)) {
      const list = [...detallesCompra];
      list[index][id] = value;

      const precioUnitario = list[index]['precio_unitario'];
      const cantidad = list[index]['cantidad'];
      list[index]['total_a_pagar'] = (Number(precioUnitario).toFixed(2) * Number(cantidad).toFixed(2)).toFixed(2);
      list[index]['base_imponible'] = ((Number(precioUnitario).toFixed(2) * Number(cantidad).toFixed(2)) / (1 + Number(Constantes.IGV).toFixed(2) / 100)).toFixed(2);
      list[index]['impuestos'] = (((Number(precioUnitario).toFixed(2) * Number(cantidad).toFixed(2)) / (1 + Number(Constantes.IGV).toFixed(2) / 100)) * (Number(Constantes.IGV).toFixed(2) / 100)).toFixed(2);
      setDetallesCompra(list);
      // console.log(list.reduce((previousValue, currentValue) => Number(previousValue) + Number(currentValue.total_a_pagar), 0));
      setCompra((compraAnt) => ({
        ...compraAnt,
        total_a_pagar: Number(list.reduce((previousValue, currentValue) => Number(previousValue) + Number(currentValue.total_a_pagar), 0)).toFixed(2),
        base_imponible: Number((list.reduce((previousValue, currentValue) => Number(previousValue) + Number(currentValue.total_a_pagar), 0).toFixed(2)) / (1 + Number(Constantes.IGV).toFixed(2) / 100)).toFixed(2),
        impuestos: Number(((list.reduce((previousValue, currentValue) => Number(previousValue) + Number(currentValue.total_a_pagar), 0).toFixed(2)) / (1 + Number(Constantes.IGV).toFixed(2) / 100)) * (Number(Constantes.IGV).toFixed(2) / 100)).toFixed(2),
      }))
    }
  }
  const saveCompra = async () => {
    setLoadingCompra(true);
    const guardarCompra = await fetch(`${Constantes.RUTA_API}mantenimiento/guardarCompra.php`, {
      method: "POST",
      body: JSON.stringify(compra),
    });
    const jsonCompra = await guardarCompra.json();
    if (jsonCompra.guardado) {
      setLoadingCompra(false);
      if (compra.id > 0) {
        const eliminarDetalles = await fetch(`${Constantes.RUTA_API}mantenimiento/eliminarCompraDetalleAll.php?id=${compra.id}&tipo=${compra.tipo}`);
      }
      detallesCompra.forEach(async (detalle) => {
        if (compra.tipo === "M") {
          detalle.fk_cmp_compra = jsonCompra.id;
          const guardarDetalle = await fetch(`${Constantes.RUTA_API}mantenimiento/guardarCompraDetalleMateriales.php`, {
            method: "POST",
            body: JSON.stringify(detalle),
          });
        }
        if (compra.tipo === "E") {
          detalle.fk_cmp_compra = jsonCompra.id;
          const guardarDetalle = await fetch(`${Constantes.RUTA_API}mantenimiento/guardarCompraDetalleEquipos.php`, {
            method: "POST",
            body: JSON.stringify(detalle),
          });
        }
      })
      setAlertCompra({
        show: true,
        message: "Compra Guardada con éxito.",
        severity: "success",
      })
      setTimeout(() => {
        clearForm();
      }, 3000);
    }
  }
  const cargarCompra = async (id) => {
    setProveedorSeleccionado({
      id: 0,
      razon_social: "",
      numero_documento: "",
      direccion: "",
      telefono: "",
      es_activo: 1,
      observaciones: "",
      fk_log_usuario_modifica: "",
      fk_log_usuario_creador: "",
      created_at: "",
      uploaded_at: "",
      deleted_at: "",
    });
    const fetchCompra = await fetch(`${Constantes.RUTA_API}recuperar/recuperarCompra.php?id=${id}`);
    const compraJSON = await fetchCompra.json();
    setCompra(compraJSON);
    listProveedor();
    if (proveedorDB.length > 0) {
      const index = proveedorDB.map(object => object.id).indexOf(compraJSON.fk_cmp_proveedor)
      setProveedorSeleccionado(proveedorDB.filter(l => l.id = compraJSON.fk_cmp_proveedor)[index]);
    }
    if (compraJSON.tipo === "M") {
      const fetchDetallesCompraMateriales = await fetch(`${Constantes.RUTA_API}recuperar/recuperarDetalleCompraMateriales.php?id=${id}`);
      const detallesCompraMaterialesJSON = await fetchDetallesCompraMateriales.json();
      setDetallesCompra(detallesCompraMaterialesJSON);
    }
    if (compraJSON.tipo === "E") {
      const fetchDetallesCompraEquipo = await fetch(`${Constantes.RUTA_API}recuperar/recuperarDetalleCompraEquipo.php?id=${id}`);
      // console.log(`${Constantes.RUTA_API}recuperar/recuperarDetalleCompraEquipo.php?id=${id}`);
      const detallesCompraEquipoJSON = await fetchDetallesCompraEquipo.json();
      setDetallesCompra(detallesCompraEquipoJSON);
    }
    setShowBusquedaCompras(false);
    setCanDelete(true);
  }
  const deleteCompra = async () => {
    const deleteDetalleFetch = await fetch(`${Constantes.RUTA_API}mantenimiento/eliminarCompraDetalleAll.php?id=${compra.id}&tipo=${compra.tipo}`)
    const deleteDetalleDB = await deleteDetalleFetch.json();
    if (deleteDetalleDB) {
      const deleteFetch = await fetch(`${Constantes.RUTA_API}mantenimiento/eliminarCompra.php?id=${compra.id}`);
      const deleteDB = await deleteFetch.json();
      if (deleteDB) {
        setAlertCompra({
          show: true,
          message: "Compra eliminada con exito.",
          severity: "success",
        })
      } else {
        setAlertCompra({
          show: true,
          message: "Ocurrio un problema al eliminar la compra, intente de nuevo.",
          severity: "error",
        })
      }
    } else {
      setAlertCompra({
        show: true,
        message: "Ocurrio un problema al eliminar la compra, intente de nuevo.",
        severity: "error",
      })
    }
    setTimeout(() => {
      setAlertCompra({
        show: false,
        message: "",
        severity: "warning",
      })
    }, 3000);
  }
  return (
    <Grid container spacing={1}>
      <Dialog open={showBusquedaCompras} maxWidth='md'>
        <DialogTitle>Busqueda Compras
          <IconButton
            aria-label="close"
            onClick={() => (setShowBusquedaCompras(false))}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <BuscarCompras childSeleccion={cargarCompra} setProveedorSeleccionado={setProveedorSeleccionado} />
        </DialogContent>
      </Dialog>
      <Dialog open={showProveedor} maxWidth='md'>
        <DialogTitle>
          PROVEEDOR
          <IconButton
            aria-label="close"
            onClick={handleCloseProveedor}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <Proveedor handleClose={handleCloseProveedor} />
        </DialogContent>
      </Dialog>
      <Collapse in={alertCompra.show}>
        <Alert
          action={
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={() => {
                setAlertCompra({
                  show: false,
                  message: "",
                  severity: "success",
                })
              }}
            >
              <CloseIcon fontSize="inherit" />
            </IconButton>
          }
          sx={{ mb: 2 }}
          severity={alertCompra.severity}
        >
          {alertCompra.message}
        </Alert>
      </Collapse>
      <Grid container item xs={12} spacing={1}>
        <Grid item xs={12} sm={6}>
          <ToggleButtonGroup
            color="secondary"
            value={compra.tipo}
            exclusive
            onChange={handleChangeTipo}
          >
            <ToggleButton value="M">MATERIALES</ToggleButton>
            <ToggleButton value="E">EQUIPOS/INSTRUMENTAL</ToggleButton>
          </ToggleButtonGroup>
        </Grid>
        <Grid item container xs={12} sm={6} spacing={1}>
          <Grid item xs='auto'>
            <LoadingButton loading={loadingCompra} variant='outlined' startIcon={<SaveOutlinedIcon />} color='success' onClick={saveCompra}>GUARDAR</LoadingButton>
          </Grid>
          <Grid item xs='auto'>
            <Button variant='outlined' startIcon={<CleaningServicesIcon />} color='secondary' onClick={clearForm}>LIMPIAR</Button>
          </Grid>
          <Grid item xs='auto'>
            <Button variant='outlined' startIcon={<ContentPasteSearchIcon />} color='primary' onClick={() => (setShowBusquedaCompras(true))}>BUSCAR REGISTROS</Button>
          </Grid>
          {canDelete && <Grid item xs='auto'>
            <Button variant='outlined' startIcon={<DeleteOutline />} color='error' onClick={deleteCompra}>ELIMINAR</Button>
          </Grid>}
        </Grid>

      </Grid>
      <Grid container item xs={12} spacing={3} >
        <Grid item container spacing={1} xs={12} sm={6}>
          <Grid item xs={12} sx={{ maxHeight: "50px" }}>
            <h5>COMPROBANTE</h5>
          </Grid>
          <Grid item xs={12}>
            <FormControl fullWidth>
              <InputLabel id="select-comprobante">Tipo Comprobante:</InputLabel>
              <Select
                labelId="select-comprobante-lb"
                id="select-comprobanteS"
                value={compra.fk_con_tipo_documento}
                onChange={handleChangeTipoDoc}
                fullWidth
                label="Tipo Comprobante:"
                defaultValue=""
              >
                {tipoDocumentoDB.map((td) => (
                  <MenuItem value={td.codigo} key={td.codigo}>{td.descripcion}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={6} sm={4}>
            <TextField
              label="Fecha:"
              fullWidth
              variant='filled'
              type="date"
              id='fecha'
              value={compra.fecha}
              onChange={handleChangeCompra}
            />
          </Grid>
          <Grid item xs={5}>
            <TextField
              label="Serie:"
              fullWidth
              variant='filled'
              type="text"
              id='serie'
              value={compra.serie}
              onChange={handleChangeCompra}
            />
          </Grid>
          <Grid item xs={3}>
            <TextField
              label="Numero:"
              fullWidth
              variant='filled'
              type="number"
              id='numero'
              value={compra.numero}
              onChange={handleChangeCompra}
              inputProps={{ min: 0 }}
            />
          </Grid>
        </Grid>
        <Grid item container xs={12} sm={6} spacing={1}>
          <Grid item xs={12} sx={{ maxHeight: "50px" }}>
            <h5>PROVEEDOR</h5>
          </Grid>
          <Grid item xs={11} sm={10}>
            <Autocomplete
              onChange={handleChangeProveedor}
              options={proveedorDB}
              getOptionLabel={(option) => option.razon_social}
              renderOption={(props, option) => {
                return (
                  <li {...props} key={option.id}>
                    {option.razon_social}
                  </li>
                );
              }}
              id="fk_cmp_proveedor"
              clearOnEscape
              value={proveedorseleccionado}
              renderInput={(params) => (
                <TextField {...params} label="Razon Social:" variant="standard" />
              )}
            />
          </Grid>
          <Grid item xs={1} sm={2}>
            <Tooltip title='Agregar/Editar Proveedor'>
              <IconButton size='large' color='info' onClick={maintanceProveedor}><AddBusinessIcon fontSize='large' /></IconButton>
            </Tooltip>
          </Grid>
          <Grid item xs={12} sm={8}>
            <TextField
              label="Direccion:"
              fullWidth
              variant='filled'
              type="text"
              id='direccion'
              value={proveedorseleccionado.direccion === null ? "" : proveedorseleccionado.direccion}
            />
          </Grid>
          <Grid item xs={6} sm={3}>
            <TextField
              label="RUC:"
              fullWidth
              variant='filled'
              type="text"
              id='numero_documento'
              value={proveedorseleccionado.numero_documento === null ? "" : proveedorseleccionado.numero_documento}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item container xs={12} sx={{ mt: 2 }}>
        <Grid item xs={11} sm={10}>
          <Autocomplete
            onChange={handleChangeDetalle}
            options={compra.tipo === "E" ? equipoDB : materialDB}
            getOptionLabel={(option) => option.nombre || " "}
            renderOption={(props, option) => {
              return (
                <li {...props} key={option.codigo}>
                  {option.nombre}
                </li>
              );
            }}
            id={compra.tipo === "E" ? "fk_cmp_equipo" : "fk_cmp_material"}
            clearOnEscape
            value={compra.tipo === "E" ? equipoSeleccionado : materialSeleccionado}
            renderInput={(params) => (
              <TextField {...params} label={compra.tipo === "E" ? "Equipos" : "Materiales"} variant="standard" />
            )}
          />
        </Grid>
        <Grid item xs={1} sm={2} align='center'>
          <Tooltip title='Agregar detalle'>
            <IconButton color='info' size='large' onClick={addDetalleCompra}><AddBoxOutlinedIcon fontSize='large' /></IconButton>
          </Tooltip>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <TableContainer>
          <Table size='small'>
            <TableHead>
              <TableRow>
                <TableCell style={{ fontWeight: 'bold' }}>#</TableCell>
                <TableCell style={{ fontWeight: 'bold' }}>CANTIDAD</TableCell>
                {compra.tipo === "E" && <TableCell style={{ fontWeight: 'bold' }}>EQUIPO</TableCell>}
                {compra.tipo === "M" && <TableCell style={{ fontWeight: 'bold' }}>MATERIAL</TableCell>}
                <TableCell style={{ fontWeight: 'bold' }}>P.U.</TableCell>
                <TableCell style={{ fontWeight: 'bold' }}>BASE IMPONIBLE</TableCell>
                <TableCell style={{ fontWeight: 'bold' }}>IMPUESTOS</TableCell>
                <TableCell style={{ fontWeight: 'bold' }}>TOTAL</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {detallesCompra.map((detalle, i) => {
                return (
                  <TableRow key={detalle.linea}>
                    <TableCell width="10%" align='center'>{i + 1}</TableCell>
                    <TableCell key='cantidad' width="10%" align='right'>
                      <TextField
                        fullWidth
                        variant='filled'
                        type="number"
                        id='cantidad'
                        value={detalle.cantidad}
                        onChange={(e) => handleChangeDetalleCompra(e, i)}
                        size="small"
                        inputProps={{ min: 0 }}
                      />
                    </TableCell>
                    <TableCell width="40%">{detalle.descripcion}</TableCell>
                    <TableCell key='precio_unitario' width="10%" align='right'>
                      <TextField
                        fullWidth
                        variant='filled'
                        type="number"
                        id='precio_unitario'
                        value={detalle.precio_unitario}
                        onChange={(e) => handleChangeDetalleCompra(e, i)}
                        size="small"
                        inputProps={{ min: 0 }}
                      />
                    </TableCell>
                    <TableCell width="10%" align='right'>{detalle.base_imponible}</TableCell>
                    <TableCell width="10%" align='right'>{detalle.impuestos}</TableCell>
                    <TableCell width="10%" align='right'>{detalle.total_a_pagar}</TableCell>
                  </TableRow>
                )
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
      <Grid item xs={12} container spacing={2} sx={{ mt: 2 }}>
        <Grid item xs={12} sm={4}>
          <TextField
            label="Base imponible:"
            fullWidth
            variant='outlined'
            type="text"
            id='base_imponible'
            value={compra.base_imponible}
            InputProps={{
              startAdornment: <InputAdornment position="start">S/</InputAdornment>,
            }}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <TextField
            label="IGV:"
            fullWidth
            variant='outlined'
            type="text"
            id='impuestos'
            value={compra.impuestos}
            InputProps={{
              startAdornment: <InputAdornment position="start">S/</InputAdornment>, align: "right"
            }}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <TextField
            label="Total:"
            fullWidth
            variant='outlined'
            type="text"
            id='total_a_pagar'
            value={compra.total_a_pagar}
            InputProps={{
              startAdornment: <InputAdornment position="start">S/</InputAdornment>,
            }}
          />
        </Grid>
      </Grid>
    </Grid>
  )
}
export default Compra
