import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControl, Grid, InputLabel, MenuItem, Select, TextField, Tooltip } from '@mui/material'
import React, { useEffect } from 'react'
import Constantes from '../../Constantes';
import SaveOutlinedIcon from '@mui/icons-material/SaveOutlined';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import CheckCircleOutlinedIcon from '@mui/icons-material/CheckCircleOutlined';
import { LoadingButton } from '@mui/lab';
function EditarMaterial(props) {
    const [loadingMaterial, setLoadingMaterial] = React.useState(false);
    const [material, setMaterial] = React.useState({
        codigo: "STK-00000000",
        nombre: "",
        descripcion: "",
        stock_minimo: 0,
        fk_alm_unidad: "",
        stock_actual: 0,
        cantidad_especifica: 0,
        fk_alm_unidad_especifica: "",
    });
    const [confirmacion, setConfirmacion] = React.useState({
        show: false,
        message: "",
        title: "",
    });
    const [unidades, setUnidades] = React.useState([]);

    useEffect(() => {
        const fillUnidades = async () => {
            const unidadesFetch = await fetch(`${Constantes.RUTA_API}listar/listarUnidades.php`);
            const unidadesDB = await unidadesFetch.json();
            setUnidades(unidadesDB);
            if (props.fk_cmp_material === "STK-00000000") {
                setMaterial({
                    codigo: "STK-00000000",
                    nombre: "",
                    descripcion: "",
                    stock_minimo: 0,
                    fk_alm_unidad: "",
                    stock_actual: 0,
                    cantidad_especifica: 0,
                    fk_alm_unidad_especifica: "",
                })
            } else {
                const materialFetch = await fetch(`${Constantes.RUTA_API}recuperar/recuperarMaterial.php?codigo=${props.fk_cmp_material}`);
                const materialDB = await materialFetch.json();
                setMaterial({
                    codigo: materialDB.codigo,
                    nombre: materialDB.nombre,
                    descripcion: materialDB.descripcion,
                    stock_minimo: materialDB.stock_minimo,
                    fk_alm_unidad: materialDB.fk_alm_unidad,
                    stock_actual: materialDB.stock_actual,
                    cantidad_especifica: materialDB.cantidad_especifica,
                    fk_alm_unidad_especifica: materialDB.fk_alm_unidad_especifica,
                })
            }
        }
        fillUnidades();
    }, [props.fk_cmp_material]);
    const handleChange = (event) => {
        let id = event.target.id;
        let valor = event.target.value;
        if (id === "nombre" || id === "descripcion") {
            setMaterial((materialAnt) => ({
                ...materialAnt,
                [id]: valor,
            }));
        } else {
            setMaterial((materialAnt) => ({
                ...materialAnt,
                [id]: Number(valor),
            }));
        }
    }
    const handleCombo = (event, newValue) => {
        if (event.target) {
            setMaterial((materialAnt) => ({
                ...materialAnt,
                [newValue.props.id]: newValue.props.value,
            }))
        }
    }
    const guardarMaterial = async () => {
        setLoadingMaterial(true);
        const guardarMaterial = await fetch(`${Constantes.RUTA_API}mantenimiento/guardarMaterial.php`, {
            method: "POST",
            body: JSON.stringify({
                codigo: material.codigo,
                nombre: material.nombre.toUpperCase(),
                descripcion: material.descripcion.toUpperCase(),
                stock_minimo: material.stock_minimo,
                fk_alm_unidad: material.fk_alm_unidad,
                stock_actual: material.stock_actual,
                cantidad_especifica: material.cantidad_especifica,
                fk_alm_unidad_especifica: material.fk_alm_unidad_especifica,
            }),
        });
        const jsonMaterial = await guardarMaterial.json();
        if (jsonMaterial.guardado) {
            setLoadingMaterial(false);
            setConfirmacion({
                show: true,
                message: `El material ${jsonMaterial.codigo} fue guardado con exito.`,
                title: "GUARDADO",
            });
            setTimeout(() => {
                setConfirmacion({
                    show: false,
                    message: "",
                    title: "",
                });
                parentCloseChild();
            }, 3000);
        } else {
            setConfirmacion({
                show: true,
                message: 'Hubo un problema al intentar guardar el material, intente de nuevo. Si el problema persiste contacte al administrador.',
                title: "ERROR",
            });
        }
        setMaterial({
            codigo: "STK-00000000",
            nombre: "",
            descripcion: "",
            stock_minimo: 0,
            fk_alm_unidad: "",
            stock_actual: 0,
            cantidad_especifica: 0,
            fk_alm_unidad_especifica: "",
        });
    }
    const parentCloseChild = () => {
        props.parentCloseChild();
        setMaterial({
            codigo: "STK-00000000",
            nombre: "",
            descripcion: "",
            stock_minimo: 0,
            fk_alm_unidad: "",
            stock_actual: 0,
            cantidad_especifica: 0,
            fk_alm_unidad_especifica: "",
        });
    }
    const closeConfirmacion = () => {
        setConfirmacion({
            show: false,
            message: "",
            title: "",
        });
        parentCloseChild();
    }
    return (
        <Grid container spacing={1}>
            <Dialog open={confirmacion.show}>
                <DialogTitle>
                    {confirmacion.title}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>{confirmacion.message}</DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button startIcon={<CheckCircleOutlinedIcon />} variant='outlined' color='primary' onClick={() => closeConfirmacion(confirmacion.title)}>OK</Button>
                </DialogActions>
            </Dialog>
            <Grid item xs={4}>
                <TextField
                    id='codigo'
                    label="Codigo:"
                    placeholder='STK-00000000'
                    variant='filled'
                    type='text'
                    fullWidth
                    style={{ textTransform: "uppercase" }}
                    value={material.codigo}
                    disabled
                    margin='normal'
                />
            </Grid>
            <Grid item xs={8}>
                <TextField
                    id='nombre'
                    label="Nombre:"
                    variant='filled'
                    type='text'
                    onChange={handleChange}
                    value={material.nombre}
                    fullWidth
                    margin="normal"
                    inputProps={{
                        style: { textTransform: 'uppercase' }
                    }}
                />
            </Grid>
            {/* <Grid item xs={2}>
                <TextField
                    id='stock_actual'
                    label="Stock Actual:"
                    variant='filled'
                    type='number'
                    value={material.stock_actual}
                    onChange={handleChange}
                    inputProps={{ style: { textAlign: 'right' }, min: 0, step: "1" }}
                    margin="normal"
                />
            </Grid> */}
            <Grid item xs={5} md={2}>
                <TextField
                    id='stock_minimo'
                    label="Stock minimo:"
                    variant='filled'
                    type='number'
                    onChange={handleChange}
                    value={material.stock_minimo}
                    inputProps={{ style: { textAlign: 'right' }, min: 0, step: "1" }}
                    margin="normal"
                />
            </Grid>
            <Grid item xs={7} md={4}>
                <FormControl fullWidth margin="normal">
                    <InputLabel>Unidad:</InputLabel>
                    <Select
                        labelId="fk_alm_unidad"
                        id="fk_alm_unidad"
                        value={material ? material.fk_alm_unidad : ''}
                        label="Unidad:"
                        onChange={handleCombo}
                    >
                        {unidades.map(uni => {
                            return (<MenuItem key={uni.codigo} value={uni.codigo} id='fk_alm_unidad'>{uni.nombre}</MenuItem>)
                        })}
                    </Select>
                </FormControl>
            </Grid>
            <Grid item xs={5} md={2}>
                <TextField
                    margin="normal"
                    id='cantidad_especifica'
                    label="Cantidad Especifica:"
                    variant='filled'
                    type='number'
                    onChange={handleChange}
                    value={material.cantidad_especifica}
                    inputProps={{ style: { textAlign: 'right' }, min: 0, step: "1" }}
                />
            </Grid>
            <Grid item xs={7} md={4}>
                <FormControl fullWidth margin="normal">
                    <InputLabel>Unidad Especifica:</InputLabel>
                    <Select
                        labelId="fk_alm_unidad_especifica"
                        id="fk_alm_unidad_especifica"
                        value={material ? material.fk_alm_unidad_especifica : ''}
                        label="Unidad Especifica:"
                        onChange={handleCombo}
                    >
                        {unidades.map(uni => {
                            return (<MenuItem key={uni.codigo} value={uni.codigo} id="fk_alm_unidad_especifica">{uni.nombre}</MenuItem>)
                        })}
                    </Select>
                </FormControl>
            </Grid>
            <Grid item xs={12}>
                <TextField
                    id='descripcion'
                    label="Descripcion:"
                    variant='filled'
                    onChange={handleChange}
                    value={material.descripcion}
                    fullWidth
                    margin='normal'
                    multiline
                    rows={3}
                    inputProps={{
                        style: { textTransform: 'uppercase' }
                    }}
                />
            </Grid>
            <Grid container sx={{ justifyContent: 'center', mt: 2 }} spacing={1}>
                <Grid item sm={4} xs={6} md={2}>
                    <LoadingButton loading={loadingMaterial} fullWidth variant='outlined' color='success' startIcon={<SaveOutlinedIcon />} onClick={guardarMaterial}>GUARDAR</LoadingButton>
                </Grid>
                <Grid item sm={4} xs={6} md={2}>
                    <Button fullWidth variant='outlined' color='error' startIcon={<CancelOutlinedIcon />} onClick={parentCloseChild}>CANCELAR</Button>
                </Grid>
            </Grid>
        </Grid >
    )
}

export default EditarMaterial
