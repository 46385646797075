import { Alert, Button, Collapse, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid, IconButton, Paper, Snackbar, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, TextField, Tooltip, Typography } from '@mui/material'
import React from 'react'
import SearchIcon from '@mui/icons-material/Search';
import AddBoxOutlinedIcon from '@mui/icons-material/AddBoxOutlined';
import CustomPaginationActionsTable from '../../table/PaginationTable';
import Constantes from '../../Constantes';
import CloseIcon from '@mui/icons-material/Close';
import QuestionMarkOutlinedIcon from '@mui/icons-material/QuestionMarkOutlined';
import CheckCircleOutlinedIcon from '@mui/icons-material/CheckCircleOutlined';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import EditarMaterial from './editarMaterial';
import DoneOutlineOutlinedIcon from '@mui/icons-material/DoneOutlineOutlined';
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';
import OutputIcon from '@mui/icons-material/Output';
import { ro } from 'date-fns/locale';
import { AddCircleOutline, Close, Delete, Edit, EditLocation, EditOutlined, RemoveShoppingCart } from '@mui/icons-material';
import EgresoMaterial from './egresoMaterial';
import DialogEgreso from './egresos/dialogEgreso';
function Materiales() {
    const [buscar, setBuscar] = React.useState("");
    const [rows, setRows] = React.useState([]);
    const [showAlert, setShowAlert] = React.useState(false);
    const [alertMessage, setAlertMessage] = React.useState("");
    const [dialogNewEgreso, setDialogNewEgreso] = React.useState(false);
    const [listarDatosEgreso, setListarDatosEgreso] = React.useState([]);
    const [dialogEliminar, setDialogEliminar] = React.useState({
        show: false,
        id: null,
    });
    const [dialogMaterial, setDialogMaterial] = React.useState({
        show: false,
        titulo: "",
        fk_cmp_material: ""
    })
    const [dialogEliminado, setDialogEliminado] = React.useState({
        show: false,
        titulo: "",
        fk_cmp_material: "",
        message: "",
        eliminado: false,
    })
    const [alertGeneral, setAlertGeneral] = React.useState({
        show: false,
        message: '',
        severity: 'warning'
    });
    const [materialStock, setMaterialStock] = React.useState({
        fk_cmp_material: "",
        stock: "",
        nombre: "",
    });
    const [dialogEgreso, setDialogEgreso] = React.useState(false);
    const columns = ['CODIGO', 'NOMBRE', 'STOCK', 'UND.', 'CANT. ESP.', 'UND. ESP.', 'ESTADO STOCK'];
    const handleChange = (event) => {
        setBuscar(event.target.value);
    }
    const [showConfirmarEliminar, setShowConfirmarEliminar] = React.useState(false);
    const search = async () => {
        const rptadetalles = await fetch(`${Constantes.RUTA_API}listar/listarMaterialesBusqueda.php?search=${buscar}`)
        const detalles = await rptadetalles.json();
        if (detalles.length > 0) {
            setRows(detalles);
        } else {
            setRows(detalles);
            setShowAlert(true);
            setAlertMessage("No se encontraron resultados.");
            setTimeout(() => {
                setShowAlert(false);
                setAlertMessage("");
            }, 3000);
        }
    }
    const ejecutarAccion = (accion, codigo) => {
        if (accion === "EDIT") {
            setDialogMaterial({
                show: true,
                titulo: "EDITAR",
                fk_cmp_material: codigo,
            })
        }
        if (accion === "DELETE") {
            setDialogMaterial({
                show: false,
                titulo: "DELETE",
                fk_cmp_material: codigo,
            })
            setShowConfirmarEliminar(true);
        }
        if (accion === "NEW") {
            setDialogMaterial({
                show: true,
                titulo: "NUEVO",
                fk_cmp_material: codigo,
            })
        }
    }


    const senviarMaterialStock = (codigo, stock, nombre) => {
        // setDialogEgreso(true);
        setDialogNewEgreso(true);
        setMaterialStock({
            fk_cmp_material: codigo,
            stock: stock,
            nombre: nombre,
        })

    }

    const eliminarMaterial = async () => {
        const eliminar = await fetch(`${Constantes.RUTA_API}/mantenimiento/eliminarMaterial.php?codigo=${dialogMaterial.fk_cmp_material}`);
        const rptaEliminar = await eliminar.json();
        if (rptaEliminar.executed) {
            setDialogEliminado({
                show: true,
                titulo: "ELIMINADO",
                fk_cmp_material: dialogMaterial.fk_cmp_material,
                message: "Fue eliminado con exito.",
                eliminado: true,
            })
        } else {
            setDialogEliminado({
                show: true,
                titulo: "ERROR",
                fk_cmp_material: dialogMaterial.fk_cmp_material,
                message: "No se pudo eliminar, tiene compras relacionadas.",
                eliminado: false,
            })
        }
        setTimeout(() => {
            cerrarEliminado();
        }, 3000);
    }
    const cerrarEliminado = () => {
        setDialogEliminado({
            show: false,
            titulo: "",
            fk_cmp_material: "",
            message: "",
            eliminado: false,
        })
        setShowConfirmarEliminar(false);
        search();
    }
    const cerrarMaterial = () => {
        setDialogMaterial({
            show: false,
            titulo: " ",
            fk_cmp_material: "",
        })
    }
    const handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            search();
        }
    }
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const confirNuevoStock = () => {
        setAlertGeneral({ message: 'STOCK ACTUALIZADO CORRECTAMENTE', severity: 'success', show: true });
        setTimeout(() => {
            setAlertGeneral({ message: '', severity: 'warning', show: false });
        }, 3000);
    }
    const closeNuevoEgreso = () => {
        setDialogNewEgreso(false);
    }
    const ErrConfirNuevoStock = () => {
        setAlertGeneral({ message: 'NO HAY STOCK SUFICIENTE PARA PROCEDER', severity: 'warning', show: true });
        setTimeout(() => {
            setAlertGeneral({ message: '', severity: 'warning', show: false });
        }, 3000);
    }
    return (
        <Grid container spacing={1}>
            <Snackbar open={alertGeneral.show} onClose={() => setAlertGeneral({ show: false, message: '', severity: 'warning' })} anchorOrigin={{ vertical: 'top', horizontal: 'right' }}>
                <Alert onClose={() => setAlertGeneral({ show: false, message: '', severity: 'warning' })} severity={alertGeneral.severity} sx={{ width: '100%' }}>
                    {alertGeneral.message}
                </Alert>
            </Snackbar>
            <Dialog open={dialogMaterial.show} maxWidth='md'>
                <DialogTitle>
                    {`${dialogMaterial.titulo} MATERIAL`}
                    <IconButton
                        aria-label="close"
                        onClick={cerrarMaterial}
                        sx={{
                            position: 'absolute',
                            right: 8,
                            top: 8,
                            color: (theme) => theme.palette.grey[500],
                        }}
                    >
                        <Close />
                    </IconButton>
                </DialogTitle>
                <DialogContent>
                    <EditarMaterial fk_cmp_material={dialogMaterial.fk_cmp_material} parentCloseChild={cerrarMaterial} />
                </DialogContent>
            </Dialog>
            <Dialog open={showConfirmarEliminar}>
                <DialogTitle>
                    ELIMINAR <QuestionMarkOutlinedIcon />
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>{`Está a punto de eliminar un material (${dialogMaterial.fk_cmp_material}), esta accion no se puede deshacer. ¿Desea continuar?`}</DialogContentText>
                </DialogContent>
                <DialogActions sx={{ alignContent: 'center' }}>
                    <Button variant='outlined' color='success' startIcon={<CheckCircleOutlinedIcon />} onClick={eliminarMaterial}>SI</Button>
                    <Button variant='outlined' color='error' startIcon={<CancelOutlinedIcon />} onClick={() => setShowConfirmarEliminar(false)}>NO</Button>
                </DialogActions>
            </Dialog>
            <Dialog open={dialogEliminado.show}>
                <DialogTitle>
                    {dialogEliminado.eliminado ? <DoneOutlineOutlinedIcon /> : <ErrorOutlineOutlinedIcon />}
                    {dialogEliminado.titulo}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>{`El material ${dialogEliminado.fk_cmp_material}, ${dialogEliminado.message}`}</DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button variant='outlined' color='primary' startIcon={<CheckCircleOutlinedIcon />} onClick={cerrarEliminado}>ACEPTAR</Button>
                </DialogActions>
            </Dialog>
            <Dialog open={dialogNewEgreso} onClose={() => setDialogNewEgreso(false)} maxWidth="xs">
                <DialogTitle sx={{ textAlign: "center" }}>
                    INGRESE CANTIDAD A EGRESAR
                    <IconButton
                        aria-label="close"
                        onClick={() => setDialogNewEgreso(false)}
                        sx={{
                            position: 'absolute',
                            right: 8,
                            top: 8,
                            color: (theme) => theme.palette.grey[500],
                        }}
                    >
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <DialogContent>
                    <DialogEgreso cmp_material={materialStock} closeNuevoEgreso={closeNuevoEgreso} search={search} confirNuevoStock={confirNuevoStock} ErrConfirNuevoStock={ErrConfirNuevoStock} />
                </DialogContent>
            </Dialog>
            <Grid item xs={12} sm={12} md={8}>
                <TextField
                    id="busqueda"
                    label="Nombre:"
                    type="text"
                    placeholder='Buscar el material'
                    inputProps={{ style: { textTransform: "uppercase" } }}
                    value={buscar}
                    fullWidth
                    size='small'
                    onChange={handleChange}
                    onKeyDown={handleKeyDown}
                />
            </Grid>
            <Grid item xs={5} sm={4} md={2} textAlign='center'>
                <Button fullWidth onClick={search} variant='contained' startIcon={<SearchIcon fontSize='large' />}> BUSCAR </Button>
            </Grid>
            <Grid item xs={5} sm={4} md={2} textAlign='center'>
                <Button fullWidth onClick={() => ejecutarAccion("NEW", "STK-00000000")} color='success' variant='contained' startIcon={<AddBoxOutlinedIcon fontSize='large' />}> NUEVO </Button>
            </Grid>
            <Grid item xs={12}>
                <Collapse in={showAlert}>
                    <Alert
                        action={
                            <IconButton
                                aria-label="close"
                                color="inherit"
                                size="small"
                                onClick={() => {
                                    setShowAlert(false);
                                }}
                            >
                                <CloseIcon fontSize="inherit" />
                            </IconButton>
                        }
                        sx={{ mb: 2 }}
                        severity="warning"
                    >
                        {alertMessage}
                    </Alert>
                </Collapse>
            </Grid>
            <Grid item xs={12}>
                <Paper sx={{ width: '100%', mb: 2, mt: 2 }}>
                    <TableContainer>
                        <Table stickyHeader>
                            <TableHead>
                                <TableRow>
                                    {columns.map((item) => (
                                        <TableCell key={item} style={{ fontWeight: 'bold' }}>
                                            {item}
                                        </TableCell>
                                    ))}
                                    <TableCell></TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {
                                    (rowsPerPage > 0
                                        ? rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                        : rows
                                    ).map((row, index) => (
                                        <TableRow key={index}>
                                            <TableCell>{row.codigo}</TableCell>
                                            <TableCell>{row.nombre}</TableCell>
                                            <TableCell align='right'>{row.stock_actual}</TableCell>
                                            <TableCell>{row.unidad}</TableCell>
                                            <TableCell align='right'>{row.cantidad_especifica}</TableCell>
                                            <TableCell>{row.unidad_especifica}</TableCell>
                                            <TableCell>{row.estado_stock}</TableCell>
                                            <TableCell align="right">
                                                <Tooltip title="EGRESOS" placement='top'>
                                                    <IconButton color="info" onClick={() => senviarMaterialStock(row.codigo, row.stock_actual, row.nombre)} ><OutputIcon /></IconButton>
                                                </Tooltip>
                                                <Tooltip title="EDITAR MATERIAL" placement='top'>
                                                    <IconButton color="info" onClick={() => ejecutarAccion("EDIT", row.codigo)}><EditOutlined /></IconButton>
                                                </Tooltip>
                                                <Tooltip title="ELIMINAR MATERIAL" placement='top'>
                                                    <IconButton color="error" onClick={() => ejecutarAccion("DELETE", row.codigo)}><Delete /></IconButton>
                                                </Tooltip>
                                            </TableCell>
                                        </TableRow>
                                    ))
                                }
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <TablePagination
                        rowsPerPageOptions={[10, 25, 50]}
                        component="div"
                        labelRowsPerPage="Filas por columnas"
                        count={rows.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                </Paper>
            </Grid>
        </Grid>
    )
}
export default Materiales


