import React from 'react'

export default function Preloader() {
    return (
        <div id="preloader">
            <div id="loader"></div>
        </div>

        // <div className="loaderBody">
        //     <div className="loader">
        //         <div></div>
        //         <div></div>
        //         <div></div>
        //         <div></div>
        //         <div></div>
        //         <div></div>
        //         <div></div>
        //         <div></div>
        //         <div></div>
        //     </div>
        // </div>
    )
}
