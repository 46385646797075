import React from 'react';
import { Navigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormHelperText, IconButton, Link, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip, Typography } from "@mui/material";
import 'react-toastify/dist/ReactToastify.css';
import axios from "axios";
import Constantes from '../Constantes';
import { Autocomplete, Button, Container, FormControl, Grid, InputLabel, MenuItem, Paper, Select, Switch, TextField } from '@mui/material';
import HomeIcon from '@mui/icons-material/Home';
import TwitterIcon from '@mui/icons-material/Twitter';
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import CancelIcon from '@mui/icons-material/Cancel';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import DeleteIcon from '@mui/icons-material/Delete';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { Check, CheckCircleOutline, Close, Error, HighlightOff } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';

class Editarcliente extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            cliente: {
                codigo: "CLI-00000000",
                apellidos_nombres: "",
                numero_documento: "",
                estado: "ACTIVO",
                telefono_1: "",
                telefono_2: "",
                e_mail: "",
                fk_ven_contacto: "",
                fk_log_usuario_creado_por: "",
                fk_log_usuario_actualizado_por: '',
                created_at: "",
                updated_at: "",
                deleted_at: "",
                fk_com_tipo_documento: "",
                fk_com_referencia: "",
            },
            accion: props.accion,
            referencias: [],
            tratamientos: [],
            tipo_documentos: [],
            contactos: [],
            contactoSeleccionado: {
                codigo: "CON-00000000",
                apellidos_nombre: "",
                telefono_1: "",
                fk_com_referencia: "",
                fecha_registro: "",
                fecha_coordinacion: "",
                fecha_consulta: "",
                fk_log_usuario_creado_por: "",
                estado_seguimiento: "REGISTRADO",
                observaciones: "",
            },
            redes: [],
            redSeleccionada: "",
            showAddRedes: false,
            showToast: false,
            messageToast: "",
            toastSeverity: "success",
            enlaceGuardar: "",
            errorFormularioNombre: { error: false, helperText: '' },
            errorFormularioTipoDoc: { error: false, helperText: '' },
            errorFormularioNumDoc: { error: false, helperText: '' },
            errorFormularioRef: { error: false, helperText: '' },
            dialogNotificacion: { show: false, text: '' },
            maxLength: 0,
            loadingCliente: false
        };
        this.manejarCambio = this.manejarCambio.bind(this);
        this.manejarEnvioDeFormulario = this.manejarEnvioDeFormulario.bind(this);
        this.handleChangeContacto = this.handleChangeContacto.bind(this);
        this.handleTipoDocumento = this.handleTipoDocumento.bind(this);
        this.handleChangeReferencia = this.handleChangeReferencia.bind(this);
        this.addRedSocial = this.addRedSocial.bind(this);
        this.showRedSocial = this.showRedSocial.bind(this);
        this.handleChangeRed = this.handleChangeRed.bind(this);

    };
    async componentDidMount() {
        const refRespuesta = await fetch(`${Constantes.RUTA_API}sistema/listarReferenciaLlegada.php`);
        const referenciasdb = await refRespuesta.json();
        const tipoDocumento = await fetch(`${Constantes.RUTA_API}sistema/listarTipoDocumento.php`)
        const tipoDocumentoDB = await tipoDocumento.json();
        const contactos = await fetch(`${Constantes.RUTA_API}listar/listarContactosSinCliente.php`);
        const contactosDB = await contactos.json();
        this.setState({
            referencias: referenciasdb,
            tipo_documentos: tipoDocumentoDB,
            contactos: contactosDB,
        });
        if (this.props.codigoCliente !== undefined) {
            const respuesta = await fetch(`${Constantes.RUTA_API}recuperar/obtenerCliente.php?codigo=${this.props.codigoCliente}`);
            const cliente = await respuesta.json();
            const getRedCliente = await fetch(`${Constantes.RUTA_API}recuperar/recuperarRedCliente.php?codigo=${this.props.codigoCliente}`);
            const response = await getRedCliente.json();
            const logedUserJSON = window.sessionStorage.getItem('logedAppUser')
            const userInfo = JSON.parse(logedUserJSON);
            cliente["fk_log_usuario_actualizado_por"] = userInfo.usuario;
            this.setState({
                cliente: {
                    codigo: cliente.codigo,
                    apellidos_nombres: cliente.apellidos_nombres,
                    numero_documento: cliente.numero_documento,
                    estado: cliente.estado,
                    telefono_1: cliente.telefono_1,
                    telefono_2: cliente.telefono_2,
                    e_mail: cliente.e_mail,
                    fk_ven_contacto: cliente.fk_ven_contacto !== null ? cliente.fk_ven_contacto : '',
                    fk_log_usuario_creado_por: cliente.fk_log_usuario_creado_por,
                    fk_log_usuario_actualizado_por: cliente.fk_log_usuario_actualizado_por,
                    created_at: cliente.created_at,
                    updated_at: new Date().toJSON().slice(0, 10),
                    deleted_at: cliente.deleted_at !== null ? cliente.deleted_at : '',
                    fk_com_tipo_documento: cliente.fk_com_tipo_documento,
                    fk_com_referencia: cliente.fk_com_referencia,
                },
                redes: response
            });
        }
        if (this.props.codigoCliente === undefined) {
            const logedUserJSON = window.sessionStorage.getItem('logedAppUser')
            const userInfo = JSON.parse(logedUserJSON);
            this.setState({
                cliente: {
                    codigo: "CLI-00000000",
                    apellidos_nombres: "",
                    numero_documento: "",
                    estado: "ACTIVO",
                    telefono_1: "",
                    telefono_2: "",
                    e_mail: "@",
                    fk_ven_contacto: "",
                    fk_log_usuario_creado_por: userInfo.usuario,
                    created_at: new Date().toJSON().slice(0, 10),
                    updated_at: new Date().toJSON().slice(0, 10),
                    deleted_at: "",
                    fk_com_tipo_documento: "",
                    fk_com_referencia: "",
                    fk_log_usuario_actualizado_por: userInfo.usuario,
                },
            })
        }

    }
    async manejarCambio(evento) {
        const clave = evento.target.id;
        let valor = evento.target.value;
        if (clave === 'apellidos_nombres') {
            if (valor !== "") { this.setState({ errorFormularioNombre: { error: false, helperText: '' } }); }
            else { this.setState({ errorFormularioNombre: { error: true, helperText: 'Nombre de cliente obligatorio' } }); }
        }
        if (clave === "numero_documento") {
            //VALIDAR DNI
            if (valor !== "") {
                this.setState({ errorFormularioNumDoc: { error: false, helperText: '' } });
                this.setState({ errorFormularioTipoDoc: { error: false, helperText: '' } });
            } else {
                if (this.state.cliente.fk_com_tipo_documento === "00") {
                    this.setState({ errorFormularioNumDoc: { error: false, helperText: '' } });
                } else {
                    this.setState({ errorFormularioNumDoc: { error: true, helperText: 'NUMERO DE DOCUMENTO OBLIGATORIO' } });
                }
            }
        }
        this.setState(state => {
            const clienteActualizado = state.cliente;
            clienteActualizado[clave] = valor;
            return {
                cliente: clienteActualizado,
            }
        });
    }
    async validarDNIOnBlur() {
        const cliente = this.state.cliente;
        //validar al actualizar dni q sea igual al anterior pero diferente a los demas
        if (this.props.codigoCliente === undefined) {
            const respuesta = await fetch(`${Constantes.RUTA_API}sistema/verificarClienteDocumento.php?numero_documento=${cliente.numero_documento}&fk_com_tipo_documento=${cliente.fk_com_tipo_documento}`);
            const validDNI = await respuesta.json();
            if (validDNI.resultado === 1) {
                this.setState({ errorFormularioNumDoc: { error: true, helperText: 'NUMERO DE DOCUMENTO EXISTENTE' } });
            } else {
                this.setState({ errorFormularioNumDoc: { error: false, helperText: '' } });
                this.setState({ errorFormularioTipoDoc: { error: false, helperText: '' } });
            }
        }
    }

    async manejarEnvioDeFormulario() {
        const cliente = this.state.cliente;
        if (cliente.apellidos_nombres !== "") {
            if (cliente.fk_com_tipo_documento !== "") {
                if (cliente.fk_com_tipo_documento !== "00") {
                    if (cliente.numero_documento !== "") {
                        if (this.props.codigoCliente === undefined) {
                            const verificarClienteNombre = await fetch(
                                `${Constantes.RUTA_API}sistema/verificarPaciente.php`,
                                {
                                    method: "POST",
                                    body: JSON.stringify({
                                        nombre: cliente.apellidos_nombres,
                                    }),
                                }
                            );
                            const verificacion_nombre = await verificarClienteNombre.json();
                            if (verificacion_nombre.conteo === 0) {
                                const verificarClienteDocumento = await fetch(
                                    `${Constantes.RUTA_API}sistema/verificarPaciente.php`,
                                    {
                                        method: "POST",
                                        body: JSON.stringify({
                                            tipo_documento: cliente.fk_com_tipo_documento,
                                            documento: cliente.numero_documento
                                        }),
                                    }
                                );
                                const verificacion = await verificarClienteDocumento.json();
                                if (verificacion.conteo === 0) {
                                    const cargaUtil = JSON.stringify({
                                        codigo: cliente.codigo,
                                        apellidos_nombres: cliente.apellidos_nombres.toUpperCase(),
                                        numero_documento: cliente.numero_documento,
                                        estado: cliente.estado,
                                        telefono_1: cliente.telefono_1,
                                        telefono_2: cliente.telefono_2,
                                        e_mail: cliente.e_mail,
                                        fk_ven_contacto: cliente.fk_ven_contacto,
                                        fk_log_usuario_creado_por: cliente.fk_log_usuario_creado_por,
                                        fk_log_usuario_actualizado_por: cliente.fk_log_usuario_actualizado_por !== null ? cliente.fk_log_usuario_actualizado_por : '',
                                        created_at: cliente.created_at,
                                        updated_at: cliente.updated_at,
                                        deleted_at: cliente.deleted_at,
                                        fk_com_tipo_documento: cliente.fk_com_tipo_documento,
                                        fk_com_referencia: cliente.fk_com_referencia,
                                    });
                                    const respuesta = await fetch(`${Constantes.RUTA_API}mantenimiento/guardarCliente.php`, {
                                        method: "POST",
                                        body: cargaUtil,
                                    });
                                    const resultado = await respuesta.json();
                                    if (resultado.guardado) {
                                        if (this.props.codigoCliente !== undefined) {
                                            const limpiarRedes = await fetch(`${Constantes.RUTA_API}mantenimiento/eliminarRedesCliente.php?codigo=${this.props.codigoCliente}`)
                                            const limpiarRedesjs = await limpiarRedes.json();
                                            if (limpiarRedesjs) {
                                                this.state.redes.forEach(async (item, index) => {
                                                    item['linea'] = index + 1;
                                                    item['fk_ven_cliente'] = resultado.codigo;
                                                    const redGuardada = await fetch(`${Constantes.RUTA_API}mantenimiento/guardarRedCliente.php`, {
                                                        method: "POST",
                                                        body: JSON.stringify(item),
                                                    });
                                                })
                                            }
                                        }
                                        if (this.props.codigoCliente === undefined) {
                                            this.state.redes.forEach(async (item, index) => {
                                                item['linea'] = index + 1;
                                                item['fk_ven_cliente'] = resultado.codigo;
                                                const interesGuardado = await fetch(`${Constantes.RUTA_API}mantenimiento/guardarRedCliente.php`, {
                                                    method: "POST",
                                                    body: JSON.stringify(item),
                                                });
                                            })
                                        }
                                        this.setState({
                                            showToast: true,
                                            messageToast: "Cliente guardado con exito.",
                                            toastSeverity: "success",
                                        })
                                        if (this.props.listarClientes !== undefined) {
                                            this.props.listarClientes();
                                        }
                                        setTimeout(() => {
                                            this.props.showCliente();
                                        }, 3000);
                                    } else {
                                        this.setState({
                                            messageToast: "Ocurrio un error al intentar Guardar el Cliente",
                                            toastSeverity: "error",
                                            showToast: false,
                                        })
                                    }
                                } else {
                                    this.setState({ dialogNotificacion: { show: true, text: verificacion.codigo } })
                                }
                            } else {
                                this.setState({ dialogNotificacion: { show: true, text: verificacion_nombre.codigo } });
                            }
                        } else {
                            const cargaUtil = JSON.stringify({
                                codigo: cliente.codigo,
                                apellidos_nombres: cliente.apellidos_nombres.toUpperCase(),
                                numero_documento: cliente.numero_documento,
                                estado: cliente.estado,
                                telefono_1: cliente.telefono_1,
                                telefono_2: cliente.telefono_2,
                                e_mail: cliente.e_mail,
                                fk_ven_contacto: cliente.fk_ven_contacto,
                                fk_log_usuario_creado_por: cliente.fk_log_usuario_creado_por,
                                fk_log_usuario_actualizado_por: cliente.fk_log_usuario_actualizado_por !== null ? cliente.fk_log_usuario_actualizado_por : '',
                                created_at: cliente.created_at,
                                updated_at: cliente.updated_at,
                                deleted_at: cliente.deleted_at,
                                fk_com_tipo_documento: cliente.fk_com_tipo_documento,
                                fk_com_referencia: cliente.fk_com_referencia,
                            });
                            const respuesta = await fetch(`${Constantes.RUTA_API}mantenimiento/guardarCliente.php`, {
                                method: "POST",
                                body: cargaUtil,
                            });
                            const resultado = await respuesta.json();
                            if (resultado.guardado) {
                                if (this.props.codigoCliente !== undefined) {
                                    const limpiarRedes = await fetch(`${Constantes.RUTA_API}mantenimiento/eliminarRedesCliente.php?codigo=${this.props.codigoCliente}`)
                                    const limpiarRedesjs = await limpiarRedes.json();
                                    if (limpiarRedesjs) {
                                        this.state.redes.forEach(async (item, index) => {
                                            item['linea'] = index + 1;
                                            item['fk_ven_cliente'] = resultado.codigo;
                                            const redGuardada = await fetch(`${Constantes.RUTA_API}mantenimiento/guardarRedCliente.php`, {
                                                method: "POST",
                                                body: JSON.stringify(item),
                                            });
                                        })
                                    }
                                }
                                if (this.props.codigoCliente === undefined) {
                                    this.state.redes.forEach(async (item, index) => {
                                        item['linea'] = index + 1;
                                        item['fk_ven_cliente'] = resultado.codigo;
                                        const interesGuardado = await fetch(`${Constantes.RUTA_API}mantenimiento/guardarRedCliente.php`, {
                                            method: "POST",
                                            body: JSON.stringify(item),
                                        });
                                    })
                                }
                                this.setState({
                                    showToast: true,
                                    messageToast: "Cliente guardado con exito.",
                                    toastSeverity: "success",
                                })
                                if (this.props.listarClientes !== undefined) {
                                    this.props.listarClientes();
                                }
                                setTimeout(() => {
                                    this.props.showCliente();
                                }, 3000);
                            } else {
                                this.setState({
                                    messageToast: "Ocurrio un error al intentar Guardar el Cliente",
                                    toastSeverity: "error",
                                    showToast: false,
                                })
                            }
                        }

                    } else {
                        this.setState({ errorFormularioNumDoc: { error: true, helperText: 'Numero de documento no puede estar vacio' } });
                    }
                } else {
                    const cargaUtil = JSON.stringify({
                        codigo: cliente.codigo,
                        apellidos_nombres: cliente.apellidos_nombres.toUpperCase(),
                        numero_documento: cliente.numero_documento,
                        estado: cliente.estado,
                        telefono_1: cliente.telefono_1,
                        telefono_2: cliente.telefono_2,
                        e_mail: cliente.e_mail,
                        fk_ven_contacto: cliente.fk_ven_contacto,
                        fk_log_usuario_creado_por: cliente.fk_log_usuario_creado_por,
                        fk_log_usuario_actualizado_por: cliente.fk_log_usuario_actualizado_por !== null ? cliente.fk_log_usuario_actualizado_por : '',
                        created_at: cliente.created_at,
                        updated_at: cliente.updated_at,
                        deleted_at: cliente.deleted_at,
                        fk_com_tipo_documento: cliente.fk_com_tipo_documento,
                        fk_com_referencia: cliente.fk_com_referencia,
                    });
                    const respuesta = await fetch(`${Constantes.RUTA_API}mantenimiento/guardarCliente.php`, {
                        method: "POST",
                        body: cargaUtil,
                    });
                    const resultado = await respuesta.json();
                    if (resultado.guardado) {
                        if (this.props.codigoCliente !== undefined) {
                            const limpiarRedes = await fetch(`${Constantes.RUTA_API}mantenimiento/eliminarRedesCliente.php?codigo=${this.props.codigoCliente}`)
                            const limpiarRedesjs = await limpiarRedes.json();
                            if (limpiarRedesjs) {
                                this.state.redes.forEach(async (item, index) => {
                                    item['linea'] = index + 1;
                                    item['fk_ven_cliente'] = resultado.codigo;
                                    const redGuardada = await fetch(`${Constantes.RUTA_API}mantenimiento/guardarRedCliente.php`, {
                                        method: "POST",
                                        body: JSON.stringify(item),
                                    });
                                })
                            }
                        }
                        if (this.props.codigoCliente === undefined) {
                            this.state.redes.forEach(async (item, index) => {
                                item['linea'] = index + 1;
                                item['fk_ven_cliente'] = resultado.codigo;
                                const interesGuardado = await fetch(`${Constantes.RUTA_API}mantenimiento/guardarRedCliente.php`, {
                                    method: "POST",
                                    body: JSON.stringify(item),
                                });
                            })
                        }
                        this.setState({
                            showToast: true,
                            messageToast: "Cliente guardado con exito.",
                            toastSeverity: "success",
                        })
                        if (this.props.listarClientes !== undefined) {
                            this.props.listarClientes();
                        }
                        setTimeout(() => {
                            this.props.showCliente();
                        }, 3000);
                    } else {
                        this.setState({
                            messageToast: "Ocurrio un error al intentar Guardar el Cliente",
                            toastSeverity: "error",
                            showToast: false,
                        })
                    }
                }
            } else {
                this.setState({ errorFormularioTipoDoc: { error: true, helperText: 'SELECCIONAR TIPO DE COCUMENTO ' } });
            }
        } else {
            this.setState({ errorFormularioNombre: { error: true, helperText: 'Nombre de cliente obligatorio' } });
        }
        if (cliente.fk_com_tipo_documento === "") {
            this.setState({ errorFormularioTipoDoc: { error: true, helperText: '' } });
        }
        if (cliente.numero_documento === "") {
            this.setState({ errorFormularioNumDoc: { error: true, helperText: 'NUMERO DE COCUMENTO OBLIGATORIO' } });
        }
    }
    handleChangeContacto(event, values) {
        const clienteActualizado = this.state.cliente
        clienteActualizado["fk_ven_contacto"] = values.codigo;
        clienteActualizado["apellidos_nombres"] = values.apellidos_nombre;
        clienteActualizado["telefono_1"] = values.telefono_1;
        clienteActualizado["fk_com_referencia"] = values.fk_com_referencia;
        this.setState({
            contactoSeleccionado: values,
            cliente: clienteActualizado,
        })
    }
    handleTipoDocumento(event) {
        if (event.target) {
            let valor = event.target.value;
            console.log({ valor });
            if (valor === "00") {
                this.setState({ errorFormularioTipoDoc: { error: false, helperText: '' } });
                this.setState({ errorFormularioNumDoc: { error: false, helperText: '' } });
            } else {
                this.setState({ errorFormularioTipoDoc: { error: false, helperText: '' } });
                this.setState({ errorFormularioNumDoc: { error: true, helperText: 'NUMERO DOCUMENTO OBLIGATORIO' } });
            }
            this.setState(state => {
                const clienteActualizado = state.cliente;
                clienteActualizado["fk_com_tipo_documento"] = valor;
                return {
                    cliente: clienteActualizado,
                }
            })
        }
    }
    handleChangeReferencia(evento) {
        let valor = evento.target.value;
        this.setState(state => {
            const clienteActualizado = this.state.cliente;
            clienteActualizado["fk_com_referencia"] = valor;
            return {
                cliente: clienteActualizado,
            }
        })
    }
    /*handleChangeEstado(event){
        if(event.target){
            let valor = event.target.value;
            this.setState(state=>{
                const clienteActualizado = state.cliente;
                clienteActualizado["estado"] = valor;        
                return {
                    cliente: clienteActualizado,
                }
            })
        }
    }*/
    showRedSocial(valor) {
        console.log(valor);
        this.setState({
            redSeleccionada: valor,
            showAddRedes: true,
        })
    }
    handleChangeRed(evento) {
        let valor = evento.target.value;
        this.setState({
            enlaceGuardar: valor,
        })
    }
    addRedSocial() {
        const redesActualizado = this.state.redes;
        redesActualizado.push({
            fk_ven_cliente: this.state.cliente.codigo,
            linea: redesActualizado.length + 1,
            enlace: this.state.enlaceGuardar,
            observaciones: this.state.redSeleccionada,
        })
        this.setState({
            redes: redesActualizado,
            showAddRedes: false,
        });
    }
    eliminarRed(redSocial) {
        const redesActualizado = [];
        var linea = 1;
        this.state.redes.forEach(element => {
            if (element.linea !== redSocial.linea) {
                redesActualizado.push({
                    fk_ven_cliente: element.fk_ven_cliente,
                    linea: linea,
                    enlace: element.enlace,
                    observaciones: element.observaciones,
                })
                linea = linea + 1;
            }
        });
        this.setState({
            redes: redesActualizado,
        })
    }
    limpiarNumDoc() {
        this.setState(state => {
            const clienteActualizado = this.state.cliente;
            clienteActualizado["numero_documento"] = "";
            return {
                cliente: clienteActualizado,
            }
        })
    }
    render() {
        const Estados = ["SUSPENDIDO",
            "ACTIVO",
            "ACTIVO_IC",
            "CULMINADO",
            "ABANDONO",
            "CONSULTA"]
        return (
            <>
                <Dialog open={this.state.dialogNotificacion.show}>
                    <DialogContent sx={{ textAlign: 'center' }}>
                        <DialogTitle >
                            <Error sx={{ fontSize: '45px' }} color="error" />
                        </DialogTitle>
                        <Typography variant="h5">Datos existentes</Typography>
                        <Typography>Datos asociados a {this.state.dialogNotificacion.text}</Typography>
                    </DialogContent>
                    <DialogActions style={{ justifyContent: 'center' }}>
                        <Button variant="outlined" color="success" startIcon={<CheckCircleOutline />} onClick={() => this.setState({ dialogNotificacion: { show: false, text: '' } })}>Aceptar</Button>
                        <Button variant="outlined" color="error" startIcon={<HighlightOff />} onClick={() => this.setState({ dialogNotificacion: { show: false, text: '' } })}>Cerrar</Button >
                    </DialogActions >
                </Dialog >
                <Dialog open={this.state.showToast}>
                    <DialogTitle style={{ textAlign: 'center' }}>
                        {this.state.toastSeverity === "success" ?
                            <CheckCircleOutlineIcon />
                            :
                            <ErrorOutlineIcon />
                        }
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText style={{ textAlign: 'center' }}>
                            {this.state.messageToast}
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions style={{ justifyContent: 'center' }}>
                        <Button onClick={() => { this.setState({ showToast: false }); }} startIcon={<CheckCircleIcon />} variant='contained' color='success'>Aceptar</Button>
                        <Button onClick={() => { this.setState({ showToast: false }) }} startIcon={<CancelIcon />} variant='contained' color='error'>Cancelar</Button>
                    </DialogActions>
                </Dialog>
                <Dialog open={this.state.showAddRedes} onClose={() => { this.setState({ showAddRedes: false }) }}>
                    <DialogTitle>{`Ingrese el enlace de la red social`}</DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            {`Ingrese el nuevo enlace para ${this.state.redSeleccionada}.`}
                        </DialogContentText>
                        <br />
                        <TextField
                            id='enlace'
                            label={`Ruta ${this.state.redSeleccionada}:`}
                            type='text'
                            fullWidth
                            style={{ textTransform: "uppercase" }}
                            values={this.state.enlaceGuardar}
                            onChange={this.handleChangeRed}
                        />
                    </DialogContent>
                    <DialogActions style={{ justifyContent: 'center' }}>
                        <Button onClick={this.addRedSocial} startIcon={<CheckCircleIcon />} variant='contained' color='success'>Guardar</Button>
                        <Button onClick={() => { this.setState({ showAddRedes: false }); }} startIcon={<CancelIcon />} variant='contained' color='error'>Cancelar</Button>
                    </DialogActions>
                </Dialog>
                {/* <h1>{this.state.accion} CLIENTE</h1> */}
                <Grid container spacing={2}>
                    <Grid item md={4} xs={12}>
                        <TextField
                            fullWidth
                            id='codigo'
                            label="Codigo:"
                            placeholder='CON-00000000'
                            variant='filled'
                            style={{ textTransform: "uppercase" }}
                            onChange={this.manejarCambio}
                            value={this.state.cliente.codigo}
                            disabled
                        />
                    </Grid>
                    <Grid item md={8} xs={12}>
                        <Autocomplete
                            onChange={this.handleChangeContacto}
                            options={this.state.contactos}
                            getOptionLabel={(option) => option.apellidos_nombre || ''}
                            id="contacto"
                            clearOnEscape
                            value={this.state.cliente.contactoSeleccionado}
                            isOptionEqualToValue={(option, value) => option.codigo === value.codigo}
                            renderOption={(props, option) => {
                                return (
                                    <li {...props} key={option.codigo}>
                                        {`${option.codigo}  |  ${option.apellidos_nombre}`}
                                    </li>
                                );
                            }}
                            renderInput={(params) => (
                                <TextField {...params} label="Contacto" variant="standard" />
                            )}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            id='apellidos_nombres'
                            label="Nombres y Apellidos:"
                            placeholder='Nombre y Apellido'
                            variant='filled'
                            style={{ textTransform: "uppercase" }}
                            onChange={this.manejarCambio}
                            value={this.state.cliente.apellidos_nombres}
                            fullWidth
                            error={this.state.errorFormularioNombre.error}
                            helperText={this.state.errorFormularioNombre.helperText}
                        />
                    </Grid>
                    <Grid item md={6} xs={6}>
                        <TextField
                            id="telefono_1"
                            label="Numero telefono 1:"
                            type="text"
                            fullWidth
                            InputLabelProps={{
                                shrink: true,
                            }}
                            value={this.state.cliente.telefono_1 !== null ? this.state.cliente.telefono_1 : ''}
                            onChange={this.manejarCambio}
                        />
                    </Grid>
                    <Grid item md={6} xs={6}>
                        <TextField
                            id="telefono_2"
                            label="Numero telefono 2:"
                            type="text"
                            fullWidth
                            InputLabelProps={{
                                shrink: true,
                            }}
                            value={this.state.cliente.telefono_2 !== null ? this.state.cliente.telefono_2 : ''}
                            onChange={this.manejarCambio}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            id="e_mail"
                            label="Correo Electronico:"
                            type="text"
                            fullWidth
                            InputLabelProps={{
                                shrink: true,
                            }}
                            value={this.state.cliente.e_mail !== null ? this.state.cliente.e_mail : ''}
                            onChange={this.manejarCambio}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <FormControl fullWidth  >
                            <InputLabel>Tipo Documento:</InputLabel>
                            <Select
                                labelId="fk_com_tipo_documento"
                                id="fk_com_tipo_documento"
                                value={this.state.cliente.fk_com_tipo_documento}
                                label="Tipo Documento:"
                                onChange={this.handleTipoDocumento}
                                error={this.state.errorFormularioTipoDoc.error}
                            >
                                {this.state.tipo_documentos.map(tipo => {
                                    return (<MenuItem key={tipo.codigo} value={tipo.codigo} onClick={() => this.limpiarNumDoc()}>{tipo.descripcion_corta}  </MenuItem>)
                                })}
                            </Select>
                            <FormHelperText sx={{ color: "red" }}>{this.state.errorFormularioTipoDoc.helperText !== "" ? this.state.errorFormularioTipoDoc.helperText : ""}</FormHelperText>
                        </FormControl>
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                            id="numero_documento"
                            label="Numero Documento:"
                            type="text"
                            fullWidth
                            InputLabelProps={{
                                shrink: true,
                            }}
                            error={this.state.errorFormularioNumDoc.error}
                            helperText={this.state.errorFormularioNumDoc.helperText}
                            value={this.state.cliente.numero_documento !== null ? this.state.cliente.numero_documento : ''}
                            onChange={this.manejarCambio}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                            id="estado"
                            label="Estado:"
                            type="text"
                            fullWidth
                            value={this.state.cliente.estado}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <FormControl fullWidth>
                            <InputLabel>Referencia Llegada</InputLabel>
                            <Select
                                labelId="fk_com_referencia"
                                id="fk_com_referencia"
                                value={this.state.cliente.fk_com_referencia}
                                label="Referencia Llegada"
                                onChange={this.handleChangeReferencia}
                            >
                                {this.state.referencias.map(referencia => {
                                    return (<MenuItem key={referencia.codigo} value={referencia.codigo}>{referencia.descripcion}</MenuItem>)
                                })}
                            </Select>
                        </FormControl>
                    </Grid>
                </Grid>
                <Grid container spacing={1} sx={{ marginTop: "10px" }}>
                    <Grid item md={12} xs={12}>
                        <Stack direction="row" spacing={1} style={{ justifyContent: 'center' }}>
                            <Tooltip title="Twiter">
                                <IconButton color='info' onClick={() => { this.showRedSocial("Twiter") }}><TwitterIcon /></IconButton>
                            </Tooltip>
                            <Tooltip title="FaceBook" >
                                <IconButton color='info' onClick={() => { this.showRedSocial("FaceBook") }}><FacebookIcon /></IconButton>
                            </Tooltip>
                            <Tooltip title="Instagram">
                                <IconButton color='secondary' onClick={() => { this.showRedSocial("Instagram") }}><InstagramIcon /></IconButton>
                            </Tooltip>
                            <Tooltip title="LinkedIn">
                                <IconButton color='primary' onClick={() => { this.showRedSocial("LinkedIn") }}><LinkedInIcon /></IconButton>
                            </Tooltip>
                        </Stack>
                    </Grid>
                    <Grid item xs={12}>
                        <TableContainer>
                            <Table>
                                <TableHead sx={{ backgroundColor: "#b9d8ee" }} >
                                    <TableRow>
                                        <TableCell>#</TableCell>
                                        <TableCell>RED</TableCell>
                                        <TableCell>ENLACE</TableCell>
                                        <TableCell sx={{ width: "60px" }}>Elim.</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {this.state.redes.map(red => {
                                        return (
                                            <TableRow key={red.linea}>
                                                <TableCell> {red.linea} </TableCell>
                                                <TableCell> {red.observaciones} </TableCell>
                                                <TableCell> {red.enlace}</TableCell>
                                                <TableCell>
                                                    <IconButton onClick={() => this.eliminarRed(red)} color="error"><DeleteIcon /></IconButton>
                                                </TableCell>
                                            </TableRow>
                                        )
                                    })}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Grid>

                </Grid>
                <Grid container spacing={2} sx={{ marginTop: "20px", justifyContent: 'center' }} >
                    <Grid item md={2} xs={6}>
                        <LoadingButton loading={this.state.loadingCliente} fullWidth startIcon={<Check />} onClick={this.manejarEnvioDeFormulario} variant="outlined" color="success">
                            GUARDAR
                        </LoadingButton>
                    </Grid>
                    <Grid item md={2} xs={6}>
                        <Button fullWidth startIcon={<Close />} onClick={this.props.showCliente} variant="outlined" color="error">
                            CANCELAR
                        </Button>
                    </Grid>
                    {/* <Grid item xs={4}>
                        <Link href="#/clientes">
                            <Button variant="contained" color="info">
                                VOLVER A CLIENTES
                            </Button>
                        </Link>
                    </Grid>
                    <Grid item xs={4}>
                        <Link href="#">
                            <Button variant="contained" color="primary" startIcon={<HomeIcon />}>
                                VOLVER AL INICIO
                            </Button>
                        </Link>
                    </Grid> */}
                </Grid>
            </>
        );
    }


}
export default (props) => (<Editarcliente {...props} params={useParams()} />); 
