import { Alert, Autocomplete, Button, Collapse, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControl, Grid, IconButton, InputAdornment, InputLabel, MenuItem, Select, TextField, Tooltip } from '@mui/material'
import React, { useEffect } from 'react'
import EditIcon from '@mui/icons-material/Edit';
import AddIcon from '@mui/icons-material/Add';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import ContentPasteSearchIcon from '@mui/icons-material/ContentPasteSearch';
import CleaningServicesIcon from '@mui/icons-material/CleaningServices';
import Constantes from '../../Constantes';
import SaveOutlinedIcon from '@mui/icons-material/SaveOutlined';
import DoneOutlineOutlinedIcon from '@mui/icons-material/DoneOutlineOutlined';
import CloseIcon from '@mui/icons-material/Close';
import BuscarRegistroGastos from '../gastos/buscarRegistroGastos';
import Trabajador from '../../sistema/trabajador';
import Gastos from '../gastos/gastos';
import { Close, DeleteOutline } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';

function OtrosGastos() {
  const logedUserJSON = window.sessionStorage.getItem('logedAppUser');
  const user = JSON.parse(logedUserJSON);
  const hoy = new Date();
  const [loadingGastos, setLoadingGastos] = React.useState(false);
  const strFecha = `${hoy.getFullYear()}-${(hoy.getMonth() + 1).toString().padStart(2, '0')}-${hoy.getDate().toString().padStart(2, '0')}`;
  const [gastos, setGastos] = React.useState([]);
  const [trabajadores, setTrabajadores] = React.useState([]);
  const [showBusquedaRegistro, setShowBusquedaRegistro] = React.useState(false);
  const [MantenimientoPersonal, setMantenimientoPersonal] = React.useState({ show: false, id: 0 });
  const [alertGasto, setAlertGasto] = React.useState({
    show: false,
    message: "",
    severity: "success",
  })
  // const [clasificaciones, setClasificaciones] = React.useState([]);
  const [showGastos, setShowGastos] = React.useState(false);
  const [canDelete, setCanDelete] = React.useState(false);
  // const [showMessageGasto, setShowMessageGasto] = React.useState(false);
  const [showMessageTrabajador, setShowMessageTrabajador] = React.useState(false);
  const [gastoSeleccionado, setGastoSeleccionado] = React.useState({
    codigo: "",
    descripcion: "",
    nombre: "",
    categoria: "",
  })
  const [registroGasto, setRegistroGasto] = React.useState({
    codigo: "OTRO-00000000",
    fecha: strFecha,
    fecha_registro: strFecha,
    referencia: "",
    fk_rh_trabajador_responsable: 0,
    fk_cmp_gasto: "",
    fk_log_usuario_creado_por: "",
    precio_unitario: 0.00,
    cantidad: 0,
    total: 0,
  });
  const [mensaje, setMensaje] = React.useState({
    titulo: "",
    mensaje: "",
    show: false,
  })

  useEffect((e) => {
    const fillData = async () => {
      const gastosFetch = await fetch(`${Constantes.RUTA_API}listar/listarGastos.php`);
      const gastosDB = await gastosFetch.json();
      setGastos(gastosDB);
      const trabajadoresFetch = await fetch(`${Constantes.RUTA_API}listar/listarTrabajadorArea.php?area=A`);
      const trabajadoresDB = await trabajadoresFetch.json();
      setTrabajadores(trabajadoresDB);
      // const clasificacionesFetch = await fetch(`${Constantes.RUTA_API}listar/listarClasificaciones.php?area=A`)
      // const clasificacionesDB = await clasificacionesFetch.json();
      // setClasificaciones(clasificacionesDB);
    }
    fillData();
  }, []);

  const listarGastos = async () => {
    const gastosFetch = await fetch(`${Constantes.RUTA_API}listar/listarGastos.php`);
    const gastosDB = await gastosFetch.json();
    setGastos(gastosDB);
  }
  const handleChangeGasto = (event, newValue) => {
    setGastoSeleccionado(newValue);
    setRegistroGasto((registroAnt) => ({
      ...registroAnt,
      fk_cmp_gasto: newValue.codigo
    }));

  }
  // const editarGasto = () =>{
  //   if(gastoSeleccionado.codigo!==""){
  //     setShowGastos({
  //       show:true,
  //       accion:'E',
  //     })
  //   }else{
  //     setShowMessageGasto(true);
  //     setTimeout(() => {
  //       setShowMessageGasto(false);
  //     }, 3000);
  //   }
  // }
  // const nuevoGasto = () =>{
  //   setShowGastos({
  //     show:true,
  //     accion:'N',
  //   })
  // }
  const handleChangeSelect = (e) => {
    setRegistroGasto((gastoAnt) => ({
      ...gastoAnt,
      fk_rh_trabajador_responsable: e.target.value,
    }));
  }
  const handleChangeRegistroGasto = (e) => {
    let id = e.target.id;
    const valor = e.target.value;
    setRegistroGasto((gastoAnt) => ({
      ...gastoAnt,
      [id]: valor,
    }));
  }
  const handleChangeRegistroGastoCalculo = (e) => {
    let id = e.target.id;
    const valor = e.target.value;
    if (!isNaN(valor)) {
      var cantidad = id === 'cantidad' ? Number(valor) : Number(registroGasto.cantidad);
      var precio_unitario = id === 'precio_unitario' ? Number(valor) : Number(registroGasto.precio_unitario);
      setRegistroGasto((gastoAnt) => ({
        ...gastoAnt,
        cantidad: cantidad,
        precio_unitario: precio_unitario,
        total: Number(Number(cantidad) * Number(precio_unitario)).toFixed(2),
      }));
    }
  }
  const saveRegistroGasto = async () => {
    setLoadingGastos(true);
    const fetchRegistroGasto = await fetch(`${Constantes.RUTA_API}mantenimiento/guardarRegistroGasto.php`, {
      method: "POST",
      body: JSON.stringify({
        codigo: registroGasto.codigo,
        fecha: registroGasto.fecha,
        fecha_registro: registroGasto.fecha_registro,
        referencia: registroGasto.referencia.toUpperCase(),
        fk_rh_trabajador_responsable: registroGasto.fk_rh_trabajador_responsable,
        fk_cmp_gasto: registroGasto.fk_cmp_gasto,
        fk_log_usuario_creado_por: user.usuario,
        precio_unitario: registroGasto.precio_unitario,
        cantidad: registroGasto.cantidad,
        total: registroGasto.total,
      }),
    })
    const registroGastoJSON = await fetchRegistroGasto.json();
    if (registroGastoJSON) {
      setLoadingGastos(false);
      setMensaje({ titulo: "GUARDADO", mensaje: "El registro fue guardado con exito.", show: true });
      clearRegistro();
    } else {
      setMensaje({ titulo: "ERROR", mensaje: "Ocurrio un error al guardar el registro. Intente de nuevo.", show: true });
    }
  }
  const clearRegistro = () => {
    setRegistroGasto({
      codigo: "OTRO-00000000",
      fecha: strFecha,
      fecha_registro: strFecha,
      referencia: "",
      fk_rh_trabajador_responsable: 0,
      fk_cmp_gasto: "",
      fk_log_usuario_creado_por: "",
      precio_unitario: 0.00,
      cantidad: 0,
      total: 0,
    });
    setGastoSeleccionado({
      codigo: "",
      descripcion: "",
      nombre: "",
      categoria: "",
    })
    setCanDelete(false);
    setAlertGasto({
      show: false,
      message: "",
      severity: "success",
    });
  }
  const cargarRegistro = async (codigo) => {
    const fetchRegistroGasto = await fetch(`${Constantes.RUTA_API}recuperar/recuperarGastoRegistro.php?codigo=${codigo}`);
    const registroGastoDB = await fetchRegistroGasto.json();
    setRegistroGasto(registroGastoDB);
    setShowBusquedaRegistro(false);
    const fetchGasto = await fetch(`${Constantes.RUTA_API}recuperar/recuperarGasto.php?codigo=${registroGastoDB.fk_cmp_gasto}`)
    const gastoDB = await fetchGasto.json();
    setGastoSeleccionado(gastoDB);
    setCanDelete(true);
  }
  const closeTrabajador = () => {
    setMantenimientoPersonal({
      show: false,
      id: 0,
    });
  }
  const editarTrabajador = () => {
    if (registroGasto.fk_rh_trabajador_responsable > 0) {
      setMantenimientoPersonal({
        show: true,
        id: registroGasto.fk_rh_trabajador_responsable,
      });
    } else {
      setShowMessageTrabajador(true);
      setTimeout(() => {
        setShowMessageTrabajador(false);
      }, 3000);
    }
  }
  const deleteGasto = async () => {
    const fetchRegistroGasto = await fetch(`${Constantes.RUTA_API}mantenimiento/eliminarGastoRegistro.php?codigo=${registroGasto.codigo}`);
    const registroGastoDB = await fetchRegistroGasto.json();
    if (registroGastoDB) {
      setAlertGasto({
        show: true,
        message: "Registro eliminado con exito.",
        severity: "success",
      });
      setTimeout(() => {
        setAlertGasto({
          show: false,
          message: "",
          severity: "success",
        });
        clearRegistro();
      }, 3000);
    } else {
      setAlertGasto({
        show: true,
        message: "Ocurrio un problema al eliminar el registro.",
        severity: "error",
      });
      setTimeout(() => {
        setAlertGasto({
          show: false,
          message: "",
          severity: "success",
        });
      }, 3000);
    }
  }
  return (
    <Grid container spacing={1} >
      <Collapse in={alertGasto.show}>
        <Alert
          action={
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={() => {
                setAlertGasto({
                  show: false,
                  message: "",
                  severity: "success",
                })
              }}
            >
              <CloseIcon fontSize="inherit" />
            </IconButton>
          }
          sx={{ mb: 2 }}
          severity={alertGasto.severity}
        >
          {alertGasto.message}
        </Alert>
      </Collapse>
      <Dialog open={showGastos} maxWidth='md'>
        <DialogTitle>
          GASTOS
          <IconButton
            aria-label="close"
            onClick={() => { setShowGastos(false); listarGastos(); }}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <Gastos />
        </DialogContent>
      </Dialog>
      <Dialog open={mensaje.show}>
        <DialogTitle>{mensaje.titulo}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {mensaje.mensaje}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button variant='outlined' color='primary' startIcon={<DoneOutlineOutlinedIcon />} onClick={() => setMensaje({ titulo: "", mensaje: "", show: false })}>ACEPTAR</Button>
        </DialogActions>
      </Dialog>
      <Dialog open={showBusquedaRegistro} maxWidth='md'>
        <DialogTitle>
          Busqueda Registros
          <IconButton
            aria-label="close"
            onClick={() => (setShowBusquedaRegistro(false))}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <BuscarRegistroGastos childSeleccion={cargarRegistro} />
        </DialogContent>
      </Dialog>
      <Dialog open={MantenimientoPersonal.show}>
        <DialogTitle>
          <IconButton
            aria-label="close"
            onClick={closeTrabajador}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <Close />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <Trabajador parentClose={closeTrabajador} id={MantenimientoPersonal.id} area="A" />
        </DialogContent>
      </Dialog>
      <Grid container item xs={12} spacing={1} direction='row-reverse'>
        <Grid item xs='auto' sx={{ mt: 3 }}>
          <Button variant='outlined' startIcon={<ContentPasteSearchIcon />} color='primary' onClick={() => setShowBusquedaRegistro(true)}>BUSCAR REGISTROS</Button>
        </Grid>
        <Grid item xs='auto' sx={{ mt: 3 }}>
          <Button variant='outlined' startIcon={<CleaningServicesIcon />} color='secondary' onClick={clearRegistro}>LIMPIAR</Button>
        </Grid>
        <Grid item xs='auto' sx={{ mt: 3 }}>
          <LoadingButton loading={loadingGastos} variant='outlined' startIcon={<SaveOutlinedIcon />} color='success' onClick={saveRegistroGasto}>GUARDAR</LoadingButton>
        </Grid>
        {canDelete && <Grid item xs='auto' sx={{ mt: 3 }}>
          <Button variant='outlined' startIcon={<DeleteOutline />} color='error' onClick={deleteGasto}>ELIMINAR</Button>
        </Grid>}
        <Grid item xs={true}>
          <TextField
            label="Codigo: "
            fullWidth
            variant='outlined'
            type='text'
            id='codigo'
            value={registroGasto.codigo}
            margin="normal"
          />
        </Grid>
      </Grid>
      <Grid item xs={6} sm={3}>
        <TextField
          label="Referencia: "
          fullWidth
          variant='filled'
          type='text'
          id='referencia'
          // inputProps={{ style: { textTransform: "uppercase" } }}
          inputProps={{ style: { textTransform: "uppercase" } }}
          value={registroGasto.referencia}
          onChange={handleChangeRegistroGasto}
          placeholder='F004-00002525'
          margin="normal"
        />
      </Grid>
      <Grid item xs={6} sm={3}>
        <TextField
          label="Fecha"
          fullWidth
          variant='filled'
          type='date'
          id='fecha'
          value={registroGasto.fecha}
          onChange={handleChangeRegistroGasto}
          margin="normal"
        />
      </Grid>
      <Grid item xs={10} sm={4}>
        <FormControl fullWidth margin="normal">
          <InputLabel id="select-responsable">Responsable:</InputLabel>
          <Select
            labelId="fk_rh_trabajador_responsable"
            id="fk_rh_trabajador_responsable"
            value={registroGasto.fk_rh_trabajador_responsable}
            onChange={handleChangeSelect}
            fullWidth
            label="Responsable:"
            defaultValue=""
          >
            {trabajadores.map((rh) => (
              <MenuItem value={rh.id} key={rh.id}>{`${rh.numero_documento} | ${rh.apellidos_nombres}`}</MenuItem>
            ))}
          </Select>
        </FormControl>
        <Collapse in={showMessageTrabajador}>
          <Alert
            action={
              <IconButton
                aria-label="close"
                color="inherit"
                size="small"
                onClick={() => {
                  setShowMessageTrabajador(false);
                }}
              >
                <CloseIcon fontSize="inherit" />
              </IconButton>
            }
            sx={{ mb: 2 }}
            severity="warning"
          >
            Debe seleccionar un trabajador antes de editar.
          </Alert>
        </Collapse>
      </Grid>
      <Grid item xs={1} textAlign='center' sx={{ mt: 3 }}>
        <Tooltip title='Editar Personal'>
          <IconButton variant='contained' color='primary' onClick={editarTrabajador}><EditIcon /></IconButton>
        </Tooltip>
      </Grid>
      <Grid item xs={1} textAlign='center' sx={{ mt: 3 }}>
        <Tooltip title='Agregar Personal'>
          <IconButton variant='contained' color='success' onClick={() => setMantenimientoPersonal({ show: true, id: 0 })}><AddIcon /></IconButton>
        </Tooltip>
      </Grid>
      <Grid item xs={11}>
        <Autocomplete
          onChange={handleChangeGasto}
          options={gastos}
          getOptionLabel={(option) => option.nombre}
          renderOption={(props, option) => {
            return (
              <li {...props} key={option.codigo}>
                {option.nombre}
              </li>
            );
          }}
          id="gastos"
          value={gastoSeleccionado}
          clearOnEscape
          renderInput={(params) => (
            <TextField {...params} label="Gastos:" variant="standard" />
          )}
        />
        {/* <Collapse in={showMessageGasto}>
          <Alert
            action={
              <IconButton
                aria-label="close"
                color="inherit"
                size="small"
                onClick={() => {
                  setShowMessageGasto(false);
                }}
              >
                <CloseIcon fontSize="inherit" />
              </IconButton>
            }
            sx={{ mb: 2 }}
            severity="warning"
          >
            Debe seleccionar un gasto antes de editar.
          </Alert>
        </Collapse> */}
      </Grid>
      <Grid item xs={1} textAlign='center' sx={{ mt: 2 }}>
        <Tooltip title='VER GASTOS'>
          <IconButton variant='contained' color='primary' onClick={() => setShowGastos(true)}><MoreHorizIcon /></IconButton>
        </Tooltip>
      </Grid>
      {/* <Grid item xs={1} textAlign='center'>
        <Tooltip title='Agregar Gasto'>
          <IconButton variant='contained' color='success' onClick={nuevoGasto}><AddIcon/></IconButton>
        </Tooltip>
      </Grid> */}
      <Grid item xs={12} sm={4} >
        <TextField
          margin="normal"
          label="Cantidad:"
          fullWidth
          variant='filled'
          type='number'
          id='cantidad'
          value={registroGasto.cantidad}
          onChange={handleChangeRegistroGastoCalculo}
          sx={{ textAlign: "right" }}
          inputProps={{ min: 0 }}

        />
      </Grid>
      <Grid item xs={12} sm={4}>
        <TextField
          margin="normal"
          label="Precio unitario:"
          fullWidth
          variant='filled'
          type='number'
          id='precio_unitario'
          value={registroGasto.precio_unitario}
          onChange={handleChangeRegistroGastoCalculo}
          inputProps={{ min: 0 }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                S/
              </InputAdornment>
            ),
          }}
        />
      </Grid>
      <Grid item xs={12} sm={4}>
        <TextField
          margin="normal"
          label="Total:"
          fullWidth
          variant='filled'
          type='number'
          id='total'
          value={registroGasto.total}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                S/
              </InputAdornment>
            ),
          }}
        />
      </Grid>
    </Grid >

  )
}
export default OtrosGastos