import React, { useEffect } from 'react';
import { useMemo } from 'react';
import { Line } from "react-chartjs-2";
import {
    Chart as Chartjs,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    Filler
} from "chart.js"
import Constantes from '../../Constantes';
// const valuesin = [21000, 11940, 17620,21740,13900,12805,21850,14050,17280,8899,0,200];
const labels = ["ENE", "FEB", "MAR", "ABR", "MAY", "JUN", "JUL", "AGO", "SEP", "OCT", "NOV", "DIC"];

Chartjs.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    Filler
);

const options = {
    responsive: true,
}
function VentasPreview() {
    const [values, setValues] = React.useState([]);
    const anho = (new Date()).getFullYear();
    useEffect((e) => {
        const fillData = async () => {
            const valuesFetch = await fetch(`${Constantes.RUTA_API}reportes/resumenVentasMes.php?anho=${anho}`);
            const valuesJSON = await valuesFetch.json();
            var anterior = 0;
            var valuesTMP = [];
            valuesJSON.forEach(element => {
                let diferencia = element.mes - anterior;
                if (diferencia > 1) {
                    for (let i = 1; i < diferencia; i++) {
                        valuesTMP.push(0);
                        anterior += Number(1);
                    }
                }
                valuesTMP.push(Number(element.total));
                anterior = element.mes;
            });
            console.log(valuesTMP);
            console.log(valuesJSON);
            setValues(valuesTMP);
        }
        fillData();
    }, [anho]);
    const data = useMemo(function () {
        return {
            datasets: [
                {
                    label: `Ventas por mes ${anho}`,
                    data: values,
                },
            ],
            labels: labels,
        }
    }, [anho, values]);
    return <Line data={data} options={options} />
}
export default VentasPreview