import React from 'react';
import { Alert, Collapse, IconButton, Link, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Autocomplete, Button, Container, FormControl, FormControlLabel, Grid, InputLabel, MenuItem, Paper, Select, Switch, TextField, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions } from "@mui/material";
import { useParams } from 'react-router-dom';
import Constantes from '../Constantes';
import AddCircleOutlineRoundedIcon from '@mui/icons-material/AddCircleOutlineRounded';
import DeleteIcon from '@mui/icons-material/Delete';
import CloseIcon from '@mui/icons-material/Close';
import SaveIcon from '@mui/icons-material/Save';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import HomeIcon from '@mui/icons-material/Home';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import CancelIcon from '@mui/icons-material/Cancel';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { Close } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';

class EditarContacto extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            contacto: {
                codigo: "CON-00000000",
                apellidos_nombre: "",
                telefono_1: "",
                fk_com_referencia: "",
                fecha_registro: "",
                fecha_coordinacion: "",
                fecha_consulta: "",
                fk_log_usuario_creado_por: "",
                estado_seguimiento: "REGISTRADO",
                observaciones: "",
            },
            accion: props.accion,
            referencias: [],
            tratamientos_interes: [{
                codigo: "N",
                descripcion: "",
                fk_ven_familia_especifica: "",
                nombre: " "
            },],
            tratamientos: [],
            contacto_interes: [],
            tratamiento_seleccionado: {
                codigo: null, nombre: "", descripcion: "", fk_ven_familia_especifica: ""
            },
            messageToast: "",
            toastSeverity: "success",
            //severity="error", "warning", "info", "success
            showToast: false,
            nombreContacto: {
                error: false,
                helpertext: ''
            },
            loadingContacto: false
        };
        this.manejarCambio = this.manejarCambio.bind(this);
        this.manejarEnvioDeFormulario = this.manejarEnvioDeFormulario.bind(this);
        this.handleChangeReferencia = this.handleChangeReferencia.bind(this);
        this.handleChangeTratamientoInteres = this.handleChangeTratamientoInteres.bind(this);
        this.agregarInteres = this.agregarInteres.bind(this);
    };

    async componentDidMount() {
        const logedUserJSON = window.sessionStorage.getItem('logedAppUser');
        const userData = JSON.parse(logedUserJSON);
        const codigoContacto = this.props.codigoContacto;
        const refRespuesta = await fetch(`${Constantes.RUTA_API}sistema/listarReferenciaLlegada.php`);
        const referenciasdb = await refRespuesta.json();
        const tratamientos = await fetch(`${Constantes.RUTA_API}listar/listarTratamientosEmpresa.php?`);
        const tratamientosDB = await tratamientos.json();
        this.setState({
            referencias: referenciasdb,
            tratamientos_interes: tratamientosDB,
        });
        if (this.props.codigoContacto !== undefined) {
            const respuesta = await fetch(`${Constantes.RUTA_API}recuperar/obtenerContacto.php?codigo=${this.props.codigoContacto}`);
            const contacto = await respuesta.json();
            contacto["fecha_registro"] = contacto.fecha_registro === null ? "" : contacto.fecha_registro;
            contacto["fecha_coordinacion"] = contacto.fecha_coordinacion === null ? "" : contacto.fecha_coordinacion;
            contacto["fecha_consulta"] = contacto.fecha_consulta === null ? "" : contacto.fecha_consulta;
            const rptaTratamientosFetch = await fetch(`${Constantes.RUTA_API}listar/listarinteresesContacto.php?codigo=${this.props.codigoContacto}`);
            const rptaTratamientosJS = await rptaTratamientosFetch.json();
            this.setState({
                contacto: contacto,
                tratamientos: rptaTratamientosJS,
            });
        }
        if (this.state.accion === "NUEVO") {
            const hoy = new Date();
            const contactoActualizado = this.state.contacto;
            contactoActualizado["fecha_registro"] = `${hoy.getFullYear()}-${(hoy.getMonth() + 1).toString().padStart(2, '0')}-${hoy.getDate().toString().padStart(2, '0')}`;
            contactoActualizado["fk_log_usuario_creado_por"] = userData.usuario;
            this.setState({
                contacto: contactoActualizado,
            })
        }
    }

    async eliminarInteres(dato) {
        const newTratamientosInteres = []
        var linea = 1;
        this.state.tratamientos.forEach((item) => {
            if (item.linea !== dato.linea) {
                newTratamientosInteres.push({
                    fk_ven_contacto: item.fk_ven_contacto,
                    linea: linea,
                    nombre_tratamiento: item.nombre_tratamiento,
                    fk_ven_tratamiento: item.fk_ven_tratamiento,
                })
                linea = linea + 1
            }
        })
        this.setState({
            tratamientos: newTratamientosInteres,
        },)
    }

    agregarInteres() {
        const newTratamiento = this.state.tratamientos;
        if (this.state.tratamiento_seleccionado.codigo !== null) {
            if (newTratamiento.filter(inte => inte.fk_ven_tratamiento === this.state.tratamiento_seleccionado.codigo).length === 0) {
                newTratamiento.push({
                    fk_ven_contacto: this.state.contacto.codigo,
                    linea: newTratamiento.length + 1,
                    nombre_tratamiento: this.state.tratamiento_seleccionado.nombre,
                    fk_ven_tratamiento: this.state.tratamiento_seleccionado.codigo,
                })
                this.setState({
                    tratamientos: newTratamiento,
                    toastSeverity: "success",
                    messageToast: "Tratamiento agregado correctamente.",
                    showToast: true,
                })
            } else {
                this.setState({
                    toastSeverity: "warning",
                    messageToast: "Este tratamiento ya existe.",
                    showToast: true,
                })
                setTimeout(() => {
                    this.setState({
                        toastSeverity: "success",
                        messageToast: "",
                        showToast: false,
                    })
                }, 2000);
            }
        } else {
            this.setState({
                toastSeverity: "warning",
                messageToast: "Debe selecionar un tratamiento",
                showToast: true,
            })
        }
    }
    handleChangeTratamientoInteres(event, values) {
        if (values !== null) {
            this.setState({
                tratamiento_seleccionado: values,
            })
        } else {
            this.setState({
                tratamiento_seleccionado: { codigo: null, nombre: "", descripcion: "", fk_ven_familia_especifica: "" },
            })
        }
    }
    async manejarEnvioDeFormulario() {
        this.setState({ loadingContacto: true });
        const contacto = this.state.contacto;
        const cargaUtil = JSON.stringify({
            codigo: contacto.codigo,
            apellidos_nombre: contacto.apellidos_nombre.toUpperCase(),
            telefono_1: contacto.telefono_1,
            fk_com_referencia: contacto.fk_com_referencia,
            fecha_registro: contacto.fecha_registro,
            fecha_coordinacion: contacto.fecha_coordinacion,
            fecha_consulta: contacto.fecha_consulta,
            fk_log_usuario_creado_por: contacto.fk_log_usuario_creado_por,
            estado_seguimiento: "REGISTRADO",
            observaciones: contacto.observaciones !== null ? contacto.observaciones.toUpperCase() : '',
        });
        if (this.state.contacto.apellidos_nombre !== "") {
            const respuesta = await fetch(`${Constantes.RUTA_API}mantenimiento/guardarContacto.php`, {
                method: "POST",
                body: cargaUtil,
            });
            const resultado = await respuesta.json();
            if (resultado.guardado) {
                if (this.props.codigoContacto != undefined) {
                    const limpiarInteres = await fetch(`${Constantes.RUTA_API}mantenimiento/eliminarInteresesContacto.php?codigo=${this.props.codigoContacto}`)
                    const limpiarInteresjs = await limpiarInteres.json();
                    if (limpiarInteresjs) {
                        this.state.tratamientos.forEach(async (item, index) => {
                            item['linea'] = index + 1;
                            item['fk_ven_contacto'] = resultado.codigo;
                            const interesGuardado = await fetch(`${Constantes.RUTA_API}mantenimiento/guardarInteresContacto.php`, {
                                method: "POST",
                                body: JSON.stringify(item),
                            });
                        })
                    }
                }
                if (this.props.accion === "NUEVO") {
                    this.state.tratamientos.forEach(async (item, index) => {
                        item['linea'] = index + 1;
                        item['fk_ven_contacto'] = resultado.codigo;
                        const interesGuardado = await fetch(`${Constantes.RUTA_API}mantenimiento/guardarInteresContacto.php`, {
                            method: "POST",
                            body: JSON.stringify(item),
                        });
                    })
                }
                this.setState({
                    loadingContacto: false,
                    showToast: true,
                    messageToast: "Contacto guardado con exito.",
                    toastSeverity: "success",
                });
                if (this.props.listarContactos !== undefined) {
                    this.props.listarContactos();
                }
                setTimeout(() => {
                    this.props.showContacto();
                }, 3000);
            }
        } else {
            this.setState({ nombreContacto: { error: true, helpertext: 'Nombre contacto obligatorio' } });
        }
    }
    manejarCambio(evento) {
        const clave = evento.target.id;
        let valor = evento.target.value;
        if (clave === "apellidos_nombre") {
            if (valor !== "") {
                this.setState({ nombreContacto: { error: false, helpertext: '' } });
            } else {
                this.setState({ nombreContacto: { error: true, helpertext: 'Nombre contacto obligatorio' } });
            }
        }
        this.setState(state => {
            const contactoActualizado = state.contacto;
            if (clave === "fecha_coordinacion") {
                contactoActualizado["estado_seguimiento"] = valor === "" ? "REGISTRADO" : "CONTACTADO";
            }
            if (clave === "fecha_consulta") {
                contactoActualizado["estado_seguimiento"] = valor === "" ? "REGISTRADO" : "CONSULTA";
            }
            if (clave === "observaciones" && (evento.target.value.toString().match(/\n/g) || []).length <= 0) {
                contactoActualizado["observaciones"] = valor;
            }
            else {
                contactoActualizado[clave] = valor.toUpperCase();
            }
            return {
                contacto: contactoActualizado,
            }
        });
    }
    handleChangeReferencia(evento) {
        let valor = evento.target.value;
        this.setState(state => {
            const contactoActualizado = state.contacto;
            contactoActualizado["fk_com_referencia"] = valor;
            return {
                contacto: contactoActualizado,
            }
        })
    }
    // async handleChangeEmpressaInteres(evento) {
    //     let valor = evento.target.value;
    //     const tratamientos = await fetch(`${Constantes.RUTA_API}listar/listarTratamientosEmpressa.php?codigo=${valor}`);
    //     const tratamientosDB = await tratamientos.json();
    //     this.setState({
    //         empressa_interes: valor,
    //         tratamientos_interes: tratamientosDB.length > 0 ? tratamientosDB : [{
    //             codigo: "N",
    //             descripcion: "",
    //             fk_ven_familia_especifica: "",
    //             nombre: ""
    //         },],
    //         tratamiento_seleccionado: tratamientosDB.length > 0 ? tratamientosDB[0] : {
    //             codigo: "N",
    //             descripcion: "",
    //             fk_ven_familia_especifica: "",
    //             nombre: ""
    //         },
    //     });
    // }
    render() {
        return (
            <>
                <Dialog open={this.state.showToast}>
                    <DialogTitle style={{ textAlign: 'center' }}>
                        {this.state.toastSeverity === "success" ?
                            <CheckCircleOutlineIcon />
                            :
                            <ErrorOutlineIcon />
                        }
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            {this.state.messageToast}
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions sx={{ justifyContent: 'center' }}>
                        <Button onClick={() => { this.setState({ showToast: false }) }} startIcon={<CheckCircleIcon />} variant='outlined' color='success'>Aceptar</Button>
                        <Button onClick={() => { this.setState({ showToast: false }) }} startIcon={<CancelIcon />} variant='outlined' color='error'>Cancelar</Button>
                    </DialogActions>
                </Dialog>
                <Grid container spacing={2}>
                    <Grid item md={4} xs={12}>
                        <TextField
                            id='codigo'
                            label="Codigo:"
                            placeholder='CON-00000000'
                            variant='filled'
                            style={{ textTransform: "uppercase" }}
                            value={this.state.contacto.codigo}
                            fullWidth
                        />
                    </Grid>
                    <Grid item md={8} xs={12}>
                        <TextField
                            id='apellidos_nombre'
                            label="Nombres y Apellidos:"
                            placeholder='Nombre y Apellido'
                            variant='filled'
                            onChange={this.manejarCambio}
                            value={this.state.contacto.apellidos_nombre}
                            fullWidth
                            error={this.state.nombreContacto.error}
                            helperText={this.state.nombreContacto.helpertext}
                        />
                    </Grid>
                    <Grid item md={6} xs={12}>
                        <TextField
                            id="telefono_1"
                            fullWidth
                            label="Numero telefono:"
                            type="text"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            value={this.state.contacto.telefono_1}
                            onChange={this.manejarCambio}
                        />
                    </Grid>
                    <Grid item md={6} xs={12}>
                        <FormControl fullWidth>
                            <InputLabel>Referencia Llegada</InputLabel>
                            <Select
                                labelId="fk_com_referencia"
                                id="fk_com_referencia"
                                value={this.state.contacto.fk_com_referencia}
                                label="Referencia Llegada:"
                                onChange={this.handleChangeReferencia}
                            >
                                {this.state.referencias.map(referencia => {
                                    return (<MenuItem key={referencia.codigo} value={referencia.codigo}>{referencia.descripcion}</MenuItem>)
                                })}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item md={4} xs={12}>
                        <TextField
                            id="fecha_registro"
                            label="Fecha Registro:"
                            type="date"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            value={this.state.contacto.fecha_registro}
                            fullWidth
                        />
                    </Grid>
                    <Grid item md={4} xs={12}>
                        <TextField
                            id="fecha_coordinacion"
                            label="Fecha Coordinacion:"
                            type="date"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            value={this.state.contacto.fecha_coordinacion}
                            onChange={this.manejarCambio}
                            fullWidth
                        />
                    </Grid>
                    <Grid item md={4} xs={12}>
                        <TextField
                            id="fecha_consulta"
                            label="Fecha Consulta:"
                            type="date"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            value={this.state.contacto.fecha_consulta}
                            onChange={this.manejarCambio}
                            fullWidth
                        // sx={{ maxWidth: "160px" }}
                        />
                    </Grid>
                    {/* <Grid item xs={2}></Grid> */}
                    <Grid item xs={12}>
                        <TextField fullWidth
                            id='estado_seguimiento'
                            label="Estado de Seguimiento:"
                            placeholder='REGISTRADO'
                            variant='filled'
                            value={this.state.contacto.estado_seguimiento}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            id="observaciones"
                            label="OBSERVACIONES:"
                            multiline
                            value={this.state.contacto.observaciones !== null ? this.state.contacto.observaciones : ''}
                            onChange={this.manejarCambio}
                            variant="filled"
                            fullWidth
                            maxRows={5}
                            inputProps={{ style: { textTransform: 'uppercase' } }}
                        />
                    </Grid>
                </Grid>
                <h3>INTERESES</h3>
                <Grid container spacing={1}>
                    <Grid item md={11} xs={12}>
                        <Autocomplete
                            onChange={this.handleChangeTratamientoInteres}
                            options={this.state.tratamientos_interes}
                            getOptionLabel={(option) => option.nombre || ''}
                            id="tratamiento"
                            clearOnEscape
                            value={this.state.tratamiento_seleccionado}
                            isOptionEqualToValue={(option, value) => option.codigo === value.codigo}
                            renderOption={(props, option) => {
                                return (
                                    <li {...props} key={option.codigo}>
                                        {option.nombre}
                                    </li>
                                );
                            }}
                            renderInput={(params) => (
                                <TextField {...params} label="Tratamiento Interés: " variant="standard" />
                            )}
                            fullWidth
                        />
                    </Grid>
                    <Grid item md={1} xs={12} sx={{ marginTop: { md: '10px', xs: '0px' }, textAlign: 'center' }}>
                        <Button color="primary" variant='contained' onClick={this.agregarInteres}><AddCircleOutlineRoundedIcon /></Button>
                    </Grid>
                    <Grid item md={12} xs={12}>
                        <TableContainer>
                            <Table>
                                <TableHead sx={{ backgroundColor: "#b9d8ee" }} >
                                    <TableRow>
                                        <TableCell>#</TableCell>
                                        <TableCell>TRATAMIENTO</TableCell>
                                        <TableCell sx={{ width: "60px" }}>Elim.</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {this.state.tratamientos.map(tratamiento => {
                                        return (
                                            <TableRow key={tratamiento.fk_ven_tratamiento}>
                                                <TableCell> {tratamiento.linea} </TableCell>
                                                <TableCell> {tratamiento.nombre_tratamiento}</TableCell>
                                                <TableCell>
                                                    <IconButton onClick={() => this.eliminarInteres(tratamiento)} color="error"><DeleteIcon /></IconButton>
                                                </TableCell>
                                            </TableRow>
                                        )
                                    })}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Grid>
                </Grid>
                <Grid container spacing={2} sx={{ marginTop: "5px", justifyContent: 'center' }}>
                    <Grid item md={3} xs={6}>
                        <LoadingButton loading={this.state.loadingContacto} fullWidth onClick={this.manejarEnvioDeFormulario} variant="outlined" color="success" startIcon={<SaveIcon />}>
                            {this.props.codigoContacto !== undefined ? 'ACTUALIZAR' : 'GUARDAR'}
                        </LoadingButton>
                    </Grid>
                    <Grid item md={3} xs={6}>
                        <Button fullWidth onClick={this.props.showContacto} variant="outlined" color="error" startIcon={<Close />}>
                            CANCELAR
                        </Button>
                    </Grid>
                </Grid>
            </>
        );
    }
}
export default (props) => (<EditarContacto {...props} params={useParams()} />);