import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, IconButton, InputAdornment, TextField, Typography } from '@mui/material';
import React from 'react'
import { Check, CheckCircleOutline, Close, ErrorOutline, HighlightAltOutlined, HighlightOffOutlined, Visibility, VisibilityOff } from '@mui/icons-material';
import Constantes from '../../../Constantes';
export default function CambiarContraseña(props) {
    const [dialogAccion, setDialogAccion] = React.useState({
        show: false,
        message: '',
        severity: 'error',
        title: ''
    });
    const codigoUsuarioSelect = props.codigoUsuarioSelect;
    const setAlertGeneral = props.setAlertGeneral;
    const setChangeClave = props.setChangeClave;
    const [claveInput, setClaveInput] = React.useState({
        type: 'password',
        show: true,
        clave: '',
        claveConfirm: ''
    });

    const cambiarClaveUsuario = async () => {
        if (claveInput.clave === "") {
            setDialogAccion({ show: true, message: 'la contraseña obligatorio', severity: 'error', title: 'Campos vacios' });
        }
        if (claveInput.claveConfirm === "") {
            setDialogAccion({ show: true, message: 'la contraseña obligatorio', severity: 'error', title: 'Campos vacios' });
        }
        if (claveInput.clave !== "" && claveInput.claveConfirm !== "") {
            setDialogAccion({ show: true, message: '¿estas seguro de realizar esta accion?', severity: 'success', title: 'Cambiar contraseña' });
        }
    }
    const cambiarContraseña = async () => {
        const claveUsuario = await fetch(`${Constantes.RUTA_API}mantenimiento/cambiarClaveUsuario.php?`, {
            method: 'POST',
            body: JSON.stringify({ codigo: codigoUsuarioSelect, clave: claveInput.clave })
        });
        const response = await claveUsuario.json();
        if (response) {
            setDialogAccion({ show: true, message: 'contraseña correctamente actualizada', severity: 'success', title: 'Accion Completada' });
        } else {
            setDialogAccion({ show: true, message: 'no se puedo cambiar la contraseña', severity: 'error', title: 'Error' });
        }
        props.setChangeClave();

    }
    const handleClickShowPassword = () => {
        if (claveInput.type === "password") {
            setClaveInput({ ...claveInput, show: !claveInput.show, type: 'text' });
        } else {
            setClaveInput({ ...claveInput, show: !claveInput.show, type: 'password' });
        }
    }
    return (
        <Grid container>
            <Dialog open={dialogAccion.show}>
                <DialogTitle sx={{ textAlign: 'center' }}>
                    {
                        dialogAccion.severity === "success" ? <CheckCircleOutline sx={{ fontSize: '50px' }} color="success" />
                            :
                            <ErrorOutline sx={{ fontSize: '50px' }} color="error" />
                    }
                </DialogTitle>
                <DialogContent sx={{ textAlign: 'center' }}>
                    <Typography variant='h5'>{dialogAccion.title}</Typography>
                    <Typography>{dialogAccion.message}</Typography>
                </DialogContent>
                <DialogActions sx={{ justifyContent: 'center' }}>
                    {
                        dialogAccion.severity === "success" &&
                        <Button onClick={() => cambiarContraseña()} variant="outlined" color="success" startIcon={<CheckCircleOutline />}>Aceptar</Button>
                    }
                    <Button onClick={() => setDialogAccion({ show: false, message: '', title: '', severity: 'warning' })} variant="outlined" color="error" startIcon={<HighlightOffOutlined />}>Cancelar</Button>
                </DialogActions>
            </Dialog>
            <Grid item md={12} xs={12}>
                <TextField
                    fullWidth
                    error={claveInput.clave !== claveInput.claveConfirm}
                    helperText={claveInput.clave !== claveInput.claveConfirm ? 'Las claves no coinciden' : ''}
                    margin='normal'
                    label="CLAVE"
                    placeholder='Clave'
                    variant='filled'
                    type={claveInput.type}
                    onChange={(e) => {
                        const { value } = e.target;
                        setClaveInput({ ...claveInput, clave: value });
                    }}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <IconButton
                                    onClick={handleClickShowPassword}
                                    edge="end"
                                >
                                    {claveInput.show ? <VisibilityOff /> : <Visibility />}
                                </IconButton>
                            </InputAdornment>
                        )
                    }}
                />
            </Grid>
            <Grid item md={12} xs={12}>
                <TextField
                    fullWidth
                    margin='normal'
                    error={claveInput.clave !== claveInput.claveConfirm}
                    helperText={claveInput.clave !== claveInput.claveConfirm ? 'Las claves no coinciden' : ''}
                    label="CONFIRMAR CLAVE"
                    placeholder='Confirmar clave'
                    variant='filled'
                    type={claveInput.type}
                    onChange={(e) => {
                        const { value } = e.target;
                        setClaveInput({ ...claveInput, claveConfirm: value });
                    }}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <IconButton
                                    onClick={handleClickShowPassword}
                                    edge="end"
                                >
                                    {claveInput.show ? <VisibilityOff /> : <Visibility />}
                                </IconButton>
                            </InputAdornment>
                        )
                    }}
                />
            </Grid>
            <Grid item md={12} xs={12} style={{ textAlign: 'center' }}>
                <Button startIcon={<Check />} variant="outlined" color="success" onClick={cambiarClaveUsuario}>Aceptar</Button>
                <Button style={{ marginLeft: '6px' }} startIcon={<Close />} variant="outlined" color="error" onClick={() => setChangeClave()}>Cancelar</Button>
            </Grid>
        </Grid >
    )
}
