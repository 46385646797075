import * as React from 'react';
import {
    Box, Button, Container, Grid, Paper, Table, TableBody, TableCell,
    TableContainer, TableHead, TableRow,
    TableSortLabel, TextField, Typography, Link as LinkM, Dialog, DialogContent, DialogTitle, DialogActions, Snackbar, Alert, TablePagination, IconButton
} from '@mui/material';

import Constantes from '../../Constantes';

import LocalHospitalOutlinedIcon from '@mui/icons-material/LocalHospitalOutlined';
import FilaListarPerfil from './filaListarPerfil';
import SavePerfil from './savePerfil';
//icons
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import { Check, Close } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';

export default function Perfiles() {
    const navigate = useNavigate();
    const [perfiles, setPerfiles] = React.useState([]);
    const [search, setSearch] = React.useState("");
    // const [rowsPerPage, setRowsPerPage] = React.useState(10);
    // const [currentPage, setCurrentPage] = React.useState(0);
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [order, setOrder] = React.useState("");
    const [orderBy, setOrderBy] = React.useState("");
    const [CodigoPerfilSelect, setCodigoPerfilSelect] = React.useState('');
    // alert
    const [alertGeneral, setAlertGeneral] = React.useState({
        show: false,
        message: '',
        severity: 'warning'
    });
    // Dialog 
    const [newPerfil, setNewPerfil] = React.useState(false);
    const [editPerfil, setEditPerfil] = React.useState(false);
    const [deletePerfil, setDeletePerfil] = React.useState(false);

    const headCells = [
        {
            id: 'codigo',
            numeric: false,
            disablePadding: true,
            label: 'CODIGO',
        },
        {
            id: 'nombre',
            numeric: false,
            disablePadding: true,
            label: 'NOMBRE',
        },
        {
            id: 'descripcion',
            numeric: false,
            disablePadding: true,
            label: 'DESCRIPCION',
        },
    ];
    // handles
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };
    const handleClickEdit = (codigo) => {
        setEditPerfil(true);
        setCodigoPerfilSelect(codigo);
    }
    const handleClickDel = (codigo) => {
        setDeletePerfil(true);
        setCodigoPerfilSelect(codigo);
    }
    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };
    // functions
    const listarPerfiles = async () => {
        const getPerfiles = await fetch(`${Constantes.RUTA_API}listar/listarPerfil.php`);
        const response = await getPerfiles.json();
        setPerfiles(response);
    }
    const filteredPerfiles = () => {
        if (search === "") {
            return perfiles;
        } else {
            let filtro = perfiles.filter(f => f.nombre.includes(search.toUpperCase()));
            return filtro;
        }
    }
    const createSortHandler = (property) => (event) => {
        handleRequestSort(event, property);
    };
    const deletePerfilCodigo = async () => {
        const deleteDetalle = await fetch(`${Constantes.RUTA_API}mantenimiento/eliminarTodoPerfil.php?codigo=${CodigoPerfilSelect}`);
        const deletePerfil = await fetch(`${Constantes.RUTA_API}mantenimiento/eliminarPerfil.php?codigo=${CodigoPerfilSelect}`);
        const response = await deletePerfil.json();
        setDeletePerfil(false);
        if (response.executed) {
            setAlertGeneral({ message: 'Perfil eliminado correctamente', severity: 'success', show: true });
            setTimeout(() => {
                setAlertGeneral({ message: '', severity: 'warning', show: false });
            }, 3000);
            listarPerfiles();
        } else {
            setAlertGeneral({ message: 'Ocurrio un error', severity: 'error', show: true });
            setTimeout(() => {
                setAlertGeneral({ message: '', severity: 'warning', show: false });
            }, 3000);
        }
    }
    React.useEffect(() => {
        listarPerfiles();
    }, []);
    return (
        <Container sx={{ marginTop: "70px", paddingBottom: "10px" }}>
            <Snackbar open={alertGeneral.show} onClose={() => setAlertGeneral({ show: false, message: '', severity: 'warning' })} anchorOrigin={{ vertical: 'top', horizontal: 'right' }}>
                <Alert onClose={() => setAlertGeneral({ show: false, message: '', severity: 'warning' })} severity={alertGeneral.severity} sx={{ width: '100%' }}>
                    {alertGeneral.message}
                </Alert>
            </Snackbar>
            <Grid container>
                <Grid item md={12}>
                    <Paper elevation={1} sx={{ p: 1 }}>
                        <Grid container>
                            <Grid item md={2} xs={12}>
                                <Typography variant='h5'>PERFILES</Typography>
                            </Grid>
                            <Grid item md={8} xs={8}>
                                <TextField
                                    fullWidth
                                    size="small"
                                    type="text"
                                    placeholder="BUSCAR POR CODIGO"
                                    value={search}
                                    inputProps={{ style: { textTransform: "uppercase" } }}
                                    onChange={(e) => {
                                        const { value } = e.target;
                                        if (value !== "") {
                                            setSearch(value);
                                        } else {
                                            setSearch('');
                                        }
                                    }}
                                />
                            </Grid>
                            {/* <Grid item md={2}></Grid> */}
                            <Grid item md={2} xs={4}>
                                <Button style={{ marginLeft: '10px' }} onClick={() => setNewPerfil(true)} startIcon={<LocalHospitalOutlinedIcon />} fullWidth variant='contained' color="success">NUEVO PERFIL</Button>
                            </Grid>
                            <Grid item md={12} xs={12}>
                                <Paper sx={{ width: '100%', mb: 2, mt: 2 }}>
                                    <TableContainer style={{ height: '65vh' }}>
                                        <Table stickyHeader>
                                            <TableHead>
                                                <TableRow>
                                                    {headCells.map((headCell) => (
                                                        <TableCell
                                                            key={headCell.id}
                                                            style={{ fontWeight: 'bold' }}
                                                            sortDirection={orderBy === headCell.id ? order : false}
                                                        >
                                                            <TableSortLabel
                                                                active={orderBy === headCell.id}
                                                                direction={orderBy === headCell.id ? order : 'asc'}
                                                                onClick={createSortHandler(headCell.id)}
                                                            >
                                                                {headCell.label}
                                                                {orderBy === headCell.id ? (
                                                                    <Box component="span" >
                                                                        {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                                                    </Box>
                                                                ) : null}
                                                            </TableSortLabel>
                                                        </TableCell>
                                                    ))}
                                                    <TableCell align='center' padding='none' sx={{ minWidth: "50px" }}></TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {filteredPerfiles().slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map(perfil => {
                                                    return <FilaListarPerfil key={perfil.codigo} perfil={perfil} handleClickEdit={handleClickEdit} handleClickDel={handleClickDel}></FilaListarPerfil>;
                                                })}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                    <TablePagination
                                        rowsPerPageOptions={[10, 25, 50]}
                                        component="div"
                                        labelRowsPerPage="Filas por columnas"
                                        count={filteredPerfiles().length}
                                        rowsPerPage={rowsPerPage}
                                        page={page}
                                        onPageChange={handleChangePage}
                                        onRowsPerPageChange={handleChangeRowsPerPage}
                                    />
                                </Paper>
                            </Grid>
                        </Grid>
                    </Paper>
                </Grid>
            </Grid>
            <Dialog open={newPerfil} onClose={() => setNewPerfil(false)} fullWidth={true} maxWidth="md">
                <DialogTitle>
                    NUEVO PERFIL
                    <IconButton
                        aria-label="close"
                        onClick={() => setNewPerfil(false)}
                        sx={{
                            position: 'absolute',
                            right: 8,
                            top: 8,
                            color: (theme) => theme.palette.grey[500],
                        }}
                    >
                        <Close />
                    </IconButton>
                </DialogTitle>
                <DialogContent>
                    <SavePerfil
                        listarPerfiles={listarPerfiles}
                        dialogClose={() => setNewPerfil(false)}
                        setAlertGeneral={setAlertGeneral}
                    />
                </DialogContent>
            </Dialog>
            <Dialog open={editPerfil} onClose={() => setEditPerfil(false)} fullWidth={true} maxWidth="md">
                <DialogTitle>
                    EDITAR PERFIL
                    <IconButton
                        aria-label="close"
                        onClick={() => setEditPerfil(false)}
                        sx={{
                            position: 'absolute',
                            right: 8,
                            top: 8,
                            color: (theme) => theme.palette.grey[500],
                        }}
                    >
                        <Close />
                    </IconButton>
                </DialogTitle>
                <DialogContent>
                    <SavePerfil
                        listarPerfiles={listarPerfiles}
                        codigoPerfilSelect={CodigoPerfilSelect}
                        dialogClose={() => setEditPerfil(false)}
                        setAlertGeneral={setAlertGeneral}
                    />
                </DialogContent>
            </Dialog>
            <Dialog open={deletePerfil} onClose={() => setDeletePerfil(false)}>
                <DialogTitle style={{ textAlign: 'center' }}>
                    <div>
                        <HelpOutlineIcon style={{ color: '#fdb924', fontSize: '80px' }} />
                    </div>
                </DialogTitle>
                <DialogContent style={{ textAlign: 'center' }}>
                    <Typography variant="h5">Eliminar Perfil</Typography>
                    ¿Esta seguro de eliminar el perfil?
                </DialogContent>
                <DialogActions style={{ justifyContent: 'center' }}>
                    <Button startIcon={<Check />} variant="contained" color="error" onClick={deletePerfilCodigo}>Aceptar</Button>
                    <Button startIcon={<Close />} variant="contained" onClick={() => setDeletePerfil(false)}>Cancelar</Button>
                </DialogActions>
            </Dialog>
        </Container>
    )
}
